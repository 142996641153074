import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import XIcon from "@mui/icons-material/X";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SiFacebook, SiHatenabookmark, SiLine, SiWhatsapp } from "react-icons/si";
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  HatenaShareButton,
  LineShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useLoginModalContext } from "../../../context/LoginModalContext";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { loadUserLanguageFromCookie } from "../../../i18n/i18n";
import { apiRequest } from "../../../utils/api";

const ActionButton = ({ title: articleTitle }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const fileUuid = location.pathname.split("/")[4];
  const lang = loadUserLanguageFromCookie();
  const { isLoggedIn } = useLoginStatus();
  const loginContext = useLoginModalContext();
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const url = window.location.href;

  // いいね
  const handleLikeClick = async () => {
    if (!isLoggedIn) {
      loginContext.setActiveModal("login");
      return;
    }
    try {
      const url = `/api/v1/notes-nebula/like`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ uuid: fileUuid }),
        headers: headers,
      });
      const result = await response.json();
      setIsLiked(result.is_liked);
      setLikeCount(result.like_count);
    } catch (e) {
      console.error(e);
    }
  };

  // いいねの状態を取得
  const getLike = async () => {
    try {
      const url = `/api/v1/notes-nebula/like?uuid=${fileUuid}`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const result = await response.json();
      setIsLiked(result.is_liked);
      setLikeCount(result.like_count);
    } catch (e) {
      console.error(e);
    }
  };

  // ブックマーク
  const handleBookmarkClick = async () => {
    if (!isLoggedIn) {
      loginContext.setActiveModal("login");
      return;
    }
    try {
      const response = await apiRequest("/api/v1/notes-nebula/bookmark", "POST", { uuid: fileUuid });
      setIsBookmarked(response.data.is_bookmarked);
      setBookmarkCount(response.data.bookmark_count);
    } catch (e) {
      console.error(e);
    }
  };

  // ブックマークの状態を取得
  const getBookmark = async () => {
    try {
      const response = await apiRequest(`/api/v1/notes-nebula/bookmark?uuid=${fileUuid}`);
      setIsBookmarked(response.data.is_bookmarked);
      setBookmarkCount(response.data.bookmark_count);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getLike();
    getBookmark();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "row", md: "column" },
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        mb: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Tooltip title={t("notesNebula.article.like")} placement="left">
          <IconButton onClick={handleLikeClick}>
            {isLiked ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
          </IconButton>
        </Tooltip>
        <Typography variant="caption" color="text.secondary">
          {likeCount}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Tooltip title={t("notesNebula.article.bookmark")} placement="left">
          <IconButton onClick={handleBookmarkClick}>
            {isBookmarked ? <BookmarkIcon color="primary" /> : <BookmarkBorderIcon />}
          </IconButton>
        </Tooltip>
        <Typography variant="caption" color="text.secondary">
          {bookmarkCount}
        </Typography>
      </Box>
      {/*  Twitter  */}
      <Tooltip title={t("notesNebula.article.x")} placement="left">
        <div>
          <TwitterShareButton url={url} title={articleTitle} hashtags={["TenakAI", "NotesNebula"]}>
            <Typography sx={{ fontSize: "24px" }} color={"textSecondary"}>
              <XIcon />
            </Typography>
          </TwitterShareButton>
        </div>
      </Tooltip>
      {/*  Facebook  */}
      <Tooltip title={t("notesNebula.article.facebook")} placement="left">
        <div>
          <FacebookShareButton url={url}>
            <Typography sx={{ fontSize: "24px" }} color={"textSecondary"}>
              <SiFacebook />
            </Typography>
          </FacebookShareButton>
        </div>
      </Tooltip>

      {/* Whatsapp*/}
      <Tooltip title={t("notesNebula.article.whatsapp")} placement="left">
        <div>
          <WhatsappShareButton url={url} title={articleTitle}>
            <Typography sx={{ fontSize: "24px" }} color={"textSecondary"}>
              <SiWhatsapp />
            </Typography>
          </WhatsappShareButton>
        </div>
      </Tooltip>

      {/*  はてなブックマーク  */}
      {lang === "ja" && (
        <Tooltip title={t("notesNebula.article.hatena")} placement="left">
          <div>
            <HatenaShareButton url={url} title={articleTitle}>
              <Typography sx={{ fontSize: "24px" }} color={"textSecondary"}>
                <SiHatenabookmark />
              </Typography>
            </HatenaShareButton>
          </div>
        </Tooltip>
      )}

      {/*  LINE  */}
      {lang === "ja" && (
        <Tooltip title={t("notesNebula.article.line")} placement="left">
          <div>
            <LineShareButton url={url} title={articleTitle}>
              <Typography sx={{ fontSize: "24px" }} color={"textSecondary"}>
                <SiLine />
              </Typography>
            </LineShareButton>
          </div>
        </Tooltip>
      )}
    </Box>
  );
};

export default ActionButton;

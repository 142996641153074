import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import React from "react";
import Cookies from "js-cookie";

interface Props {
  uuid: string;
  onImage?: boolean;
  initialLiked?: boolean;
}

const Like = ({ uuid, onImage = true, initialLiked = false }: Props) => {
  const [isLiked, setIsLiked] = React.useState(initialLiked);

  // initialLikedが変更された場合、状態を更新
  React.useEffect(() => {
    setIsLiked(initialLiked);
  }, [initialLiked]);

  const handleLikeClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const url = `/api/v1/juno/like`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ uuid: uuid }),
        headers: headers,
      });
      const result = await response.json();
      setIsLiked(result.is_liked);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Tooltip title={"LIKE!"}>
      <IconButton onClick={handleLikeClick}>
        {isLiked ? (
          <FavoriteIcon color="error" fontSize="small" />
        ) : (
          <FavoriteIcon sx={{ color: onImage ? "common.white" : "textPrimary" }} fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
  );
};
export default Like;

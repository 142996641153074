import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CampaignIcon from "@mui/icons-material/Campaign";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import axios from "axios";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

interface NotificationProps {
  notificationKey: string;
  size?: "small" | "medium" | "large";
}

interface NotificationData {
  uuid: string;
  key: string;
  icon_type: "warning" | "info" | "error" | "success" | "campaign" | "announcement" | "new";
  color: "info" | "warning" | "error" | "success" | "primary" | "secondary";
  text_color:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "text.primary"
    | "text.secondary"
    | "text.disabled";
  content: string;
}

const NotificationDisplay: React.FC<NotificationProps> = ({ notificationKey, size = "medium" }) => {
  const [notification, setNotification] = useState<NotificationData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/api/v1/notifications/?key=${notificationKey}`);
        if (response.data && response.data.length > 0) {
          setNotification(response.data[0]);
        }
      } catch (err) {
        setError("通知の取得に失敗しました");
        console.error("通知の取得エラー:", err);
      } finally {
        setLoading(false);
      }
    };

    if (notificationKey) {
      fetchNotification();
    }
  }, [notificationKey]);

  if (loading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={size} />
      </Box>
    );
  if (error || !notification) return null;

  const getIcon = () => {
    switch (notification.icon_type) {
      case "info":
        return <InfoIcon fontSize={size} />;
      case "warning":
        return <WarningIcon fontSize={size} />;
      case "error":
        return <ErrorIcon fontSize={size} />;
      case "success":
        return <CheckCircleIcon fontSize={size} />;
      case "campaign":
        return <CampaignIcon fontSize={size} />;
      case "announcement":
        return <AnnouncementIcon fontSize={size} />;
      case "new":
        return <NewReleasesIcon fontSize={size} />;
      default:
        return <InfoIcon fontSize={size} />;
    }
  };

  // アイコンの色を取得
  const getIconColor = () => {
    switch (notification.color) {
      case "info":
        return "info.main";
      case "warning":
        return "warning.main";
      case "error":
        return "error.main";
      case "success":
        return "success.main";
      case "primary":
        return "primary.main";
      case "secondary":
        return "secondary.main";
      default:
        return "info.main";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box
        sx={{
          color: getIconColor(),
          display: "flex",
          alignItems: "center",
        }}
      >
        {getIcon()}
      </Box>
      <Typography
        variant={size === "small" ? "body2" : size === "large" ? "h6" : "body1"}
        color={
          notification.text_color !== "default"
            ? notification.text_color.includes(".")
              ? notification.text_color
              : notification.text_color + ".main"
            : undefined
        }
      >
        {notification.content}
      </Typography>
    </Box>
  );
};

export default NotificationDisplay;

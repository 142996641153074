import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Grow, IconButton, Modal, Pagination, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import ScrollableBox from "./ScrollableBox";

import { GradientTypography } from "../../utils/gradientTypography";

type ImageInfo = {
  uuid: string;
  request_uuid: string;
  url: string;
  params: string;
  created_at: string;
  is_r2?: boolean; // R2ストレージかどうか
  is_oversized?: boolean; // オーバーサイズ画像かどうか
  r2_image?: string; // 互換性のために残す
};

interface GeneratedImageModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setImage: (image: string) => void;
  useBlobUrl?: boolean; // デフォルトはtrue
}

const GeneratedImageModal: React.FC<GeneratedImageModalProps> = ({ open, setOpen, setImage, useBlobUrl = true }) => {
  const [images, setImages] = useState<ImageInfo[]>([]);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // 画像一覧取得
  const fetchImageList = async (pageNum: number) => {
    try {
      // r2_image=falseを削除し、exclude_oversized=trueを追加
      // これにより、R2に保存された画像も表示されるが、オーバーサイズの画像は除外される
      const url = `/api/v1/juno/gallery?page=${pageNum}&exclude_oversized=true&num=20`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const newImages = await response.json();
      setLoading(false);
      return newImages;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  // 初期画像一覧取得
  useEffect(() => {
    if (!open) return;
    const loadInitialImages = async () => {
      setLoading(true);
      const newImages = await fetchImageList(1); // 最初のページのみロード
      if (newImages && newImages.length > 0) {
        setImages(newImages);
        setPage(1); // 最初のページに設定
        setTotalPages(newImages[0].total_pages);
      }
      setLoading(false);
    };
    loadInitialImages();
  }, [open]);

  const imageCache: Record<string, string> = {};

  // 画像をキャッシュ
  const loadImage = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (imageCache[url]) {
        resolve(imageCache[url]);
      } else {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          imageCache[url] = img.src;
          resolve(img.src);
        };
        img.onerror = reject;
      }
    });
  };

  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});

  // 画像のURLをキャッシュ
  useEffect(() => {
    images.forEach(async (image) => {
      // R2の画像の場合はw300を追加しない
      const url = image.is_r2 ? image.url : `${image.url}w300`;
      try {
        const cachedUrl = await loadImage(url);
        setImageUrls((prevUrls) => ({ ...prevUrls, [image.uuid]: cachedUrl }));
      } catch (error) {
        console.error("Error loading image", error);
      }
    });
  }, [images]);

  const handleChangePage = async (newPage: number) => {
    setLoading(true);
    const newImages = await fetchImageList(newPage);
    if (newImages && newImages.length > 0) {
      setImages(newImages);
      setPage(newPage);
    }
    setLoading(false);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Paper
        sx={{
          position: "relative",
          width: "90%",
          maxWidth: "1200px",
          height: "90vh",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 3,
          bgcolor: theme.palette.background.paper + "D9",
          backdropFilter: "blur(5px)",
        }}
      >
        <IconButton sx={{ position: "absolute", top: 8, right: 8 }} size={"small"} onClick={() => setOpen(false)}>
          <CloseIcon fontSize={"small"} />
        </IconButton>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <GradientTypography variant="h6" component="h2">
            Generated Images
          </GradientTypography>
        </Box>

        <ScrollableBox sx={{ height: "calc(100% - 85px)" }}>
          <Grid container spacing={2} sx={{ p: 1 }}>
            {loading && (
              <Grid item xs={12}>
                <Typography variant="subtitle2">Loading...</Typography>
              </Grid>
            )}
            {!loading && images.length === 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2">No images found</Typography>
              </Grid>
            )}
            {!loading &&
              images.map((image) => (
                <Grid item xs={4} md={3} lg={2.4} key={image.uuid}>
                  <Grow in={true} timeout={1000}>
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "calc(100vh * 0.25 - 70px)",
                        overflow: "hidden",
                        transition: "all 0.3s ease",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.05)",
                          zIndex: 100,
                          outline: `solid 2px ${theme.palette.primary.main}`,
                        },
                        borderRadius: 2,
                      }}
                      onClick={async () => {
                        try {
                          if (useBlobUrl) {
                            // Blob URLを返す場合（デフォルト）
                            // APIを使用して画像を取得
                            const response = await axios.get(`/api/v1/juno/image/${image.uuid}`, {
                              responseType: "blob",
                            });
                            const blob = new Blob([response.data], { type: response.data.type });

                            // Blobから画像URLを作成
                            const imageUrl = URL.createObjectURL(blob);
                            setImage(imageUrl);
                          } else {
                            // 元のURLを返す場合（TextFileなど）
                            // R2の場合はそのまま、CloudflareImagesの場合はpublicを追加
                            const imageUrl = image.is_r2 ? image.url : `${image.url}public`;
                            setImage(imageUrl);
                          }
                          setOpen(false);
                        } catch (error) {
                          console.error("Error loading image:", error);
                        }
                      }}
                    >
                      <img
                        src={imageUrls[image.uuid] ?? (image.is_r2 ? image.url : `${image.url}w300`)}
                        alt=""
                        loading="lazy"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                  </Grow>
                </Grid>
              ))}
          </Grid>
        </ScrollableBox>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination count={totalPages} page={page} onChange={(event, newPage) => handleChangePage(newPage)} />
        </Box>
      </Paper>
    </Modal>
  );
};
export default GeneratedImageModal;

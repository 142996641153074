import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowCenteredBox } from "../../utils/styledBox";
import { Images } from "../../assets";

interface TenakLogoProps {
  onClick?: () => void;
}

const TenakLogoComponent: React.FC<TenakLogoProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <RowCenteredBox onClick={onClick} sx={{ cursor: onClick ? "pointer" : "default" }}>
      <img src={Images.tenak.small} style={{ width: 35, height: 35, marginRight: 8 }} alt="Tenak.ai" />
      <Box display={{ xs: "none", md: "block" }}>
        <Typography
          variant="h5"
          component="p"
          noWrap
          sx={{
            flexGrow: 1,
            fontFamily: "'Pacifico', cursive",
            fontSize: "1.8rem!important",
            p: 0.5,
          }}
        >
          {t("title2")}
        </Typography>
      </Box>
    </RowCenteredBox>
  );
};

TenakLogoComponent.displayName = "TenakLogo";

export const TenakLogo = React.memo(TenakLogoComponent);

import { alpha } from "@mui/material/styles";
import { motion } from "framer-motion";
import FadeInSection from "../../../../components/common/FadeInSection";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import Companies from "../components/Companies";

const CompaniesSection = () => {
  // アニメーションバリアント
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };

  const backgroundVariants = {
    initial: { backdropFilter: "blur(0px)" },
    animate: {
      backdropFilter: "blur(20px)",
      transition: {
        duration: 1.2,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
    >
      <motion.div
        variants={backgroundVariants}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
        style={{ width: "100%" }}
      >
        <ColumnCenteredBox
          sx={(theme) => ({
            backgroundColor: alpha(theme.palette.secondary.contrastText, 0.9),
            width: "100%",
          })}
        >
          <ColumnCenteredBox>
            <FadeInSection>
              <Companies />
            </FadeInSection>
          </ColumnCenteredBox>
        </ColumnCenteredBox>
      </motion.div>
    </motion.div>
  );
};

export default CompaniesSection;

import ArticleIcon from "@mui/icons-material/Article";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { Avatar, Fade, IconButton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useLoginModalContext } from "../../../context/LoginModalContext";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { apiRequest } from "../../../utils/api";
import { customLink, extractTextFromHTML, localDate } from "../../../utils/utils";

interface BookmarkArticle {
  uuid: string;
  title: string;
  content: string;
  user: string;
  avatar: string;
  thumbnail: string;
  created_at: string;
}

interface ArticleCardProps {
  article: BookmarkArticle;
  loadedImages: string[];
  index?: number;
}

const ArticleCard = ({ article, loadedImages, index = 0 }: ArticleCardProps) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useLoginStatus();
  const loginContext = useLoginModalContext();
  const [isBookmarked, setIsBookmarked] = useState(false);

  // ブックマーク
  const handleBookmarkClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isLoggedIn) {
      loginContext.setActiveModal("login");
      return;
    }
    try {
      const response = await apiRequest("/api/v1/notes-nebula/bookmark", "POST", { uuid: article.uuid });
      setIsBookmarked(response.data.is_bookmarked);
    } catch (e) {
      console.error(e);
    }
  };

  // ブックマークの状態を取得
  const getBookmark = async () => {
    try {
      const response = await apiRequest(`/api/v1/notes-nebula/bookmark?uuid=${article.uuid}`);
      setIsBookmarked(response.data.is_bookmarked);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getBookmark();
  }, []);

  const location = useLocation();
  const theme = useTheme();
  const lang = location.pathname.split("/")[1];

  const fadeDelay = Math.min(index * 500, 1500);

  return (
    <Fade in timeout={fadeDelay}>
      <Link href={customLink(`/notes-nebula/articles/${article["uuid"]}`)} underline={"none"}>
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            "&:hover > div": {
              backgroundColor: theme.palette.action.hover,
            },
            "> div": {
              transition: "background-color 0.3s",
            },
          }}
          elevation={1}
        >
          {/* サムネイル */}
          <Box sx={{ position: "relative" }}>
            {article["thumbnail"] ? (
              <>
                {loadedImages.includes(article["uuid"]) ? (
                  <CardMedia
                    image={`${article["thumbnail"]}`}
                    sx={{
                      height: 120,
                      background: theme.custom.gradient.main,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                ) : (
                  <Skeleton variant="rectangular" width={"100%"} height={120} />
                )}
              </>
            ) : (
              <Box
                sx={{
                  height: 120,
                  background: theme.custom.gradient.main,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <ArticleIcon sx={{ color: "white" }} />
                <Typography
                  variant={"h1"}
                  component={"p"}
                  sx={{
                    position: "absolute",
                    fontSize: "8rem!important;",
                    whiteSpace: "nowrap",
                    color: alpha(theme.palette.common.white, 0.03),
                  }}
                >
                  {article["title"]}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                position: "absolute",
                top: 4,
                right: 4,
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <Tooltip title={t("notesNebula.article.bookmark")}>
                <IconButton size="small" onClick={handleBookmarkClick} sx={{ p: 0.5 }}>
                  {isBookmarked ? (
                    <BookmarkIcon fontSize="small" sx={{ color: "primary.light" }} />
                  ) : (
                    <BookmarkBorderIcon fontSize="small" sx={{ color: "White" }} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", p: 1.5, pb: 1 }}>
            {/* タイトル */}
            <Typography
              variant="subtitle2"
              component="h5"
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                height: "2.8rem",
                mb: 1,
              }}
            >
              {article["title"]}
            </Typography>
            {/*説明*/}
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                height: "3.6rem",
                mb: 2,
              }}
            >
              {extractTextFromHTML(article["content"].replace(/#|\*|--|\|/g, ""))}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
              {article["avatar"] ? (
                <Avatar sx={{ width: 32, height: 32, mr: 1 }} src={article["avatar"]} />
              ) : (
                <Avatar color="primary" sx={{ width: 32, height: 32 }}>
                  {article["user"].slice(0, 2)}
                </Avatar>
              )}
              <Box flexGrow={1} />
              <Box sx={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                <Typography variant="caption" color="text.secondary">
                  @{article["user"]}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {localDate(article["created_at"], lang)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Link>
    </Fade>
  );
};
export default ArticleCard;

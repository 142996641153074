const message = {
  subscription: {
    success: "Thank you for subscribing! We hope you enjoy using Tenak AI.",
    cancel: "Subscription canceled. You can continue to use Tenak AI until the end of the current billing period.",
    error: "An error occurred during subscription. Please try again later or contact support.",
  },
  permanent_credit: {
    success: "Thank you for purchasing extra credits! The credits have been added to your account.",
    cancel: "Credit purchase canceled. No charges have been made to your account.",
    error: "An error occurred during credit purchase. Please try again later or contact support.",
  },
};
export default message;

import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import { Grid, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Meta from "../../../components/common/Meta";
import VipLabel from "../../../components/common/VipLabel";
import { Images } from "../../../assets";
import { GradientTypography } from "../../../utils/gradientTypography";
import InputPrompt from "./components/InputPrompt";
import LatestVideoList from "./components/LatestVideoList";
import ParamsManager from "./components/ParamsManager";
import QuickSelection from "./components/QuickSelection";
import SampleVideoList from "./components/SampleVideoList";
import SelectVideoModel from "./components/SelectVideoModel";
import NotificationDisplay from "../../../components/common/NotificationDisplay";
import { RowCenteredBox } from "../../../utils/styledBox";
import Fade from "@mui/material/Fade";
import { RowBox } from "../../../utils/styledBox";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { Typography } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`video-tabpanel-${index}`}
      aria-labelledby={`video-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const VideoGenerator = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${Images.common.bg01})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <Meta title={t("juno.video.title")} route={"Studio JUNO by Tenak AI"} />
      <Container
        maxWidth="xl"
        sx={{
          pb: 8,
        }}
      >
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <MovieFilterIcon sx={{ mr: 2 }} fontSize={"medium"} />
              <GradientTypography component="h1" variant="h5" align="center" mb={0.5}>
                {t("juno.video.title")}
              </GradientTypography>
              <VipLabel sx={{ ml: 2 }} />
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <QuickSelection />
          </Grid>
          <Grid item xs={12}>
            <Fade in={true} timeout={1000}>
              <RowCenteredBox sx={{ mb: 3, justifyContent: "center" }}>
                <NotificationDisplay notificationKey="juno-video" size="small" />
              </RowCenteredBox>
            </Fade>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} md={2.5}>
            <SelectVideoModel />
            <RowBox mb={4}>
              <SettingsApplicationsIcon color={"secondary"} sx={{ mr: 1 }} />
              <Typography variant="subtitle1">{t("juno.video.params.title")}</Typography>
            </RowBox>

            <ParamsManager />
          </Grid>
          <Grid item xs={12} md={9.5}>
            <InputPrompt />
            <Box sx={{ width: "100%", mb: 4 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="video list tabs">
                  <Tab
                    icon={<VideoLibraryIcon />}
                    iconPosition="start"
                    label="My Video"
                    id="video-tab-0"
                    aria-controls="video-tabpanel-0"
                  />
                  <Tab
                    icon={<SlideshowIcon />}
                    iconPosition="start"
                    label={t("juno.video.sampleVideo")}
                    id="video-tab-1"
                    aria-controls="video-tabpanel-1"
                  />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <LatestVideoList size={9} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <SampleVideoList />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default VideoGenerator;

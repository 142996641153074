import BoltIcon from "@mui/icons-material/Bolt";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import PhotoSizeSelectSmallIcon from "@mui/icons-material/PhotoSizeSelectSmall";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import {
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Fade,
  Grid,
  Paper,
  Popover,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiEngineList, MenuList, useVideo } from "../../../../context/juno/VideoContext";
import { useJunoModels } from "../../../../hooks/useJunoModels";
import { Images } from "../../../../assets";
import { RowBox, RowCenteredBox } from "../../../../utils/styledBox";
import NotificationDisplay from "../../../../components/common/NotificationDisplay";

export default function SelectVideoModel() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getVideoModels } = useJunoModels();
  const models = getVideoModels;

  const { menu, setMenu, aiEngine, setAiEngine } = useVideo();
  const [tempMenu, setTempMenu] = useState<MenuList>(menu);
  const [selectedEngine, setSelectedEngine] = useState<AiEngineList>(aiEngine);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [rotation, setRotation] = useState(0);
  const isMounted = useRef(false);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleMenuChange = (_: React.SyntheticEvent, newMenu: MenuList) => {
    setTempMenu(newMenu);
  };

  useEffect(() => {
    setSelectedEngine(aiEngine);
  }, [aiEngine]);

  useEffect(() => {
    if (menu !== tempMenu) {
      setTempMenu(menu);
    }
  }, [menu]);

  const handleAiEngineChange = (value: AiEngineList) => {
    // 先にselectedEngineを更新して、UIの表示を即座に反映
    setSelectedEngine(value);

    // ポップオーバーを閉じる
    handlePopoverClose();

    setMenu(tempMenu);
    setAiEngine(value);
  };

  useEffect(() => {
    if (isMounted.current) {
      setRotation((prev) => prev + 360);
    } else {
      isMounted.current = true;
    }
  }, [selectedEngine]);

  const getMenuIcon = () => {
    switch (menu) {
      case "t2v":
        return <TextFieldsIcon color="primary" />;
      case "i2v":
        return <ImageIcon color="primary" />;
      case "lipsync":
        return <RecordVoiceOverIcon color="primary" />;
      case "upscale":
        return <PhotoSizeSelectSmallIcon color="primary" />;
      case "v2v":
        return <MovieIcon color="primary" />;
      default:
        return null;
    }
  };

  const getMenuTitle = () => {
    switch (menu) {
      case "t2v":
        return t("juno.video.menu.t2v");
      case "i2v":
        return t("juno.video.menu.i2v");
      case "lipsync":
        return t("juno.video.menu.lipsync");
      case "upscale":
        return t("juno.video.menu.upscale");
      case "v2v":
        return t("juno.video.menu.v2v");
      default:
        return null;
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundImage: `url(${Images.common.bg01})`,
        backgroundSize: "600px",
        backgroundPositionX: -300,
        backgroundPositionY: -150,
        backgroundColor: "transparent",
        width: "100%",
        mb: 4,
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <ButtonBase onClick={handlePopoverOpen} sx={{ width: "100%", borderRadius: 1, overflow: "hidden" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              position: "relative",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              p: 2,
            }}
          >
            <RowBox sx={{ gap: 1 }}>
              {getMenuIcon()}
              <Typography variant="body2">{getMenuTitle()}</Typography>
            </RowBox>
            <RowBox sx={{ gap: 1 }}>
              <BoltIcon
                sx={{
                  color: "primary.main",
                  transform: `rotate(${rotation}deg)`,
                  transition: "transform 1s ease-in-out",
                }}
              />
              <Typography variant="body2" whiteSpace={"nowrap"}>
                {t(`juno.video.ai.${selectedEngine.toLowerCase()}.title`)}
              </Typography>
            </RowBox>
            <Box sx={{ width: "100%", height: 70, overflow: "hidden" }}>
              <Fade in={true} timeout={1000} key={selectedEngine}>
                <CardMedia
                  component="img"
                  image={
                    Images.company[selectedEngine.toLowerCase() as keyof typeof Images.company] ||
                    `${Images.common.bg01}`
                  }
                  alt={getMenuTitle() || ""}
                  sx={{
                    width: "100%",
                    height: 70,
                    objectFit: "cover",
                    transition: "opacity 0.5s",
                    borderRadius: 1,
                  }}
                />
              </Fade>
            </Box>
          </Box>
        </ButtonBase>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          elevation={4}
          sx={{
            mx: 1,
          }}
          slotProps={{
            paper: {
              sx: {
                backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.5),
                backdropFilter: "blur(15px)",
              },
              elevation: 0,
            },
          }}
        >
          <Box
            sx={{
              p: 2,
              width: { xs: "100vw", md: 900 },
            }}
          >
            <Tabs
              value={tempMenu}
              onChange={handleMenuChange}
              sx={{ mb: 2, width: "100%" }}
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab
                value="t2v"
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <TextFieldsIcon fontSize="small" />
                    {t("juno.video.menu.t2v")}
                  </Box>
                }
              />
              <Tab
                value="i2v"
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <ImageIcon fontSize="small" />
                    {t("juno.video.menu.i2v")}
                  </Box>
                }
              />
              <Tab
                value="lipsync"
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <RecordVoiceOverIcon fontSize="small" />
                    {t("juno.video.menu.lipsync")}
                  </Box>
                }
              />
              <Tab
                value="upscale"
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <PhotoSizeSelectSmallIcon fontSize="small" />
                    {t("juno.video.menu.upscale")}
                  </Box>
                }
              />
              {/* <Tab
                value="v2v"
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <MovieIcon fontSize="small" />
                    {t("juno.video.menu.v2v")}
                  </Box>
                }
                disabled
              /> */}
            </Tabs>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Fade in={true} timeout={1000}>
                  <RowCenteredBox sx={{ mb: 1, justifyContent: "center" }}>
                    <NotificationDisplay notificationKey="juno-video-model" size="small" />
                  </RowCenteredBox>
                </Fade>
              </Grid>

              {models
                .filter((model) => !model.disabled.includes(tempMenu))
                .map((model, index) => (
                  <Grid item xs={6} sm={4} md={3} key={index}>
                    <Fade in={true} timeout={200 * index > 1000 ? 1000 : 200 * index}>
                      <Card
                        sx={{
                          width: "100%",
                          border: selectedEngine === model.value && tempMenu === menu ? "2px solid" : "0px solid",
                          borderColor:
                            selectedEngine === model.value && tempMenu === menu
                              ? theme.palette.primary.main
                              : "transparent",
                          position: "relative",
                        }}
                        onClick={() => handleAiEngineChange(model.value as AiEngineList)}
                        elevation={0}
                      >
                        <CardActionArea>
                          <CardContent
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              textAlign: "center",
                              p: 0,
                              position: "relative",
                              img: {
                                transition: "transform 0.5s",
                              },
                              "&:hover img": {
                                transform: "scale(1.05)",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: 0.5,
                                p: 1,
                                width: "100%",
                              }}
                            >
                              <Typography variant="body2" fontWeight={"bold"} sx={{ textAlign: "left" }}>
                                {model.label}
                              </Typography>
                            </Box>
                            <CardMedia
                              component="img"
                              height="80"
                              image={
                                Images.company[model.value.toLowerCase() as keyof typeof Images.company] ||
                                `${Images.common.bg01}`
                              }
                              alt={model.label}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: 0.5,
                                p: 1,
                                width: "100%",
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  fontSize: "0.7rem",
                                  textAlign: "left",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 3,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "normal",
                                }}
                              >
                                {model.description}
                              </Typography>
                            </Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Fade>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Popover>
      </Box>
    </Paper>
  );
}

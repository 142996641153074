import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import MicIcon from "@mui/icons-material/Mic";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Button, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Cookies from "js-cookie";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GeneratedVoicesModal from "../../../../components/common/GeneratedVoicesModal";
import { useAlert } from "../../../../context/AlertContext";
import { webmToWav } from "../../../../utils/audioConverter";
import { ColumnBox, RowBox } from "../../../../utils/styledBox";
import { alpha } from "@mui/material/styles";

interface AudioUploadProps {
  audio: string;
  setAudio: (audio: string) => void;
  setAudioDuration?: (duration: number) => void;
}

const AudioUpload = ({ audio, setAudio, setAudioDuration }: AudioUploadProps) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const { setAlert } = useAlert();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [silenceTimeout, setSilenceTimeout] = useState<NodeJS.Timeout | null>(null);
  const [recordingDuration, setRecordingDuration] = useState<number>(0);
  const [localAudioDuration, setLocalAudioDuration] = useState<number>(0);
  const chunks = useRef<Blob[]>([]);
  const recordingTimer = useRef<NodeJS.Timer | null>(null);
  const currentDuration = useRef<number>(0);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
      setMediaRecorder(recorder);
      chunks.current = [];

      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };

      recorder.onstop = async () => {
        try {
          // WebMからWavに変換
          const webmBlob = new Blob(chunks.current, { type: "audio/webm" });
          const wavBlob = await webmToWav(webmBlob);

          // Base64に変換
          const base64Data = await new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target?.result as string);
            reader.readAsDataURL(wavBlob);
          });

          // 音声の長さを設定
          const finalDuration = currentDuration.current;
          setLocalAudioDuration(finalDuration);
          setAudioDuration?.(finalDuration);

          // 状態を更新
          setAudio(base64Data);
          setRecordingDuration(0);
          currentDuration.current = 0;

          // ストリームを停止
          stream.getTracks().forEach((track) => track.stop());
        } catch (error) {
          console.error("Failed to convert audio: ", error);
          setAlert("error", "Failed to convert audio");
        }
      };

      // 無音検出の設定
      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      source.connect(analyser);

      let silenceStartTime: number | null = null;
      const startTime = Date.now();

      // 録音時間の更新を開始
      setRecordingDuration(0);
      currentDuration.current = 0;
      recordingTimer.current = setInterval(() => {
        setRecordingDuration((prev) => {
          const newDuration = prev + 1;
          currentDuration.current = newDuration;
          return newDuration;
        });
      }, 1000);

      const checkSilence = () => {
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((a, b) => a + b) / dataArray.length;

        // 60sの制限チェック
        if (Date.now() - startTime >= 60000) {
          recorder.stop();
          setIsRecording(false);
          if (recordingTimer.current) {
            clearInterval(recordingTimer.current);
            recordingTimer.current = null;
          }
          return;
        }

        if (average < 2) {
          if (silenceStartTime === null) {
            silenceStartTime = Date.now();
          } else if (Date.now() - silenceStartTime >= 1500) {
            recorder.stop();
            setIsRecording(false);
            if (recordingTimer.current) {
              clearInterval(recordingTimer.current);
              recordingTimer.current = null;
            }
            return;
          }
        } else {
          silenceStartTime = null;
        }

        if (isRecording) {
          requestAnimationFrame(checkSilence);
        }
      };

      recorder.start();
      setIsRecording(true);
      checkSilence();
    } catch (err) {
      console.error("録音の開始に失敗しました:", err);
      setAlert("error", "録音の開始に失敗しました");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
      setIsRecording(false);
      if (silenceTimeout) {
        clearTimeout(silenceTimeout);
        setSilenceTimeout(null);
      }
      if (recordingTimer.current) {
        clearInterval(recordingTimer.current);
        recordingTimer.current = null;
      }
    }
  };

  // 音声メタデータの取得
  React.useEffect(() => {
    if (audio) {
      const fetchMetadata = async () => {
        const csrftoken = Cookies.get("csrftoken");
        const response = await fetch("/api/v1/juno/audio-metadata", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken!,
          },
          body: JSON.stringify({
            audio1: audio,
          }),
        });

        const data = await response.json();
        if (data.status === "success" && data.metadata) {
          const duration = Math.round(data.metadata.duration);
          setLocalAudioDuration(duration);
          setAudioDuration?.(duration);
        }
      };

      // 録音データの場合はスキップ（Base64文字列が "data:audio/wav" で始まる）
      if (!audio.startsWith("data:audio/wav")) {
        fetchMetadata().catch(console.error);
      }
    } else {
      const duration = 0;
      setLocalAudioDuration(duration);
      setAudioDuration?.(duration);
    }
  }, [audio]);

  React.useEffect(() => {
    return () => {
      if (recordingTimer.current) {
        clearInterval(recordingTimer.current);
      }
    };
  }, []);

  React.useEffect(() => {
    const audioElement = audioRef.current;
    if (!audioElement) return;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);

    audioElement.addEventListener("play", handlePlay);
    audioElement.addEventListener("pause", handlePause);
    audioElement.addEventListener("ended", handleEnded);

    return () => {
      audioElement.removeEventListener("play", handlePlay);
      audioElement.removeEventListener("pause", handlePause);
      audioElement.removeEventListener("ended", handleEnded);
    };
  }, [audio]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      const file = files[0];
      updateAudio(file);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      updateAudio(file);
    }
  };

  const updateAudio = (file: File) => {
    if (file.type !== "audio/mp3" && file.type !== "audio/wav" && file.type !== "audio/mpeg") {
      setAlert("error", t("juno.input.uploadError.audioType"));
      return;
    }

    if (file.size > 31457280) {
      setAlert("error", t("juno.video.error.max.audioSize"));
      return;
    }

    const audio = document.createElement("audio");
    audio.preload = "metadata";

    audio.onloadedmetadata = () => {
      URL.revokeObjectURL(audio.src);

      if (audio.duration > 60) {
        setAlert("error", t("juno.video.error.max.audioDuration"));
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          setAudio(e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    };

    audio.src = URL.createObjectURL(file);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileSelect = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("fileInputRef is null");
    }
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAudio("");
  };

  const renderUploadArea = () => (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.7),
      }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleFileSelect}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          p: 2,
          "&:hover": {
            cursor: "pointer",
          },
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
            <CloudUploadIcon fontSize="large" sx={{ my: 2 }} />
            <Box>音声をアップロード</Box>
            <Box sx={{ position: "relative" }}>
              <IconButton
                sx={{
                  my: 2,
                  backdropFilter: "blur(5px)",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  transition: "0.3s",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                  },
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  if (!isRecording) {
                    startRecording();
                  } else {
                    stopRecording();
                  }
                }}
              >
                <MicIcon style={{ color: isRecording ? "#ff4444" : "white" }} />
              </IconButton>
              {isRecording && (
                <Box
                  sx={{
                    position: "absolute",
                    top: -20,
                    left: "50%",
                    transform: "translateX(-50%)",
                    color: "white",
                    fontSize: "0.8rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  {recordingDuration}s
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );

  const renderAudioControls = () => (
    <ColumnBox>
      <RowBox gap={2} sx={{ width: "100%", alignItems: "center" }}>
        <audio ref={audioRef} src={audio} style={{ display: "none" }} />
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", position: "relative" }}>
          <IconButton
            sx={{
              backdropFilter: "blur(5px)",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              transition: "0.3s",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              },
            }}
            onClick={(event) => {
              event.stopPropagation();
              const audioElement = audioRef.current;
              if (!audioElement) return;

              if (!isPlaying) {
                audioElement.play().catch(console.error);
                setIsPlaying(true);
              } else {
                audioElement.pause();
                setIsPlaying(false);
              }
            }}
          >
            {!isPlaying ? <PlayArrowIcon style={{ color: "white" }} /> : <StopIcon style={{ color: "white" }} />}
          </IconButton>
          <Box sx={{ position: "relative" }}>
            <IconButton
              sx={{
                backdropFilter: "blur(5px)",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                transition: "0.3s",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                },
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (!isRecording) {
                  startRecording();
                } else {
                  stopRecording();
                }
              }}
            >
              <MicIcon style={{ color: isRecording ? "#ff4444" : "white" }} />
            </IconButton>
            {isRecording && (
              <Box
                sx={{
                  position: "absolute",
                  top: 36,
                  left: "50%",
                  transform: "translateX(-50%)",
                  color: "white",
                  fontSize: "0.8rem",
                  whiteSpace: "nowrap",
                }}
              >
                {recordingDuration}s
              </Box>
            )}
          </Box>
        </Box>
        <IconButton
          sx={{
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            transition: "0.3s",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            },
          }}
          onClick={handleFileSelect}
        >
          <SyncIcon style={{ color: "white" }} />
        </IconButton>
        <IconButton
          sx={{
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            transition: "0.3s",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            },
          }}
          onClick={handleDelete}
        >
          <DeleteIcon style={{ color: "white" }} />
        </IconButton>
      </RowBox>
      <Box sx={{ fontSize: "0.8rem", whiteSpace: "nowrap", p: 1 }}>{localAudioDuration}s</Box>
    </ColumnBox>
  );

  const handleOpenModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  return (
    <Box
      sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
    >
      <input type="file" style={{ display: "none" }} onChange={handleChange} accept="audio/*" ref={fileInputRef} />
      {audio ? renderAudioControls() : renderUploadArea()}
      <Button onClick={handleOpenModal} sx={{ mt: 1 }} startIcon={<GraphicEqIcon />}>
        Generated Voices
      </Button>
      <GeneratedVoicesModal open={open} setOpen={setOpen} setAudio={setAudio} />
    </Box>
  );
};

export default AudioUpload;

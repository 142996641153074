import { FormControl, MenuItem, Select, Slider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoopMode, useVideo } from "../../../../../context/juno/VideoContext";
import { ColumnBox } from "../../../../../utils/styledBox";
import AudioUpload from "../AudioUpload";
import ModelVersionSelectBox from "../ModelVersionSelectBox";
import VideoUpload from "../VideoUpload";

const ParamsBytedance = () => {
  const { t } = useTranslation();
  const {
    guidanceScale,
    setGuidanceScale,
    loopMode,
    setLoopMode,
    video1,
    setVideo1,
    audio1,
    setAudio1,
    modelVersion,
    updateParams,
  } = useVideo();

  return (
    <ColumnBox sx={{ gap: 4 }}>
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <ModelVersionSelectBox
          platform="BYTEDANCE"
          value={modelVersion.BYTEDANCE}
          onChange={(value) => updateParams("BYTEDANCE", "modelVersion", value)}
          menu={"lipsync"}
        />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.uploadFaceVideo")}
        </Typography>
        <VideoUpload video={video1} setVideo={setVideo1} />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.audio")}
        </Typography>
        <AudioUpload audio={audio1} setAudio={setAudio1} />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.loopMode")}
        </Typography>
        <FormControl fullWidth>
          <Select value={loopMode} onChange={(e) => setLoopMode(e.target.value as LoopMode)}>
            <MenuItem value="pingpong">{t("juno.video.params.pingpong")}</MenuItem>
            <MenuItem value="loop">{t("juno.video.params.loop")}</MenuItem>
          </Select>
        </FormControl>
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.guidanceScale")}
        </Typography>
        <Slider
          value={guidanceScale}
          onChange={(_, value) => setGuidanceScale(value as number)}
          min={1}
          max={2}
          step={0.1}
          valueLabelDisplay="auto"
          marks={[
            { value: 1, label: "1" },
            { value: 1.5, label: "1.5" },
            { value: 2, label: "2" },
          ]}
        />
        <Typography variant="caption" color="textSecondary">
          {t("juno.video.params.guidanceScaleHelp")}
        </Typography>
      </ColumnBox>
    </ColumnBox>
  );
};

export default ParamsBytedance;

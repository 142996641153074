import { Box, Grid, Typography, Alert, ToggleButtonGroup, ToggleButton, Container, alpha, Fade } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoginStatus } from "../../../../context/LoginStatusContext";
import PermanentCreditCard from "./PermanentCreditCard";
import PermanentCreditModal from "./PermanentCreditModal";

interface PermanentCreditSectionProps {
  currentPlan: string;
  handlePurchaseClick?: (productId: string) => void;
}

const PermanentCreditSection = ({ currentPlan, handlePurchaseClick }: PermanentCreditSectionProps) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useLoginStatus();
  // loadingステートは不要なので削除
  const [selectedPlanType, setSelectedPlanType] = useState<string>("standard");
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductData | null>(null);

  // プランタイプの切り替え処理
  const handlePlanTypeChange = (_event: React.MouseEvent<HTMLElement>, newPlanType: string) => {
    if (newPlanType !== null) {
      setSelectedPlanType(newPlanType);
    }
  };

  interface ProductData {
    id: string;
    name: string;
    credit_amount: number;
    unit_price: number;
    price: number;
    currency: string;
    original_unit_price?: number; // 割引前の単価
  }

  const [products, setProducts] = useState<ProductData[]>([]);

  // 永久クレジット商品データの生成
  useEffect(() => {
    // 通貨の判定（バックエンドでのみ使用）
    const currency = currentPlan.includes("_jpy") ? "JPY" : "USD";

    // 基本データの作成
    const generateProducts = (planType: string): ProductData[] => {
      // クレジット数と基本割引率の定義
      const creditAmounts = [500, 1000, 2000, 5000];
      // スタンダードの段階的割引率（0%, 4%, 7%, 10%）
      const standardDiscountRates = [0, 0.04, 0.07, 0.1];

      // プレミアムの段階的割引率（5%, 10%, 15%, 20%）
      const premiumDiscountRates = [0.05, 0.1, 0.15, 0.2];

      // 基本単価（スタンダードとプレミアム共通）
      const baseUnitPrice = 0.025;

      return creditAmounts.map((amount, index) => {
        // 段階的割引率を適用
        const discountRate =
          planType === "premium"
            ? premiumDiscountRates[Math.min(index, premiumDiscountRates.length - 1)]
            : standardDiscountRates[Math.min(index, standardDiscountRates.length - 1)];

        // 単価計算
        const unitPrice = baseUnitPrice * (1 - discountRate);

        // 価格計算
        const price = unitPrice * amount;

        // 割引前の価格計算
        const originalPrice = baseUnitPrice * amount;

        return {
          id: `permanent_credits_${planType}_${amount}_${currency.toLowerCase()}`,
          name: `Credits ${amount.toLocaleString()}`,
          credit_amount: amount,
          unit_price: unitPrice,
          price: price,
          currency: "USD", // 表示用の通貨は常にUSD
          original_unit_price: baseUnitPrice,
          original_price: originalPrice,
        };
      });
    };

    // 選択されたプランタイプに基づいて商品データを生成
    const newProducts = generateProducts(selectedPlanType);
    setProducts(newProducts);
  }, [currentPlan, selectedPlanType]);

  // 購入処理
  const handleProductSelect = (productId: string) => {
    const product = products.find((p) => p.id === productId);
    if (product) {
      setSelectedProduct(product);
      setOpenModal(true);
    }
  };

  // モーダルを閉じる
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // 購入処理（親コンポーネントから渡された場合はそれを使用）
  const onPurchaseClick = (productId: string) => {
    if (handlePurchaseClick) {
      handlePurchaseClick(productId);
    } else {
      // デフォルトの処理
      console.log(`Purchase clicked for: ${productId}`);
    }
    setOpenModal(false);
  };

  // Standard以上のプランかどうかをチェック
  const isEligibleForPermanentCredits = () => {
    const basePlan = currentPlan.replace("_jpy", "");
    return ["standard", "premium"].includes(basePlan);
  };

  return (
    <Container maxWidth="md">
      <Fade in={true} timeout={1000}>
        <Box mb={4} textAlign="center">
          <Typography variant="body2" mb={3} whiteSpace={"pre-line"}>
            {t("pricing.permanentCredit.description")}
          </Typography>

          <ToggleButtonGroup
            value={selectedPlanType}
            exclusive
            onChange={handlePlanTypeChange}
            aria-label="plan type"
            size="small"
            sx={{
              borderRadius: 1,
              backgroundColor: alpha("#fff", 0.2),
              borderColor: "divider",
              color: "#fff",
              "& .MuiToggleButton-root": {
                "&.Mui-selected": {
                  backgroundColor: alpha("#fff", 0.3),
                  color: "#fff",
                  fontWeight: 600,
                },
              },
            }}
          >
            <ToggleButton value="standard" aria-label="standard plan" sx={{ px: 2, fontWeight: 400, color: "#fff" }}>
              {t("pricing.permanentCredit.forStandardUsers")}
            </ToggleButton>
            <ToggleButton value="premium" aria-label="premium plan" sx={{ px: 2, fontWeight: 400, color: "#fff" }}>
              {t("pricing.permanentCredit.forPremiumUsers")}
            </ToggleButton>
          </ToggleButtonGroup>

          {/* 割引率の説明は不要 */}
        </Box>
      </Fade>

      {isLoggedIn && !isEligibleForPermanentCredits() && (
        <Alert severity="info" sx={{ mb: 4 }}>
          {t("pricing.permanentCredit.upgradeRequired")}
        </Alert>
      )}

      <Grid container spacing={3} justifyContent="center">
        {products.map((product, index) => (
          <PermanentCreditCard
            key={product.id}
            productData={product}
            index={index}
            handlePurchaseClick={handleProductSelect}
            currentPlan={currentPlan}
          />
        ))}
      </Grid>
      {/* 永久クレジット購入モーダル */}
      {selectedProduct && (
        <PermanentCreditModal
          open={openModal}
          handleClose={handleCloseModal}
          productData={selectedProduct}
          handlePurchaseClick={onPurchaseClick}
        />
      )}
    </Container>
  );
};

export default PermanentCreditSection;

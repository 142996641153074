import drawer from "./authenticated/drawer";
import header from "./authenticated/header";
import top from "./unauthenticated/top";
import notesNebula from "./unauthenticated/notesNebula";
import pricing from "./unauthenticated/pricing";
import dashboard from "./authenticated/dashboard";
import browsing from "./authenticated/browsing";
import library from "./authenticated/library";
import juno from "./authenticated/juno";
import voice from "./authenticated/voice";
import file from "./authenticated/file";
import mindmap from "./authenticated/mindmap";
import summarize from "./authenticated/summarize";
import colorPalette from "./authenticated/colorPalette";
import article from "./authenticated/article";
import videoScript from "./authenticated/videoScript";
import textEditor from "./authenticated/textEditor";
import template from "./authenticated/template";
import preference from "./authenticated/preference";
import common from "./authenticated/common";
import validation from "./authenticated/validation";
import aboutJuno from "./unauthenticated/aboutJuno";
import message from "./authenticated/message";
import meta from "./unauthenticated/meta";
import Announcement from "./unauthenticated/announcement";

export const english = {
  translations: {
    title: "Tenak AI",
    title2: "Tenak.ai",
    company: "Diocode, Inc.",
    drawer: drawer,
    header: header,
    top: top,
    notesNebula: notesNebula,
    aboutJuno: aboutJuno,
    pricing: pricing,
    dashboard: dashboard,
    browsing: browsing,
    library: library,
    juno: juno,
    voice: voice,
    file: file,
    mindmap: mindmap,
    summarize: summarize,
    colorPalette: colorPalette,
    article: article,
    videoScript: videoScript,
    textEditor: textEditor,
    template: template,
    preferences: preference,
    common: common,
    validation: validation,
    message: message,
    announcement: Announcement,
    meta: meta,
  },
};

import axios from "axios";
import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLoginStatus } from "./LoginStatusContext";

interface Announcement {
  uuid: string;
  title: string;
  content: string;
  category: string;
}

interface AnnouncementContextType {
  announcements: Announcement[];
  currentAnnouncement: Announcement | null;
  showModal: boolean;
  setAnnouncements: (announcements: Announcement[]) => void;
  closeModal: () => void;
  showNextAnnouncement: () => void;
  fetchLoginAnnouncements: () => Promise<void>;
}

const AnnouncementContext = createContext<AnnouncementContextType | undefined>(undefined);

export const useAnnouncement = () => {
  const context = useContext(AnnouncementContext);
  if (!context) {
    throw new Error("useAnnouncement must be used within an AnnouncementProvider");
  }
  return context;
};

interface AnnouncementProviderProps {
  children: React.ReactNode;
}

export const AnnouncementProvider: React.FC<AnnouncementProviderProps> = ({ children }) => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const location = useLocation();
  const { isLoggedIn } = useLoginStatus();

  const currentAnnouncement = announcements[currentIndex] || null;

  const closeModal = React.useCallback(() => {
    if (currentAnnouncement) {
      // クッキーを設定（30日間有効）
      Cookies.set(`announcement_read_${currentAnnouncement.uuid}`, new Date().toISOString(), { expires: 30 });
    }
    setShowModal(false);

    // 次のアナウンスメントがあれば表示
    if (currentIndex < announcements.length - 1) {
      setCurrentIndex((prev) => prev + 1);
      setShowModal(true);
    }
  }, [currentAnnouncement, currentIndex, announcements.length]);

  const showNextAnnouncement = React.useCallback(() => {
    if (currentIndex < announcements.length - 1) {
      setCurrentIndex((prev) => prev + 1);
      setShowModal(true);
    }
  }, [currentIndex, announcements.length]);

  const fetchLoginAnnouncements = React.useCallback(async () => {
    // 既に表示中の場合は何もしない
    if (showModal) {
      return;
    }

    try {
      const response = await axios.get("/api/v1/announcement/news/");
      const fetchedAnnouncements = response.data.announcements;

      // 未読のアナウンスメントのみをフィルタリング
      const unreadAnnouncements = fetchedAnnouncements.filter(
        (announcement: Announcement) => !Cookies.get(`announcement_read_${announcement.uuid}`)
      );

      if (unreadAnnouncements.length > 0) {
        setAnnouncements(unreadAnnouncements);
        setCurrentIndex(0);
        setShowModal(true);
      }
    } catch (error) {
      console.error("Failed to fetch login announcements:", error);
    }
  }, [showModal]);

  // ログインアナウンスメントの初期化
  useEffect(() => {
    // 言語プレフィックスを除いたパスを取得
    const path = location.pathname.split("/").slice(2).join("/");

    // メインページのみチェック
    const isMainPage =
      path === "" || // Dashboard
      path === "juno" || // Juno
      path === "notes-nebula"; // Notes Nebula

    if (isMainPage && isLoggedIn) {
      const now = new Date();
      const lastChecked = Cookies.get("announcement_last_checked");
      const shouldCheck = !lastChecked || now.getTime() - new Date(lastChecked).getTime() >= 3600000; // 1時間 = 3600000ミリ秒

      if (shouldCheck) {
        fetchLoginAnnouncements();
        Cookies.set("announcement_last_checked", now.toISOString());
      }
    }
  }, [location.pathname, isLoggedIn, fetchLoginAnnouncements]);

  return (
    <AnnouncementContext.Provider
      value={{
        announcements,
        currentAnnouncement,
        showModal,
        setAnnouncements,
        closeModal,
        showNextAnnouncement,
        fetchLoginAnnouncements,
      }}
    >
      {children}
    </AnnouncementContext.Provider>
  );
};

const common = {
  save: "Save",
  add: "Add",
  edit: "Edit",
  share: "Share",
  apply: "Apply",
  delete: "Delete",
  deleteSelected: "Delete {{length}} selected items",
  selected: "{{length}} selected",
  releaseSelected: "Deselect All",
  bulkDelete: "Bulk Delete",
  refresh: "Refresh",
  cancel: "Cancel",
  download: "Download",
  close: "Close",
  or: "or",
  copy: "Copy to Clipboard",
  generatingMessage: "Generating... You can leave this page and the generation will continue.",
  complete: "Generation is complete. Check in the library.",
  play: "Play",
  stop: "Stop",
  next: "Next",
  back: "Back",
  submit: "Submit",
  error: {
    somethingWentWrong: "Something went wrong. Try again later.",
  },
  enable: "Enable",
  disable: "Disable",
  generate: "Generate with Tenak AI",
  stopGenerating: "Stop Generating",
  generating: "Generating...",
  pro: "Only available to those subscribed to the paid plan.",
  footer: {
    pricing: "Pricing",
    terms: "Terms of Use",
    privacy: "Privacy Policy",
    contact: "Contact Us",
    notesNebula: "Notes Nebula",
    juno: "Studio Juno",
    TenakAI: "Tenak.ai ",
  },
  proPlanDialog: {
    title: "You need to subscribe to the paid plan",
    description:
      "This feature is only available to users who have subscribed to the paid plan. If you are already subscribed, you may have insufficient credits.",
    cancel: "Cancel",
    confirm: "Check the plan",
  },
};
export default common;

const drawer = {
  home: "Tenak.ai",
  notesNebula: "Notes Nebula",
  notesNebulaDescription: "記事投稿プラットフォーム",
  juno: "STUDIO JUNO",
  junoDescription: "画像・動画生成AI",
  browsing: "ブラウジング",
  library: "ライブラリ",
  images: "Image",
  videos: "Video",
  text: "Text",
  mindmap: "Mind map",
  voice: "Voice",
  writingAI: "ライティング AI",
  AIs: "Tenak AI",
  explore: "探索",
  myImages: "マイイメージ",
  myVideos: "マイビデオ",
  myVoices: "マイボイス",
  models: "全てのモデル",
  imageTemplates: "画像生成テンプレート",
  imageGenerator: " 画像生成 AI",
  videoGenerator: "動画生成 AI",
  voiceGenerator: "音声\n生成 AI",
  colorGenerator: "カラーパレット\n生成 AI",
  mindmapGenerator: "マインドマップ\n生成 AI",
  templates: "プロンプト\nテンプレート",
  aiEditor: "AIテキストエディター",
  createText: "AIテキストエディター",
  bookmark: "ブックマーク",
  articles: "記事",
  speedMode: "速度＆節約モード",
  intelligentMode: "高知能モード",
  modeDescription:
    "Tenak AI のテキスト生成のAIモードを変更できます。スピードモードは、AIの処理速度の最適化とクレジットを削減し、インテリジェントモードは知能を優先します。",
};
export default drawer;

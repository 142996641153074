import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { ButtonBase, Grid, IconButton, TextField } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Tooltip from "@mui/material/Tooltip";
import Cookies from "js-cookie";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import CopyButtonOrIcon from "../../components/common/CopyButtonOrIcon";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import ColorModal from "./components/ColorModal";
import { setCreditTrigger } from "../../redux/slices/triggerSlice";
import { useDispatch } from "react-redux";
import { useCheckCredit } from "../../hooks/useCreditCheck";
import { useTheme } from "@mui/system";
import { StyledTypography } from "../../utils/styledTypography";
import Meta from "../../components/common/Meta";
import Grow from "@mui/material/Grow";
import { GradientTypography } from "../../utils/gradientTypography";
import { Images } from "../../assets";

function ColorGenerator() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { checkCredit } = useCheckCredit();
  const [keyword, setKeyword] = useState<string>("");
  const [palettes, setPalettes] = useState<any[]>([]);
  const [generatedPalette, setGeneratedPalette] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const getPalettes = async () => {
    try {
      const res = await axios.get("/api/v1/color/");
      if (res.data && Array.isArray(res.data.data)) {
        setPalettes(res.data.data);
      } else {
        console.error("Expected an array but got:", res.data);
        setPalettes([]);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!(await checkCredit())) return;
    if (loading || !keyword) {
      return;
    }
    setLoading(true);
    const csrfToken = Cookies.get("csrftoken");
    const res = await axios.post(
      "/api/v1/color/generate",
      { keyword: keyword },
      { headers: { "X-CSRFToken": csrfToken } }
    );
    setGeneratedPalette(res.data.data);
    getPalettes();
    dispatch(setCreditTrigger(true));
    setLoading(false);
  };

  useEffect(() => {
    getPalettes();
  }, []);

  const [open, setOpen] = useState(false);
  const [color, setColor] = useState({});
  const [title, setTitle] = useState("");

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleModalOpen = (color: any, palette: {}, titleIndex: number) => {
    setColor(color);
    const finalTitle = palette["title"] + " - " + (titleIndex + 1);
    setTitle(finalTitle);
    setOpen(true);
  };

  return (
    <>
      <Meta title={t("colorPalette.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Container maxWidth={"lg"} sx={{ my: 4 }}>
        <DrawerHeader />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                height: "fit-content",
              }}
            >
              <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                <Box display={{ xs: "none", md: "block" }} mr={2}>
                  <img alt={"logo"} src={Images.logo.main} width={"40px"} />
                </Box>
                <GradientTypography variant="h5" component={"h2"} gutterBottom>
                  {t("colorPalette.title")}
                </GradientTypography>
              </Box>
              <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center", pt: 2, pb: 4 }}>
                <FlashOnIcon sx={{ mr: 1 }} color={"secondary"} />
                <Typography variant={"body1"} gutterBottom>
                  {t("colorPalette.description")}
                </Typography>
              </Box>

              <Box sx={{ justifyContent: "center", display: "flex", mb: 1 }}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  gap={2}
                  sx={{ width: "100%" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1, justifyContent: "center" }}>
                    <TextField
                      variant={"standard"}
                      sx={{ width: 500 }}
                      placeholder={t("colorPalette.placeholder")}
                      required
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress size={24} sx={{ m: 1 }} />
                            ) : (
                              <Tooltip title={t("colorPalette.generate")} placement={"right"}>
                                <IconButton type="submit" color="primary" disabled={loading}>
                                  <AutoAwesomeIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ justifyContent: "center", display: "flex", mb: 4 }}>
                <Typography variant={"caption"} color={"text.secondary"}>
                  {t("colorPalette.caution")}
                </Typography>
              </Box>

              {/* 回答 */}
              {generatedPalette?.["colors"] && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    justifyContent: "center",
                  }}
                >
                  <Grow in={true} timeout={500}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        justifyContent: "center",
                        mb: 1,
                      }}
                    >
                      <StyledTypography variant={"subtitle1"}>{t("colorPalette.generated")}</StyledTypography>
                      <Typography variant={"subtitle2"}>{generatedPalette["title"]}</Typography>
                    </Box>
                  </Grow>

                  {/* パターン */}
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1, height: "100%" }}>
                    {generatedPalette["colors"] &&
                      generatedPalette["colors"].map((color: string, pattern_index: number) => (
                        <Grow in={!!color} key={`pattern-${pattern_index}`} timeout={pattern_index * 500}>
                          <Paper
                            elevation={1}
                            sx={{
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                border: "1px solid",
                                borderColor: "white",
                                borderRadius: 2,
                                m: 2,
                                overflow: "hidden",
                              }}
                            >
                              {["color1", "color2", "color3", "color4", "color5"].map((colorName, index) => (
                                <Box
                                  key={`palette-${pattern_index}-${index}`}
                                  sx={{
                                    width: "100%",
                                    height: 64,
                                    backgroundColor: color[colorName],
                                  }}
                                />
                              ))}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                height: 380,
                                overflowY: "auto",
                                flexDirection: "column",
                                px: 2,
                                pb: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                  mb: 2,
                                }}
                              >
                                {["color1", "color2", "color3", "color4", "color5"].map((colorName, index) => (
                                  <Box
                                    key={`label-${pattern_index}-${index}`}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 1,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        width: 24,
                                        height: 24,
                                        backgroundColor: color[colorName],
                                      }}
                                    />
                                    <TextField
                                      key={`palette-${pattern_index}-${index}-label`}
                                      sx={{
                                        width: "100%",
                                      }}
                                      value={color[colorName]}
                                      size={"small"}
                                      variant={"standard"}
                                      InputProps={{
                                        endAdornment: (
                                          <CopyButtonOrIcon
                                            displayType={"icon"}
                                            textToCopy={color[colorName]}
                                            size={"small"}
                                          />
                                        ),
                                      }}
                                    />
                                  </Box>
                                ))}
                              </Box>
                              <Typography variant={"body2"}>{color["description"]}</Typography>
                            </Box>
                          </Paper>
                        </Grow>
                      ))}
                  </Box>
                  <Typography variant={"caption"} color={"text.secondary"} textAlign={"right"}>
                    {t("colorPalette.colorDescription")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>

          {/* リスト */}
          <Grid item xs={12} sm={12}>
            <Box sx={{ pb: 2 }}>
              <StyledTypography variant={"subtitle1"} mb={2}>
                {t("colorPalette.list")}
              </StyledTypography>
              <Grid container spacing={2} justifyContent="center" sx={{ overflowY: "auto" }}>
                {palettes.map((palette, req_index) =>
                  palette.colors.map(
                    (color: string, palette_index: number) =>
                      palette.colors[palette_index]["description"] && (
                        <Grid item xs={4} md={1.5} key={`list-request-${req_index}-${palette_index}`}>
                          <ButtonBase
                            sx={{ width: "100%" }}
                            onClick={() => handleModalOpen(color, palette, palette_index)}
                          >
                            <Paper elevation={1} sx={{ overflow: "hidden", width: "100%" }}>
                              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                                <Box
                                  sx={{
                                    flexDirection: "row",
                                    display: "flex",
                                    width: "100%",
                                    height: 64,
                                  }}
                                >
                                  {["color1", "color2", "color3", "color4", "color5"].map((colorName, index) => (
                                    <Box
                                      key={`list-request-${req_index}-${palette_index}-${index}`}
                                      sx={{
                                        width: "100%",
                                        height: 64,
                                        backgroundColor: color[colorName],
                                      }}
                                    />
                                  ))}
                                </Box>
                              </Box>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: "primary.main",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  p: 0.5,
                                }}
                              >
                                {palette.title} - {palette_index + 1}
                              </Typography>
                            </Paper>
                          </ButtonBase>
                        </Grid>
                      )
                  )
                )}
              </Grid>
              <ColorModal open={open} handleClose={handleModalClose} color={color} title={title} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ColorGenerator;

import axios from "axios";
import Cookies from "js-cookie";
import React, { createContext, useContext, useState, ReactNode } from "react";
import { useLoginStatus } from "./LoginStatusContext";

interface AIEngineContextType {
  advancedAi: boolean;
  changeAiEngine: (isAdvanced: boolean) => Promise<void>;
}

const AIEngineContext = createContext<AIEngineContextType | undefined>(undefined);

interface AIEngineProviderProps {
  children: ReactNode;
}

export const AIEngineProvider: React.FC<AIEngineProviderProps> = ({ children }) => {
  const { user } = useLoginStatus();
  const [advancedAi, setAdvancedAi] = useState<boolean>(user?.advanced_ai || false);

  const changeAiEngine = async (isAdvanced: boolean): Promise<void> => {
    try {
      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };
      await axios.patch("/api/v1/users/user", { advanced_ai: isAdvanced }, config).then(() => {
        setAdvancedAi(isAdvanced);
        if (user) {
          user.advanced_ai = isAdvanced;
        }
      });
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  return <AIEngineContext.Provider value={{ advancedAi, changeAiEngine }}>{children}</AIEngineContext.Provider>;
};

export const useAIEngine = (): AIEngineContextType => {
  const context = useContext(AIEngineContext);
  if (context === undefined) {
    throw new Error("useAIEngine must be used within an AIEngineProvider");
  }
  return context;
};

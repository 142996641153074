import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import NegativePromptSaveModal from "./NegativePromptSaveModal";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Cookies from "js-cookie";
import InputIcon from "@mui/icons-material/Input";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "../../../../../context/AlertContext";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";

export default function NegativePrompt() {
  const { t } = useTranslation();
  const { negativePrompt, setNegativePrompt } = useParamsContext();
  const [localNegativePrompt, setLocalNegativePrompt] = useState(negativePrompt);
  const [openNegativePromptSaveModal, setOpenNegativePromptSaveModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedUuid, setSelectedUuid] = React.useState<string | null>(null);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { setAlert } = useAlert();

  // 入力値が変更されたときにコンテキストを更新するデバウンス処理
  useEffect(() => {
    // コンポーネントがマウントされたときに初期値を設定
    setLocalNegativePrompt(negativePrompt);
  }, [negativePrompt]);

  // デバウンス処理を行う関数
  const handleNegativePromptChange = (value: string): void => {
    setLocalNegativePrompt(value);

    // 既存のタイムアウトをクリア
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // 500ms後にコンテキストを更新
    debounceTimeoutRef.current = setTimeout(() => {
      setNegativePrompt(value);
    }, 500);
  };

  // コンポーネントのアンマウント時にタイムアウトをクリア
  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  interface NegativePrompt {
    uuid: string;
    title: string;
    prompt: string;
  }

  const [negativePromptList, setNegativePromptList] = React.useState<NegativePrompt[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const list = getNegativePrompt();
    list.then((value) => {
      setNegativePromptList(value);
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNegativePrompt = async () => {
    try {
      const url = "/api/v1/juno/negative-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      return json;
    } catch (e) {
      console.log(e);
    }
  };

  const deleteNegativePrompt = async (uuid: string) => {
    try {
      const url = "/api/v1/juno/negative-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      const response = await fetch(url, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify({ uuid: uuid }),
      });
      const json = await response.json();
      return json;
    } catch (e) {
      console.log(e);
    }
  };

  const handleDialogOpen = (uuid: string) => {
    setSelectedUuid(uuid);
    setDialogOpen(true);
  };

  const handleConfirmedDelete = () => {
    if (selectedUuid) {
      deleteNegativePrompt(selectedUuid).then(() => {
        setAlert("success", t("juno.input.delete.success"));
      });
    }
    setDialogOpen(false);
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ width: "100%", mb: 2 }}>
          <TextField
            sx={{ my: 1, width: "100%" }}
            placeholder={t("juno.input.negativePromptPlaceholder")}
            multiline
            minRows={3}
            maxRows={4}
            size={"small"}
            label={t("juno.input.negativePrompt")}
            value={localNegativePrompt}
            onChange={(event) => handleNegativePromptChange(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <Box sx={{ justifyContent: "space-between", display: "flex", mb: 1 }}>
            <Button disableElevation variant={"text"} size={"small"} onClick={handleClick}>
              <InputIcon fontSize={"small"} sx={{ mr: 1 }} />
              <Typography variant={"button"}>{t("juno.input.negativePromptList")}</Typography>
            </Button>
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              {negativePromptList.map((negativePrompt, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleNegativePromptChange(negativePrompt.prompt);
                      setNegativePrompt(negativePrompt.prompt);
                      handleClose();
                    }}
                    value={negativePrompt.uuid}
                    sx={{
                      width: "100%",
                      maxWidth: 300,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    <Box
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maskImage: "linear-gradient(to left, transparent 1%, black 30%)",
                        minWidth: 200,
                      }}
                    >
                      {negativePrompt.title}
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton edge="end" aria-label="more" onClick={() => handleDialogOpen(negativePrompt.uuid)}>
                      <DeleteIcon fontSize={"small"} color={"error"} />
                    </IconButton>
                  </MenuItem>
                );
              })}
            </Menu>
            <Button
              disableElevation
              variant={"text"}
              size={"small"}
              onClick={() => setOpenNegativePromptSaveModal(true)}
            >
              <SaveAsIcon fontSize={"small"} sx={{ mr: 1 }} />
              <Typography variant={"button"}>{t("common.save")}</Typography>
            </Button>
            <Tooltip title={t("juno.input.negativePromptDescription")} placement={"top"}>
              <IconButton size={"small"}>
                <InfoIcon fontSize={"small"} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      {/* ネガティブプロンプト保存モーダル */}
      <NegativePromptSaveModal
        openNegativePromptSaveModal={openNegativePromptSaveModal}
        setOpenNegativePromptSaveModal={setOpenNegativePromptSaveModal}
      />

      {/* 削除確認ダイアログ */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{t("juno.input.delete.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("juno.input.delete.description")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disableElevation onClick={() => setDialogOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleConfirmedDelete} color="error">
            {t("common.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import AcUnitIcon from "@mui/icons-material/AcUnit";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { GradientTypography } from "../../../../utils/gradientTypography";
import { ColumnBox, ColumnCenteredBox, RowCenteredBox } from "../../../../utils/styledBox";
import { Images } from "../../../../assets";

const WhatIs = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" sx={{ my: 8 }}>
      <ColumnCenteredBox my={4}>
        <RowCenteredBox mb={4} alignItems={"baseline!important"}>
          <Typography variant={"h5"} component={"h2"} fontFamily={"'Noto Serif JP', roboto"} fontWeight={"bold"} mr={2}>
            What is
          </Typography>
          <GradientTypography
            variant={"h4"}
            component={"h2"}
            fontFamily={"'Pacifico',cursive"}
            fontWeight={"500"}
            pr={1}
          >
            Tenak.ai
          </GradientTypography>
          <Typography variant={"h5"} component={"h2"} fontFamily={"'Noto Serif JP', roboto"} fontWeight={"bold"}>
            ?
          </Typography>
        </RowCenteredBox>
        <img src={Images.tenak.small} alt={"tenakai"} style={{ width: "64px", height: "64px" }} />
        <Paper
          elevation={0}
          sx={{
            width: { xs: "100%" },
            backgroundImage: `url(${Images.common.bg01})`,
            backgroundSize: "150%",
            backgroundPosition: "-50px -50px",
            backgroundRepeat: "no-repeat",
            borderRadius: 2,
            p: { xs: 2, sm: 3 },
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            height: "auto",
            gap: 4,
            mt: 4,
          }}
        >
          <img
            src={Images.top.whatIs}
            alt={"about tenak ai"}
            style={{ width: "100%", maxWidth: 500, display: "block", aspectRatio: "1/1", borderRadius: "4px" }}
          />

          <ColumnBox>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <AcUnitIcon fontSize="large" />
            </Box>
            <Box flexGrow={1} />
            <Typography variant={"body1"} mb={1} color={"text.secondary"}>
              May 30, 2024
            </Typography>
            <Typography variant={"h3"} component={"h3"} fontWeight={"bold"} fontFamily={"Noto serif JP"} mb={2}>
              {t("top.fv.whatIsTenak")}
            </Typography>
            <Typography paragraph>{t("top.fv.detail")}</Typography>
          </ColumnBox>
        </Paper>
      </ColumnCenteredBox>
    </Container>
  );
};
export default WhatIs;

import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DeleteChatDialog from "./DeleteChatDialog";
import CustomInstructionsDialog from "./CustomInstructionsDialog";
import DeleteHistoriesDialog from "./DeleteHistoriesDialog";

interface ChatItemMenuProps {
  chatUuid: string;
  stopPropagation: (event: React.MouseEvent) => void;
}

export default function ChatItemMenu({ chatUuid, stopPropagation }: ChatItemMenuProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openCustomInstructions, setOpenCustomInstructions] = React.useState(false);
  const [openHistoriesDelete, setOpenHistoriesDelete] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event?: React.MouseEvent | object) => {
    if (event && typeof event === "object" && "stopPropagation" in event) {
      stopPropagation(event as React.MouseEvent);
    }
    setAnchorEl(null);
  };

  const handleClickOpenDelete = (event: React.MouseEvent) => {
    stopPropagation(event);
    handleClose(event);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpenHistoriesDelete = (event: React.MouseEvent) => {
    stopPropagation(event);
    handleClose(event);
    setOpenHistoriesDelete(true);
  };

  const handleCloseHistoriesDelete = () => {
    setOpenHistoriesDelete(false);
  };

  const handleEdit = (event: React.MouseEvent) => {
    stopPropagation(event);
    handleClose(event);
    setOpenCustomInstructions(true);
  };

  const handleCloseCustomInstructions = () => {
    setOpenCustomInstructions(false);
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 1 }} onMouseDown={stopPropagation}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={stopPropagation}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleEdit}>
          <EditIcon sx={{ mr: 2 }} fontSize="small" />
          {t("browsing.customize")}
        </MenuItem>
        <MenuItem onClick={handleClickOpenHistoriesDelete}>
          <ClearAllIcon sx={{ mr: 2 }} fontSize="small" />
          {t("browsing.clear")}
        </MenuItem>
        <MenuItem onClick={handleClickOpenDelete} sx={{ color: "error.main" }}>
          <DeleteIcon sx={{ mr: 2 }} fontSize="small" />
          {t("browsing.delete")}
        </MenuItem>
      </Menu>

      {/* ダイアログ */}
      <DeleteChatDialog open={openDelete} onClose={handleCloseDelete} chatUuid={chatUuid} />
      <DeleteHistoriesDialog open={openHistoriesDelete} onClose={handleCloseHistoriesDelete} />
      <CustomInstructionsDialog open={openCustomInstructions} onClose={handleCloseCustomInstructions} />
    </>
  );
}

import CompaniesSection from "./sections/CompaniesSection";
import CTASection from "./sections/CTASection";
import FeaturesSection from "./sections/FeaturesSection";
import HeroSection from "./sections/HeroSection";
import WhatIsSection from "./sections/WhatIsSection";

const Top = () => {
  return (
    <>
      <HeroSection />
      <CompaniesSection />
      <WhatIsSection />
      <FeaturesSection />
      <CTASection />
    </>
  );
};

export default Top;

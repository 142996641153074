import BookmarkIcon from "@mui/icons-material/Bookmark";
import EditIcon from "@mui/icons-material/Edit";
import { Container, Fade, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Meta from "../../components/common/Meta";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import { useAlert } from "../../context/AlertContext";
import { useLoginModalContext } from "../../context/LoginModalContext";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { useLocation } from "react-router-dom";
import { Images } from "../../assets";
import { GradationButton } from "../../utils/gradationButton";
import { GradientTypography } from "../../utils/gradientTypography";
import Bookmarks from "./components/Bookmarks";
import Latest from "./components/Latest";
import Official from "./components/Official";
import Popular from "./components/Popular";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { ColumnCenteredBox } from "../../utils/styledBox";

const NotesNebula = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useLoginStatus();
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();
  const loginContext = useLoginModalContext();
  const location = useLocation();

  // URLパラメータからタブ情報を取得
  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get("tab");

  // URLパラメータに基づいてタブを設定
  const [view, setView] = useState<"articles" | "bookmarks">(() => {
    if (tabParam === "bookmark") return "bookmarks";
    if (tabParam === "articles") return "articles";
    return "articles"; // デフォルト値
  });

  useEffect(() => {
    if (tabParam === "bookmark") {
      setView("bookmarks");
    } else if (tabParam === "articles") {
      setView("articles");
    }
  }, [tabParam]);

  const handleViewChange = (_: React.MouseEvent<HTMLElement>, newView: "articles" | "bookmarks" | null) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handlePost = async () => {
    try {
      if (isLoggedIn) {
        const csrftoken = Cookies.get("csrftoken");
        const config = {
          headers: { "X-CSRFToken": csrftoken },
        };
        const res = await axios.post("/api/v1/text-file/", { type: 1 }, config);
        navigate(`/notes-nebula/files/${res.data.uuid}`);
      } else {
        loginContext.setActiveModal("login");
      }
    } catch (error) {
      setAlert("error", "Failed to create a new note. Please try again.");
      console.error(error);
    }
  };

  const meta = [
    { name: "robots", content: "index, follow" },
    { name: "keywords", content: t("meta.notesNebula.keywords") },
    { name: "description", content: t("meta.notesNebula.description") },
  ];

  return (
    <>
      <Meta title={t("meta.notesNebula.title")} meta={meta} />
      <Fade in={true} timeout={1500}>
        <Container maxWidth={"lg"}>
          <DrawerHeader />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              backgroundImage: `url(${Images.notesNebula.top})`,
              height: { xs: 300, md: 400 },
              borderRadius: 2,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.6)",
                borderRadius: 2,
                zIndex: 1,
              },
              mt: 4,
            }}
          >
            <Box sx={{ display: "inline", fontWeight: "bold", zIndex: 100 }} component={"h1"}>
              <GradientTypography variant="h2" component="span">
                N
              </GradientTypography>
              <Typography variant="h2" component="span" color={"#fff"}>
                otes&nbsp;
              </Typography>
              <GradientTypography variant="h2" component="span">
                N
              </GradientTypography>
              <Typography variant="h2" component="span" color={"#fff"}>
                ebula
              </Typography>
              <Typography variant="h6" component="span" sx={{ ml: 2 }} color={"#fff"}>
                Beta
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                zIndex: 2,
              }}
            >
              <Typography variant={"subtitle1"} sx={{ textAlign: "center", mb: 4, mx: 2 }} color={"#fff"}>
                {t("notesNebula.description")}
              </Typography>
              <GradationButton onClick={handlePost} sx={{ px: 2 }} startIcon={<EditIcon />}>
                {t("notesNebula.post")}
              </GradationButton>
            </Box>
          </Box>

          <ColumnCenteredBox>
            <GradientTypography variant="h5" sx={{ py: 6 }}>
              {t("notesNebula.message")}
            </GradientTypography>
          </ColumnCenteredBox>

          {/* 切り替えボタン */}
          <Box sx={{ display: "flex", justifyContent: { xs: "center", sm: "flex-end" }, pb: 4 }}>
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label="view mode"
              color="secondary"
              size="small"
            >
              <ToggleButton value="articles" aria-label="articles" sx={{ width: 180 }}>
                <TrendingUpIcon sx={{ mr: 1 }} fontSize="small" />
                {t("notesNebula.all")}
              </ToggleButton>
              <ToggleButton value="bookmarks" aria-label="bookmarks" sx={{ width: 180 }}>
                <BookmarkIcon sx={{ mr: 1 }} fontSize="small" />
                {t("notesNebula.bookmarks")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* コンテンツ表示 */}
          <Box mb={4}>
            <Fade in={view === "bookmarks"} timeout={300}>
              <Box sx={{ display: view === "bookmarks" ? "block" : "none" }}>
                <Bookmarks view={view} />
              </Box>
            </Fade>
            <Fade in={view === "articles"} timeout={300}>
              <Stack sx={{ display: view === "articles" ? "block" : "none" }} spacing={4}>
                <Popular pageSize={10} view="popular" />
                <Latest pageSize={10} view="latest" />
              </Stack>
            </Fade>
          </Box>
          <Box py={4}>
            <Official pageSize={20} />
          </Box>
        </Container>
      </Fade>
    </>
  );
};
export default NotesNebula;

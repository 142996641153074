const junoVideo = {
  selection: {
    new: "新モデル",
    popularModel: "人気モデル",
    highQualityModel: "高画質",
    lowCostModel: "低コスト",
    highPrecisionModel: "高精度",
    cameraControl: "カメラコントロール",
    trending: "注目",
    fastModel: "高速",
    costEffective: "高コストパフォーマンス",
    popularLipSync: "リップシンク人気",
    imageToVideo: "画像から動画",
    videoEdit: "動画編集",
    upscale: "アップスケール",
    lipsync: "リップシンク",
  },
  title: "VIDEO GENERATOR",
  caution: "Google の提供価格により、VEO2のコストは非常に高くなっています。ご利用の際は注意してください。",
  menu: {
    t2v: "テキストから動画を生成",
    i2v: "画像から動画を生成",
    v2v: "動画から動画を生成",
    library: "ライブラリ",
    upscale: "アップスケール",
    lipsync: "リップシンク",
  },
  ai: {
    select: "AIモデルを選択してください",
    model: "モデル",
    runway: {
      title: "RunwayML",
      description: "【高速】AIと機械学習を活用して動画制作プロセスを簡素化し、プロから初心者まで幅広いユーザー向け。",
      model: {
        GEN3_ALPHA_TURBO: "Gen3 Alpha Turbo",
        GEN3_ALPHA_TURBO_description: "Runwayの最速モデル。迅速なイテレーションとプレビューに最適。",
      },
    },
    kling: {
      title: "KLING AI",
      description: "【低速】KLING AIは、高度な物理シミュレーションと3D技術を活用したリアルで自然な動きの動画を生成。",
      model: {
        V1_0_STANDARD: "スタンダード v1.0",
        V1_6_STANDARD: "スタンダード v1.6",
        V1_0_PRO: "プロ v1.0",
        V1_5_PRO: "プロ v1.5",
        V1_6_PRO: "プロ v1.6",
        V1_0_STANDARD_description: "初期のKLINGモデル。低コストで動画を生成。",
        V1_6_STANDARD_description: "KLIMGハイエンドモデルの廉価版。720pだが、優れた品質とリアリズムを提供。",
        V1_0_PRO_description: "初期のKLIMGプロモデル。高品質で長時間の動画を生成。",
        V1_5_PRO_description: "フルHDに対応したプロバージョン1。品質とリアリズムが向上しています。",
        V1_6_PRO_description: "フルHDに対応したプロバージョン1.6。最も高度なKLINGモデル。",
      },
    },
    luma: {
      title: "Luma",
      description: "【高速】Luma Dream Machineは、テキストや画像から高品質でリアルな動画を生成するAIモデル。",
      model: {
        V1_5: "Dream MACHINE v1.5",
        RAY_2: "RAY 2",
        RAY_2_1080P: "RAY 2 1080P",
        V1_5_description: "標準のLumaモデル。品質と速度のバランスが取れています。",
        RAY_2_description: "従来の倍以上の計算精度を持つLumaモデルでリアリズムが向上。",
        RAY_2_1080P_description: "従来の倍以上の計算精度を持つLumaモデルで1080p解像度によりさらに高品質。",
        RAY_2_FLASH: "RAY 2 Flash",
        RAY_2_FLASH_description: "RAY 2モデルの高速かつコスト効率の良いバージョン。",
        RAY_2_FLASH_1080P: "RAY 2 Flash 1080P",
        RAY_2_FLASH_1080P_description: "RAY 2 Flashモデルの1080P解像度バージョン。",
      },
    },
    hailuo: {
      title: "Hailuo AI",
      description: "【低速】Hailuo AI/Minimaxは、高品質な動画生成と人物の動きの正確な再現に優れているとされています。",
      model: {
        MINI_MAX: "VIDEO-01",
        MINI_MAX_LIVE: "VIDEO-01-LIVE",
        MINI_MAX_REF: "VIDEO-01-SUBJECT-REFFRENCE",
        MINI_MAX_DIRECTOR: "VIDEO-01-DIRECTOR",
        MINI_MAX_description: "標準のHailuoモデル。品質と速度のバランスが取れています。",
        MINI_MAX_LIVE_description: "2Dアニメーションスタイルに最適化されたHailuoモデル。",
        MINI_MAX_REF_description: "被写体参照用のHailuoモデル。一貫性を維持します。",
        MINI_MAX_DIRECTOR_description: "詳細なカメラコントロールが可能なHailuoモデル。",
      },
    },
    haiper: {
      title: "Haiper AI",
      description: "【低速】Haiper AIは、日本人の顔やポーズを自然に再現する能力に優れています",
      model: {
        V2_0: "Haiper V2.0",
        V2_5: "Haiper V2.5",
        V2_0_description: "Haiperの初期バージョン。基本的な機能を備えています。",
        V2_5_description: "V2.0の改良版。品質と機能が向上しています。",
      },
    },
    pixverse: {
      title: "PixVerse",
      description: "【高速】PixVerseは、高品質な動画生成AIで、テキストや画像から自然な動きの動画を生成します",
      model: {
        V3_5: "V3.5",
        V3_5_1080P: "V3.5 1080P",
        V3_5_FAST: "V3.5 Peformance",
        V3_5_description: "720P解像度のV3.5。高品質な動画を生成できます。",
        V3_5_1080P_description: "1080P解像度のV3.5。より高品質な動画を生成できます。",
        V3_5_FAST_description:
          "V3.5の改良版。アクションシーンや動きの多い場面に最適です。よりダイナミックで活発な動きを表現できます。",
      },
      style: {
        default: "デフォルト",
        anime: "アニメ",
        "3d_animation": "3Dアニメーション",
        clay: "クレイアート",
        comic: "コミック",
        cyberpunk: "サイバーパンク",
      },
    },
    vidu: {
      title: "Vidu",
      description: "【高速】Viduは、テキストや画像から高品質な動画を生成するAIモデルです。",
      model: {
        VIDU_2_0_720P: "Vidu v2.0 720P",
        VIDU_2_0_1080P: "Vidu v2.0 1080P",
        VIDU_2_0_REF_720P: "Vidu v2.0 Reference",
        VIDU_2_0_720P_description: "720P解像度のVidu 2.0。高品質な動画を生成できます。",
        VIDU_2_0_1080P_description: "1080P解像度のVidu 2.0。より高品質な動画を生成できます。",
        VIDU_2_0_REF_720P_description: "最大3枚の参照画像を組み合わせて理想の動画を生成可能",
      },
      movement: {
        auto: "自動",
        small: "小",
        medium: "中",
        large: "大",
      },
    },
    bytedance: {
      title: "ByteDance",
      description: "【高速】ByteDanceは、動画と音声を組み合わせて自然な動きを生成するAIモデルです。",
      model: {
        LATENTSYNC: "LantentSync",
        LATENTSYNC_description:
          "顔を検出してリアルな動きを生成するAIモデル。顔が検出されない場合は動画が生成されません。",
      },
    },
    sync: {
      title: "Sync",
      description: "【高速】Syncは、動画と音声を同期させて自然なリップシンクを生成するAIモデルです。",
      model: {
        LIPSYNC_190: "LipSync v1.9.0 Beta",
        LIPSYNC_190_description: "最新のリップシンクモデル。より自然な口の動きを生成します。",
      },
    },
    realesrgan: {
      title: "Real-ESRGAN 4K Upscaler",
      description:
        "画像アップスケールAIで有名なReal-ESRGANの技術を動画に応用し、動画の解像度を向上させ、より鮮明な映像を生成します。",
      model: {
        REALESRGAN: "Real-ESRGAN 4K",
        REALESRGAN_description:
          "画像アップスケールAIで有名なReal-ESRGANの技術を動画に応用し、動画の解像度を向上させ、より鮮明な映像を生成します。",
      },
    },
    google: {
      title: "Google",
      description: "【高速】Googleの動画生成AIです。高いコストがかかりますが、品質と自然な動きを提供します。",
      model: {
        VEO2: "VEO2",
        VEO2_description: "Googlが動画生成AI。コストは高いが、品質と自然な動きを提供。",
      },
    },
    wan: {
      title: "Wan",
      description:
        "【中速】Wanは、中国の大手IT企業Alibabaが開発した動画生成AIモデルです。比較的低コストで高精度な動画生成が可能です。",
      model: {
        V2_1: "Wan v2.1 720P",
        V2_1_description:
          "テキストから動画と画像から動画の両方に対応した最新のWanモデル。高品質で自然な動きの動画を生成します。",
        V2_1_PRO: "Wan Pro v2.1 1080P",
        V2_1_PRO_description: "V2.1のプロバージョン。1080P解像度30FPSで高品質な動画を生成します。",
      },
    },
    pika: {
      title: "Pika",
      description: "【高速】Pikaは高品質な画像から動画の生成と様々なアスペクト比に対応した動画生成AIモデルです。",
      model: {
        V2_2_720P: "Pika v2.2 720P",
        V2_2_720P_description: "720P解像度のPika 2.2。高品質な動画を生成できます。",
        V2_2_1080P: "Pika v2.2 1080P",
        V2_2_1080P_description: "1080P解像度のPika 2.2。より高品質な動画を生成できます。",
        V2_2_SCENES_720P: "Pika Scenes v2.2 720P",
        V2_2_SCENES_720P_description: "複数の画像からひとつのシーンを生成できます。",
        V2_2_SCENES_1080P: "Pika Scenes v2.2 1080P",
        V2_2_SCENES_1080P_description: "複数の画像からひとつのシーンを生成できます。",
        V2_TURBO_720P: "Pika Turbo v2 720P",
        V2_TURBO_720P_description: "高速生成に最適化されたPika Turbo 720P。",
      },
    },
    topazlabs: {
      title: "Topaz Labs",
      description:
        "次世代AIを活用した高品質なビデオアップスケーリングとフレームレート補完を提供します。最大4K 60FPSにまで対応。",
      model: {
        TOPAZ_VIDEO_UPSCALE: "Upscale 4K 60FPS",
        TOPAZ_VIDEO_UPSCALE_description:
          "動画アップスケーリングAI。高品質なビデオアップスケーリングとフレームレート補完を提供します。",
      },
    },
  },
  params: {
    title: "動画設定",
    size: "動画サイズ",
    upload: "画像アップロード",
    uploadFace: "画像アップロード（顔検出）",
    uploadFaceVideo: "動画アップロード（顔検出）",
    video: "動画アップロード",
    audio: "音声アップロード",
    duration: "動画の長さ",
    aspect: "アスペクト比",
    aspectTooltip:
      "画像から動画を生成する場合、モデルにより元画像がストレッチ、クロップ、リサイズされることがあります。",
    loop: "ループ",
    loopMode: "ループモード",
    style: "スタイル",
    resolution: "解像度",
    negativePrompt: "ネガティブプロンプト",
    movement: "動きの激しさ",
    guidanceScale: "ガイダンススケール",
    guidanceScaleHelp: "生成される動画の品質とクリエイティビティのバランスを調整します",
    scale: "スケール",
    scaleHelp: "長辺が3840px、短辺が2160pxを超えないように設定してください",
    syncModel: "リップシンクモデル",
    syncMode: "同期モード",
    pingpong: "ピンポン",
    loopType: "ループ",
    bounce: "バウンス",
    cutOff: "カットオフ",
    lipsync190: "LipSync v1.9.0 Beta",
    lipsync180: "LipSync v1.8.0",
    lipsync171: "LipSync v1.7.1",
    inferenceSteps: "推論ステップ数",
    inferenceStepsHelp: "高いほど高精度で低いほど早い",
    fps: "FPS",
    fpsHelp: "出力動画のフレームレートを設定します",
    input: {
      label: "プロンプト",
      placeholder: "動画のプロンプトを入力してください（英語推奨）",
      notRequired: "このモードではプロンプトは不要です",
      warning: "※ ポリシーに反する動画は生成できず、その際のクレジットは保証されません。",
      error: {
        button: "エラーの原因",
        tooltip:
          "エラーが続く場合、プロバイダー側で障害が発生している可能性が高いです。時間をおいて再度お試しください。",
      },
    },
    submit: "動画を生成",
  },
  myVideo: "マイビデオ",
  processing: "動画生成中...\n通常5分以内に完了します",
  sampleVideo: "サンプル動画",
  error: {
    generate: "動画の生成に失敗しました。\nポリシー違反がないか確認し、\n時間をおいて再度お試しください。",
    required: {
      image: "画像をアップロードしてください。",
      prompt: "プロンプトを入力してください。",
      onlyLastImage: "画像(FIRST)は必須です。",
    },
    max: {
      prompt: "プロンプトは512文字以内で入力してください。",
      resolution: "動画の解像度は829万画素（4K相当）以下にしてください。",
      videoSize: "動画のファイルサイズは50MB以下にしてください。",
      videoDuration: "動画の長さは{{sec}}秒以下にしてください。",
      audioSize: "音声のファイルサイズは30MB以下にしてください。",
      audioDuration: "音声の長さは60秒以下にしてください。",
      frames: "REALESRGANでは1000フレーム以上の動画は処理できません。",
    },
    processingLimit:
      "処理中のリクエスト数が上限に達しています。現在{{current}}件のリクエスト（画像と動画の合計）が処理中です。あなたのプランでは最大{{limit}}件の同時リクエストが可能です。",
  },
  startGenerating: "動画生成リクエストをキューに追加しました。",
  videoMenu: {
    download: "ダウンロード",
    delete: "削除",
    lastFrame: "最終フレームから生成",
  },
  message: {
    deleted: "動画を削除しました。",
  },
};
export default junoVideo;

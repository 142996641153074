import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CasinoIcon from "@mui/icons-material/Casino";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { Hidden, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAlert } from "../../../../context/AlertContext";
import { AiEngineList, AspectRatio, MenuList, PixVerseStyle, useVideo } from "../../../../context/juno/VideoContext";

import Typography from "@mui/material/Typography";
import ProPlanModal from "../../../../components/common/ProPlanModal";
import { useCheckCredit } from "../../../../hooks/useCreditCheck";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import { GradationButton } from "../../../../utils/gradationButton";
import { RowBox } from "../../../../utils/styledBox";
import InputPreset from "../../Images/components/InputPrompt/InputPreset";
import CameraMovementPreset from "./CameraMovementPreset";
import { alpha } from "@mui/material/styles";

type AiEngine = AiEngineList;

const InputPrompt = () => {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const [openProDialog, setOpenProDialog] = useState(false);

  const {
    prompt,
    setPrompt,
    setProcessing,
    aiEngine,
    modelVersion,
    duration,
    aspectRatios,
    lumaLoop,
    menu,
    image1,
    image2,
    image3,
    fetchVideoTrigger,
    setFetchVideoTrigger,
    styles,
    negativePrompt,
    inferenceSteps,
    // 動画と音声
    video1,
    audio1,
    // ByteDance用のパラメータ
    guidanceScale,
    loopMode,
    // Sync用のパラメータ
    syncMode,
    // Upscaler用のパラメータ
    scale,
    video1Metadata,
    fps,
  } = useVideo();

  const [localPrompt, setLocalPrompt] = useState<string>("");
  const [rotationDegree, setRotationDegree] = useState(0);
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);
  const [updatingType, setUpdatingType] = useState<"dice" | "brush" | null>(null);
  const [credit, setCredit] = useState<number | string | undefined>(undefined);
  const [isCalculatingCredit, setIsCalculatingCredit] = useState(false);
  const [disable3sec, setDisable3sec] = useState(false);

  useEffect(() => {
    if (disable3sec) {
      const timer = setTimeout(() => {
        setDisable3sec(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [disable3sec]);

  // メニュー変更時のプロンプト更新

  // 外部からのプロンプト変更をローカルに反映（メニューがupscaleまたはlipsyncの場合は更新しない）
  useEffect(() => {
    if (menu !== "upscale" && menu !== "lipsync") {
      setLocalPrompt(prompt);
    }
  }, [prompt, menu]);

  // ローカルプロンプト変更時の処理（入力中）
  const handleLocalPromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setLocalPrompt(newValue);
  };

  // フォーカスアウト時の処理
  const handlePromptBlur = () => {
    if (menu !== "upscale" && menu !== "lipsync") {
      setPrompt(localPrompt);
    }
  };

  const fetchCredit = async () => {
    const url = "/api/v1/juno/video-credit";
    const csrftoken = Cookies.get("csrftoken");
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken!,
    });

    interface CreditRequestBody {
      aiEngine: AiEngineList;
      model: string;
      action: MenuList;
      video1?: string;
      audio1?: string;
      scale?: number;
      duration?: number;
    }

    const currentModel = modelVersion[aiEngine];
    const body: CreditRequestBody = {
      aiEngine,
      model: currentModel,
      action: menu,
    };

    if (["lipsync", "upscale"].includes(menu) && video1) body.video1 = video1;
    if (["lipsync"].includes(menu) && audio1) body.audio1 = audio1;
    if (["upscale"].includes(menu) && scale) body.scale = scale;
    if (["t2v", "i2v"].includes(menu) && duration) body.duration = duration[aiEngine as AiEngine];

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    return response.json();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const currentModel = modelVersion[aiEngine];

      // クレジット計算の条件チェック
      const shouldCalculateCredit = () => {
        if (currentModel === "REALESRGAN" || currentModel === "TOPAZ_VIDEO_UPSCALE") {
          return !!video1;
        } else if (aiEngine === "SYNC" || aiEngine === "BYTEDANCE") {
          return video1 && audio1;
        }
        return true;
      };

      if (shouldCalculateCredit()) {
        setIsCalculatingCredit(true); // 計算開始前にフラグを設定
        fetchCredit().then((data) => {
          if (data.status === "success") {
            setCredit(data.credit);
          }
          setIsCalculatingCredit(false); // 計算完了後にフラグをリセット
        });
      } else {
        setCredit(" - ");
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [aiEngine, modelVersion, duration, audio1, video1, video1Metadata, scale]);

  // プロンプト生成APIへのリクエスト（ダイス）
  const generateSampleText = async () => {
    try {
      setUpdating(true);
      setUpdatingType("dice");
      if (!(await checkCredit())) return;
      setRotationDegree(rotationDegree + 360);
      setProcessing(true);

      const url = "/api/v1/juno/generate-video-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ aiEngine: aiEngine, modelVersion: modelVersion[aiEngine] }),
      });
      await response.json().then((data) => {
        setPrompt(data);
        dispatch(setCreditTrigger(true));
      });
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
      setUpdating(false);
      setUpdatingType(null);
    }
  };

  // プロンプト生成APIへのリクエスト（マジックブラシ）
  const updatePrompt = async () => {
    try {
      setUpdating(true);
      setUpdatingType("brush");
      if (!(await checkCredit())) return;
      setProcessing(true);

      const url = "/api/v1/juno/update-video-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          aiEngine: aiEngine,
          modelVersion: modelVersion[aiEngine],
          prompt: localPrompt,
        }),
      });
      await response.json().then((data) => {
        setPrompt(data);
        dispatch(setCreditTrigger(true));
      });
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
      setUpdating(false);
      setUpdatingType(null);
    }
  };

  const request_api_url = "/api/v1/juno/generate-video";

  const getHeaders = () => {
    const csrftoken = Cookies.get("csrftoken");
    return new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken!,
    });
  };

  // リクエストボディの生成
  interface RequestBody {
    generatedVideoUuid: string;
    prompt: string;
    aiEngine: AiEngineList;
    action: MenuList;
    model: string;
    duration: number;
    aspectRatio: AspectRatio;
    loop: boolean;
    image1: string;
    image2: string;
    image3: string;
    style?: PixVerseStyle;
    negativePrompt?: string;
    usageHistoryUuid?: string;
    steps?: number;
    // ByteDance用のパラメータ
    video1?: string;
    audio1?: string;
    guidanceScale?: number;
    loopMode?: string;
    // Sync用のパラメータ
    syncMode?: string;
    // Upscaler用のパラメータ
    scale?: number;
    fps?: number;
  }

  const buildRequestBody = (overrides = {}): RequestBody => {
    const body: RequestBody = {
      generatedVideoUuid: "",
      prompt: localPrompt,
      aiEngine,
      action: menu,
      model: modelVersion[aiEngine as AiEngine],
      duration: duration[aiEngine as AiEngine],
      aspectRatio: aspectRatios[aiEngine as AiEngine],
      loop: lumaLoop,
      steps: inferenceSteps[aiEngine as AiEngine],
      image1,
      image2,
      image3,
      ...overrides,
    };

    if (aiEngine === "PIXVERSE") {
      body.style = styles[aiEngine];
      body.negativePrompt = negativePrompt;
    } else if (aiEngine === "BYTEDANCE") {
      body.prompt = "";
      body.video1 = video1;
      body.audio1 = audio1;
      body.guidanceScale = guidanceScale;
      body.loopMode = loopMode;
    } else if (aiEngine === "SYNC") {
      body.prompt = "";
      body.video1 = video1;
      body.audio1 = audio1;
      body.syncMode = syncMode;
    } else if (aiEngine === "REALESRGAN") {
      body.prompt = "";
      body.video1 = video1;
      body.scale = scale;
    } else if (aiEngine === "PIKA") {
      body.negativePrompt = negativePrompt;
    } else if (aiEngine === "TOPAZLABS") {
      body.prompt = "";
      body.video1 = video1;
      body.scale = scale;
      body.fps = fps;
    }
    return body;
  };

  const handleSubmit = async () => {
    try {
      setProcessing(true);
      setDisable3sec(true);
      if (!validation()) return;
      const res = await createRequest();

      if (res.status === "error" && res.message === "UPGRADE_PLAN") {
        setOpenProDialog(true);
        return;
      }

      if (res.status === "error" && res.message === "NO_CREDITS") {
        setOpenProDialog(true);
        return;
      }

      // 同時リクエスト数制限エラーの処理を追加
      if (res.status === "error" && res.message === "PROCESSING_LIMIT_EXCEEDED") {
        setAlert(
          "error",
          t("juno.error.processingLimit", {
            current: res.current,
            limit: res.limit,
          })
        );
        return;
      }

      setFetchVideoTrigger(!fetchVideoTrigger);
      dispatch(setCreditTrigger(true));

      if (res.status === "success") {
        setAlert("success", t("juno.video.startGenerating"));
        await generateVideo(res.generated_video_uuid, res.usage_history_uuid);
      } else if (res.status === "error") {
        setAlert("error", t("juno.video.error.generate"));
      }
    } catch (e) {
      // SyntaxErrorの場合は特別な処理
      if (e instanceof SyntaxError && (e as SyntaxError).message.includes("Unexpected token '<'")) {
        console.warn("Request Timeout 524 (HTML Error Response Detected)");
      } else {
        console.error(e);
        setAlert("error", t("juno.video.error.generate"));
      }
    } finally {
      setFetchVideoTrigger(!fetchVideoTrigger);
      dispatch(setCreditTrigger(true));
      setDisable3sec(false);
      setProcessing(false);
    }
  };

  const validation = () => {
    // BYTEDANCE、SYNC、UPSCALERの場合はプロンプト入力を不要とする
    if (!["BYTEDANCE", "SYNC", "REALESRGAN", "TOPAZLABS"].includes(aiEngine)) {
      if (localPrompt.trim().length === 0) {
        setDisable3sec(false);
        setAlert("error", t("juno.video.error.required.prompt"));
        return false;
      }
      // 512文字以上の場合
      if (localPrompt.length > 512 && aiEngine == "RUNWAY") {
        setDisable3sec(false);
        setAlert("error", t("juno.video.error.max.prompt"));
        return false;
      }
      if (localPrompt.length > 1000 && aiEngine !== "RUNWAY") {
        setDisable3sec(false);
        setAlert("error", t("juno.video.error.max.prompt"));
        return false;
      }
    }
    // BYTEDANCE、SYNC、UPSCALERの場合は動画/音声ファイルのチェック
    if (aiEngine === "BYTEDANCE" || aiEngine === "SYNC") {
      if (!video1) {
        setDisable3sec(false);
        setAlert("error", t("juno.video.error.required.video"));
        return false;
      }
      if (!audio1) {
        setDisable3sec(false);
        setAlert("error", t("juno.video.error.required.audio"));
        return false;
      }
    } else if (aiEngine === "REALESRGAN") {
      if (!video1) {
        setDisable3sec(false);
        setAlert("error", t("juno.video.error.required.video"));
        return false;
      }
      // REALESRGANの場合、1000フレーム以上はエラー
      if (modelVersion[aiEngine] === "REALESRGAN" && video1Metadata) {
        const totalFrames = Math.floor(video1Metadata.fps * video1Metadata.duration);
        if (totalFrames >= 1000) {
          setDisable3sec(false);
          setAlert("error", t("juno.video.error.max.frames"));
          return false;
        }
      }
    } else if (menu === "i2v") {
      if (aiEngine === "RUNWAY") {
        if (image1.trim().length === 0 && image2.trim().length === 0) {
          setDisable3sec(false);
          setAlert("error", t("juno.video.error.required.image"));
          return false;
        }
      } else if (aiEngine === "VIDU") {
        if (modelVersion.VIDU === "VIDU_2_0_REF_720P") {
          if (image1.trim().length === 0 && image2.trim().length === 0 && image3.trim().length === 0) {
            setDisable3sec(false);
            setAlert("error", t("juno.video.error.required.image"));
            return false;
          }
        } else if (modelVersion.VIDU === "VIDU_2_0_720P" || modelVersion.VIDU === "VIDU_2_0_1080P") {
          if (image1.trim().length === 0) {
            setDisable3sec(false);
            setAlert("error", t("juno.video.error.required.onlyLastImage"));
            return false;
          }
        }
      } else if (modelVersion.LUMA === "RAY_2" || modelVersion.LUMA === "RAY_2_1080P") {
        if (image1.trim().length === 0 && image2.trim().length === 0) {
          setDisable3sec(false);
          setAlert("error", t("juno.video.error.required.image"));
          return false;
        }
      } else {
        if (image1.trim().length === 0) {
          setDisable3sec(false);
          setAlert("error", t("juno.video.error.required.image"));
          return false;
        }
      }
    }

    // i2vの場合、画像が16MB以下かどうかチェック
    const maxSizeInBytes = 16000000; // 16MB
    const validateImageSize = (image: string) => {
      if (image.trim().length === 0) return false; // 空の場合は無視
      const base64Length = image.length - (image.indexOf(",") + 1); // ヘッダー部分を除外
      const padding = image.endsWith("==") ? 2 : image.endsWith("=") ? 1 : 0;
      const actualFileSizeInBytes = (base64Length * 3) / 4 - padding; // 実際のサイズをバイトに換算
      return actualFileSizeInBytes > maxSizeInBytes;
    };

    if (aiEngine === "RUNWAY") {
      if (image1.trim().length > 0 && validateImageSize(image1)) {
        setDisable3sec(false);
        setAlert("error", t("juno.input.uploadError.size", { size: "16" }));
        return false;
      }
      if (image2.trim().length > 0 && validateImageSize(image2)) {
        setDisable3sec(false);
        setAlert("error", t("juno.input.uploadError.size", { size: "16" }));
        return false;
      }
    } else {
      if (validateImageSize(image1)) {
        setDisable3sec(false);
        setAlert("error", t("juno.input.uploadError.size", { size: "16" }));
        return false;
      }
    }
    return true;
  };

  // リクエスト生成
  const createRequest = async () => {
    const response = await fetch(request_api_url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(buildRequestBody()),
    });
    return response.json();
  };

  // 動画生成
  const generateVideo = async (generatedVideoUuid: string, usageHistoryUuid: string) => {
    setPrompt(localPrompt);

    const response = await fetch(request_api_url, {
      method: "PATCH",
      headers: getHeaders(),
      body: JSON.stringify(
        buildRequestBody({
          generatedVideoUuid,
          usageHistoryUuid,
        })
      ),
    });

    const res = await response.json();

    if (res === "success") {
      setFetchVideoTrigger(!fetchVideoTrigger);
      dispatch(setCreditTrigger(true));
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.5),
        borderRadius: 2,
        p: 2,
        // border: "1px solid",
        // borderColor: "divider",
      }}
    >
      <RowBox sx={{ gap: 2, alignItems: "flex-end", mb: 1 }}>
        <TextField
          // label={t("juno.video.params.input.label")}
          variant="standard"
          autoComplete={"off"}
          placeholder={t("juno.video.params.input.placeholder")}
          autoFocus
          value={menu === "upscale" || menu === "lipsync" ? t("juno.video.params.input.notRequired") : localPrompt}
          onChange={handleLocalPromptChange}
          onBlur={handlePromptBlur}
          disabled={menu === "upscale" || menu === "lipsync"}
          multiline
          fullWidth
          maxRows={8}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="body2" color="secondary" sx={{ ml: 1, display: { xs: "none", sm: "block" } }}>
                  @{aiEngine}:
                </Typography>
              </InputAdornment>
            ),
            sx: {
              borderRadius: 2,
              backgroundColor: "transparent",
              overflow: "hidden",
            },
            inputProps: { maxLength: aiEngine === "RUNWAY" ? 512 : 1000 },
          }}
          sx={{
            "& .MuiInput-root": {
              "&:before, &:after": {
                border: "none",
                display: "none",
              },
            },
          }}
        />
      </RowBox>
      <RowBox sx={{ gap: 2, justifyContent: "space-between", mt: 0.5 }}>
        <Box sx={{ gap: 2, display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            {/* サイコロアイコン（常に表示、プロンプトが入力されている場合はdisabled） */}
            <Tooltip title={t("juno.history.dice")}>
              <span>
                <IconButton
                  onClick={generateSampleText}
                  disabled={updating || menu === "upscale" || menu === "lipsync" || localPrompt.trim().length > 0}
                >
                  <CasinoIcon
                    sx={{
                      transform: `rotate(${rotationDegree}deg)`,
                      transition: "transform 1s ease-in-out",
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>

            {/* マジックブラシアイコン（処理中はプログレスアイコンを表示、それ以外は通常表示） */}
            <Tooltip title={t("juno.history.improve")}>
              <span>
                <IconButton
                  onClick={() => updatePrompt()}
                  disabled={updating || menu === "upscale" || menu === "lipsync" || localPrompt.trim().length === 0}
                >
                  {updating && updatingType === "brush" ? (
                    <CircularProgress size={24} color="primary" />
                  ) : (
                    <AutoFixHighIcon />
                  )}
                </IconButton>
              </span>
            </Tooltip>

            <InputPreset
              prompt={localPrompt}
              setPrompt={(newPrompt: string) => {
                setLocalPrompt(newPrompt);
                setPrompt(newPrompt);
              }}
              position="bottom"
              disabled={menu === "upscale" || menu === "lipsync" || updating}
            />
            <CameraMovementPreset
              prompt={localPrompt}
              setPrompt={(newPrompt: string) => {
                setLocalPrompt(newPrompt);
                setPrompt(newPrompt);
              }}
              position="bottom"
              disabled={menu === "upscale" || menu === "lipsync" || updating}
            />

            {/* delete */}
            <Tooltip title={t("common.delete")}>
              <span>
                <IconButton
                  onClick={() => {
                    setLocalPrompt("");
                    setPrompt("");
                  }}
                  disabled={menu === "upscale" || menu === "lipsync"}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          {aiEngine === "RUNWAY" && (
            <Typography variant="caption" color={localPrompt.length > 512 ? "error" : "textSecondary"}>
              {localPrompt.length}/512
            </Typography>
          )}
          {aiEngine !== "RUNWAY" && (
            <Typography variant="caption" color={localPrompt.length > 1000 ? "error" : "textSecondary"}>
              {localPrompt.length}/1000
            </Typography>
          )}
        </Box>

        <Typography variant="caption" sx={{ display: { xs: "none", sm: "block" } }}>
          {t("juno.video.params.input.warning")}
        </Typography>

        <GradationButton
          onClick={() => handleSubmit()}
          sx={{ minWidth: { xs: 40, sm: 240 }, px: 2 }}
          startIcon={disable3sec ? <CircularProgress size={20} /> : <SendIcon />}
          disabled={disable3sec}
        >
          <Hidden smDown>{t("juno.video.params.submit")}</Hidden>
          {isCalculatingCredit ? <CircularProgress size={16} color="inherit" sx={{ ml: 1 }} /> : `(${credit})`}
        </GradationButton>
      </RowBox>
      <ProPlanModal open={openProDialog} setOpen={setOpenProDialog} />
    </Box>
  );
};
export default InputPrompt;

import { Slider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import { useEffect, useState } from "react";

const Steps = () => {
  const { t } = useTranslation();
  const { step, setStep, aiEngine } = useParamsContext();

  // ローカルステートの初期値をコンテキストから取得
  const [localStep, setLocalStep] = useState(step[aiEngine!]);

  // コンポーネントがマウントされた時、またはaiEngineが変更された時にローカルステートを更新
  useEffect(() => {
    setLocalStep(step[aiEngine!]);
  }, [aiEngine, step]);

  // 確定時にコンテキストを更新する関数
  const handleConfirm = () => {
    setStep({ ...step, [aiEngine!]: localStep });
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
          <Typography variant={"body2"}>{t("juno.input.step")}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
          <Slider
            value={localStep}
            onChange={(e, value) => setLocalStep(value as number)}
            onChangeCommitted={handleConfirm}
            min={["Flux1Schnell", "Flux1SchnellRedux", "FluxJuggernautLightning"].includes(aiEngine) ? 1 : 10}
            max={["Flux1Schnell", "Flux1SchnellRedux", "FluxJuggernautLightning"].includes(aiEngine) ? 12 : 50}
            step={1}
          />
          <Box
            sx={{
              width: "120px",
              bgcolor: "rgba(0, 0, 0, 0.1)",
              px: 1,
              py: 0.5,
              justifyContent: "center",
              display: "flex",
              borderRadius: "5px",
            }}
          >
            <Typography variant={"body2"}>{localStep}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Steps;

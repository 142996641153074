import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import { Images } from "../../../../assets";
import { GradationButton } from "../../../../utils/gradationButton";
import { GradientTypography } from "../../../../utils/gradientTypography";
import { ColumnBox } from "../../../../utils/styledBox";

const WritingAI = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const cards = [
    {
      title: t("top.fv.writingAI.videoScript"),
      desc: t("top.fv.writingAI.videoScriptDesc"),
      image: Images.writing.shortVideo,
    },
    {
      title: t("top.fv.writingAI.summarize"),
      desc: t("top.fv.writingAI.summarizeDesc"),
      image: Images.writing.summary,
    },
    {
      title: t("top.fv.writingAI.seo"),
      desc: t("top.fv.writingAI.seoDesc"),
      image: Images.writing.article,
    },
    {
      title: t("top.fv.writingAI.template"),
      desc: t("top.fv.writingAI.templateDesc"),
      image: Images.top.promptTemplate,
    },
  ];

  const CardItem = ({ card }) => (
    <Card sx={{ height: "100%" }}>
      <CardMedia component="img" height="140" image={card.image} alt={card.title} />
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography gutterBottom variant="subtitle1" color="primary" component="span">
            {card.title}
          </Typography>
        </Box>
        <Typography variant="body2">{card.desc}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        overflow: "hidden",
        width: "100%",
        gap: 2,
        borderRadius: 2,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <GradientTypography variant="h5" component="h2" gutterBottom mb={4}>
            {t("top.fv.writingAI.title")}
          </GradientTypography>
          {/* <Typography paragraph>{t("top.fv.writingAI.description")}</Typography> */}

          <Grid container spacing={2}>
            {cards.map((card, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <CardItem key={index} card={card} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ColumnBox px={2} gap={2}>
            <GradientTypography variant="h5" component="h3" mb={2}>
              {t("top.fv.notesNebula.title")}
            </GradientTypography>
            <Box
              sx={{
                backgroundImage: `url(${Images.og.notesNebula})`,
                backgroundSize: "cover",
                height: 300,
                backgroundPosition: "center",
                borderRadius: 1,
              }}
            />
            <Typography variant="body1" gutterBottom>
              {t("top.fv.notesNebula.description")}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <GradationButton onClick={() => navigate("/notes_nebula")} sx={{}}>
              {t("top.fv.notesNebula.button")}
            </GradationButton>
          </ColumnBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WritingAI;

import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import MovieIcon from "@mui/icons-material/Movie";
import SubjectIcon from "@mui/icons-material/Subject";
import { Box, ButtonBase, Grid } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { ColumnCenteredBox, RowBox } from "../../../utils/styledBox";

const LibraryList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useCustomNavigate();
  const libraryItems = [
    {
      key: "images",
      path: "/juno/gallery",
      icon: <ImageOutlinedIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.images",
    },
    {
      key: "videos",
      path: "/juno/videos",
      icon: <MovieIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.videos",
    },
    {
      key: "text",
      path: "/notes-nebula/files",
      icon: <SubjectIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.text",
    },
    {
      key: "voices",
      path: "/juno/voice-generator",
      icon: <GraphicEqIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.voice",
    },
    {
      key: "mind-maps",
      path: "/library/mind-maps",
      icon: <AccountTreeOutlinedIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.mindmap",
    },
  ];

  return (
    <Box sx={{ pt: 2 }}>
      <RowBox sx={{ ml: 2, mb: 0.5, width: "inherit" }}>
        <FolderIcon fontSize={"small"} color={"secondary"} sx={{ mr: 1 }} />
        <Typography variant={"caption"} component={"span"}>
          {t("drawer.library")}
        </Typography>
      </RowBox>
      <Grid container spacing={0.5} sx={{ px: 1, py: 0.5 }}>
        {libraryItems.map((item) => (
          <Grid item xs={4} key={item.key}>
            <ButtonBase
              onClick={() => navigate(item.path)}
              sx={{
                borderRadius: 1,
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
                backgroundColor: (theme) =>
                  location.pathname.includes(item.path)
                    ? alpha(theme.palette.primary.main, 0.2)
                    : alpha(theme.palette.action.selected, 0.05),
                "&:hover": {
                  backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                },
              }}
            >
              <ColumnCenteredBox sx={{ m: 1, whiteSpace: "break-spaces" }}>
                {item.icon}
                <Typography
                  variant={"caption"}
                  component={"span"}
                  whiteSpace={"nowrap"}
                  sx={{ fontWeight: location.pathname.includes(item.path) ? "bold" : "normal" }}
                >
                  {t(item.label)}
                </Typography>
              </ColumnCenteredBox>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default LibraryList;

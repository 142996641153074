import FontDownloadIcon from "@mui/icons-material/FontDownload";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import OneKIcon from "@mui/icons-material/OneK";
import PhotoSizeSelectSmallIcon from "@mui/icons-material/PhotoSizeSelectSmall";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import TwoKIcon from "@mui/icons-material/TwoK";
import { RowBox } from "../../../../utils/styledBox";
import FourKIcon from "@mui/icons-material/FourK";

export const getActionIcon = (action: string) => {
  switch (action) {
    case "i2v":
      return (
        <RowBox gap={0.5}>
          <ImageIcon sx={{ fontSize: "16px" }} />
          <span>I2V</span>
        </RowBox>
      );
    case "t2v":
      return (
        <RowBox gap={0.5}>
          <FontDownloadIcon sx={{ fontSize: "16px" }} />
          <span>T2V</span>
        </RowBox>
      );
    case "v2v":
      return (
        <RowBox gap={0.5}>
          <MovieIcon sx={{ fontSize: "16px" }} />
          <span>V2V</span>
        </RowBox>
      );
    case "upscale":
      return (
        <RowBox gap={0.5}>
          <PhotoSizeSelectSmallIcon sx={{ fontSize: "16px" }} />
          <span>Upscale</span>
        </RowBox>
      );
    case "lipsync":
      return (
        <RowBox gap={0.5}>
          <RecordVoiceOverIcon sx={{ fontSize: "16px" }} />
          <span>Lipsync</span>
        </RowBox>
      );
    default:
      return null;
  }
};

export const getIcon = (version: string) => {
  if (version === "LATENTSYNC" || version === "LIPSYNC_190") {
    return undefined;
  }
  if (
    version === "V3_5_1080P" ||
    version === "V1_5_PRO" ||
    version === "V1_6_PRO" ||
    version === "VIDU_2_0_1080P" ||
    version === "V2_1_PRO" ||
    version === "RAY_2_1080P" ||
    version === "RAY_2_FLASH_1080P"
  ) {
    return <TwoKIcon fontSize="small" color="primary" />;
  }
  if (version === "REALESRGAN" || version === "TOPAZ_VIDEO_UPSCALE") {
    return <FourKIcon fontSize="small" color="success" />;
  }

  return <OneKIcon fontSize="small" color="secondary" />;
};

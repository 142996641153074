const header = {
  // クレジットのツールチップ
  tooltip: {
    totalCredits: "クレジット合計（月間クレジット + デイリークレジット + 追加クレジット）",
    monthlyCredits: "サブスクリプションプランで取得したクレジット（更新日にリセット）",
    dailyCredits: "毎日ログイン時にゲットできる50クレジット（最終受取から24時間経過でリセット）",
    additionalCredits: "購入済みの追加クレジット（無期限）",
  },
  upgrade: "アップグレード",
  addCredit: "クレジット",
};
export default header;

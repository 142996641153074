import { Box, Typography } from "@mui/material";
import React from "react";
import { RowCenteredBox } from "../../utils/styledBox";
import { Images } from "../../assets";
// srcディレクトリの画像をインポート

interface JunoLogoProps {
  onClick?: () => void;
}

const JunoLogoComponent: React.FC<JunoLogoProps> = ({ onClick }) => {
  return (
    <RowCenteredBox onClick={onClick} sx={{ cursor: onClick ? "pointer" : "default" }}>
      <img src={Images.juno.mini2} style={{ width: 35, height: 35, marginRight: 8 }} alt="Juno" />
      <Box display={{ xs: "none", md: "block" }}>
        <Typography variant="h5" component="p" sx={{ fontWeight: "bold" }}>
          STUDIO JUNO
        </Typography>
      </Box>
    </RowCenteredBox>
  );
};

JunoLogoComponent.displayName = "JunoLogo";

export const JunoLogo = React.memo(JunoLogoComponent);

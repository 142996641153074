import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorIcon from "@mui/icons-material/Error";
import LastPageIcon from "@mui/icons-material/LastPage";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PhotoSizeSelectSmallIcon from "@mui/icons-material/PhotoSizeSelectSmall";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../context/AlertContext";
import { API_STATUS, useVideo } from "../../../../context/juno/VideoContext";
import { useVideoOperations } from "../../../../hooks/useVideoOperations";
import { ColumnBox, ColumnCenteredBox, RowBox } from "../../../../utils/styledBox";
import VideoModal from "./VideoModal";
import { getActionIcon, getIcon } from "./utils";

// --------------------------------------------------
// 型定義
// --------------------------------------------------
type VideoType = {
  uuid: string;
  status: number;
  params: {
    action: string;
    aiEngine: string;
    model: string;
    prompt: string;
    duration: number;
    aspectRatio: string;
    error_code?: number;
    error_detail?: string;
  };
  url: string;
};

interface VideoListProps {
  grid?: number;
  size?: number;
  setVideo?: (video: string) => void;
  setOpen?: (open: boolean) => void;
}

/**
 * 最新動画一覧コンポーネント
 */
const LatestVideoList = ({ grid, size, setVideo, setOpen }: VideoListProps) => {
  const ITEMS_PER_PAGE = size || 6;
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { fetchVideoTrigger } = useVideo();
  const videoOperations = useVideoOperations();

  const [videos, setVideos] = useState<VideoType[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // モーダル関連
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // 動画のリファレンス配列と各動画の duration
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [videoDurations, setVideoDurations] = useState<number[]>([]);

  // シークの頻度を下げるために最後にシークを行った時間を記録
  const lastSeekTimeRef = useRef<number>(0);

  // --------------------------------------------------
  // 動画取得
  // --------------------------------------------------
  const fetchVideos = async (page: number) => {
    try {
      const response = await fetch(`/api/v1/juno/videos?page_size=${ITEMS_PER_PAGE}&page=${page}`);
      const data = await response.json();
      if (data.status === "error") {
        console.error("ビデオの取得中にエラーが発生しました:", data.message);
        return [];
      } else {
        setTotalPages(data.total_pages);
        return data.videos as VideoType[];
      }
    } catch (error) {
      console.error("フェッチエラー:", error);
      return [];
    }
  };

  useEffect(() => {
    const loadVideos = async () => {
      const data = await fetchVideos(currentPage);
      setVideos(data);
      // 動画の数に合わせてduration配列をリセット
      setVideoDurations(Array(data.length).fill(0));
    };
    loadVideos();
  }, [currentPage, fetchVideoTrigger]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  // PROCESSINGステータスのビデオが存在する場合、10秒ごとにポーリングしてステータスを更新
  useEffect(() => {
    const interval = setInterval(async () => {
      const processingVideos = videos.filter((video) => video.status === API_STATUS.PROCESSING);
      if (processingVideos.length > 0) {
        const data = await fetchVideos(currentPage);
        setVideos(data);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [videos, currentPage]);

  // --------------------------------------------------
  // メニュー関係（右上の ... アイコン）
  // --------------------------------------------------
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, videoUuid: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedVideo(videoUuid);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedVideo(null);
  };

  // --------------------------------------------------
  // 削除（確認ダイアログ制御）
  // --------------------------------------------------
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);

  /**
   * メニュー「Delete」がクリックされた場合 (or モーダルから削除ボタンを押された場合)
   * ダイアログを開き、どのuuidを削除するかを保持
   */
  const handleDialogOpen = (uuid: string) => {
    setSelectedUuid(uuid);
    setDialogOpen(true);
    handleMenuClose();
  };

  // ダイアログで「Delete」が押された場合 -> 実際の削除処理
  const handleConfirmedDelete = async (uuid: string) => {
    await handleDeleteVideo(uuid);
    setDialogOpen(false);
  };

  // 削除関数（APIコール）
  const handleDeleteVideo = async (generatedVideoUuid: string) => {
    const csrftoken = Cookies.get("csrftoken");
    const headers = new Headers();
    if (csrftoken) {
      headers.append("X-CSRFToken", csrftoken);
    }
    headers.append("Content-Type", "application/json");

    try {
      const response = await fetch(`/api/v1/juno/generate-video`, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify({ generatedVideoUuid: generatedVideoUuid }),
      });

      if (!response.ok) {
        throw new Error("ビデオの削除に失敗しました");
      }

      // 削除後にビデオを再取得
      const data = await fetchVideos(currentPage);
      setVideos(data);
      setAlert("success", "Video removed successfully.");
    } catch (error) {
      console.error("削除エラー:", error);
    }
  };

  // ビデオ操作関連の関数を共通フックから使用
  const handleVideoDownload = videoOperations.handleVideoDownload;

  // --------------------------------------------------
  // モーダル制御
  // --------------------------------------------------
  const handleOpenModal = (index: number) => {
    if (setVideo && setOpen) {
      // GeneratedVideoModalから呼ばれた場合
      const video = videos[index];
      fetch(`/api/v1/juno/generate-video?generatedVideoUuid=${video.uuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            setVideo(base64data);
            setOpen(false);
          };
          reader.readAsDataURL(blob);
        });
    } else {
      // 通常のモーダル表示
      setCurrentIndex(index);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleNextVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const handlePrevVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
  };

  // --------------------------------------------------
  // ホバー時のフレームプレビュー (シーク) の実装
  // --------------------------------------------------
  const handleLoadedMetadata = (index: number, duration: number) => {
    setVideoDurations((prev) => {
      const next = [...prev];
      next[index] = duration;
      return next;
    });
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    const now = Date.now();
    // 100ms より短い間隔ではシークしない -> シークの負荷を軽減
    if (now - lastSeekTimeRef.current < 75) {
      return;
    }
    lastSeekTimeRef.current = now;

    const videoEl = videoRefs.current[index];
    if (!videoEl) return;

    // カード内動画の横幅に対する相対位置を求める
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left; // カード左端からのマウス相対X位置
    const fraction = x / rect.width;
    const totalDuration = videoDurations[index] || 0;

    // シーク位置（0 ~ duration の範囲）
    if (totalDuration > 0 && fraction >= 0 && fraction <= 1) {
      videoEl.currentTime = fraction * totalDuration;
    }
  };

  const handleMouseEnter = (index: number) => {
    const videoEl = videoRefs.current[index];
    if (videoEl) {
      videoEl.pause(); // 音声含め停止
      videoEl.muted = true;
    }
  };

  const handleMouseLeave = (index: number) => {
    const videoEl = videoRefs.current[index];
    if (videoEl) {
      // ホバーを外れたら0秒に戻す
      videoEl.currentTime = 0;
    }
  };

  return (
    <ColumnBox>
      {/* Video Grid */}
      <Grid container spacing={2} sx={{ minHeight: 50 }}>
        {videos.map((video, index) => (
          <Grid item key={video.uuid} xs={12} sm={6} md={grid ? grid : 4}>
            <Fade in={true} timeout={index * 500 > 2000 ? 2000 : index * 500}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 2,
                  backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.7),
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    overflow: "hidden",
                    height: 265,
                  }}
                >
                  {/* ステータス別表示 */}
                  {video.status === API_STATUS.SUCCESS && (
                    <Box
                      sx={{ width: "100%", height: 215 }}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseMove={(e) => handleMouseMove(e, index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                      onClick={() => handleOpenModal(index)}
                    >
                      <video
                        ref={(el) => (videoRefs.current[index] = el)}
                        style={{ width: "100%", maxHeight: 215, objectFit: "contain", cursor: "pointer" }}
                        crossOrigin="anonymous"
                        controlsList="nodownload"
                        disablePictureInPicture
                        preload="auto"
                        onLoadedMetadata={(e) => {
                          const videoEl = e.currentTarget as HTMLVideoElement;
                          const duration = videoEl.duration;
                          handleLoadedMetadata(index, duration);
                          // メタデータ読み込み時に初期フレームを表示（初回のみ）
                          if (!videoDurations[index]) {
                            videoEl.currentTime = 0;
                            videoEl.pause();
                          }
                        }}
                        onError={(e) => {
                          console.error("ビデオの読み込みエラー:", e);
                        }}
                      >
                        <source src={video.url} type="video/mp4" />
                      </video>
                    </Box>
                  )}
                  {video.status === API_STATUS.ERROR && (
                    <ColumnCenteredBox
                      sx={{
                        height: 210,
                        gap: 2,
                        backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.8),
                      }}
                    >
                      <ErrorIcon color="error" />
                      <Typography variant={"body2"} sx={{ whiteSpace: "pre-line" }}>
                        Error generating video.
                      </Typography>
                      <Tooltip
                        title={
                          String(video.params.error_code ?? "").indexOf("400") >= 0 ||
                          String(video.params.error_code ?? "").indexOf("422") >= 0 ||
                          String(video.params.error_code ?? "").indexOf("500") >= 0
                            ? `${video.params.error_code}: ${video.params.error_detail}`
                            : t("juno.video.params.input.error.tooltip")
                        }
                        sx={{ whiteSpace: "pre-line" }}
                      >
                        <Button variant="text" size="small" sx={{ color: "text.secondary", px: 2 }}>
                          {t("juno.video.params.input.error.button")}
                        </Button>
                      </Tooltip>
                    </ColumnCenteredBox>
                  )}
                  {video.status === API_STATUS.PROCESSING && (
                    <Box sx={{ width: "100%", height: 210, position: "relative" }}>
                      <Skeleton variant="rectangular" width="100%" height={210} />
                      <ColumnCenteredBox
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: 210,
                          width: "100%",
                          gap: 2,
                          backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.8),
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                        <Typography variant="body2" sx={{ whiteSpace: "pre-line", textAlign: "center" }}>
                          Now Processing...
                        </Typography>
                      </ColumnCenteredBox>
                    </Box>
                  )}

                  {/* AIモデルなどの表示 + メニュー */}
                  <Box p={1}>
                    <RowBox justifyContent="space-between" mb={1}>
                      <RowBox onClick={() => handleOpenModal(index)} sx={{ cursor: "pointer" }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 600,
                            px: 1,
                            py: 0.2,
                            mr: 2,
                            backgroundColor: (theme) => theme.palette.secondary.dark,
                            borderRadius: "2px",
                            display: "inline-flex",
                          }}
                          color="#fff"
                          component="span"
                        >
                          {getActionIcon(video.params.action)}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 600, mr: 1 }} color="secondary">
                          {t(`juno.video.ai.${video.params.aiEngine.toLowerCase()}.title`)} -{" "}
                          {t(`juno.video.ai.${video.params.aiEngine.toLowerCase()}.model.${video.params.model}`)}
                        </Typography>
                        {getIcon(video.params.model)}
                      </RowBox>
                      <Tooltip title={t("common.download")}>
                        <span>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation(); // カード自体のクリック発火を阻止
                              handleVideoDownload(video.uuid);
                            }}
                            size={"small"}
                            disabled={video.status !== API_STATUS.SUCCESS}
                          >
                            <DownloadIcon fontSize={"small"} />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMenuClick(e, video.uuid);
                        }}
                        size={"small"}
                        disabled={video.status === API_STATUS.PROCESSING}
                      >
                        <MoreVertIcon fontSize={"small"} />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl && selectedVideo === video.uuid)}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {video.status !== API_STATUS.PROCESSING && (
                          <>
                            <MenuItem
                              onClick={() => {
                                // ここでダイアログを呼び出す
                                handleDialogOpen(video.uuid);
                              }}
                            >
                              <DeleteIcon fontSize={"small"} sx={{ mr: 2 }} color={"error"} />
                              {t("common.delete")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                videoOperations.handleLipSync(video.uuid, handleMenuClose);
                              }}
                              disabled={video.status !== API_STATUS.SUCCESS}
                            >
                              <RecordVoiceOverIcon fontSize={"small"} sx={{ mr: 2 }} />
                              {t("juno.video.menu.lipsync")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                videoOperations.handleUpscale(video.uuid, handleMenuClose);
                              }}
                              disabled={video.status !== API_STATUS.SUCCESS}
                            >
                              <PhotoSizeSelectSmallIcon fontSize={"small"} sx={{ mr: 2 }} />
                              {t("juno.video.menu.upscale")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                videoOperations.handleLastFrame(undefined, video.uuid, handleMenuClose);
                              }}
                              disabled={video.status !== API_STATUS.SUCCESS}
                            >
                              <LastPageIcon fontSize={"small"} sx={{ mr: 2 }} />
                              {t("juno.video.videoMenu.lastFrame")}
                            </MenuItem>
                          </>
                        )}
                      </Menu>
                    </RowBox>
                  </Box>
                </Box>
              </Card>
            </Fade>
          </Grid>
        ))}
        {videos.length === 0 && (
          <Grid item xs={12}>
            <Typography variant={"subtitle2"}>No videos found</Typography>
          </Grid>
        )}
      </Grid>

      {/* Pagination */}
      {totalPages > 1 && (
        <Box mt={4} display="flex" justifyContent="center">
          <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
        </Box>
      )}

      {/* モーダル */}
      <VideoModal
        open={openModal}
        onClose={handleCloseModal}
        videos={videos}
        currentIndex={currentIndex}
        handleNextVideo={handleNextVideo}
        handlePrevVideo={handlePrevVideo}
        // モーダル内「Delete」ボタンからも同じ確認ダイアログを呼び出したいので
        handleOpenDeleteDialog={handleDialogOpen}
        handleVideoDownload={handleVideoDownload}
      />

      {/* 削除確認ダイアログ */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} disableEnforceFocus>
        <DialogTitle>{t("juno.history.delete.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("juno.history.delete.description")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disableElevation onClick={() => setDialogOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
          <Button
            onClick={() => {
              if (selectedUuid) {
                handleConfirmedDelete(selectedUuid);
              }
            }}
            color="error"
          >
            {t("common.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </ColumnBox>
  );
};

export default LatestVideoList;

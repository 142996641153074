import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MoneyIcon from "@mui/icons-material/Money";
import PaletteIcon from "@mui/icons-material/Palette";
import { Container, Fade, Grid, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { alpha, Box, styled } from "@mui/system";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { JunoLogo } from "../../components/common/JunoLogo";
import Meta from "../../components/common/Meta";
import { NotesNebulaLogo } from "../../components/common/NotesNebulaLogo";
import { TenakLogo } from "../../components/common/TenakLogo";
// import { getDataLayer } from "../../context/EventContext";
import { useNotice } from "../../context/NoticeContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { Images } from "../../assets";
import { ColumnCenteredBox, RowBox } from "../../utils/styledBox";
import { default as AIButton, default as AIs } from "./components/AIs";
import Browsing from "./components/Browsing";
import Image from "./components/Image";
import Mindmap from "./components/Mindmap";
import Text from "./components/Text";
import WritingAI from "./components/WritingAI";
import { uiHeight } from "../../utils/uiHeight";
import { useCheckCredit } from "../../hooks/useCreditCheck";

const MemoizedBrowsing = React.memo(Browsing);
const MemoizedText = React.memo(Text);
const MemoizedMindmap = React.memo(Mindmap);
const MemoizedImage = React.memo(Image);
const MemoizedWriting = React.memo(WritingAI);

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const { triggerNotice } = useNotice();

  // 各コンポーネントが期待する正確な型を定義
  interface Chats {
    uuid: string;
    name: string;
    latest_history: string;
    updated_at: string;
    [key: string]: unknown;
  }

  interface MindmapData {
    mind_maps?: {
      uuid: string;
      title: string;
      created_at: string;
      [key: string]: unknown;
    }[];
    [key: string]: unknown;
  }

  interface TextData {
    text_files?: {
      uuid: string;
      title: string;
      content: string;
      [key: string]: unknown;
    }[];
    [key: string]: unknown;
  }

  // APIから返されるデータの型を定義
  interface ApiData {
    chats?: Chats[];
    mindmaps?: MindmapData[];
    text_files?: TextData[];
    [key: string]: unknown;
  }

  const [data, setData] = React.useState<ApiData | null>(null);
  const { fetchUserCreditsBackground } = useCheckCredit();

  // URLパラメータの処理
  useEffect(() => {
    // 購入時のメッセージを表示
    const params = new URLSearchParams(location.search);
    // 永久クレジット購入時のメッセージ
    if (params.get("permanent_credit") === "success") {
      triggerNotice("success", t("message.permanent_credit.success"));
    } else if (params.get("permanent_credit") === "cancel") {
      triggerNotice("info", t("message.permanent_credit.cancel"));
    } else if (params.get("permanent_credit") === "error") {
      triggerNotice("error", t("message.permanent_credit.error"));
    }

    // サブスクリプション購入時のメッセージ
    if (params.get("subscription") === "success") {
      triggerNotice("success", t("message.subscription.success"));
    } else if (params.get("subscription") === "cancel") {
      triggerNotice("info", t("message.subscription.cancel"));
    } else if (params.get("subscription") === "error") {
      triggerNotice("error", t("message.subscription.error"));
    }
  }, [location]);

  const getData = useCallback(async () => {
    try {
      const csrfToken = Cookies.get("csrftoken");
      const response = await axios.get("/api/v1/dash-board/", {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      });
      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    // バックグラウンドでクレジット情報を取得（再レンダリングを引き起こさない）
    fetchUserCreditsBackground();
  }, []);

  useEffect(() => {
    getData();
    // Daily Creditsの取得
  }, []);

  const StyledBox = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 16,
    // backgroundColor: theme.palette.background.custom1,
    borderRadius: 8,
    width: "100%",
    // height: "100%",
  }));

  // 各コンポーネントに渡すpropsを適切な型に変換
  const browsingProps = useMemo(() => {
    // nullの場合は空のオブジェクトを返す
    if (!data) return { data: { chats: [] }, getData };

    // APIデータをBrowsingコンポーネントが期待する形式に変換
    const formattedChats: Chats[] = (data.chats || []).map((chat: any) => ({
      uuid: chat.id || chat.uuid || "",
      name: chat.title || chat.name || "",
      latest_history: chat.latest_history || "",
      updated_at: chat.created_at || chat.updated_at || "",
      ...chat,
    }));

    return {
      data: { chats: formattedChats },
      getData,
    };
  }, [data, getData]);

  const mindmapProps = useMemo(() => {
    // nullの場合はundefinedを返す
    if (!data) return { data: undefined };

    // APIデータをMindmapコンポーネントが期待する形式に変換
    const formattedMindmaps = (data.mindmaps || []).map((mindmap: any) => ({
      uuid: mindmap.id || mindmap.uuid || "",
      title: mindmap.title || "",
      created_at: mindmap.created_at || "",
      ...mindmap,
    }));

    return {
      data: {
        mind_maps: formattedMindmaps,
        ...data,
      },
    };
  }, [data]);

  const textProps = useMemo(() => {
    // nullの場合はundefinedを返す
    if (!data) return { data: undefined };

    // APIデータをTextコンポーネントが期待する形式に変換
    const formattedTextFiles = (data.text_files || []).map((textFile: any) => ({
      uuid: textFile.id || textFile.uuid || "",
      title: textFile.title || "",
      content: textFile.content || textFile.created_at || "",
      ...textFile,
    }));

    return {
      data: {
        text_files: formattedTextFiles,
        ...data,
      },
    };
  }, [data]);
  const navigate = useCustomNavigate();

  return (
    <>
      <Meta title={t("dashboard.title")} />
      <Fade in={true} timeout={1500}>
        <Container
          maxWidth={false}
          sx={{
            padding: "0px!Important",
            backgroundImage: `url(${Images.common.bg01})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            // backgroundAttachment: "fixed",
          }}
        >
          <Meta title={t("dashboard.title")} />

          <Grid container spacing={0} sx={{ minHeight: uiHeight }}>
            {/* 通知 */}
            {/* <Grid item xs={12}>
              {massage && (
                <Grid item xs={12} sm={12}>
                  <Grow in={true} timeout={1000}>
                    <StyledBox>
                      <Typography variant={"body1"}>
                        {getDataLayer("DashboadMessage")?.message || "Dashboard"}
                      </Typography>
                    </StyledBox>
                  </Grow>
                </Grid>
              )}
            </Grid> */}
            {/* TENAK */}
            <Grid item xs={12} sm={4} sx={{ minHeight: "100%" }}>
              <ColumnCenteredBox
                sx={{
                  background: (theme) => alpha(theme.palette.background.custom1, 0.5),
                  pb: 8,
                  px: 4,
                  height: "100%",
                }}
              >
                <StyledBox sx={{ mt: 8 }}>
                  <TenakLogo onClick={() => navigate("")} />
                </StyledBox>

                <Typography variant="body2" sx={{ mb: 2 }}>
                  {t("dashboard.tenak.description")}
                </Typography>

                <StyledBox>{data && <MemoizedBrowsing {...browsingProps} />}</StyledBox>
                {/* Mind Map */}
                <StyledBox>{data && <MemoizedMindmap {...mindmapProps} />}</StyledBox>

                <StyledBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <MemoizedWriting />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <RowBox sx={{ height: 48, mb: -2, mt: 2 }}>
                        <Typography
                          variant={"h6"}
                          component={"h3"}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <AutoAwesomeIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
                          {t("dashboard.misc")}
                        </Typography>
                      </RowBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <AIButton
                        title={t("dashboard.tenak.color")}
                        icon={<PaletteIcon />}
                        path="/color-generator"
                        img={Images.dashBoard.color}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <AIButton
                        title={t("dashboard.tenak.template")}
                        icon={<MoneyIcon />}
                        path="/template"
                        img={Images.dashBoard.prompt}
                      />
                    </Grid>
                  </Grid>
                </StyledBox>
                <Box sx={{ flexGrow: 1 }} />
              </ColumnCenteredBox>
            </Grid>

            {/* STUDIO JUNO */}
            <Grid item xs={12} sm={4} sx={{ minHeight: "100%" }}>
              <ColumnCenteredBox
                sx={{
                  background: (theme) => alpha(theme.palette.background.paper, 0.5),
                  pb: 8,
                  px: 4,
                  height: "100%",
                }}
              >
                <StyledBox sx={{ mt: 8 }}>
                  <JunoLogo onClick={() => navigate("/juno")} />
                </StyledBox>

                <Typography variant="body2" sx={{ mb: 2 }}>
                  {t("dashboard.juno.description")}
                </Typography>

                <StyledBox>{data && <MemoizedImage />}</StyledBox>
                <Box sx={{ flexGrow: 1 }} />
              </ColumnCenteredBox>
            </Grid>

            {/* Notes Nebula */}
            <Grid item xs={12} sm={4} sx={{ minHeight: "100%" }}>
              <ColumnCenteredBox
                sx={{
                  background: (theme) => alpha(theme.palette.background.custom1, 0.5),
                  pb: 8,
                  px: 4,
                  height: "100%",
                }}
              >
                <StyledBox sx={{ mt: 8 }}>
                  <NotesNebulaLogo onClick={() => navigate("/notes-nebula")} />
                </StyledBox>

                <Typography variant="body2" sx={{ mb: 2 }}>
                  {t("dashboard.notesNebula.description")}
                </Typography>

                <StyledBox>
                  <AIs
                    title={t("dashboard.notesNebula.read")}
                    icon={<LibraryBooksIcon />}
                    path="/notes-nebula"
                    img={Images.notesNebula.top}
                    mainTitle={t("dashboard.notesNebula.read")}
                  />
                </StyledBox>
                <StyledBox>{data && <MemoizedText {...textProps} />}</StyledBox>
                <Box sx={{ flexGrow: 1 }} />
              </ColumnCenteredBox>
            </Grid>
          </Grid>
        </Container>
      </Fade>
    </>
  );
};

export default React.memo(Dashboard);

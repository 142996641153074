import { ButtonBase, Fade } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { GradientTypography } from "../../../utils/gradientTypography";
import { uiHeight } from "../../../utils/uiHeight";
import Assistant from "./Assistant/Assistant";
import PublicSetting from "./PublicSetting/PublicSetting";

const Sidebar = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState("assistant");
  const tabWidth = 200;

  return (
    <Paper
      elevation={0}
      sx={{
        maxHeight: uiHeight,
        overflowY: { xs: "", md: "auto" },
        borderRadius: 2,
        backgroundColor: "background.default",
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            borderRadius: 2,
            bgcolor: alpha(theme.palette.background.custom1, 1),
            overflow: "hidden",
            width: "fit-content",
            mt: 2,
          }}
        >
          <ButtonBase
            color="primary"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setSelectedTab("assistant");
            }}
            sx={{
              py: 1,
              px: 2,
              transition: "color 0.2s ease-in-out",
              width: { xs: 160, sm: tabWidth },
              bgcolor: selectedTab === "assistant" ? alpha(theme.palette.primary.main, 0.2) : "transparent",
              "&:hover": { color: theme.palette.primary.main },
            }}
          >
            <GradientTypography variant={"button"}>{t("textEditor.sidebar.aiWritingAssistant")}</GradientTypography>
          </ButtonBase>
          <ButtonBase
            color="primary"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setSelectedTab("publicSetting");
            }}
            sx={{
              py: 1,
              px: 2,
              transition: "color 0.2s ease-in-out",
              bgcolor: selectedTab === "publicSetting" ? alpha(theme.palette.primary.main, 0.2) : "transparent",
              width: { xs: 160, sm: tabWidth },
              "&:hover": { color: theme.palette.primary.main },
            }}
          >
            <Typography variant={"button"}>{t("textEditor.sidebar.publicSettings")}</Typography>
          </ButtonBase>
        </Box>
      </Box>
      <Fade in={true} timeout={1000}>
        <div>
          {selectedTab === "assistant" && <Assistant />}
          {selectedTab === "publicSetting" && <PublicSetting />}
        </div>
      </Fade>
    </Paper>
  );
};
export default Sidebar;

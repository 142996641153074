import BoltIcon from "@mui/icons-material/Bolt";
import InfoIcon from "@mui/icons-material/Info";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { alpha, Box, ButtonBase, IconButton, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAIEngine } from "../../../context/AIEngineContext";
import { ColumnCenteredBox, RowCenteredBox } from "../../../utils/styledBox";

const AIEngineSwitch: React.FC = () => {
  const { t } = useTranslation();
  const { advancedAi, changeAiEngine } = useAIEngine();

  const handleToggle = () => {
    changeAiEngine(!advancedAi);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        zIndex: 100,
      }}
    >
      <ButtonBase onClick={handleToggle} sx={{ borderRadius: 25, width: "100%" }}>
        <RowCenteredBox
          color="primary"
          sx={{
            height: 30,
            // backgroundColor: "primary.light",
            backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.25),
            borderRadius: 25,
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <ColumnCenteredBox
            sx={{
              position: "absolute",
              left: 0,
              backgroundColor: "primary.main",
              borderRadius: "50%",
              width: 36,
              height: 36,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {advancedAi ? (
              <SmartToyIcon sx={{ color: "#fff", fontSize: 20 }} />
            ) : (
              <BoltIcon sx={{ color: "#fff", fontSize: 20 }} />
            )}
          </ColumnCenteredBox>
          <Typography variant={"body2"} width={"100%"} sx={{ pl: 4, pr: 1, fontWeight: 600 }}>
            {advancedAi ? t("drawer.intelligentMode") : t("drawer.speedMode")}
          </Typography>
        </RowCenteredBox>
      </ButtonBase>
      <Box ml={1}>
        <Tooltip title={t("drawer.modeDescription")} placement="bottom">
          <IconButton size={"small"}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default AIEngineSwitch;

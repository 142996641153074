import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import { ManualPlacementSelectionEnum } from "../../../../../types/junoTypes";

const ManualPlacementSelection: React.FC = () => {
  const { t } = useTranslation();
  const { aiEngine, manualPlacementSelection, setManualPlacementSelection } = useParamsContext();
  const [selectedPlacement, setSelectedPlacement] = useState<ManualPlacementSelectionEnum>("bottom_center");

  // 初期値の設定
  useEffect(() => {
    if (aiEngine === "BriaProductShot") {
      const currentSelection = manualPlacementSelection[aiEngine];
      if (currentSelection) {
        setSelectedPlacement(currentSelection as ManualPlacementSelectionEnum);
      } else {
        // デフォルト値
        setSelectedPlacement("bottom_center");
        setManualPlacementSelection({
          ...manualPlacementSelection,
          [aiEngine]: "bottom_center",
        });
      }
    }
  }, [aiEngine, manualPlacementSelection, setManualPlacementSelection]);

  // BriaProductShotモデルの場合のみ表示
  if (aiEngine !== "BriaProductShot") {
    return null;
  }

  // 選択肢の変更ハンドラ
  const handleChange = (placement: ManualPlacementSelectionEnum) => {
    setSelectedPlacement(placement);
    setManualPlacementSelection({
      ...manualPlacementSelection,
      [aiEngine]: placement,
    });
  };

  const placementOptions: ManualPlacementSelectionEnum[] = [
    "original",
    "upper_left",
    "upper_center",
    "upper_right",
    "left_center",
    "center_vertical",
    "right_center",
    "bottom_left",
    "bottom_center",
    "bottom_right",
    "center_horizontal",
  ];

  return (
    <Box sx={{ mb: 2 }}>
      <FormControl fullWidth variant="standard">
        <Typography variant="body2" mb={1}>
          {t("juno.input.manualPlacementSelection.title")}
        </Typography>
        <Select
          value={selectedPlacement}
          onChange={(e) => handleChange(e.target.value as ManualPlacementSelectionEnum)}
          size="small"
        >
          {placementOptions.map((placement) => (
            <MenuItem key={placement} value={placement}>
              {t(`juno.input.manualPlacementSelection.${placement}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ManualPlacementSelection;

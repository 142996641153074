import Crop169Icon from "@mui/icons-material/Crop169";
import CropDinIcon from "@mui/icons-material/CropDin";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../../context/juno/VideoContext";
import { ColumnBox, RowCenteredBox } from "../../../../../utils/styledBox";
import AspectRatioIcons from "../AspectRatioIcons";
import ImageUpload from "../ImageUpload";
import ModelVersionSelectBox from "../ModelVersionSelectBox";

const ParamsVidu = () => {
  const { t } = useTranslation();
  const {
    menu,
    updateParams,
    image1,
    setImage1,
    duration,
    modelVersion,
    movements,
    setImage2,
    image2,
    image3,
    setImage3,
  } = useVideo();

  const aspectRatioRef: AspectRatioIconType[] = [
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
    { ratio: "1:1", icon: CropDinIcon },
  ];
  const aspectRatio: AspectRatioIconType[] = [{ ratio: "AUTO", icon: HdrAutoIcon }];

  const isDurationDisabled = modelVersion.VIDU === "VIDU_2_0_REF_720P" || modelVersion.VIDU === "VIDU_2_0_1080P";

  const durationMarks =
    modelVersion.VIDU === "VIDU_2_0_1080P"
      ? [{ value: 4, label: "4s" }]
      : [
          { value: 4, label: "4s" },
          { value: 8, label: "8s" },
        ];

  useEffect(() => {
    if (
      (menu === "i2v" && duration.VIDU != 4 && modelVersion.VIDU === "VIDU_2_0_REF_720P") ||
      modelVersion.VIDU === "VIDU_2_0_1080P"
    ) {
      updateParams("VIDU", "duration", 4);
    }
  }, [menu, modelVersion.VIDU]);

  const replaceImage = () => {
    const tmp = image1;
    setImage1(image2);
    setImage2(tmp);
  };

  const movementAmplitudeOptions = ["auto", "small", "medium", "large"];

  return (
    <ColumnBox sx={{ gap: 4 }}>
      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <ModelVersionSelectBox
          platform="VIDU"
          value={modelVersion.VIDU}
          onChange={(value) => updateParams("VIDU", "modelVersion", value)}
          menu={menu}
        />
      </ColumnBox>
      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.duration")}
        </Typography>
        <Slider
          aria-label="duration"
          marks={durationMarks}
          min={4}
          max={8}
          step={4}
          value={isDurationDisabled ? 4 : duration.VIDU}
          onChange={(_, value) => updateParams("VIDU", "duration", value)}
          disabled={isDurationDisabled}
        />
      </ColumnBox>
      <ColumnBox sx={{ justifyContent: "center" }}>
        {modelVersion.VIDU === "VIDU_2_0_REF_720P" ? (
          <>
            <Typography variant="body2" mb={1}>
              {t("juno.video.params.upload")} - 01
            </Typography>
            <ImageUpload image={image1} setImage={setImage1} />
            <Typography variant="body2" mb={1} mt={2}>
              {t("juno.video.params.upload")} - 02
            </Typography>
            <ImageUpload image={image2} setImage={setImage2} />
            <Typography variant="body2" mb={1} mt={2}>
              {t("juno.video.params.upload")} - 03
            </Typography>
            <ImageUpload image={image3} setImage={setImage3} />
          </>
        ) : (
          <>
            <Typography variant="body2" mb={1}>
              {t("juno.video.params.upload")} - FIRST
            </Typography>
            <ImageUpload image={image1} setImage={setImage1} />
            <RowCenteredBox sx={{ my: 2 }}>
              <IconButton onClick={() => replaceImage()}>
                <SwapVertIcon />
              </IconButton>
            </RowCenteredBox>
            <Typography variant="body2" mb={1}>
              {t("juno.video.params.upload")} - LAST
            </Typography>
            <ImageUpload image={image2} setImage={setImage2} />
          </>
        )}
      </ColumnBox>
      <ColumnBox>
        <AspectRatioIcons aspectRatioList={modelVersion.VIDU === "VIDU_2_0_REF_720P" ? aspectRatioRef : aspectRatio} />
      </ColumnBox>
      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.movement")}
        </Typography>
        <Select
          value={movements.VIDU}
          onChange={(e) => updateParams("VIDU", "movement", e.target.value)}
          variant="standard"
          fullWidth
        >
          {movementAmplitudeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {t(`juno.video.ai.vidu.movement.${option}`)}
            </MenuItem>
          ))}
        </Select>
      </ColumnBox>
    </ColumnBox>
  );
};

export default ParamsVidu;

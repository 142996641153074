import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SyncMode, useVideo } from "../../../../../context/juno/VideoContext";
import { ColumnBox } from "../../../../../utils/styledBox";
import AudioUpload from "../AudioUpload";
import ModelVersionSelectBox from "../ModelVersionSelectBox";
import VideoUpload from "../VideoUpload";

const ParamsSync = () => {
  const { t } = useTranslation();
  const { syncMode, setSyncMode, video1, setVideo1, audio1, setAudio1, modelVersion, updateParams } = useVideo();

  return (
    <ColumnBox sx={{ gap: 4 }}>
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <ModelVersionSelectBox
          platform="SYNC"
          value={modelVersion.SYNC}
          onChange={(value) => updateParams("SYNC", "modelVersion", value)}
          menu={"lipsync"}
        />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.video")}
        </Typography>
        <VideoUpload video={video1} setVideo={setVideo1} />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.audio")}
        </Typography>
        <AudioUpload audio={audio1} setAudio={setAudio1} />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.syncMode")}
        </Typography>
        <FormControl fullWidth>
          <Select value={syncMode} onChange={(e) => setSyncMode(e.target.value as SyncMode)}>
            <MenuItem value="cut_off">{t("juno.video.params.cutOff")}</MenuItem>
            <MenuItem value="loop">{t("juno.video.params.loop")}</MenuItem>
            <MenuItem value="bounce">{t("juno.video.params.bounce")}</MenuItem>
          </Select>
        </FormControl>
      </ColumnBox>
    </ColumnBox>
  );
};

export default ParamsSync;

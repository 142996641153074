import BoltIcon from "@mui/icons-material/Bolt";
import GppBadIcon from "@mui/icons-material/GppBad";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { Box, BoxProps, Button, Divider, Grid, Grow, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GradationButton } from "../../../../utils/gradationButton";
import { GradientTypography } from "../../../../utils/gradientTypography";

interface PricingCardProps {
  planKey: string;
  currentPlan: string;
  isLoggedIn: boolean | null;
  index: number;
  handleSubscribeClick: (plan: string) => void;
  getPlanLevel: (planName: string) => number;
  loading?: boolean; // プランのロード状態
}

const PricingCard = ({
  planKey,
  currentPlan,
  isLoggedIn,
  index,
  handleSubscribeClick,
  getPlanLevel,
  loading = false,
}: PricingCardProps) => {
  const { t } = useTranslation();

  const LabelBox = (props: BoxProps) => {
    return (
      <Box
        {...props}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "row",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        {props.children}
      </Box>
    );
  };

  // mountedRef を使用して初回マウント時のみアニメーションを発火させる
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Grow in={mounted} timeout={(index + 1) * 500} key={index}>
      <Grid item xs={12} md={3}>
        <Paper sx={{ p: 2, textAlign: "center", height: "100%" }}>
          <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <GradientTypography variant="h5" mb={2}>
              {t(`pricing.${planKey}.title`)}
            </GradientTypography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "row",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <Typography variant="h4" component={"p"} mr={1}>
                ${t(`pricing.${planKey}.price`)}
              </Typography>
              <Typography variant="subtitle1" component={"p"} sx={{ mr: 1 }}>
                USD
              </Typography>
              <Typography variant="subtitle1" component={"p"}>
                {t(`pricing.perMonth`)}
              </Typography>
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box mb={2} gap={1}>
              {/* クレジット */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                  {t(`pricing.${planKey}.credit`)}&nbsp;
                  {t(`pricing.credit`)}
                  {planKey === "free" && <>&nbsp;{t(`pricing.firstTimeOnly`)}</>}
                </Typography>
              </LabelBox>

              {/* デイリークレジット */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                  {"50 " + t(`pricing.dailyCredit`)}
                </Typography>
              </LabelBox>

              {/* AI エンジン */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                  {t(`pricing.aiEngine`)}
                </Typography>
              </LabelBox>

              {/* 画像生成 */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                  {t(`pricing.juno`)}
                </Typography>
              </LabelBox>

              {/* 生成画像の非公開設定 */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  {["free", "basic"].includes(planKey) ? (
                    <GppBadIcon fontSize={"small"} color={"error"} sx={{ mx: 1 }} />
                  ) : (
                    <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                  )}
                  {t(`pricing.junoPrivate`)}
                </Typography>
              </LabelBox>

              {/* 全ての画像生成エンジン */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  {["free"].includes(planKey) ? (
                    <>
                      <GppBadIcon fontSize={"small"} color={"error"} sx={{ mx: 1 }} />
                      {t(`pricing.junoEnginePart`)}
                    </>
                  ) : (
                    <>
                      <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                      {t(`pricing.junoEngine`)}
                    </>
                  )}
                </Typography>
              </LabelBox>

              {/* 動画生成 */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  {["free"].includes(planKey) ? (
                    <>
                      <GppBadIcon fontSize={"small"} color={"error"} sx={{ mx: 1 }} />
                      {t(`pricing.junoVideo`)}
                    </>
                  ) : (
                    <>
                      <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                      {t(`pricing.junoVideo`)}
                    </>
                  )}
                </Typography>
              </LabelBox>

              {/* STUDIO JUNO 同時生成数上限 */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  <GppGoodIcon
                    fontSize={"small"}
                    color={["free"].includes(planKey) ? "success" : "info"}
                    sx={{ mx: 1 }}
                  />
                  {t(`pricing.junoSimultaneousLimit`)}:&nbsp;
                  {planKey === "free" ? "2" : planKey === "basic" ? "4" : planKey === "standard" ? "6" : "8"}
                </Typography>
              </LabelBox>

              {/* MindMap */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                  {t(`pricing.mindmap`)}
                </Typography>
              </LabelBox>

              {/* MindMap */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  {["free"].includes(planKey) ? (
                    <GppBadIcon fontSize={"small"} color={"error"} sx={{ mx: 1 }} />
                  ) : (
                    <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                  )}
                  {t(`pricing.export`)}
                </Typography>
              </LabelBox>

              {/* 音声生成 */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                  {t(`pricing.voice`)}
                </Typography>
              </LabelBox>

              {/* Template */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                  {t(`pricing.template`)}
                </Typography>
              </LabelBox>

              {/* AI Text editor */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                  {t(`pricing.textEditor`)}
                </Typography>
              </LabelBox>

              {/* 追加クレジットの購入 */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  {["free", "basic"].includes(planKey) ? (
                    <GppBadIcon fontSize={"small"} color={"error"} sx={{ mx: 1 }} />
                  ) : planKey === "premium" ? (
                    <GppGoodIcon fontSize={"small"} color={"info"} sx={{ mx: 1 }} />
                  ) : (
                    <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                  )}
                  {planKey === "premium"
                    ? t("pricing.additionalCredits") + t("pricing.permanentCredit.discountRatePremium")
                    : t(`pricing.additionalCredits`)}
                </Typography>
              </LabelBox>

              {/* サポート */}
              <LabelBox>
                <Typography variant="body1" component={"p"} mr={1} alignItems={"center"} display={"inline-flex"}>
                  {["free"].includes(planKey) ? (
                    <>
                      <GppBadIcon fontSize={"small"} color={"error"} sx={{ mx: 1 }} />
                      {t(`pricing.supportNo`)}
                    </>
                  ) : ["basic"].includes(planKey) ? (
                    <>
                      <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                      {t(`pricing.support`)}
                    </>
                  ) : (
                    <>
                      <GppGoodIcon fontSize={"small"} color={"info"} sx={{ mx: 1 }} />
                      {t(`pricing.supportPriority`)}
                    </>
                  )}
                </Typography>
              </LabelBox>
            </Box>

            <Box flexGrow={1} />

            {loading ? (
              // ロード中は全てのボタンをDisable
              <Button variant="contained" disabled fullWidth>
                Loading...
              </Button>
            ) : isLoggedIn && planKey === currentPlan.replace("_jpy", "") ? (
              <Button variant="contained" disabled fullWidth>
                {t("preferences.planAndBillings.currentPlan")}
              </Button>
            ) : (
              <>
                {getPlanLevel(currentPlan) >= getPlanLevel(planKey) ? (
                  <Box height={36.5} />
                ) : (
                  <GradationButton
                    fullWidth
                    onClick={() => {
                      handleSubscribeClick(planKey);
                    }}
                  >
                    <BoltIcon sx={{ mr: 1 }} />
                    {isLoggedIn ? t("preferences.planAndBillings.upgrade") : t("pricing.subscribe")}
                  </GradationButton>
                )}
              </>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grow>
  );
};

export default PricingCard;

import { zodResolver } from "@hookform/resolvers/zod";
import CreateIcon from "@mui/icons-material/Create";
import TitleIcon from "@mui/icons-material/Title";
import { Box, DialogContentText, Grow, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { useAlert } from "../../../context/AlertContext";
import { triggerChatFetch } from "../../../redux/slices/browsingSlice";

interface CustomInstructionsDialogProps {
  open: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

export default function CustomInstructionsDialog({ open, onClose }: CustomInstructionsDialogProps) {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { chatUuid } = useParams();
  const dispatch = useDispatch();
  const customInstructionFormSchema = z.object({
    chatTitle: z.string().min(1, { message: "Required" }).max(50, { message: "Please enter within 50 characters." }),
    customInstruction: z.string().max(1000, { message: "Please enter within 1000 characters." }),
  });
  type FormData = z.infer<typeof customInstructionFormSchema>;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(customInstructionFormSchema),
  });

  const getCustomInstruction = async () => {
    const res = await axios.get("/api/v1/chat/" + chatUuid);
    if (res.data) {
      setValue("chatTitle", res.data.data.name || "");
      setValue("customInstruction", res.data.data.custom_instruction || "");
    }
  };
  useEffect(() => {
    if (open) {
      getCustomInstruction();
    }
  }, [open]);

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      const csrftoken = Cookies.get("csrftoken");
      const headers = {
        "X-CSRFToken": csrftoken!,
      };
      const data = {
        name: formData.chatTitle,
        custom_instruction: formData.customInstruction,
      };
      await axios.patch("/api/v1/chat/" + chatUuid, data, { headers: headers });
      dispatch(triggerChatFetch());
      onClose();
      setAlert("success", t("browsing.customizeChat.success"));
    } catch (error) {
      console.error("An unknown error occurred:", error);
      setAlert("error", t("common.error"));
    }
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} disableRestoreFocus={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("browsing.customizeChat.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("browsing.customizeChat.description")}</DialogContentText>
          <DialogContentText display={"flex"} variant={"body1"} fontWeight={900} mt={6} alignItems={"center"}>
            <TitleIcon color={"primary"} sx={{ mr: 1 }}></TitleIcon>
            {t("browsing.customizeChat.chatTitle")}
          </DialogContentText>
          <DialogContentText mt={1} variant={"body2"}>
            {t("browsing.customizeChat.chatTitleDescription")}
          </DialogContentText>
          <Controller
            name="chatTitle"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={watch("chatTitle", "")}
                autoFocus
                fullWidth
                variant="outlined"
                margin="dense"
                error={!!errors.chatTitle}
                helperText={errors.chatTitle && errors.chatTitle.message}
              />
            )}
          />
          <Box display={"flex"} justifyContent={"flex-end"} sx={{ flexGrow: 1 }}>
            <Typography variant="body1" color="textprimary" sx={{ whiteSpace: "nowrap" }}>
              {watch("chatTitle", "").length} / 50
            </Typography>
          </Box>

          <DialogContentText display={"flex"} variant={"body1"} fontWeight={900} mt={2} alignItems={"center"}>
            <CreateIcon color={"primary"} sx={{ mr: 1 }}></CreateIcon>
            {t("browsing.customizeChat.instructionTitle")}
          </DialogContentText>
          <DialogContentText mt={1} variant={"body2"}>
            {t("browsing.customizeChat.instructionDescription")}
          </DialogContentText>
          <Controller
            name="customInstruction"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={watch("customInstruction", "")}
                margin="dense"
                multiline
                maxRows={8}
                fullWidth
                variant="outlined"
                error={!!errors.customInstruction}
                helperText={errors.customInstruction && errors.customInstruction.message}
              />
            )}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Button
              color={"primary"}
              onClick={() => setValue("customInstruction", t("browsing.customizeChat.instructionTemplate"))}
            >
              {t("browsing.customizeChat.setTemplate")}
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Typography variant="body1" color="textprimary" sx={{ whiteSpace: "nowrap" }}>
              {watch("customInstruction", "").length} / 1000
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button disableElevation color={"primary"} onClick={onClose}>
            {t("common.cancel")}
          </Button>
          <Button disableElevation color={"primary"} type={"submit"} variant="contained">
            {t("common.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

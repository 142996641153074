import { Box, Fade } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useVideo } from "../../../../context/juno/VideoContext";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import ParamsBytedance from "./params/ParamsBytedance";
import ParamsGoogle from "./params/ParamsGoogle";
import ParamsHailuo from "./params/ParamsHailuo";
import ParamsHaiper from "./params/ParamsHaiper";
import ParamsKling from "./params/ParamsKling";
import ParamsLuma from "./params/ParamsLuma";
import ParamsPika from "./params/ParamsPika";
import ParamsPixverse from "./params/ParamsPixverse";
import ParamsRunway from "./params/ParamsRunway";
import ParamsSync from "./params/ParamsSync";
import ParamsRealESRGAN from "./params/ParamsRealESRGAN";
import ParamsTopazVideoUpscale from "./params/ParamsTopazVideoUpscale";
import ParamsVidu from "./params/ParamsVidu";
import ParamsWan from "./params/ParamsWan";

// 安定したAIエンジン状態を提供するカスタムフック
const useStableAiEngine = (aiEngine: string, delay = 150) => {
  const [stableAiEngine, setStableAiEngine] = useState(aiEngine);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    // AIエンジンが変更されたらトランジション状態にする
    if (stableAiEngine !== aiEngine) {
      setIsTransitioning(true);

      // 遅延後に新しいAIエンジンを設定
      const timer = setTimeout(() => {
        setStableAiEngine(aiEngine);
        setIsTransitioning(false);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [aiEngine, stableAiEngine, delay]);

  return { stableAiEngine, isTransitioning };
};

const ParamsManager = () => {
  const { aiEngine, setAiEngine } = useVideo();
  const location = useLocation();
  const { stableAiEngine, isTransitioning } = useStableAiEngine(aiEngine);

  useEffect(() => {
    const ai = location.state?.aiEngine;
    if (ai) {
      setAiEngine(ai);
    }
  }, []);

  // トランジション中は何も表示しない
  if (isTransitioning) {
    return <></>;
  }

  return (
    <ColumnCenteredBox mb={4}>
      <ColumnCenteredBox sx={{}}>
        <Fade in={stableAiEngine === "KLING"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsKling />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "LUMA"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsLuma />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "RUNWAY"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsRunway />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "HAIPER"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsHaiper />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "HAILUO"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsHailuo />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "PIXVERSE"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsPixverse />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "VIDU"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsVidu />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "BYTEDANCE"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsBytedance />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "SYNC"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsSync />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "REALESRGAN"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsRealESRGAN />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "GOOGLE"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsGoogle />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "WAN"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsWan />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "PIKA"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsPika />
          </Box>
        </Fade>
        <Fade in={stableAiEngine === "TOPAZLABS"} timeout={300} unmountOnExit mountOnEnter style={{ width: "100%" }}>
          <Box>
            <ParamsTopazVideoUpscale />
          </Box>
        </Fade>
      </ColumnCenteredBox>
    </ColumnCenteredBox>
  );
};
export default ParamsManager;

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollableBox from "../../../../components/common/ScrollableBox";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import { useJunoModels } from "../../../../hooks/useJunoModels";
import { RowBox } from "../../../../utils/styledBox";

type FilterMenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

const FilterMenu = ({ anchorEl, onClose }: FilterMenuProps) => {
  const { t } = useTranslation();
  const { getImageModels } = useJunoModels();
  const navigate = useCustomNavigate();
  const open = Boolean(anchorEl);

  // txt2img、img2img、editorのモデルを使用（重複を排除）
  const uniqueModelsMap = new Map();
  [...getImageModels.txt2img, ...getImageModels.img2img, ...getImageModels.editor].forEach((model) => {
    if (!uniqueModelsMap.has(model.value)) {
      uniqueModelsMap.set(model.value, model);
    }
  });
  const models = Array.from(uniqueModelsMap.values());

  // 現在のURLパラメータを取得
  const urlParams = new URLSearchParams(window.location.search);
  const currentEngine = urlParams.get("ai_engine") ?? "all";
  const currentAction = urlParams.get("action") ?? "all";

  // ローカルステート変数を追加
  const [selectedEngine, setSelectedEngine] = useState<string>(currentEngine);
  const [selectedAction, setSelectedAction] = useState<string>(currentAction);

  // 共通のパラメータ設定関数
  const createFilterParams = () => {
    const newParams = new URLSearchParams();

    // ページネーションをリセット（page パラメータを含めない）

    // 検索クエリがあれば保持
    const queryParam = urlParams.get("q");
    if (queryParam) {
      newParams.set("q", queryParam);
    }

    // ユーザーパラメータがあれば保持
    const userParam = urlParams.get("user");
    if (userParam) {
      newParams.set("user", userParam);
    }

    // モデルパラメータがあれば保持
    const modelParam = urlParams.get("model");
    if (modelParam) {
      newParams.set("model", modelParam);
    }

    // Loraパラメータがあれば保持
    const loraParam = urlParams.get("lora");
    if (loraParam) {
      newParams.set("lora", loraParam);
    }

    return newParams;
  };

  const handleEngineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedEngine(value);

    // 即時適用
    const newParams = createFilterParams();

    // 現在のアクションを保持
    if (currentAction !== "all") {
      newParams.set("action", currentAction);
    }

    // 新しいエンジン値を設定
    if (value !== "all") {
      newParams.set("ai_engine", value);
    }

    navigate(`/juno?${newParams.toString()}`);
  };

  const handleActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedAction(value);

    // 即時適用
    const newParams = createFilterParams();

    // 現在のエンジンを保持
    if (currentEngine !== "all") {
      newParams.set("ai_engine", currentEngine);
    }

    // 新しいアクション値を設定
    if (value !== "all") {
      newParams.set("action", value);
    }

    navigate(`/juno?${newParams.toString()}`);
  };

  // 適用ボタンのハンドラー
  const handleApply = () => {
    const newParams = createFilterParams();

    // エンジンの設定
    if (selectedEngine !== "all") {
      newParams.set("ai_engine", selectedEngine);
    }

    // アクションの設定
    if (selectedAction !== "all") {
      newParams.set("action", selectedAction);
    }

    navigate(`/juno?${newParams.toString()}`);
    onClose();
  };

  const handleReset = () => {
    setSelectedEngine("all");
    setSelectedAction("all");
    navigate("/juno");
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        sx: {
          maxHeight: "80vh",
          minHeight: 600,
        },
      }}
      sx={{ py: 0 }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", minWidth: 500, position: "relative" }}>
        <IconButton onClick={onClose} size="small" sx={{ position: "absolute", top: 0, right: 4 }}>
          <CloseIcon fontSize="small" />
        </IconButton>

        <Box sx={{ display: "flex", flex: 1 }}>
          <Box sx={{ flex: 1, borderRight: 1, borderColor: "divider", width: 300 }}>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              sx={{ px: 2, py: 1, borderBottom: 1, borderColor: "divider" }}
            >
              Mode
            </Typography>
            <ScrollableBox sx={{}}>
              <Box sx={{ p: 2 }}>
                <FormControl fullWidth>
                  <RadioGroup
                    aria-labelledby="action-radio-buttons-group"
                    name="action-radio-buttons"
                    onChange={handleActionChange}
                    value={selectedAction}
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label={<Typography variant="body2">All</Typography>}
                    />
                    <FormControlLabel
                      value="txt2img"
                      control={<Radio />}
                      label={<Typography variant="body2">Text to Image</Typography>}
                    />
                    <FormControlLabel
                      value="img2img"
                      control={<Radio />}
                      label={<Typography variant="body2">Image to Image</Typography>}
                    />
                    <FormControlLabel
                      value="editor"
                      control={<Radio />}
                      label={<Typography variant="body2">Edit</Typography>}
                    />
                    <FormControlLabel
                      value="upscale"
                      control={<Radio />}
                      label={<Typography variant="body2">Upscale</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </ScrollableBox>
          </Box>

          <Box sx={{ flex: 2 }}>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              sx={{ px: 2, py: 1, borderBottom: 1, borderColor: "divider" }}
            >
              MODEL
            </Typography>
            <ScrollableBox sx={{ height: 500 }}>
              <Box sx={{ p: 2 }}>
                <FormControl fullWidth>
                  <RadioGroup
                    aria-labelledby="engine-radio-buttons-group"
                    name="engine-radio-buttons"
                    onChange={handleEngineChange}
                    value={selectedEngine}
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label={<Typography variant="body2">All</Typography>}
                    />
                    {models.map((model) => (
                      <FormControlLabel
                        key={model.value}
                        value={model.value}
                        control={<Radio />}
                        label={<Typography variant="body2">{model.label}</Typography>}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            </ScrollableBox>
          </Box>
        </Box>

        <RowBox
          sx={{ width: "100%", justifyContent: "space-between", px: 1, pt: 1, borderTop: 1, borderColor: "divider" }}
        >
          <Button variant="text" onClick={onClose} size="small" startIcon={<CloseIcon />} disableElevation>
            {t("common.close")}
          </Button>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              onClick={handleReset}
              size="small"
              startIcon={<RestartAltIcon />}
              disableElevation
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApply}
              size="small"
              startIcon={<CheckIcon />}
              disableElevation
            >
              {t("common.apply")}
            </Button>
          </Stack>
        </RowBox>
      </Box>
    </Menu>
  );
};

export default FilterMenu;

import VideocamIcon from "@mui/icons-material/Videocam";
import { Box, Card, Grid, IconButton, Popover, Tooltip, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useVideo } from "../../../../context/juno/VideoContext";
import { alpha } from "@mui/material/styles";
import { Videos } from "../../../../assets";

interface Movement {
  label: string;
  video: string;
  value?: string;
}

interface Props {
  prompt: string;
  setPrompt: (prompt: string) => void;
  disabled: boolean;
  position?: "top" | "bottom";
}

const CameraMovementPreset = ({ prompt, setPrompt, disabled, position = "top" }: Props) => {
  const { aiEngine, modelVersion } = useVideo();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChipClick = (label: string) => {
    // 複数のChipがクリックされた場合は、カンマ区切りで追加
    const newPrompt = prompt ? `${prompt}, ${label}` : label;
    console.log(newPrompt);
    setPrompt(newPrompt);
  };

  const getMovements = (): Movement[] => {
    if (aiEngine === "LUMA" && modelVersion.LUMA === "RAY_2") {
      return [
        { label: "Pan left", value: "camera pan left", video: "pan-left.mp4" },
        { label: "Pan right", value: "camera pan right", video: "pan-right.mp4" },
        { label: "Orbit left", value: "camera orbit left", video: "orbit-left.mp4" },
        { label: "Orbit right", value: "camera orbit right", video: "orbit-right.mp4" },
        { label: "Crane up", value: "camera crane up", video: "crane-up.mp4" },
        { label: "Crane down", value: "camera crane down", video: "crane-down.mp4" },
        { label: "Move left", value: "camera move left", video: "move-left.mp4" },
        { label: "Move right", value: "camera move right", video: "move-right.mp4" },
        { label: "Move up", value: "camera move up", video: "tilt-up.mp4" },
        { label: "Move down", value: "camera move down", video: "tilt-down.mp4" },
        { label: "Push in", value: "camera push in", video: "push-in.mp4" },
        { label: "Pull out", value: "camera pull out", video: "pull-out.mp4" },
      ];
    }

    if (aiEngine === "HAILUO" && modelVersion.HAILUO === "MINI_MAX_DIRECTOR") {
      return [
        { label: "Push in", value: "[push in]", video: "push-in.mp4" },
        { label: "Pull out", value: "[pull out]", video: "pull-out.mp4" },
        { label: "Zoom in", value: "[zoom in]", video: "zoom-in.mp4" },
        { label: "Zoom out", value: "[zoom out]", video: "zoom-out.mp4" },
        { label: "Pan left", value: "[pan left]", video: "pan-left.mp4" },
        { label: "Pan right", value: "[pan right]", video: "pan-right.mp4" },
        { label: "Tilt up", value: "[tilt up]", video: "tilt-up.mp4" },
        { label: "Tilt down", value: "[tilt down]", video: "tilt-down.mp4" },
        { label: "Move left", value: "[truck left]", video: "move-left.mp4" },
        { label: "Move right", value: "[truck right]", video: "move-right.mp4" },
        { label: "Crane up", value: "[pedestal up]", video: "crane-up.mp4" },
        { label: "Crane down", value: "[pedestal down]", video: "crane-down.mp4" },
        { label: "Tracking shot", value: "[tracking shot]", video: "tracking.mp4" },
        { label: "Shake", value: "[shake]", video: "shake.mp4" },
        { label: "Static shot", value: "[static shot]", video: "static.mp4" },
        { label: "Orbit left", value: "[truck left, pan right, tracking shot]", video: "orbit-left.mp4" },
        { label: "Orbit right", value: "[truck right, pan left, tracking shot]", video: "orbit-right.mp4" },
        { label: "Truck left", value: "[truck left, tracking shot]", video: "walk-left.mp4" },
        { label: "Truck right", value: "[truck right, tracking shot]", video: "walk-right.mp4" },
        { label: "Upwardtilt", value: "[push in, pedestal up]", video: "tilt-up-forward.mp4" },
        { label: "Downwardtilt", value: "[pedestal down, tilt up]", video: "tilt-down-back.mp4" },
        { label: "Stage shot right", value: "[pan right, zoom in]", video: "stage-right.mp4" },
        { label: "Stage shot left", value: "[pan left, zoom in]", video: "stage-left.mp4" },
        { label: "Scenic shot", value: "[truck left, pedestal up]", video: "scenic.mp4" },
      ];
    }

    return [
      { label: "Pan left", value: "camera pan left", video: "pan-left.mp4" },
      { label: "Pan right", value: "camera pan right", video: "pan-right.mp4" },
      { label: "Move left", value: "camera move left", video: "move-left.mp4" },
      { label: "Move right", value: "camera move right", video: "move-right.mp4" },
      { label: "Tilt up", value: "camera tilt up", video: "tilt-up.mp4" },
      { label: "Tilt down", value: "camera tilt down", video: "tilt-down.mp4" },
      { label: "Zoom in", value: "camera zoom in", video: "zoom-in.mp4" },
      { label: "Zoom out", value: "camera zoom out", video: "zoom-out.mp4" },
      { label: "Orbit left", value: "camera orbit left", video: "orbit-left.mp4" },
      { label: "Orbit right", value: "camera orbit right", video: "orbit-right.mp4" },
      { label: "Static shot", value: "static shot", video: "static.mp4" },
    ];
  };

  return (
    <span>
      <Tooltip title={t("juno.cameraMovementPreset.icon")}>
        <span>
          <IconButton onClick={handleOpen} ref={anchorRef} disabled={disabled}>
            <VideocamIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: position === "top" ? "top" : "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: position === "top" ? "bottom" : "top",
          horizontal: "center",
        }}
        sx={{ width: "1100px" }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.5),
              backdropFilter: "blur(15px)",
            },
            elevation: 0,
          },
        }}
      >
        <Box sx={{ p: 2, width: "100%", maxHeight: "80vh", overflowY: "auto" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ flexDirection: "row", justifyContent: "space-between", display: "flex", alignItems: "flex-end" }}
            >
              <Typography variant="subtitle1">{t("juno.cameraMovementPreset.title")}</Typography>
              <Typography variant="caption" color="warning.main">
                {t("juno.cameraMovementPreset.warning")}
              </Typography>
            </Grid>
            {getMovements().map((item, index) => (
              <Grid item key={index} xs={6} sm={4} md={3} lg={2}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                  onClick={() => handleChipClick(item.value ?? item.label)}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: 0,
                      paddingBottom: "56.25%", // 16:9 aspect ratio
                      overflow: "hidden",
                      minWidth: 60,
                    }}
                  >
                    <video
                      src={Videos.juno.camera[item.video.replace(".mp4", "") as keyof typeof Videos.juno.camera]}
                      muted
                      loop
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.play();
                        e.currentTarget.playbackRate = 1.5;
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.pause();
                        e.currentTarget.playbackRate = 1;
                      }}
                    />
                    <Typography
                      variant="caption"
                      align="center"
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        color: "white",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        py: 0.2,
                      }}
                    >
                      {t(`juno.cameraMovementPreset.movement.${item.label.replace(/ /g, "").toLowerCase()}`)}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </span>
  );
};

export default CameraMovementPreset;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  Grow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import {
  FaBolt,
  FaCrown,
  FaFacebook,
  FaHashtag,
  FaInstagram,
  FaThreads,
  FaTiktok,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import TagIcon from "@mui/icons-material/Tag";
import Meta from "../../components/common/Meta";
import InputModal from "./components/InputModal";
import SearchBox from "./components/SearchBox";
import { Images } from "../../assets";
import { GradientTypography } from "../../utils/gradientTypography";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";

const IMAGE_NUM = 33;

export default function Templates() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useCustomNavigate();
  const location = useLocation();
  const [templates, setTemplates] = useState([]);
  const [rankings, setRankings] = useState([]);
  const [paramTag, setParamTag] = useState("");
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  const openModal = (template: any) => {
    setSelectedTemplate(template);
    let url = "/template?selected=" + template.id;
    if (paramTag) url += "&tag=" + paramTag;
    navigate(url);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selected = params.get("selected") || "";
    const q = params.get("q") || "";
    if (selected) {
      const template = templates.find((template: any) => template.id === selected);
      if (template) {
        setSelectedTemplate(template);
        setOpen(true);
      }
    }
    if (!q) {
      // 検索ボックスが空の場合、全てのテンプレートを表示
      setSearchResults(templates);
    }
  }, [location, templates]);

  const get_templates = () => {
    const csrftoken = Cookies.get("csrftoken");
    const baseUrl = "/api/v1/template/list";
    let get_url = baseUrl;

    if (paramTag) {
      get_url += "?tag=" + encodeURIComponent(paramTag);
    }

    axios
      .get(get_url, {
        headers: {
          "X-CSRFToken": csrftoken,
        },
      })
      .then((response) => {
        setTemplates(response.data);
        setSearchResults(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const get_rankings = () => {
    const csrftoken = Cookies.get("csrftoken");
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken!,
    });
    const base_url = "/api/v1/template/rankings";
    fetch(base_url, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setRankings(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const get_tags = () => {
    const csrftoken = Cookies.get("csrftoken");
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken!,
    });
    const base_url = "/api/v1/template/all-tags";
    fetch(base_url, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setTags(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // タグの変更を監視
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const newTag = params.get("tag") || "";
    setParamTag(newTag);
  }, [location]);

  // 初期値セット
  useEffect(() => {
    get_rankings();
    get_templates();
    get_tags();
  }, [paramTag]);

  interface Tag {
    tag: string;
    count?: number;
  }

  interface Template {
    id: number;
    icon: string;
    name: string;
    description: string;
    tags: string[];
    args: any;
  }

  const getIcon = (icon: string) => {
    switch (icon) {
      case "instagram":
        return <FaInstagram />;
      case "facebook":
        return <FaFacebook />;
      case "x":
        return <FaXTwitter />;
      case "youtube":
        return <FaYoutube />;
      case "tiktok":
        return <FaTiktok />;
      case "threads":
        return <FaThreads />;
      case "sns":
        return <FaHashtag />;
      default:
        return null;
    }
  };

  const avatarColor = (icon: string) => {
    switch (icon) {
      case "instagram":
        return "rgba(86, 0, 161, 0.7)";
      case "facebook":
        return "rgba(35,123,243, 0.7)";
      case "x":
        return "rgba(20, 20, 20, 0.7)";
      case "youtube":
        return "rgba(255, 36, 36, 0.7)";
      case "tiktok":
        return "rgba(59,242,235, 0.7)";
      case "sns":
        return "rgba(234,129,197,0.7)";
      default:
        return "rgb(0, 0, 0)";
    }
  };

  const templateImage = (index: number) => {
    const key = `template${index}` as keyof typeof Images.template;
    if (index <= IMAGE_NUM && Images.template[key]) {
      return Images.template[key];
    } else {
      const fallbackKey = `template${index % IMAGE_NUM}` as keyof typeof Images.template;
      return Images.template[fallbackKey] || Images.template.top;
    }
  };

  const randomTemplateImage = () => {
    const randomIndex = Math.floor(Math.random() * IMAGE_NUM);
    const key = `template${randomIndex}` as keyof typeof Images.template;
    return Images.template[key] || Images.template.top;
  };

  return (
    <>
      <Meta title={t("template.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Container maxWidth={"xl"}>
        <Box sx={{ height: "100%" }}>
          <Box
            sx={{
              position: "relative",
              mx: { xs: 1, md: 4 },
              pb: 8,
              height: "100%",
            }}
          >
            <DrawerHeader />
            <Box sx={{ display: "flex", pt: 2, mb: 4, alignItems: "center", flexDirection: "column" }}>
              <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", mb: 1 }}>
                <img alt={"logo"} src={Images.logo.main} width={"40px"} />
                <GradientTypography variant={"h4"} component={"h2"} fontWeight={600} sx={{ ml: 1 }}>
                  <Link to={""}>{t("template.title")}</Link>
                </GradientTypography>
              </Box>
              <Typography variant={"subtitle1"}>{t("template.description")}</Typography>
            </Box>
            <SearchBox templates={templates} onSearch={setSearchResults} />
            {/*タグリスト*/}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                pb: 1,
                gap: 1,
                overflowX: "auto",
                mb: 4,
              }}
            >
              <Chip
                label={t("template.all")}
                onClick={() => navigate("/template")}
                color={paramTag === "" ? "primary" : "default"}
              />
              {tags.map((tag: Tag) => (
                <Chip
                  key={tag.tag}
                  label={tag.tag}
                  onClick={() => navigate("/template?tag=" + tag.tag)}
                  color={paramTag === tag.tag ? "primary" : "default"}
                  avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
                />
              ))}
            </Box>

            {/* ランキング */}
            {!paramTag && (
              <Grid container spacing={2} sx={{ mb: 6 }}>
                <Grid item xs={12}>
                  <Typography variant="h6" component={"h3"} justifyContent="center" display="flex" alignItems="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      <FaCrown />
                    </Box>
                    {t("template.rankings")}
                  </Typography>
                </Grid>
                {rankings.map((template: Template) => (
                  <Grow in={true} key={template.id}>
                    <Grid item xs={12} sm={4} md={2.4}>
                      <Card
                        sx={{
                          maxWidth: 345,
                          boxShadow: "none",
                          "&:hover div.template-image": {
                            filter: "blur(5px)",
                          },
                        }}
                      >
                        <CardActionArea onClick={() => openModal(template)}>
                          <CardMedia
                            sx={{
                              height: 80,
                              position: "relative",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              alignItems: "flex-end",
                              display: "flex",
                              overflow: "hidden",
                            }}
                          >
                            {/* 背景画像 */}
                            <Box
                              className={"template-image"}
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundImage: `url(${randomTemplateImage()})`,
                                filter: "blur(10px) brightness(0.8)",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                transform: "scale(1.6)",
                                transition: "all 0.3s",
                              }}
                            ></Box>
                            {/* アイコン */}
                            {template.icon && (
                              <Tooltip title={template.icon}>
                                <Avatar
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    m: 1,
                                    bgcolor: avatarColor(template.icon),
                                    color: "white",
                                    zIndex: 1,
                                  }}
                                >
                                  {getIcon(template.icon)}
                                </Avatar>
                              </Tooltip>
                            )}
                            {/* テキスト */}
                            <Typography
                              variant="subtitle1"
                              component="h4"
                              sx={{
                                mx: 2,
                                mb: 1,
                                color: "white",
                                zIndex: 1,
                                whiteSpace: "break-spaces",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {t(`template.label.${template.id}.title`)}
                            </Typography>
                          </CardMedia>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grow>
                ))}
              </Grid>
            )}

            {/*ALL*/}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant={"h6"}
                  component={"h3"}
                  justifyContent={"center"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: 1,
                      color: theme.palette.primary.main,
                    }}
                  >
                    <FaBolt />
                  </Box>
                  {t("template.all")}
                </Typography>
              </Grid>

              {searchResults.map((template: Template, index: number) => (
                <Grow in={true} key={template.id}>
                  <Grid item xs={12} sm={4} md={2}>
                    <Card
                      sx={{
                        maxWidth: 345,
                        bgcolor: "background.paper",
                        boxShadow: 1,
                        "&:hover div.template-image": {
                          filter: "blur(5px)",
                        },
                      }}
                    >
                      <CardActionArea onClick={() => openModal(template)}>
                        <CardMedia
                          sx={{
                            height: 100,
                            position: "relative",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            overflow: "hidden",
                          }}
                        >
                          {/* 背景画像 */}
                          <Box
                            className={"template-image"}
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundImage: `url(${templateImage(index)})`,
                              filter: "blur(10px) brightness(0.8)",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              transform: "scale(2)",
                              transition: "all 0.3s",
                            }}
                          ></Box>
                          {/* アイコン */}
                          {template.icon && (
                            <Tooltip title={template.icon}>
                              <Avatar
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  m: 1,
                                  bgcolor: avatarColor(template.icon),
                                  color: "white",
                                  zIndex: 1,
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  navigate("/template?tag=" + template.icon);
                                }}
                              >
                                {getIcon(template.icon)}
                              </Avatar>
                            </Tooltip>
                          )}
                          {/* テキスト */}
                          <Typography
                            variant="subtitle1"
                            component="h4"
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              px: 2,
                              pb: 1,
                              color: "white",
                              zIndex: 1,
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {t(`template.label.${template.id}.title`)}
                          </Typography>
                        </CardMedia>

                        <CardContent sx={{ display: "flex", flexDirection: "column", height: 165 }}>
                          {/*説明*/}
                          <Box sx={{}}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {t(`template.label.${template.id}.description`)}
                            </Typography>
                          </Box>
                          <Box flexGrow={1} />

                          {/*タグ*/}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {template.tags.map((tag: any) => (
                              <Chip
                                key={template.id + tag}
                                label={tag}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  navigate("/template?tag=" + tag);
                                }}
                                size={"small"}
                                avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "none" }} />}
                              />
                            ))}
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grow>
              ))}
            </Grid>
            {selectedTemplate && <InputModal open={open} setOpen={setOpen} template={selectedTemplate} />}
          </Box>
        </Box>
      </Container>
    </>
  );
}

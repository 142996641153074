import { zodResolver } from "@hookform/resolvers/zod";
import ArticleIcon from "@mui/icons-material/Article";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import LinkIcon from "@mui/icons-material/Link";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Fade, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import React, { ReactNode, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import Meta from "../../../components/common/Meta";
import { useAlert } from "../../../context/AlertContext";
import { useCheckCredit } from "../../../hooks/useCreditCheck";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { setCreditTrigger } from "../../../redux/slices/triggerSlice";
import { Images } from "../../../assets";
import { GradationButton } from "../../../utils/gradationButton";
import { StyledTypography } from "../../../utils/styledTypography";

const Summarize = () => {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const [tabValue, setTabValue] = React.useState(0);
  const navigate = useCustomNavigate();
  const [generating, setGenerating] = React.useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { setAlert } = useAlert();

  useEffect(() => {
    // 外部からテキストを受け取った場合
    const text = location.state?.text;
    if (text) {
      setValue("text", text);
      setTabValue(2);
    }
  }, []);

  const urlRegex = /^https?:\/\/.+/;
  const getDomainFromUrl = (url: string): string => {
    try {
      const { hostname } = new URL(url);
      return hostname;
    } catch (e) {
      return "";
    }
  };

  const textFormSchema = z.object({
    text: z
      .string()
      .min(1, { message: t("validation.required") })
      .max(30000, { message: t("validation.maxLength", { max: "30,000" }) }),
    additional: z.string().max(1000, { message: t("validation.maxLength", { max: 1000 }) }),
  });
  const youtubeFormSchema = z.object({
    youtube: z
      .string()
      .min(1, { message: t("validation.required") })
      .max(2000, { message: t("validation.maxLength", { max: "2,000" }) })
      .refine((url) => urlRegex.test(url) && getDomainFromUrl(url)?.includes("youtube"), {
        message: "Invalid YouTube URL format.",
      }),
    additional: z.string().max(1000, { message: t("validation.maxLength", { max: 1000 }) }),
  });

  const urlFormSchema = z.object({
    url: z
      .string()
      .min(1, { message: t("validation.required") })
      .max(2000, { message: t("validation.maxLength", { max: "2,000" }) })
      .refine((url) => urlRegex.test(url) && !getDomainFromUrl(url)?.includes("youtube"), {
        message: t("validation.invalidURL"),
      }),
    additional: z.string().max(1000, { message: t("validation.maxLength", { max: 1000 }) }),
  });

  type TabDataProps = {
    title: string;
    description: string;
    placeholder: string;
    inputLabel: string;
    name: "title" | "text" | "youtube" | "url";
    icon: JSX.Element;
    src: string;
  };

  const tabsData: TabDataProps[] = [
    {
      title: "summarize.URLToSummarize",
      description: "summarize.URLToSummarizeDescription",
      placeholder: "summarize.urlPlaceholder",
      inputLabel: "summarize.URL",
      name: "url",
      icon: <LinkIcon />,
      src: "url",
    },
    {
      title: "summarize.YouTubeToSummarize",
      description: "summarize.YouTubeToSummarizeDescription",
      placeholder: "summarize.youtubePlaceholder",
      inputLabel: "summarize.URL",
      name: "youtube",
      icon: <YouTubeIcon />,
      src: "youtube",
    },
    {
      title: "summarize.TextToSummarize",
      description: "summarize.TextToSummarizeDescription",
      placeholder: "summarize.textPlaceholder",
      inputLabel: "summarize.TextToSummarize",
      name: "text",
      icon: <ArticleIcon />,
      src: "text",
    },
  ];

  const schemaMapping: { [key: string]: any } = {
    text: textFormSchema,
    youtube: youtubeFormSchema,
    url: urlFormSchema,
  };

  const currentSchema = schemaMapping[tabsData[tabValue].src] || urlFormSchema;

  type FormData = z.infer<typeof currentSchema>;
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: zodResolver(currentSchema),
  });

  // Submit
  const generate = async (data: any) => {
    if (!(await checkCredit())) return;
    setGenerating(true);
    try {
      setAlert("info", t("common.generatingMessage"));
      const post_url = "/api/v1/summarize/get-article";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(post_url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ data }),
      });

      const responseData = await response.json();
      if (responseData.success) {
        setGenerating(false);
        dispatch(setCreditTrigger(true));

        // writing-ai/summarizerパスからのアクセス時のみナビゲート
        const currentPath = location.pathname.toLowerCase();
        setAlert("success", t("common.complete"));
        if (currentPath.includes("writing-ai/summarizer")) {
          setGenerating(false);
          navigate("/notes-nebula/files/" + responseData.data.file_uuid);
        }
      } else {
        setGenerating(false);
      }
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : t("common.errorMessage");
      setAlert("error", errorMessage);
      setGenerating(false);
    } finally {
      setGenerating(false);
    }
  };

  // Tabs
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box component="div">{children}</Box>}
      </div>
    );
  }

  function tabProps(index: number) {
    return { id: `simple-tab-${index}`, "aria-controls": `simple-tabpanel-${index}` };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

  type CenteredBoxProps = { children: ReactNode };
  const CenteredBox: React.FC<CenteredBoxProps> = ({ children }) => (
    <Box sx={{ justifyContent: "center", display: "flex", my: 2, alignItems: "center" }}>{children}</Box>
  );

  const maxLength = (src: string) => {
    switch (src) {
      case "text":
        return 30000;
      case "additional":
        return 1000;
      default:
        return 2000;
    }
  };
  const [radioValue, setRadioValue] = React.useState("text");

  const handleRadioChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setRadioValue(target.value);
  };

  return (
    <>
      <Meta title={t("summarize.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Fade in={true}>
        <Box sx={{ height: "100%" }}>
          <Box sx={{ position: "relative", width: "100%" }}>
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              <img alt={"logo"} src={Images.logo.main} width={"75px"} />
            </Box>
            <CenteredBox>
              <FlashOnIcon sx={{ mr: 1 }} color={"secondary"} />
              <Typography variant={"body1"} gutterBottom>
                {t("summarize.description")}
              </Typography>
            </CenteredBox>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
              <Tabs value={tabValue} onChange={handleChange} centered variant="fullWidth">
                {tabsData.map((tab, index) => (
                  <Tab icon={tab.icon} label={t(tab.title)} {...tabProps(index)} key={index} />
                ))}
              </Tabs>
            </Box>
            <Box>
              {tabsData.map((tab, index) => (
                <CustomTabPanel value={tabValue} index={index} key={index}>
                  <CenteredBox>
                    <StyledTypography variant={"body1"} gutterBottom>
                      {t(tab.description)}
                    </StyledTypography>
                  </CenteredBox>
                  <Box>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit((data) =>
                          generate({
                            ...data,
                            outputType: radioValue,
                            src: tab.src,
                          })
                        )(e);
                      }}
                      noValidate
                    >
                      <Box sx={{ justifyContent: "", display: "flex", mb: 1 }}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">{t("summarize.type")}</FormLabel>
                          <RadioGroup
                            row
                            aria-label="output-type"
                            name="gender1"
                            value={radioValue}
                            onChange={handleRadioChange}
                          >
                            <FormControlLabel
                              value="text"
                              control={<Radio {...register("outputType")} />}
                              label={t("summarize.outputText")}
                            />
                            <FormControlLabel
                              value="list"
                              control={<Radio {...register("outputType")} />}
                              label={t("summarize.outputList")}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <TextField
                        {...register(tab.name, {})}
                        fullWidth
                        sx={{ mb: 2 }}
                        autoComplete={"off"}
                        autoFocus
                        multiline={tab.src === "text"}
                        rows={tab.src === "text" ? 10 : 1}
                        placeholder={t(tab.placeholder)}
                        label={t(tab.inputLabel)}
                        error={!!errors[tab.name]}
                        helperText={(errors[tab.name]?.message as string) || ""}
                        inputProps={{
                          maxLength: maxLength(tab.src),
                        }}
                      />

                      <TextField
                        {...register("additional", {})}
                        fullWidth
                        sx={{ mb: { xs: 2, md: 3 } }}
                        multiline
                        minRows={3}
                        maxRows={8}
                        error={!!errors["additional"]}
                        placeholder={t("summarize.additionalPlaceholder")}
                        label={t("summarize.additional")}
                        inputProps={{
                          maxLength: maxLength("additional"),
                        }}
                      />
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <GradationButton type={"submit"} disabled={generating}>
                          {generating && (
                            <>
                              <Typography variant={"button"}>{"Generating..."}</Typography>
                              <CircularProgress size={20} sx={{ ml: 1, color: "white" }} />
                            </>
                          )}
                          {!generating && (
                            <>
                              <AutoAwesomeIcon sx={{ mr: 1 }} />
                              <Typography variant={"button"}>{t("summarize.submit")}</Typography>
                            </>
                          )}
                        </GradationButton>
                      </Box>
                    </form>
                  </Box>
                </CustomTabPanel>
              ))}
            </Box>
          </Box>
        </Box>
      </Fade>
    </>
  );
};
export default Summarize;

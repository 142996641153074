import LightModeIcon from "@mui/icons-material/LightMode";
import PersonIcon from "@mui/icons-material/Person";
import StyleIcon from "@mui/icons-material/Style";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import { Box, Chip, Grid, IconButton, Popover, Stack, Tab, Tabs, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollableBox from "../../../../../components/common/ScrollableBox";
import { alpha } from "@mui/material/styles";

interface Props {
  prompt: string;
  setPrompt: (prompt: string) => void;
  position?: "top" | "bottom";
  disabled?: boolean; // 追加
}

const InputPreset = ({ prompt, setPrompt, position = "top", disabled = false }: Props) => {
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleChipClick = (label: string) => {
    let sample = `${prompt}, ${label}`.trim();
    if (sample.startsWith(", ")) {
      sample = sample.slice(1);
    }

    setPrompt(sample.trim());
  };

  const getPresetKeys = (category: "mood" | "character" | "illumination" | "background" | "style") => {
    const presetPath = `juno.inputPreset.${category}`;
    const keys: string[] = [];

    // i18nのキーを取得
    Object.keys(t(presetPath, { returnObjects: true })).forEach((key) => {
      keys.push(key);
    });

    return keys;
  };

  const tabs = [
    {
      label: "Mood",
      icon: <TipsAndUpdatesIcon fontSize={"small"} />,
    },
    {
      label: "Character",
      icon: <PersonIcon fontSize={"small"} />,
    },
    {
      label: "Illumination",
      icon: <LightModeIcon fontSize={"small"} />,
    },
    {
      label: "Background",
      icon: <WallpaperIcon fontSize={"small"} />,
    },
    {
      label: "Style",
      icon: <StyleIcon fontSize={"small"} />,
    },
  ];

  return (
    <>
      <Tooltip title={t("juno.inputPreset.icon")}>
        <span>
          <IconButton onClick={handleOpen} ref={anchorRef} disabled={disabled}>
            <TipsAndUpdatesIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: position === "top" ? "top" : "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: position === "top" ? "bottom" : "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.7),
              backdropFilter: "blur(15px)",
            },
            elevation: 0,
          },
        }}
      >
        <Box sx={{ display: "flex", width: 800 }}>
          <Tabs
            orientation="vertical"
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            sx={{ borderRight: 1, borderColor: "divider", minWidth: 200, py: 2 }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                sx={{
                  minHeight: 60,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  p: 2,
                }}
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    {tab.icon}
                    <Typography variant="body2" color="textSecondary">
                      {tab.label}
                    </Typography>
                  </Stack>
                }
              />
            ))}
          </Tabs>
          <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
              <ScrollableBox sx={{ flexGrow: 1, height: 250, p: 2 }}>
                {tabIndex === 0 && (
                  <Grid container spacing={1}>
                    {getPresetKeys("mood").map((item, index) => (
                      <Grid item key={index}>
                        <Chip
                          label={t(`juno.inputPreset.mood.${item}`)}
                          onClick={() => handleChipClick(t(`juno.inputPreset.mood.${item}`))}
                          size="small"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
                {tabIndex === 1 && (
                  <Grid container spacing={1}>
                    {getPresetKeys("character").map((item, index) => (
                      <Grid item key={index}>
                        <Chip
                          label={t(`juno.inputPreset.character.${item}`)}
                          onClick={() => handleChipClick(t(`juno.inputPreset.character.${item}`))}
                          size="small"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
                {tabIndex === 2 && (
                  <Grid container spacing={1}>
                    {getPresetKeys("illumination").map((item, index) => (
                      <Grid item key={index}>
                        <Chip
                          label={t(`juno.inputPreset.illumination.${item}`)}
                          onClick={() => handleChipClick(t(`juno.inputPreset.illumination.${item}`))}
                          size="small"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
                {tabIndex === 3 && (
                  <Grid container spacing={1}>
                    {getPresetKeys("background").map((item, index) => (
                      <Grid item key={index}>
                        <Chip
                          label={t(`juno.inputPreset.background.${item}`)}
                          onClick={() => handleChipClick(t(`juno.inputPreset.background.${item}`))}
                          size="small"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
                {tabIndex === 4 && (
                  <Grid container spacing={1}>
                    {getPresetKeys("style").map((item, index) => (
                      <Grid item key={index}>
                        <Chip
                          label={t(`juno.inputPreset.style.${item}`)}
                          onClick={() => handleChipClick(t(`juno.inputPreset.style.${item}`))}
                          size="small"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </ScrollableBox>
            </Box>
            <Box sx={{ p: 0.5 }}>
              <Typography variant={"caption"} align="center" sx={{ display: "block" }}>
                {t("juno.inputPreset.description")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default InputPreset;

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAvatar } from "../../../context/AvatarContext";
import { useLoginModalContext } from "../../../context/LoginModalContext";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { apiRequest } from "../../../utils/api";
import { localDate } from "../../../utils/utils";

interface Comment {
  uuid: string;
  user: string;
  avatar: string;
  content: string;
  like_count: number;
  is_liked: boolean;
  is_author: boolean;
  is_article_author: boolean;
  is_visible: boolean;
  created_at: string;
  updated_at: string;
}

interface CommentsProps {
  textFileUuid: string;
}

const Comments = ({ textFileUuid }: CommentsProps) => {
  const { t } = useTranslation();
  useTheme(); // テーマを使用する場合に備えて残しておく
  const { isLoggedIn, user } = useLoginStatus();
  const { avatarUrl } = useAvatar();
  const loginContext = useLoginModalContext();
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState(true);
  const [commentContent, setCommentContent] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [editingComment, setEditingComment] = useState<string | null>(null);
  const [editContent, setEditContent] = useState("");

  // コメント一覧を取得
  const fetchComments = async () => {
    try {
      setLoading(true);
      const response = await apiRequest(`/api/v1/notes-nebula/comments?uuid=${textFileUuid}`);
      if (response.data.success) {
        setComments(response.data.comments);
      }
    } catch (error) {
      console.error("コメント取得エラー:", error);
    } finally {
      setLoading(false);
    }
  };

  // コメント投稿
  const handleSubmitComment = async () => {
    if (!isLoggedIn) {
      loginContext.setActiveModal("login");
      return;
    }

    if (!commentContent.trim()) return;

    try {
      setSubmitting(true);
      const response = await apiRequest("/api/v1/notes-nebula/comments", "POST", {
        uuid: textFileUuid,
        content: commentContent,
      });

      if (response.data.success) {
        setComments([response.data.comment, ...comments]);
        setCommentContent("");
      }
    } catch (error) {
      console.error("コメント投稿エラー:", error);
    } finally {
      setSubmitting(false);
    }
  };

  // コメント編集
  const handleEditComment = async (commentUuid: string) => {
    if (!editContent.trim()) return;

    try {
      const response = await apiRequest("/api/v1/notes-nebula/comments", "PUT", {
        uuid: commentUuid,
        content: editContent,
      });

      if (response.data.success) {
        setComments(
          comments.map((comment) =>
            comment.uuid === commentUuid
              ? { ...comment, content: response.data.comment.content, updated_at: response.data.comment.updated_at }
              : comment
          )
        );
        setEditingComment(null);
        setEditContent("");
      }
    } catch (error) {
      console.error("コメント編集エラー:", error);
    }
  };

  // コメント削除
  const handleDeleteComment = async (commentUuid: string) => {
    try {
      const response = await apiRequest(`/api/v1/notes-nebula/comments?uuid=${commentUuid}`, "DELETE");
      if (response.data.success) {
        setComments(comments.filter((comment) => comment.uuid !== commentUuid));
      }
    } catch (error) {
      console.error("コメント削除エラー:", error);
    }
  };

  // コメント表示/非表示切り替え
  const handleToggleVisibility = async (commentUuid: string, isVisible: boolean) => {
    try {
      const response = await apiRequest("/api/v1/notes-nebula/comments/visibility", "POST", {
        uuid: commentUuid,
        is_visible: isVisible,
      });
      if (response.data.success) {
        const updatedComment = response.data.comment;
        setComments(
          comments.map((comment) => (comment.uuid === commentUuid ? { ...comment, ...updatedComment } : comment))
        );
      }
    } catch (error) {
      console.error("コメント表示/非表示切り替えエラー:", error);
    }
  };

  // いいね
  const handleLikeComment = async (commentUuid: string) => {
    if (!isLoggedIn) {
      loginContext.setActiveModal("login");
      return;
    }

    try {
      const response = await apiRequest("/api/v1/notes-nebula/like", "POST", {
        comment_uuid: commentUuid,
      });

      if (response.data.success) {
        setComments(
          comments.map((comment) => {
            if (comment.uuid === commentUuid) {
              return {
                ...comment,
                is_liked: response.data.is_liked,
                like_count: response.data.is_liked ? comment.like_count + 1 : comment.like_count - 1,
              };
            }
            return comment;
          })
        );
      }
    } catch (error) {
      console.error("いいねエラー:", error);
    }
  };

  useEffect(() => {
    fetchComments();
  }, [textFileUuid]);

  return (
    <Box sx={{ mt: 4, width: "100%" }}>
      {/* コメント投稿フォーム */}
      {isLoggedIn && (
        <Paper
          elevation={0}
          sx={{
            p: 2,
            mb: 2,
            border: 1,
            borderColor: "divider",
            bgcolor: "background.paper",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
            <Avatar src={avatarUrl || undefined} alt={user?.username} sx={{ width: 40, height: 40 }} />
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                fullWidth
                multiline
                variant="standard"
                placeholder={t("notesNebula.comments.placeholder")}
                value={commentContent}
                onChange={(e) => setCommentContent(e.target.value)}
                sx={{ mb: 1 }}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  onClick={handleSubmitComment}
                  disabled={submitting || !commentContent.trim()}
                >
                  {submitting ? <CircularProgress size={24} color="inherit" /> : t("notesNebula.comments.submit")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      )}

      {/* コメント一覧 */}
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : comments.length > 0 ? (
        <Box>
          {comments.map((comment) => (
            <Paper
              key={comment.uuid}
              elevation={0}
              sx={{
                p: 2,
                mb: 2,
                border: 1,
                borderColor: "divider",
                bgcolor: comment.is_visible ? "background.paper" : "action.disabledBackground",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                <Avatar src={comment.avatar} alt={comment.user} sx={{ width: 40, height: 40 }} />
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" fontWeight="bold">
                      @{comment.user}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {localDate(comment.created_at, "ja")}
                      {comment.created_at !== comment.updated_at && ` (${t("notesNebula.comments.edited")})`}
                    </Typography>
                  </Box>

                  {editingComment === comment.uuid ? (
                    <Box sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        value={editContent}
                        onChange={(e) => setEditContent(e.target.value)}
                        variant="outlined"
                        sx={{ mb: 1 }}
                      />
                      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setEditingComment(null);
                            setEditContent("");
                          }}
                        >
                          {t("common.cancel")}
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleEditComment(comment.uuid)}
                          disabled={!editContent.trim()}
                        >
                          {t("common.save")}
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Typography variant="body1" sx={{ mb: 2, whiteSpace: "pre-wrap" }}>
                        {comment.content}
                      </Typography>
                      {!comment.is_visible && (
                        <Typography variant="caption" color="text.secondary" sx={{ mb: 2, display: "block" }}>
                          {t("notesNebula.comments.hiddenMessage")}
                        </Typography>
                      )}
                    </>
                  )}

                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Tooltip
                      title={comment.is_liked ? t("notesNebula.comments.unlike") : t("notesNebula.comments.like")}
                    >
                      <IconButton
                        size="small"
                        onClick={() => handleLikeComment(comment.uuid)}
                        color={comment.is_liked ? "error" : "default"}
                      >
                        {comment.is_liked ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderIcon fontSize="small" />}
                      </IconButton>
                    </Tooltip>
                    <Typography variant="body2" color="text.secondary">
                      {comment.like_count}
                    </Typography>

                    {/* コメント投稿者のみ表示 */}
                    {comment.is_author && (
                      <>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title={t("notesNebula.comments.edit")}>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setEditingComment(comment.uuid);
                              setEditContent(comment.content);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("notesNebula.comments.delete")}>
                          <IconButton size="small" onClick={() => handleDeleteComment(comment.uuid)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}

                    {/* 記事投稿者のみ表示 */}
                    {comment.is_article_author && !comment.is_author && (
                      <>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip
                          title={comment.is_visible ? t("notesNebula.comments.hide") : t("notesNebula.comments.show")}
                        >
                          <IconButton
                            size="small"
                            onClick={() => handleToggleVisibility(comment.uuid, !comment.is_visible)}
                          >
                            {comment.is_visible ? (
                              <VisibilityOffIcon fontSize="small" />
                            ) : (
                              <VisibilityIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
      ) : (
        <Box sx={{ textAlign: "center", my: 4 }}>
          <Typography variant="body1" color="text.secondary">
            {t("notesNebula.comments.noComments")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Comments;

import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import ReactCompareImage from "react-compare-image";
import { useTranslation } from "react-i18next";
import { Images } from "../../../../assets";
import { ColumnCenteredBox } from "../../../../utils/styledBox";

const Section1 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box component={"section"}>
        <Grid container spacing={{ xs: 8 }}>
          <Grid item xs={12}>
            <Typography
              variant={"h5"}
              component={"h3"}
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              mb={2}
              textAlign={"center"}
            >
              {t("aboutJuno.section1.title")}
            </Typography>
          </Grid>

          {/* t2i */}
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={6}>
                <ColumnCenteredBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h3"
                    fontWeight={"bold"}
                    fontFamily={"'Noto Serif JP', roboto"}
                    mb={2}
                  >
                    {t("aboutJuno.section1.t2i.title")}
                  </Typography>
                  <Box
                    sx={{
                      mb: 2,
                      borderRadius: 2,
                      height: { xs: 300, md: 350 },
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                    }}
                  >
                    <ReactCompareImage
                      leftImage={Images.juno.about.t2iBefore}
                      rightImage={Images.juno.about.t2iAfter}
                      leftImageAlt={"before"}
                      leftImageLabel={"Before"}
                      rightImageAlt={"after"}
                      rightImageLabel={"After"}
                      skeleton={<Skeleton variant="rectangular" width="100%" height={"100%"} />}
                    />
                  </Box>
                  <Typography variant="body1" component="p">
                    {t("aboutJuno.section1.t2i.description")}
                  </Typography>
                </ColumnCenteredBox>
              </Grid>

              {/* i2i */}
              <Grid item xs={12} md={6}>
                <ColumnCenteredBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h3"
                    fontWeight={"bold"}
                    fontFamily={"'Noto Serif JP', roboto"}
                    mb={2}
                  >
                    {t("aboutJuno.section1.i2i.title")}
                  </Typography>
                  <Box
                    sx={{
                      mb: 2,
                      borderRadius: 2,
                      height: { xs: 300, md: 350 },
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                    }}
                  >
                    <ReactCompareImage
                      leftImage={Images.juno.about.i2iBefore}
                      rightImage={Images.juno.about.i2iAfter}
                      leftImageAlt={"before"}
                      leftImageLabel={"Before"}
                      rightImageAlt={"after"}
                      rightImageLabel={"After"}
                      skeleton={<Skeleton variant="rectangular" width="100%" height={"100%"} />}
                    />
                  </Box>
                  <Typography variant="body1" component="p">
                    {t("aboutJuno.section1.i2i.description")}
                  </Typography>
                </ColumnCenteredBox>
              </Grid>
            </Grid>
          </Grid>

          {/* upscaler */}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h3"
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              textAlign={"center"}
              mb={2}
            >
              {t("aboutJuno.section1.upscale.title")}
            </Typography>

            <Grid container spacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    backgroundImage: `url(${Images.juno.about.sample01})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: 200,
                    height: "100%",
                    maxHeight: 350,
                    borderRadius: 2,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Box
                  sx={{
                    borderRadius: 2,
                    height: { xs: 200, md: 350 },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                  }}
                >
                  <ReactCompareImage
                    leftImage={Images.juno.about.before01}
                    rightImage={Images.juno.about.after01}
                    leftImageAlt={"before"}
                    leftImageLabel={"Before"}
                    rightImageAlt={"after"}
                    rightImageLabel={"After"}
                    skeleton={<Skeleton variant="rectangular" width="100%" height={"100%"} />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" textAlign={"center"}>
                  {t("aboutJuno.section1.upscale.description")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* edit */}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h3"
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              textAlign={"center"}
              mb={2}
            >
              {t("aboutJuno.section1.edit.title")}
            </Typography>

            <Grid container spacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    backgroundImage: `url(${Images.juno.about.editSample})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: 200,
                    height: "100%",
                    maxHeight: 350,
                    borderRadius: 2,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Box
                  sx={{
                    borderRadius: 2,
                    height: { xs: 200, md: 350 },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                  }}
                >
                  <ReactCompareImage
                    leftImage={Images.juno.about.editBefore}
                    rightImage={Images.juno.about.editAfter}
                    leftImageAlt={"before"}
                    leftImageLabel={"Before"}
                    rightImageAlt={"after"}
                    rightImageLabel={"After"}
                    skeleton={<Skeleton variant="rectangular" width="100%" height={"100%"} />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" textAlign={"center"}>
                  {t("aboutJuno.section1.edit.description")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Relight */}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h3"
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              textAlign={"center"}
              mb={2}
            >
              {t("aboutJuno.section1.relight.title")}
            </Typography>

            <Grid container spacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={8}>
                <Box
                  sx={{
                    borderRadius: 2,
                    height: { xs: 200, md: 350 },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                  }}
                >
                  <ReactCompareImage
                    leftImage={Images.juno.about.relightBefore}
                    rightImage={Images.juno.about.relightAfter}
                    leftImageAlt={"before"}
                    leftImageLabel={"Before"}
                    rightImageAlt={"after"}
                    rightImageLabel={"After"}
                    skeleton={<Skeleton variant="rectangular" width="100%" height={"100%"} />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    borderRadius: 2,
                    height: { xs: 200, md: 350 },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                  }}
                >
                  <ReactCompareImage
                    leftImage={Images.juno.about.productBefore}
                    rightImage={Images.juno.about.productAfter}
                    leftImageAlt={"before"}
                    leftImageLabel={"Before"}
                    rightImageAlt={"after"}
                    rightImageLabel={"After"}
                    skeleton={<Skeleton variant="rectangular" width="100%" height={"100%"} />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" textAlign={"center"}>
                  {t("aboutJuno.section1.relight.description")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Section1;

import DeleteIcon from "@mui/icons-material/Delete";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import styled from "@mui/material/styles/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAlert } from "../../../../context/AlertContext";
import { useVoicePlayer } from "../../../../hooks/useVoicePlayer";
import type { Voice } from "../../../../types/voice";
import { formatBytes, getFileDateName, localDate } from "../../../../utils/utils";

interface LibraryProps {
  generationCompleted: boolean;
}

export default function Library({ generationCompleted }: LibraryProps) {
  const { t } = useTranslation();
  const csrftoken = Cookies.get("csrftoken");
  const headers = new Headers({
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken!,
  });

  const [voices, setVoices] = useState<Voice[]>([]);
  const location = useLocation();
  const { playingUuid, loadingUuid, toggleAudio, downloadVoice } = useVoicePlayer();
  const [page, setPage] = React.useState(1);
  const [rows, setRows] = React.useState({} as { [key: string]: number });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);
  const { setAlert } = useAlert();
  const itemBoxRef = React.useRef<HTMLDivElement>(null);
  const uiLanguage = Cookies.get("ui_language") || "en";
  const [disabledDeleteButton, setDisabledDeleteButton] = useState<{ [key: string]: boolean }>({});

  interface FetchDataBody {
    [key: string]: unknown;
  }

  type ApiResponse = {
    data: Voice[];
    total_count: number;
  };

  const fetchData = async (
    url: string,
    method: string,
    body: FetchDataBody = {},
    responseType = "json"
  ): Promise<ApiResponse | Blob> => {
    try {
      const options: RequestInit = {
        method,
        headers,
      };
      if (method !== "GET") {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(url, options);

      if (responseType === "blob") {
        return await response.blob();
      } else {
        const responseData = await response.json();
        if (!responseData.success) {
          return {
            data: [] as Voice[],
            total_count: 0,
          };
        }
        return {
          data: responseData.data as Voice[],
          total_count: responseData.total_count,
        };
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error("An unknown error occurred");
      }
      return {
        data: [] as Voice[],
        total_count: 0,
      };
    }
  };

  useEffect(() => {
    // ページ遷移が発生したときに実行される
    return () => {
      // 音声が再生中の場合、停止する
      if (playingUuid) {
        toggleAudio(playingUuid);
      }
    };
  }, [location, playingUuid, toggleAudio]);

  const [totalCount, setTotalCount] = useState(0);

  const getVoices = async (currentPage: number, perPage: number): Promise<ApiResponse> => {
    const response = await fetchData(`/api/v1/voice-generator/voices?page=${currentPage}&per_page=${perPage}`, "GET");
    return response as ApiResponse;
  };

  useEffect(() => {
    getVoices(page, 10).then((response) => {
      setVoices(response.data);
      setTotalCount(response.total_count);
    });
  }, [page, generationCompleted]);

  // 秒数を整数にする
  function customRound(num: number) {
    if (num - Math.floor(num) >= 0.001) {
      return Math.ceil(num);
    } else {
      return Math.floor(num);
    }
  }

  // 削除確認ダイアログを開く
  const handleDialogOpen = (uuid: string) => {
    setSelectedUuid(uuid);
    setDialogOpen(true);
  };

  // 削除
  const handleConfirmedDelete = async (uuid: string) => {
    try {
      setDisabledDeleteButton((prevState) => ({
        ...prevState,
        [uuid]: true,
      }));
      const response = await fetch("/api/v1/voice-generator/delete/" + uuid, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken!,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result && result.success) {
        setAlert("success", t("library.voice.delete.success"));
        setVoices(voices.filter((voice) => voice.uuid !== uuid));
        setDisabledDeleteButton((prevState) => ({
          ...prevState,
          [uuid]: false,
        }));
      } else {
        setAlert("error", t("library.voice.delete.error"));
        setDisabledDeleteButton((prevState) => ({
          ...prevState,
          [uuid]: false,
        }));
      }
    } catch (error: unknown) {
      console.error(
        "Error during the fetch operation:",
        error instanceof Error ? error.message : "An unknown error occurred"
      );
      setAlert("error", t("library.voice.delete.error"));
      setDisabledDeleteButton((prevState) => ({
        ...prevState,
        [uuid]: false,
      }));
    }
  };

  const googleVoiceLabel = (gender: string, type: string | undefined, voice_number: string | undefined) => {
    if (!type || !voice_number) return "";
    return t(`voice.googleVoiceLabel.${type}`) + " - " + t(`voice.gender.${gender}`) + " " + voice_number;
  };

  const openaiVoiceLabel = (name: string, gender: string) => {
    return t(`voice.openaiVoiceLabel.${name}`) + " - " + t(`voice.gender.${gender}`);
  };

  // ページ切り替え
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // テキスト行数変更
  const changeRows = (id: string) => {
    setRows((prevState) => ({
      ...prevState,
      [id]: 10,
    }));
  };

  // テキスト行数リセット
  const resetRows = (id: string) => {
    setRows((prevState) => ({
      ...prevState,
      [id]: 2,
    }));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const [expanded, setExpanded] = React.useState({} as { [key: string]: boolean });

  return (
    <>
      <Grow in={true}>
        <Box
          sx={{
            height: "100%",
            backgroundColor: "background.paper",
            borderRadius: { xs: 0, sm: 2 },
          }}
        >
          <Box sx={{ mx: { xs: 1, md: 2 }, display: "flex", flexDirection: "column" }}>
            <Box sx={{ justifyContent: "space-between", display: "flex", mt: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <GraphicEqIcon sx={{ mr: 1 }} color={"secondary"} />
                <Typography variant="h6" component={"h2"} sx={{ position: "relative" }}>
                  {t("library.voice.title")}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                {t("library.voice.description")}
              </Typography>
            </Box>

            <TableContainer sx={{ maxHeight: { xs: `calc(100vh - 158px)`, sm: `calc(100vh - 220px)` } }}>
              <Table sx={{ minWidth: 650 }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: "background.paper", whiteSpace: "nowrap" }}>No</TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper", whiteSpace: "nowrap" }} align="right">
                      {t("library.voice.text")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper", whiteSpace: "nowrap" }} align="right">
                      {t("library.voice.seconds")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper", whiteSpace: "nowrap" }} align="right">
                      {t("library.voice.speakingRate")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper", whiteSpace: "nowrap" }} align="right">
                      {t("library.voice.pitch")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper", whiteSpace: "nowrap" }} align="right">
                      {t("library.voice.voice_name")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper", whiteSpace: "nowrap" }} align="right">
                      {t("library.voice.created_at")}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "background.paper", whiteSpace: "nowrap" }} align="right">
                      {t("library.voice.action")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {voices.map((voice, index) => (
                    <StyledTableRow key={voice.uuid} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <StyledTableCell align="center">{(page - 1) * 10 + index + 1}</StyledTableCell>
                      <StyledTableCell align="left" sx={{ minWidth: 400 }}>
                        <TextField
                          multiline
                          rows={rows[voice.uuid] || 2}
                          value={voice.text}
                          variant="outlined"
                          size={"small"}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <IconButton
                                onClick={() => {
                                  setExpanded((prevState) => ({
                                    ...prevState,
                                    [voice.uuid]: !prevState[voice.uuid],
                                  }));
                                  if (expanded[voice.uuid]) {
                                    resetRows(voice.uuid);
                                  } else {
                                    changeRows(voice.uuid);
                                  }
                                }}
                              >
                                {expanded[voice.uuid] ? (
                                  <UnfoldLessIcon fontSize={"small"} />
                                ) : (
                                  <UnfoldMoreIcon fontSize={"small"} />
                                )}
                              </IconButton>
                            ),
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="right">{customRound(voice.seconds)}</StyledTableCell>
                      <StyledTableCell align="right">{voice.speaking_rate}</StyledTableCell>
                      <StyledTableCell align="right">{voice.pitch}</StyledTableCell>
                      <StyledTableCell align="right">
                        {voice.provider === "openai" && openaiVoiceLabel(voice.voice_name, voice.gender)}
                        {voice.provider === "google" && (
                          <>
                            {voice.language_label} <br />
                            {googleVoiceLabel(voice.gender, voice.type, voice.number)}
                          </>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">{localDate(voice["created_at"], uiLanguage)}</StyledTableCell>
                      <StyledTableCell align="right">
                        <Tooltip title={t("common.play") + " / " + t("common.stop")}>
                          <IconButton onClick={() => toggleAudio(voice.uuid)}>
                            {playingUuid === voice.uuid ? (
                              <StopCircleIcon color={"error"} />
                            ) : loadingUuid === voice.uuid ? (
                              <CircularProgress size={24} />
                            ) : (
                              <PlayCircleIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("common.download") + ": " + formatBytes(voice.size)}>
                          <IconButton onClick={() => downloadVoice(voice.uuid, `voice-${getFileDateName()}.mp3`)}>
                            <DownloadForOfflineIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("common.delete")}>
                          <span>
                            <IconButton
                              onClick={() => handleDialogOpen(voice.uuid)}
                              disabled={disabledDeleteButton[voice.uuid]}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box flexGrow={1} ref={itemBoxRef} />
            <Pagination
              sx={{ my: 1, display: "flex", justifyContent: "center" }}
              count={Math.ceil(totalCount / 10)}
              page={page}
              onChange={handleChangePage}
            />
          </Box>

          {/* 削除確認ダイアログ */}
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>{t("library.voice.delete.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("library.voice.delete.description")}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disableElevation onClick={() => setDialogOpen(false)} color="primary">
                {t("common.cancel")}
              </Button>
              <Button
                onClick={() => {
                  handleConfirmedDelete(selectedUuid!);
                  setDialogOpen(false);
                }}
                color="error"
              >
                {t("common.delete")}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Grow>
    </>
  );
}

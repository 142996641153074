import Box from "@mui/material/Box";
import { IconButton, Tooltip } from "@mui/material";
import { AspectRatio } from "../../../../types/junoTypes";
import Typography from "@mui/material/Typography";
import React from "react";
import { AspectRatioIconType, useVideo } from "../../../../context/juno/VideoContext";
import { ColumnBox, RowBox } from "../../../../utils/styledBox";
import { useTranslation } from "react-i18next";
import { Info } from "@mui/icons-material";

const AspectRatioIcons = ({ aspectRatioList }: { aspectRatioList: AspectRatioIconType[] }) => {
  const { aiEngine, updateParams, aspectRatios } = useVideo();
  const { t } = useTranslation();

  return (
    <ColumnBox>
      <RowBox justifyContent={"space-between"}>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.aspect")}
        </Typography>
        <Tooltip title={t("juno.video.params.aspectTooltip")} placement="top">
          <IconButton size="small">
            <Info fontSize="small" />
          </IconButton>
        </Tooltip>
      </RowBox>

      <RowBox gap={1}>
        {aspectRatioList.map(({ ratio, icon: Icon, rotate }) => (
          <Box key={ratio} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <IconButton
              onClick={() => updateParams(aiEngine, "aspectRatio", ratio as AspectRatio)}
              color={aspectRatios[aiEngine] === ratio ? "primary" : "default"}
            >
              <Icon sx={{ rotate: rotate || "0deg" }} />
            </IconButton>
            <Typography
              variant={"caption"}
              onClick={() => updateParams(aiEngine, "aspectRatio", ratio as AspectRatio)}
              sx={{ cursor: "pointer" }}
            >
              {ratio}
            </Typography>
          </Box>
        ))}
      </RowBox>
    </ColumnBox>
  );
};

export default AspectRatioIcons;

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import Meta from "../../../components/common/Meta";
import { DrawerHeader } from "../../../components/layout/Drawer/DrawerMain";
import { useLoginModalContext } from "../../../context/LoginModalContext";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import useSubscription from "../../../hooks/useSubscription";
import { Images } from "../../../assets";
import { ColumnCenteredBox } from "../../../utils/styledBox";
import UpgradeModal from "../../Preferences/components/UpgradeModal";
import AboutCredit from "./components/AboutCredit";
import FAQ from "./components/FAQ";
import PermanentCreditSection from "./components/PermanentCreditSection";
import PricingCard from "./components/PricingCard";

const Pricing = () => {
  const { t } = useTranslation();
  const plan = {
    free: 0,
    basic: 1,
    standard: 2,
    premium: 3,
    basic_jpy: 1,
    standard_jpy: 2,
    premium_jpy: 3,
  };

  // プランレベルを取得する関数（_jpyを除外して比較）
  const getPlanLevel = (planName: string) => {
    const basePlan = planName.replace("_jpy", "");
    return plan[basePlan] || 0;
  };
  const { isLoggedIn } = useLoginStatus();
  const loginContext = useLoginModalContext();
  const [currentPlan, setCurrentPlan] = useState("free");
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [loading, setLoading] = useState(true); // プランのロード状態を追跡
  // URLパラメータからタブ値を取得
  const getTabFromUrl = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get("tab");
    if (tab === "credits") return 1;
    if (tab === "business") return 2;
    return 0; // デフォルトは個人プラン
  }, []);

  const [tabValue, setTabValue] = useState(getTabFromUrl());

  // タブ変更時にURLも更新
  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    const params = new URLSearchParams(window.location.search);

    if (newValue === 0) {
      params.delete("tab");
    } else if (newValue === 1) {
      params.set("tab", "credits");
    } else if (newValue === 2) {
      params.set("tab", "business");
    }

    const newUrl = `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ""}`;
    window.history.pushState({}, "", newUrl);
  };

  // URLが変更された時にタブを更新
  useEffect(() => {
    const handlePopState = () => {
      setTabValue(getTabFromUrl());
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [getTabFromUrl]);
  const navigate = useCustomNavigate();
  const theme = useTheme();
  const { getSubscription } = useSubscription();

  const handleSubscribeClick = async (plan: string) => {
    if (isLoggedIn) {
      // if (plan === 'free') return;
      setSelectedPlan(plan);
      setOpen(true);
    } else {
      loginContext.setActiveModal("login");
    }
  };

  const handlePermanentCreditPurchase = async (productId: string) => {
    if (isLoggedIn) {
      try {
        const csrftoken = Cookies.get("csrftoken");
        const config = { headers: { "X-CSRFToken": csrftoken } };
        const data = { product_id: productId };
        const res = await axios.post("/api/v1/users/permanent-credit", data, config);
        const url = res.data.url;
        if (url) {
          window.location.href = url;
        } else {
          console.error("Failed to obtain checkout URL");
        }
      } catch (error) {
        console.error("Failed to purchase permanent credit", error);
      }
    } else {
      loginContext.setActiveModal("login");
    }
  };

  const fetchData = useCallback(async () => {
    if (!isLoggedIn) {
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const res = await getSubscription();
      if (res.subscription) {
        setCurrentPlan(res.subscription.lookup_key);
      }
      setLoading(false);
    } catch (error) {
      console.error("Subscription data fetching failed", error);
      setLoading(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const meta = [
    { name: "robots", content: "index, follow" },
    { name: "description", content: t("meta.pricing.description") },
  ];

  return (
    <>
      <Meta title={t("meta.pricing.title")} meta={meta} />
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "100vh",
          backgroundImage: `url(${Images.pricing.top})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(1, 1, 1, 0.5)",
            backdropFilter: "blur(10px)",
          },
        }}
      >
        <Box
          sx={{
            zIndex: 1,
            textAlign: "center",
            color: "white",
            width: "100%",
          }}
        >
          <Container maxWidth={"xl"} sx={{ mb: 8, mt: { xs: 8, md: 4 } }}>
            <ColumnCenteredBox>
              <DrawerHeader />
              <Box mb={4}>
                <Typography variant="h2">{t("title")}</Typography>
                <Typography variant="h5" component={"p"}>
                  {t("pricing.description")}
                </Typography>
              </Box>

              {/* タブ切り替え */}
              <Box sx={{ mb: 4, width: "100%", maxWidth: 700, mx: "auto" }}>
                <Tabs value={tabValue} onChange={handleTabChange} centered textColor="inherit" sx={{}}>
                  <Tab label={t("pricing.tabs.subscriptionPlans")} />
                  <Tab label={t("pricing.tabs.permanentCredits")} />
                  <Tab label={t("pricing.tabs.businessPlans")} />
                </Tabs>
              </Box>

              {/* 個人向けサブスクリプションプラン */}
              {tabValue === 0 && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    minHeight: 700,
                  }}
                >
                  <Grid container spacing={2} justifyContent={"center"} sx={{ width: "100%" }}>
                    {Object.keys(plan)
                      .filter((p) => !p.includes("_jpy"))
                      .map((planKey, index) => (
                        <PricingCard
                          key={index}
                          planKey={planKey}
                          currentPlan={currentPlan}
                          isLoggedIn={isLoggedIn}
                          index={index}
                          handleSubscribeClick={handleSubscribeClick}
                          getPlanLevel={getPlanLevel}
                          loading={loading}
                        />
                      ))}
                  </Grid>
                </Box>
              )}

              {/* 永久クレジット */}
              {tabValue === 1 && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    minHeight: 700,
                  }}
                >
                  <PermanentCreditSection
                    currentPlan={currentPlan}
                    handlePurchaseClick={handlePermanentCreditPurchase}
                  />
                </Box>
              )}

              {/* 法人向けサブスクリプションプラン */}
              {tabValue === 2 && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    minHeight: 700,
                  }}
                >
                  <Typography variant="h5" sx={{ mb: 4 }}>
                    Coming soon...
                  </Typography>
                </Box>
              )}
            </ColumnCenteredBox>
          </Container>
        </Box>
      </Box>

      {/* クレジットについて */}
      <AboutCredit />
      <FAQ />
      <UpgradeModal
        open={open}
        handleClose={() => setOpen(false)}
        selectedPlan={selectedPlan}
        currentPlan={currentPlan}
      />

      <ColumnCenteredBox>
        <Button variant="text" size="small" sx={{ color: "common.white" }} onClick={() => navigate("/legal-notice")}>
          {t("pricing.legalNotice")}
        </Button>
      </ColumnCenteredBox>
    </>
  );
};

export default Pricing;

import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import { useUploadImageContext } from "../../../../../context/juno/UploadImageContext";
import { useUpscaleContext } from "../../../../../context/juno/UpscaleContext";
import { ColumnBox } from "../../../../../utils/styledBox";
import ImageUpload from "./ImageUpload";
import SelectPublic from "./SelectPublic";

export default function Upscale() {
  const { t } = useTranslation();
  const {
    upscaleFactor,
    setUpscaleFactor,
    upscaleCreativity,
    setUpscaleCreativity,
    upscaleResemblance,
    setUpscaleResemblance,
    upscaleDetail,
    setUpscaleDetail,
    uuidParent,
  } = useUpscaleContext();
  const { image } = useUploadImageContext();
  const { setPrompt } = useParamsContext();
  const { menu, aiEngine } = useParamsContext();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // ローカルステート
  const [localUpscaleFactor, setLocalUpscaleFactor] = useState(upscaleFactor[aiEngine]);
  const [localUpscaleCreativity, setLocalUpscaleCreativity] = useState(upscaleCreativity[aiEngine]);
  const [localUpscaleResemblance, setLocalUpscaleResemblance] = useState(upscaleResemblance[aiEngine]);
  const [localUpscaleDetail, setLocalUpscaleDetail] = useState(upscaleDetail[aiEngine]);

  // コンテキスト値の変更時にローカルステートを更新
  useEffect(() => {
    setLocalUpscaleFactor(upscaleFactor[aiEngine]);
  }, [aiEngine, upscaleFactor]);

  useEffect(() => {
    setLocalUpscaleCreativity(upscaleCreativity[aiEngine]);
  }, [aiEngine, upscaleCreativity]);

  useEffect(() => {
    setLocalUpscaleResemblance(upscaleResemblance[aiEngine]);
  }, [aiEngine, upscaleResemblance]);

  useEffect(() => {
    setLocalUpscaleDetail(upscaleDetail[aiEngine]);
  }, [aiEngine, upscaleDetail]);

  // 確定時の処理
  const handleFactorConfirm = () => {
    setUpscaleFactor((prev) => ({ ...prev, [aiEngine]: localUpscaleFactor }));
  };

  const handleCreativityConfirm = () => {
    setUpscaleCreativity((prev) => ({ ...prev, [aiEngine]: localUpscaleCreativity }));
  };

  const handleResemblanceConfirm = () => {
    setUpscaleResemblance((prev) => ({ ...prev, [aiEngine]: localUpscaleResemblance }));
  };

  const handleDetailConfirm = () => {
    setUpscaleDetail((prev) => ({ ...prev, [aiEngine]: localUpscaleDetail }));
  };

  // アップロード時に画像の横幅を取得
  useEffect(() => {
    if (image) {
      const img = new Image();
      img.src = image;
    }
  }, [image]);

  const handleSliderChange = (event: Event, value: number | number[]) => {
    setLocalUpscaleFactor(value as number);
  };

  useEffect(() => {
    setPrompt("");
  }, [aiEngine]);

  // ClarityUpscalerの最大値を返す
  const maxFactor = () => {
    if (aiEngine === "CreativeUpscaler") {
      if (image) {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          setWidth(img.width);
          setHeight(img.height);
        };
        // 4194304pxを超えない範囲の最大値、小数点第一位まで
        return Math.floor(Math.min(2048 / width, 2048 / height) * 10) / 10;
      } else {
        return 2;
      }
    } else {
      return 4;
    }
  };

  useEffect(() => {
    const factor = aiEngine === "AuraSR" ? 4 : aiEngine === "CreativeUpscaler" ? 1 : 1;
    setUpscaleFactor((prev) => ({ ...prev, [aiEngine]: factor }));
  }, [image]);

  return (
    <>
      <Box mb={2}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
          <Typography variant={"body2"}>{t("juno.input.image")}</Typography>
        </Box>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 1 }}>
          <ImageUpload type="primary" />
        </Box>
      </Box>

      {/* upscale サイズ */}
      {menu === "upscale" && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {/* upscale factor */}
          {["AuraSR", "ClarityUpscaler", "CreativeUpscaler"].includes(aiEngine) && (
            <>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography variant={"body2"} sx={{ mr: 1 }}>
                  {t("juno.input.scale")}
                </Typography>
                <Tooltip title={t("juno.input.scaleInfo")} placement="right">
                  <IconButton size={"small"}>
                    <InfoIcon fontSize={"small"} />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
                <Slider
                  defaultValue={1}
                  value={localUpscaleFactor}
                  onChange={handleSliderChange}
                  onChangeCommitted={handleFactorConfirm}
                  min={1}
                  max={maxFactor()}
                  step={0.1}
                  disabled={aiEngine === "AuraSR"}
                />
                <Box
                  sx={{
                    width: "120px",
                    bgcolor: "rgba(0, 0, 0, 0.1)",
                    px: 1,
                    py: 0.5,
                    justifyContent: "center",
                    display: "flex",
                    borderRadius: "5px",
                  }}
                >
                  <Typography variant={"body2"}>{localUpscaleFactor} x</Typography>
                </Box>
              </Box>
            </>
          )}
          {aiEngine === "ClarityUpscaler" && (
            // creativity Slider
            <ColumnBox>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography variant={"body2"} sx={{ mr: 1 }}>
                    {t("juno.input.creativity")}
                  </Typography>
                  <Tooltip title={t("juno.input.creativityInfo")} placement="right">
                    <IconButton size={"small"}>
                      <InfoIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
                  <Slider
                    defaultValue={0.35}
                    value={localUpscaleCreativity}
                    onChange={(event, value) => setLocalUpscaleCreativity(value as number)}
                    onChangeCommitted={handleCreativityConfirm}
                    min={0}
                    max={1}
                    step={0.1}
                  />
                  <Box
                    sx={{
                      width: "120px",
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      px: 1,
                      py: 0.5,
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant={"body2"}>{localUpscaleCreativity}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography variant={"body2"} sx={{ mr: 1 }}>
                    {t("juno.input.resemblance")}
                  </Typography>
                  <Tooltip title={t("juno.input.resemblanceInfo")} placement="right">
                    <IconButton size={"small"}>
                      <InfoIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
                  <Slider
                    defaultValue={0.6}
                    value={localUpscaleResemblance}
                    onChange={(event, value) => setLocalUpscaleResemblance(value as number)}
                    onChangeCommitted={handleResemblanceConfirm}
                    min={0.1}
                    max={1}
                    step={0.1}
                  />
                  <Box
                    sx={{
                      width: "120px",
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      px: 1,
                      py: 0.5,
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant={"body2"}>{localUpscaleResemblance}</Typography>
                  </Box>
                </Box>
              </Box>
            </ColumnBox>
          )}

          {aiEngine === "CreativeUpscaler" && (
            <ColumnBox>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography variant={"body2"} sx={{ mr: 1 }}>
                    {t("juno.input.creativity")}
                  </Typography>
                  <Tooltip title={t("juno.input.creativityInfo")} placement="right">
                    <IconButton size={"small"}>
                      <InfoIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
                  <Slider
                    defaultValue={0.35}
                    value={localUpscaleCreativity}
                    onChange={(event, value) => setLocalUpscaleCreativity(value as number)}
                    onChangeCommitted={handleCreativityConfirm}
                    min={0}
                    max={1}
                    step={0.1}
                  />

                  <Box
                    sx={{
                      width: "120px",
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      px: 1,
                      py: 0.5,
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant={"body2"}>{localUpscaleCreativity}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography variant={"body2"} sx={{ mr: 1 }}>
                    {t("juno.input.detail")}
                  </Typography>
                  <Tooltip title={t("juno.input.detailInfo")} placement="right">
                    <IconButton size={"small"}>
                      <InfoIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
                  <Slider
                    defaultValue={1}
                    value={localUpscaleDetail}
                    onChange={(event, value) => setLocalUpscaleDetail(value as number)}
                    onChangeCommitted={handleDetailConfirm}
                    min={0}
                    max={5}
                    step={0.1}
                  />
                  <Box
                    sx={{
                      width: "120px",
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      px: 1,
                      py: 0.5,
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant={"body2"}>{localUpscaleDetail}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography variant={"body2"} sx={{ mr: 1 }}>
                    {t("juno.input.resemblance")}
                  </Typography>
                  <Tooltip title={t("juno.input.resemblanceInfo")} placement="right">
                    <IconButton size={"small"}>
                      <InfoIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
                  <Slider
                    defaultValue={4}
                    value={localUpscaleResemblance}
                    onChange={(event, value) => setLocalUpscaleResemblance(value as number)}
                    onChangeCommitted={handleResemblanceConfirm}
                    min={aiEngine === "CreativeUpscaler" ? 0 : 0}
                    max={aiEngine === "CreativeUpscaler" ? 3 : 1}
                    step={0.1}
                  />

                  <Box
                    sx={{
                      width: "120px",
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      px: 1,
                      py: 0.5,
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant={"body2"}>{localUpscaleResemblance}</Typography>
                  </Box>
                </Box>
              </Box>
            </ColumnBox>
          )}

          {uuidParent && <SelectPublic />}
        </Box>
      )}
    </>
  );
}

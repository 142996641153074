const preference = {
  title: "Preferences",
  account: {
    title: "アカウント",
    subtitle: "アカウント設定を管理する",
    avatar: "アバター",
    username: "ユーザー名",
    email: "メールアドレス（非公開）",
    introduction: "自己紹介",
    introductionPlaceholder: "自己紹介を入力",
    introductionDescription: "自己紹介は、「Notes Nebura」「Studio Juno」で公開されます。",
    deleteDialog: {
      title: "アカウントの削除",
      description:
        "アカウントを削除するとデータは削除されますが、一部の公開データは匿名化され残る可能性があります。本当に削除しますか？",
      confirm: "削除",
      error: {
        common: "アカウントの削除に失敗しました。サポートに問い合わせてください。",
        subscription: "有料プランに加入しているため、アカウントを削除できません。プラン解約後、削除してください。",
      },
    },
  },
  settings: {
    title: "設定",
    appearance: "外観",
    uiLanguage: "UI言語",
    uiLanguageDescription: "ユーザーインターフェースの言語",
    aiLanguage: "AI言語",
    aiLanguageButton: "AI言語設定",
    aiLanguageDescription: "AIの応答言語",
    aiEngine: "AIエンジンモード",
    aiEngineDescription:
      "テキスト生成のAIエンジンモードを選択します。o3-mini, GPT-4o, Claude 3.7 Sonnet, GPT-4o-mini の中から適宜切り替えられます。この設定は右上のユーザーアイコンからいつでも変更できます。",
    country: "国",
    countryDescription: "国を選択し、検索結果を最適化します。",
    urlMode: {
      title: "URL参照モード",
      description:
        "AIがURLを参照するモードを選択します。モード '厳密' は、大量のクレジットを消費する可能性があります。",
      strict: "厳密",
      economy: "エコノミー",
    },
  },
  planAndBillings: {
    title: "サブスクリプションと請求",
    credit: "クレジット残高",
    totalCredit: "合計クレジット",
    monthlyCredit: "月額クレジット",
    dailyCredit: "デイリークレジット",
    extraCredit: "追加クレジット",
    dailyRefresh: "デイリー更新",
    subscriptionPeriod: "サブスクリプション期間",
    currentPlan: "サブスクリプションプラン",
    upgrade: "アップグレード",
    billings: "サブスクリプション請求履歴",
    cancel: "サブスクリプションのキャンセル",
    reactivate: "サブスクリプションの再開",
    invoice: "顧客ポータルで請求書を確認",
    noInvoice: "請求書はありません。",
    aboutCredit: "クレジットについて",
    checkoutHistory: "購入履歴",
    checkoutHistoryNote: "アップグレードとクレジットの購入（過去12ヶ月）",
    receipt: "領収書",
    document: "ドキュメント",
    selectHistory: "履歴を選択",
    pleaseSelect: "選択してください",
    resetDate: "リセット日時",
    upgradeModal: {
      title: "プランをアップグレード",
      description: "プランをアップグレードすると、より多くの機能を利用できます。",
      caution1: "アップグレードすると、即時にクレジットが加算され、機能制限が開放されます。",
      caution2: "すでに有料プランに加入済みの場合は、差額が請求されます。",
      caution3: "次回請求日（クレジット失効日）は変更されません。次回請求日は、顧客ポータルで確認できます。",
      caution4: "購入すると、上記及び、利用規約、プライバシーポリシーに同意したものとみなされます。",
      price: "請求額",
      nextPrice: "次回請求額",
      newPlan: "新しいプラン",
      upgrade: "アップグレード（JCB非対応）",
      upgrade_jcb: "JCB / ドル決済非対応カードはこちら",
    },
  },
  misc: {
    title: "その他",
    privacyPolicy: "プライバシーポリシー",
    termsOfService: "利用規約",
    contact: "お問い合わせ",
    startContact: "お問い合わせを開始",
    confirm: "確認する",
    contactModal: {
      title: "お問い合わせ",
      description: "お問い合わせ内容を入力してください。",
      warn: "Tenak AI に関するご質問以外、一般的な AI の使い方やご相談には対応いたしかねます。ご了承ください。",
      subject: "タイトル",
      type: {
        description: "お問い合わせ種別を選択してください。",
        question: "質問",
        problem: "不具合の報告",
        payment: "支払いに関して",
        request: "ご意見、ご要望",
        other: "その他",
      },
      name: "お名前",
      email: "メールアドレス",
      message: "お問い合わせ内容",
      send: "送信",
      error: "お問い合わせに失敗しました。時間をおいてから、もう一度お試しください。",
      success: "お問い合わせを受け付けました。担当者から追ってご連絡いたします。",
    },
  },
};
export default preference;

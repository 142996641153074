// src/assets/documents/index.ts
// ドキュメントファイルのインポート

// 英語ドキュメント
import enLegalNotice from "./en/legal-notice.md";
import enPrivacyPolicy from "./en/privacy-policy.md";
import enTermsOfService from "./en/terms-of-service.md";

// 日本語ドキュメント
import jaLegalNotice from "./ja/legal-notice.md";
import jaPrivacyPolicy from "./ja/privacy-policy.md";
import jaTermsOfService from "./ja/terms-of-service.md";

// エクスポート
export const Documents = {
  en: {
    legalNotice: enLegalNotice,
    privacyPolicy: enPrivacyPolicy,
    termsOfService: enTermsOfService,
  },
  ja: {
    legalNotice: jaLegalNotice,
    privacyPolicy: jaPrivacyPolicy,
    termsOfService: jaTermsOfService,
  },
};

// 個別エクスポート
export { enLegalNotice, enPrivacyPolicy, enTermsOfService, jaLegalNotice, jaPrivacyPolicy, jaTermsOfService };

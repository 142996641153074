import React from "react";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from "@mui/material";

const CreditUsageExamples: React.FC = () => {
  const { t } = useTranslation();

  // 最小・最大クレジット消費量（ハードコード）
  const creditConsumption = {
    text: {
      min: 15, // 最小クレジット消費量（1Mトークンあたり）- 速度優先モード（入力）
      max: 250, // 最大クレジット消費量（1Mトークンあたり）- 知能優先モード（入力）
    },
    images: {
      min: 0.4, // 最小クレジット消費量（1枚あたり）- Flux.1 Schnell
      max: 15, // 最大クレジット消費量（1枚あたり）- OPEN AI: DALL-E 3
    },
    videos: {
      min: 2.5, // 最小クレジット消費量（1秒あたり）- ByteDance LatentSync
      max: 35.7, // 最大クレジット消費量（1秒あたり）- Google VEO2
    },
    voice: {
      min: 2, // 最小クレジット消費量（1000文字あたり）- Google TTS
      max: 20, // 最大クレジット消費量（1000文字あたり）- OPEN AI Advanced Voice Mode
    },
  };

  // クレジット使用例の計算用ヘルパー関数
  const calculateUsageRange = (
    creditAmount: number,
    category: "text" | "images" | "videos" | "voice"
  ): { min: string; max: string } => {
    const consumption = creditConsumption[category];

    // クレジット数 ÷ 消費量 = 利用可能量
    const minUsage = Math.floor(creditAmount / consumption.max);
    const maxUsage = Math.floor(creditAmount / consumption.min);

    let formattedMin: string;
    let formattedMax: string;

    if (category === "text") {
      // トークンはM tokens単位で表示（1百万トークンあたり）
      // 1Mトークンあたりのクレジット数なので、逆数を取ってクレジットあたりのMトークン数を計算
      const minTokensInM = creditAmount / consumption.max;
      const maxTokensInM = creditAmount / consumption.min;
      formattedMin = `${minTokensInM.toFixed(1)}`;
      formattedMax = `${maxTokensInM.toFixed(1)}`;
    } else if (category === "voice") {
      // 音声は千文字単位で表示
      formattedMin = `${minUsage.toFixed(0)}`;
      formattedMax = `${maxUsage.toFixed(0)}`;
    } else {
      // 画像と動画はそのまま表示
      formattedMin = `${minUsage}`;
      formattedMax = `${maxUsage}`;
    }

    return { min: formattedMin, max: formattedMax };
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        {t("pricing.aboutCredit.usageExamples.title")}
      </Typography>
      <Typography paragraph>{t("pricing.aboutCredit.usageExamples.description")}</Typography>

      <TableContainer sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "primary.main" }}>
              <TableCell>{t("pricing.aboutCredit.usageExamples.category")}</TableCell>
              <TableCell align="center">{t("pricing.aboutCredit.usageExamples.basic")}</TableCell>
              <TableCell align="center">{t("pricing.aboutCredit.usageExamples.standard")}</TableCell>
              <TableCell align="center">{t("pricing.aboutCredit.usageExamples.premium")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {t("pricing.aboutCredit.text.title")}
              </TableCell>
              {[900, 2000, 4200].map((credits, index) => {
                const { min, max } = calculateUsageRange(credits, "text");
                return (
                  <TableCell key={index} align="center">
                    {t("pricing.aboutCredit.usageExamples.textRange", { min, max })}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t("pricing.aboutCredit.image.title")}
              </TableCell>
              {[900, 2000, 4200].map((credits, index) => {
                const { min, max } = calculateUsageRange(credits, "images");
                return (
                  <TableCell key={index} align="center">
                    {t("pricing.aboutCredit.usageExamples.imageRange", { min, max })}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t("pricing.aboutCredit.video.title")}
              </TableCell>
              {[900, 2000, 4200].map((credits, index) => {
                const { min, max } = calculateUsageRange(credits, "videos");
                return (
                  <TableCell key={index} align="center">
                    {t("pricing.aboutCredit.usageExamples.videoRange", { min, max })}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t("pricing.aboutCredit.voice.title")}
              </TableCell>
              {[900, 2000, 4200].map((credits, index) => {
                const { min, max } = calculateUsageRange(credits, "voice");
                return (
                  <TableCell key={index} align="center">
                    {t("pricing.aboutCredit.usageExamples.voiceRange", { min, max })}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="caption" sx={{ display: "block", mt: 2, textAlign: "right" }}>
        {t("pricing.aboutCredit.usageExamples.note")}
      </Typography>
    </Box>
  );
};

export default CreditUsageExamples;

import Crop169Icon from "@mui/icons-material/Crop169";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../../context/juno/VideoContext";
import { ColumnBox } from "../../../../../utils/styledBox";
import AspectRatioIcons from "../AspectRatioIcons";
import ModelVersionSelectBox from "../ModelVersionSelectBox";
import ImageUpload from "../ImageUpload";

const ParamsGoogle = () => {
  const { t } = useTranslation();
  const { updateParams, duration, modelVersion, menu, image1, setImage1 } = useVideo();

  const aspectRatio: AspectRatioIconType[] = [
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
  ];

  const durationMarks = [
    {
      value: 5,
      label: "5s",
    },
    {
      value: 6,
      label: "6s",
    },
    {
      value: 7,
      label: "7s",
    },
    {
      value: 8,
      label: "8s",
    },
  ];

  return (
    <ColumnBox sx={{ gap: 4 }}>
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <ModelVersionSelectBox
          platform="GOOGLE"
          value={modelVersion.GOOGLE}
          onChange={(value) => updateParams("GOOGLE", "modelVersion", value)}
          menu={menu}
        />
      </ColumnBox>

      <ColumnBox>
        {/* Duration */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.duration")}
        </Typography>
        <Slider
          aria-label="duration"
          marks={durationMarks}
          min={5}
          max={8}
          step={1}
          value={duration.GOOGLE}
          onChange={(_, value) => updateParams("GOOGLE", "duration", value)}
        />
      </ColumnBox>

      {/* Image */}
      {menu === "i2v" && (
        <ColumnBox sx={{ justifyContent: "center" }}>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")}
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
        </ColumnBox>
      )}

      <ColumnBox>
        {/* aspect */}
        <AspectRatioIcons aspectRatioList={aspectRatio} />
      </ColumnBox>
    </ColumnBox>
  );
};

export default ParamsGoogle;

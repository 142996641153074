import { Box, Chip, Divider, Grid, Grow, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoginModalContext } from "../../../../context/LoginModalContext";
import { useLoginStatus } from "../../../../context/LoginStatusContext";
import { GradationButton } from "../../../../utils/gradationButton";
import { GradientTypography } from "../../../../utils/gradientTypography";

interface PermanentCreditCardProps {
  productData: {
    id: string;
    name: string;
    credit_amount: number;
    unit_price: number;
    price: number;
    currency: string;
    original_unit_price?: number; // 割引前の単価
    original_price?: number; // 割引前の価格
  };
  index: number;
  handlePurchaseClick: (productId: string) => void;
  currentPlan?: string; // 追加: 現在のプラン
}

const PermanentCreditCard = ({ productData, index, handlePurchaseClick, currentPlan }: PermanentCreditCardProps) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useLoginStatus();
  const loginContext = useLoginModalContext();

  // 割引率の計算
  const getDiscountRate = () => {
    if (productData.original_unit_price) {
      // 元の単価と現在の単価から割引率を計算
      const discount =
        ((productData.original_unit_price - productData.unit_price) / productData.original_unit_price) * 100;
      return Math.round(discount);
    }

    // 従来の計算方法（互換性のため残す）
    const baseUnitPrice = productData.name.startsWith("S") ? 0.025 : 0.022;
    const discount = ((baseUnitPrice - productData.unit_price) / baseUnitPrice) * 100;
    return Math.round(discount);
  };

  const discountRate = getDiscountRate();

  // 商品IDからプランタイプを抽出（例: permanent_credits_standard_500_usd → standard）
  const getProductPlanType = (productId: string): string => {
    const parts = productId.split("_");
    return parts.length >= 3 ? parts[2] : "";
  };

  // ユーザーのプランに基づいて購入ボタンの有効/無効を判定
  const isButtonDisabled = (): boolean => {
    if (!currentPlan) return false;

    const basePlan = currentPlan.replace("_jpy", "");

    // Standard以上のプランでない場合、すべての購入ボタンを無効化
    if (!["standard", "premium"].includes(basePlan)) {
      return true;
    }

    const productPlanType = getProductPlanType(productData.id);
    const userPlanType = currentPlan.includes("premium") ? "premium" : "standard";

    // Premiumユーザーの場合、Standard商品は購入不可
    if (userPlanType === "premium" && productPlanType === "standard") {
      return true;
    }

    // Standardユーザーの場合、Premium商品は購入不可
    if (userPlanType === "standard" && productPlanType === "premium") {
      return true;
    }

    return false;
  };

  // mountedRef を使用して初回マウント時のみアニメーションを発火させる
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Grow in={mounted} timeout={(index + 1) * 500} key={index}>
      <Grid item xs={12} md={6} sx={{ width: "100%" }}>
        <Paper sx={{ p: 2, textAlign: "center", height: "100%", position: "relative" }}>
          {discountRate > 0 && (
            <Chip label={`${discountRate}% OFF`} color="error" sx={{ position: "absolute", top: 10, right: 10 }} />
          )}

          <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <GradientTypography variant="h5" mb={2}>
              {productData.name}
            </GradientTypography>

            <Box sx={{ mb: 2 }}>
              {discountRate > 0 && productData.original_price ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography
                    variant="h5"
                    component="p"
                    sx={{ textDecoration: "line-through", color: "text.secondary", mr: 1 }}
                  >
                    ${productData.original_price.toFixed(2)}
                  </Typography>
                  <Typography variant="h4" component="p">
                    ${productData.price.toFixed(2)}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="h4" component="p">
                  ${productData.price.toFixed(2)}
                </Typography>
              )}
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">
                {productData.credit_amount.toLocaleString()} {t("pricing.permanentCredit.credits")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("pricing.permanentCredit.neverExpire")}
              </Typography>
            </Box>

            <Box flexGrow={1} />

            <GradationButton
              fullWidth
              disabled={isButtonDisabled()}
              onClick={() => {
                if (isLoggedIn) {
                  handlePurchaseClick(productData.id);
                } else {
                  loginContext.setActiveModal("login");
                }
              }}
            >
              {t("pricing.permanentCredit.purchase")}
            </GradationButton>
          </Box>
        </Paper>
      </Grid>
    </Grow>
  );
};

export default PermanentCreditCard;

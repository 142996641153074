import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import ShortTextIcon from "@mui/icons-material/ShortText";
import { Avatar, Box, Button, CircularProgress, Paper, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CopyButtonOrIcon from "../../../components/common/CopyButtonOrIcon";
import { MarkdownContentBox } from "../../../components/common/MarkdownContentBox";
import { useAlert } from "../../../context/AlertContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { Images } from "../../../assets";
import { AnswerBoxProps } from "../../../types/chatTypes";

export default function AnswerBox({ historyItem, setSelectSourceId }: AnswerBoxProps) {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const [saving, setSaving] = useState(false);
  const { setAlert } = useAlert();

  const handleNavigate = (key: string) => {
    switch (key) {
      case "article":
        navigate("/writing-ai/article", { state: { text: historyItem.content } });
        break;
      case "mindMap":
        navigate("/mind-map-generator", { state: { text: historyItem.content } });
        break;
      case "summary":
        navigate("/writing-ai/summarizer", { state: { text: historyItem.content } });
        break;
      default:
        break;
    }
  };

  const handleNewFile = async () => {
    try {
      setSaving(true);

      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      // リクエストヘッダーにCSRFトークンを追加
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };

      const title = historyItem.content.split("\n")[0].slice(0, 36);

      const res = await axios.post(
        "/api/v1/text-file/",
        {
          type: 1,
          content: historyItem.content,
          title: title,
        },
        config
      );
      setAlert("success", t("file.createFile"), `/notes-nebula/files/${res.data.uuid}`);
    } catch (error) {
      console.error("An unknown error occurred:", error);
    } finally {
      // 1秒遅延
      setTimeout(() => {
        setSaving(false);
      }, 1000);
    }
  };

  return (
    <Paper
      data-id={historyItem.message_uuid}
      elevation={2}
      sx={{
        px: { xs: 2, sm: 3 },
        pt: 2,
        pb: 2,
        mb: 1,
        transition: "all 0.3s ease",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "left",
          flexDirection: "row",
        }}
      >
        <Avatar alt="User avatar" src={Images.tenak.small} sx={{ width: 32, height: 32 }} />
        <Typography variant="subtitle1" component={"p"} sx={{ ml: 2 }} color={"textSecondary"}>
          {t("title")}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box display="flex" justifyContent="right" alignItems={"flex-start"} gap={1}>
          <Tooltip title={t("browsing.save")} placement="top">
            <IconButton size="small" aria-label="article" onClick={() => handleNewFile()} disabled={saving}>
              {saving ? (
                <CircularProgress size={24} />
              ) : (
                <img src={Images.notesNebula.ico} alt="save" style={{ height: 24 }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("browsing.article")} placement="top">
            <IconButton size="small" aria-label="article" onClick={() => handleNavigate("article")}>
              <ArticleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("browsing.mindMap")} placement="top">
            <IconButton size="small" aria-label="article" onClick={() => handleNavigate("mindMap")}>
              <AccountTreeOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("browsing.summary")} placement="top">
            <IconButton size="small" aria-label="article" onClick={() => handleNavigate("summary")}>
              <ShortTextIcon />
            </IconButton>
          </Tooltip>

          <CopyButtonOrIcon displayType={"icon"} textToCopy={historyItem.content} size={"small"} />
        </Box>
      </Box>
      <Box flexDirection={"column"} justifyContent={"left"} display={"flex"} sx={{ overflowWrap: "break-word" }} mt={1}>
        {historyItem.content && historyItem.content.length > 0 && (
          <>
            <MarkdownContentBox>{historyItem.content}</MarkdownContentBox>
            {historyItem.search_results && (
              <Box>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setSelectSourceId(historyItem.message_uuid ?? "")}
                >
                  <Typography variant="button">SOURCE</Typography>
                </Button>
              </Box>
            )}
          </>
        )}

        {(!historyItem.content || historyItem.content.length === 0) && (
          <Typography variant="body2" textAlign="left" color={"error"} sx={{ fontWeight: "bold", ml: 2, mt: 2 }}>
            No answer
          </Typography>
        )}
      </Box>
    </Paper>
  );
}

import Crop169Icon from "@mui/icons-material/Crop169";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import InfoIcon from "@mui/icons-material/Info";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../../context/juno/VideoContext";
import { ColumnBox, RowBox } from "../../../../../utils/styledBox";
import AspectRatioIcons from "../AspectRatioIcons";
import ImageUpload from "../ImageUpload";
import ModelVersionSelectBox from "../ModelVersionSelectBox";

const ParamsWan = () => {
  const { t } = useTranslation();
  const { updateParams, modelVersion, menu, inferenceSteps, image1, setImage1, duration } = useVideo();

  // V2_1_PROモデルが選択されたときの処理
  useEffect(() => {
    if (modelVersion.WAN === "V2_1_PRO") {
      // アスペクト比をAUTOに設定
      updateParams("WAN", "aspectRatio", "AUTO");
    }
  }, [modelVersion.WAN]);

  // 通常のWanモデル用のアスペクト比
  const standardAspectRatio: AspectRatioIconType[] = [
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
  ];

  // Pro用のアスペクト比（AUTO）
  const proAspectRatio: AspectRatioIconType[] = [{ ratio: "AUTO", icon: HdrAutoIcon }];

  const durationMarks = [
    {
      value: 5,
      label: "5s",
    },
  ];

  // 現在のモデルに基づいてアスペクト比を選択
  const currentAspectRatio = modelVersion.WAN === "V2_1_PRO" ? proAspectRatio : standardAspectRatio;
  const isProModel = modelVersion.WAN === "V2_1_PRO";

  return (
    <ColumnBox sx={{ gap: 4 }}>
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <ModelVersionSelectBox
          platform="WAN"
          value={modelVersion.WAN}
          onChange={(value) => updateParams("WAN", "modelVersion", value)}
          menu={menu}
        />
      </ColumnBox>

      <ColumnBox>
        {/* Duration */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.duration")}
        </Typography>
        <Slider
          aria-label="duration"
          marks={durationMarks}
          min={5}
          max={10}
          step={5}
          value={duration.WAN}
          onChange={(_, value) => updateParams("WAN", "duration", value)}
          disabled
        />
      </ColumnBox>

      {menu === "i2v" && (
        <ColumnBox sx={{ justifyContent: "center" }}>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")}
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
        </ColumnBox>
      )}

      <ColumnBox>
        {/* Inference Steps */}
        <RowBox sx={{ mb: 1 }}>
          <Typography variant="body2">{t("juno.video.params.inferenceSteps")}</Typography>
          <Tooltip title={t("juno.video.params.inferenceStepsHelp")}>
            <InfoIcon fontSize="small" sx={{ ml: 1 }} />
          </Tooltip>
        </RowBox>
        <Slider
          aria-label="inferenceSteps"
          marks={[
            { value: 10, label: "10" },
            { value: 20, label: "20" },
            { value: 30, label: "30" },
            { value: 40, label: "40" },
          ]}
          min={10}
          max={40}
          step={2}
          value={inferenceSteps.WAN}
          onChange={(_, value) => updateParams("WAN", "inferenceSteps", value as number)}
          valueLabelDisplay="auto"
          disabled={isProModel}
        />
      </ColumnBox>

      <ColumnBox>
        {/* aspect */}
        <AspectRatioIcons aspectRatioList={currentAspectRatio} />
      </ColumnBox>
    </ColumnBox>
  );
};

export default ParamsWan;

import CropDinIcon from "@mui/icons-material/CropDin";
import SquareIcon from "@mui/icons-material/Square";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { colorList, NodeColor } from "../CustomNode/types";

interface ColorButtonsProps {
  /** 選択中のノードID */
  selectedNode: string | null;
  /** ノードの色を変更する関数 */
  onColorChange: (color: NodeColor) => void;
}

/**
 * ノードの色を変更するボタン群コンポーネント
 */
export const ColorButtons: React.FC<ColorButtonsProps> = ({ selectedNode, onColorChange }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Tooltip arrow title={t("mindmap.nodeColorTooltip")}>
      <Box sx={{ display: "flex", px: 2 }}>
        <IconButton
          disabled={!selectedNode}
          onClick={() =>
            onColorChange({
              name: "",
              lightColor: theme.palette.grey[300],
              darkColor: theme.palette.grey[800],
            })
          }
        >
          <CropDinIcon sx={{ fontSize: "medium" }} />
        </IconButton>
        {colorList.map((item, index) => (
          <span key={index}>
            <IconButton disabled={!selectedNode} onClick={() => onColorChange(item)}>
              <SquareIcon
                sx={{
                  fontSize: "medium",
                  color: selectedNode ? item.darkColor : "",
                }}
              />
            </IconButton>
          </span>
        ))}
      </Box>
    </Tooltip>
  );
};

ColorButtons.propTypes = {
  selectedNode: PropTypes.string,
  onColorChange: PropTypes.func.isRequired,
};

import { Slider, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../../context/AlertContext";
import { useVideo } from "../../../../../context/juno/VideoContext";
import { ColumnBox } from "../../../../../utils/styledBox";
import ModelVersionSelectBox from "../ModelVersionSelectBox";
import VideoUpload from "../VideoUpload";

const ParamsREALESRGAN = () => {
  const { t } = useTranslation();
  const { scale, setScale, video1, setVideo1, modelVersion, updateParams, setVideo1Metadata, aiEngine } = useVideo();
  const { setAlert } = useAlert();

  const [maxScale, setMaxScale] = useState<number>(4);

  // メタデータの取得
  useEffect(() => {
    if (video1) {
      const fetchMetadata = async () => {
        const csrftoken = Cookies.get("csrftoken");
        const response = await fetch("/api/v1/juno/video-metadata", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken!,
          },
          body: JSON.stringify({
            video1,
          }),
        });

        const data = await response.json();
        if (data.status === "success" && data.metadata) {
          setVideo1Metadata(data.metadata);

          // バリデーション
          try {
            // 解像度チェック
            if (data.metadata.width * data.metadata.height > 8294400) {
              setVideo1("");
              setAlert("error", t("juno.video.error.max.resolution"));
              return;
            }

            // 動画の長さをチェック
            if (data.metadata.duration > 60) {
              setVideo1("");
              setAlert("error", t("juno.video.error.max.videoDuration", { sec: 60 }));
              return;
            }

            // REALESRGANの場合、フレーム数チェック
            if (modelVersion[aiEngine] === "REALESRGAN") {
              const totalFrames = Math.floor(data.metadata.fps * data.metadata.duration);
              if (totalFrames >= 1000) {
                setVideo1("");
                setAlert("error", t("juno.video.error.max.frames"));
                return;
              }
            }

            // 4Kの総ピクセル数を基準に計算
            const maxPixels = 3840 * 2160;
            const inputPixels = data.metadata.width * data.metadata.height;
            const calculatedMaxScale = Math.sqrt(maxPixels / inputPixels);
            // 小数点第1位までに制限（4以下）
            const finalMaxScale = Math.min(Math.floor(calculatedMaxScale * 10) / 10, 4);
            setMaxScale(finalMaxScale);

            // スケール値の制限
            if (scale > finalMaxScale) {
              setScale(finalMaxScale);
            }
          } catch (error) {
            console.error(error);
            setVideo1("");
            setAlert("error", t("juno.error.validation"));
            return;
          }
        }
      };

      fetchMetadata().catch(console.error);
    } else {
      setVideo1Metadata(null);
      setMaxScale(4);
    }
  }, [video1]);

  // スライダーのマークを計算
  const marks = useMemo(() => {
    const baseMarks = [
      { value: 1, label: "1x" },
      { value: 2, label: "2x" },
      { value: 3, label: "3x" },
      { value: 4, label: "4x" },
    ];
    return baseMarks.filter((mark) => mark.value <= maxScale);
  }, [maxScale]);

  return (
    <ColumnBox sx={{ gap: 4 }}>
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <ModelVersionSelectBox
          platform="REALESRGAN"
          value={modelVersion.REALESRGAN}
          onChange={(value) => updateParams("REALESRGAN", "modelVersion", value)}
          menu={"upscale"}
        />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.video")}
        </Typography>
        <VideoUpload video={video1} setVideo={setVideo1} />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.scale")}
        </Typography>
        <Slider
          value={scale}
          onChange={(_, value) => {
            // ローカルのスケール値を更新
            const newScale = value as number;
            if (newScale > maxScale) {
              setScale(maxScale);
            } else {
              setScale(newScale);
            }
          }}
          min={1}
          max={maxScale}
          step={0.1}
          valueLabelDisplay="auto"
          marks={marks}
        />
      </ColumnBox>
    </ColumnBox>
  );
};

export default ParamsREALESRGAN;

// JunoImageProvider.tsx
import React from "react";
import { CommonProvider } from "./CommonContext";
import { EditorProvider } from "./EditorContext";
import { ParamsProvider } from "./ParamsContext";
import { UploadImageProvider } from "./UploadImageContext";
import { UpscaleProvider } from "./UpscaleContext";
import { VideoProvider } from "./VideoContext";

export const JunoImageProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <ParamsProvider>
      <UpscaleProvider>
        <UploadImageProvider>
          <CommonProvider>
            <EditorProvider>
              <VideoProvider>{children}</VideoProvider>
            </EditorProvider>
          </CommonProvider>
        </UploadImageProvider>
      </UpscaleProvider>
    </ParamsProvider>
  );
};

import junoImageEngine from "./junoImageEngine";
import junoPreset from "./junoPreset";
import junoStyle from "./junoStyle";
import junoVideo from "./junoVideo";

const juno = {
  title: "Studio Juno",
  description: "Studio Juno is a platform that provides various AI models for image generation.",
  explore: "Explore",
  goToGenerator: "Generator",
  goToVideoGenerator: "Video Generator",
  goToVoiceGenerator: "Voice Generator",
  models: "Models",
  myGallery: "My Images",
  videos: "My Videos",
  likes: "My Likes",
  searchResult: "Search Results for",
  noResult: "No search results were found. (Please ensure you are entering correct English.)",
  menu: {
    title: "MENU",
    txt2img: "Text to Image",
    img2img: "Image to Image",
    upscale: "Upscale",
    editor: "Image Editor",
  },
  input: {
    engine: junoImageEngine,
    category: {
      juno: "STUDIO JUNO Original Model. Expands the possibilities of image generation with our AI technology.",
      vary: "Variation Generation Model: Reproduces images from original images, enabling image refinement. Generates image restyling and variations according to the prompt.",
      twoK: "2K Model: Capable of generating high-quality 2K resolution (4MP) images.",
      vector: "Vector Model: Also supports vector illustrations.",
      controlNet:
        "ControlNet: Allows control of image generation. Canny generates edges from the original image, Depth generates depth, and new images are generated from them.",
      editor:
        "Editor: Provides advanced inpainting capabilities using text descriptions and binary masks to seamlessly edit existing images.",
    },
    aspectRatio: {
      title: "Aspect Ratio",
      custom: "Custom",
    },
    width: "Width",
    height: "Height",
    step: "Steps",
    sample: "Number of Generated Images",
    sampleDescription:
      "'Width' or 'height' exceeding {{px}}px will limit the number of generated images to one. If you wish to generate multiple images, it is recommended to generate them below {{px}}px and then upscale.",
    rawMode: "RAW Mode",
    rawModeDescription:
      "Generates images with natural beauty rather than artificial beauty. Compared to models that generate images from text, RAW mode significantly enhances the diversity of human subjects and increases the realism of natural photos.",
    resolution: "High-resolution fix (Auto)",
    resolutionCaution: "The number of generations is limited to 1",
    resolutionTooltip:
      "Applying high-resolution fix improves the resolution of the generated image. It is automatically applied when either the width or height is {{px}}px or more.",
    guidanceScale: "Prompt Emphasis",
    seedSD: "Seed (Valid for the same model only)",
    dallE3RevisedPrompt: "AI-Enhanced Prompt",
    colorSelection: {
      title: "Color Palette",
    },
    style: junoStyle,
    basicSettings: "Basic Settings",
    advancedSettings: "Advanced Settings",
    negativePrompt: "Negative Prompt",
    negativePromptHistory: "Negative Prompt",
    negativePromptPlaceholder: "e.g. worst quality, nsfw, bad anatomy, out of focus",
    negativePromptDescription: "Please specify elements you do not want included in the image.",
    negativePromptAI: "Generate Negative Prompt",
    negativePromptTitle: "Preset Name",
    negativePromptSave: "Save Negative Prompt",
    negativePromptList: "Load Negative Prompt",
    delete: {
      title: "Are you sure you want to delete the negative prompt?",
      description: "Deleting the negative prompt will remove all data within the prompt. This action cannot be undone.",
      success: "The negative prompt has been deleted.",
    },
    systemPrompt: "System Prompt",
    systemPromptPlaceholder: "e.g. You are professional photographer",
    image: "Original Image",
    imageFace: "Original Image (Face)",
    imageHuman: "Original Image (Human)",
    imageVTO: "Original Image (Clothes)",
    imageMain: "Main Image",
    imageBackground: "Background Image",
    imageProduct: "Product Image",
    imageStrength: "Influence of Original Image",
    manualPlacementSelection: {
      title: "Placement Position",
      original: "Original",
      upper_left: "Upper Left",
      upper_right: "Upper Right",
      bottom_left: "Bottom Left",
      bottom_right: "Bottom Right",
      right_center: "Right Center",
      left_center: "Left Center",
      upper_center: "Upper Center",
      bottom_center: "Bottom Center",
      center_vertical: "Center Vertical",
      center_horizontal: "Center Horizontal",
    },
    initialLatent: {
      title: "Lighting Condition",
      None: "AUTO",
      Left: "Left",
      Right: "Right",
      Top: "Top",
      Bottom: "Bottom",
    },
    imageEditor: {
      title: "Image Editor",
      mask: "Mask",
      original: "Original",
      open: "Open Editor",
      openImage: "Open Image",
      openImageDescription: "Automatically set from the image editor",
      save: "Save",
      brushSize: "Mask Brush Size",
      noImage: "Please upload at least one image.",
      reset: "Reset All",
      aspectRatio: "Image Size",
      eraser: "Eraser",
      eraserSize: "Eraser Size",
      select: "Image Selection Tool: Move, Enlarge, Reduce\n(Delete with DELETE key while selecting image)",
      upload: "Upload Image",
      generated: "Upload from Generated Images",
      unsplash: "Add images from Unsplash",
      description: "Insert an image. New images will be generated in the white margin and brush drawing area.",
    },
    idWeight: "Influence of Facial Features",
    alert: {
      imageType: "File must be in JPG or PNG format.",
      imageSize: "File size must be under 10MB.",
    },
    scale: "Scale",
    scaleInfo:
      "The scale determines the size of the generated image. The larger the scale, the more detailed the image will be, but it will consume more credits.",
    creativity: "Creativity",
    creativityInfo: "The higher the creativity, the more creative the generated image will be.",
    detail: "Detail",
    detailInfo: "The higher the detail, the more detailed the generated image will be.",
    resemblance: "Resemblance",
    resemblanceInfo: "The higher the resemblance, the more similar the generated image will be to the original image.",
    upscaleMode: "Upscaling Mode",
    smooth: "Smooth (Illustration)",
    details: "Detailed (Photorealistic)",
    faceFix: "Face Correction",
    uploadSuccess: "The image has been uploaded.",
    uploadError: {
      size: "The file size must be under {{size}}MB.",
      type: "The file must be in JPG or PNG format.",
      scale: "The maximum width of the image must be under 3,000px.",
    },
    filtered: "BLOCKED",
    filteredDescription: "It is not displayed because it may violate the policy.",
    show: "Display images that may violate the policy",
    hide: "Hide images that may violate the policy",
    publicStatus: "Public Setting",
    publicDescription:
      "Private settings are available for Standard plan and above. Even if set to public, it may be changed to private.",
    public: "Public",
    private: "Private",
    warning:
      "NOTE: Images that violate the policy cannot be generated, and credits will not be guaranteed in such cases.",
    submit: "Generate Image",
  },
  filterSettings: "Filter Settings",
  enableFilter: "Enable Filter",
  filter: {
    description: "A blur filter is applied to images with a safety level of 2.0 or higher.",
  },
  safetyLevel: {
    title: "Safety Level",
    description: "Selecting a higher level will display more content. (Not applied to My Images)",
  },

  history: {
    title: "The latest 10 messages",
    input: "Enter Text",
    dice: "[Random Prompt Generation] Generates a sample text for image generation randomly. * 0.1〜0.2 credit will be consumed to prevent fraud.",
    improve:
      "[Enhance Prompt]enerates a prompt from the entered text (keywords). Also translates to English. * 0.1〜0.2 credit will be consumed to prevent fraud.",
    prompt: "Prompt",
    size: "Size",
    upscale: "Upscale",
    edit: "Edit",
    img2img: "Image to Image",
    img2vid: "Image to Video",
    useParams: "Parameters",
    download: "DOWNLOAD",
    generating: "Generating Images",
    generatingDescription:
      "The image is being generated. Please wait for a while.\nThe generation will continue even if you leave the page.",
    delete: {
      title: "Are you sure you want to delete the data?",
      description: "Deleting the data will remove all data within the image. This action cannot be undone.",
      success: "The data has been deleted.",
    },
    optionalPromptPlaceholder: "Please enter the image prompt (optional)",
    notNeededPlaceholder: "Image prompt is not needed",
    promptPlaceholder: "Please enter the image prompt",
  },
  modelList: {
    title: "Models",
    description: "STUDIO JUNO offers various models for image generation. ",
  },
  video: junoVideo,
  inputPreset: junoPreset,
  cameraMovementPreset: {
    icon: "Camera Movement Preset",
    description: "Click to add camera movement to the prompt.",
    title: "Camera Movement",
    warning: "NOTE: Camera movements may not be reflected in some models.",
    movement: {
      // Ray2 movements
      panleft: "Pan Left",
      panright: "Pan Right",
      orbitleft: "Orbit Left",
      orbitright: "Orbit Right",
      craneup: "Crane Up",
      cranedown: "Crane Down",
      moveleft: "Move Left",
      moveright: "Move Right",
      moveup: "Move Up",
      movedown: "Move Down",
      pushin: "Push In",
      pullout: "Pull Out",

      // Hailuo movements
      leftcirclingshot: "Left Circling Shot",
      rightcirclingshot: "Right Circling Shot",
      leftwalkingshot: "Left Walking Shot",
      rightwalkingshot: "Right Walking Shot",
      upwardtilt: "Upward Tilt",
      scenicshot: "Scenic Shot",
      stageshotright: "Stage Shot Right",
      stageshotleft: "Stage Shot Left",
      downwardtilt: "Downward Tilt",
      zoomin: "Zoom In",
      zoomout: "Zoom Out",
      tiltup: "Tilt Up",
      tiltdown: "Tilt Down",
      truckleft: "Truck Left",
      truckright: "Truck Right",
      pedestalup: "Pedestal Up",
      pedestaldown: "Pedestal Down",
      trackingshot: "Tracking Shot",
      shake: "Shake",
      staticshot: "Static Shot",
    },
  },
  message: {
    delete: "The image has been deleted.",
  },
  validation: {
    model: "Please select a model.",
    image: "Please upload an image.",
    prompt: "Please enter a prompt within 1,000 characters.",
    negativePrompt: "Please enter a negative prompt within 1,000 characters.",
    editorImage: "The edited image or mask image is not set. Open the editor and press 'Done' to set it.",
  },
  selection: {
    featured: "FEATURED",
    new: "NEW",
    popularModel: "Popular Model",
    highResolutionModel: "High Resolution Model",
    lowCostModel: "Low Cost Model",
    highPrecisionModel: "High Precision Model",
    japanesePortrait: "Japanese Portrait",
    depthToImage: "From Depth",
    outlineToImage: "From Outline",
    relighting: "Relighting",
    background: "Background Replace",
    products: "Product Shot",
    fixedPerson: "Fixed Person",
    changeClothes: "Change Clothes",
    brush: "Brush",
  },
};
export default juno;

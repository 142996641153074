import TagIcon from "@mui/icons-material/Tag";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useVideo, MenuList, AiEngineList } from "../../../../context/juno/VideoContext";

const QuickSelection = () => {
  const { t } = useTranslation();
  const { menu, aiEngine, setAiEngine, setMenu, updateParams, modelVersion } = useVideo();

  // モデルとメニューをコンテキストで設定する関数
  const handleSelection = (selectedMenu: MenuList, selectedEngine: AiEngineList, modelVersion: string) => {
    setMenu(selectedMenu);
    setAiEngine(selectedEngine);
    updateParams(selectedEngine, "modelVersion", modelVersion);
  };

  return (
    <Stack spacing={1} direction="row" sx={{ py: 2, overflowX: "auto", whiteSpace: "nowrap" }}>
      {/* 注目 */}
      <Chip
        label={t("juno.video.selection.trending")}
        onClick={() => handleSelection("t2v", "WAN", "V2_1_PRO")}
        color={aiEngine === "WAN" && menu === "t2v" && modelVersion["WAN"] === "V2_1_PRO" ? "primary" : "default"}
        avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
      />

      {/* 新モデル */}
      <Chip
        label={t("juno.video.selection.new")}
        onClick={() => handleSelection("t2v", "PIKA", "V2_2_1080P")}
        color={aiEngine === "PIKA" && menu === "t2v" && modelVersion["PIKA"] === "V2_2_1080P" ? "primary" : "default"}
        avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      {/* 人気モデル */}
      <Chip
        label={t("juno.video.selection.popularModel")}
        onClick={() => handleSelection("i2v", "VIDU", "VIDU_2_0_REF_720P")}
        color={aiEngine === "VIDU" && menu === "i2v" ? "primary" : "default"}
        avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      {/* 高画質 */}
      <Chip
        label={t("juno.video.selection.highQualityModel")}
        onClick={() => handleSelection("t2v", "KLING", "V1_6_PRO")}
        color={aiEngine === "KLING" && menu === "i2v" ? "primary" : "default"}
        avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      {/* 低コスト */}
      <Chip
        label={t("juno.video.selection.lowCostModel")}
        onClick={() => handleSelection("t2v", "KLING", "V1_6_STANDARD")}
        color={
          aiEngine === "KLING" && menu === "t2v" && modelVersion["KLING"] === "V1_6_STANDARD" ? "primary" : "default"
        }
        avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      {/* 高精度 */}
      <Chip
        label={t("juno.video.selection.highPrecisionModel")}
        onClick={() => handleSelection("t2v", "LUMA", "RAY_2")}
        color={aiEngine === "LUMA" && menu === "t2v" && modelVersion["LUMA"] === "RAY_2" ? "primary" : "default"}
        avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      {/* カメラコントロール */}
      <Chip
        label={t("juno.video.selection.cameraControl")}
        onClick={() => handleSelection("t2v", "HAILUO", "MINI_MAX_DIRECTOR")}
        color={aiEngine === "HAILUO" && menu === "t2v" ? "primary" : "default"}
        avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      {/* 高速 */}
      <Chip
        label={t("juno.video.selection.fastModel")}
        onClick={() => handleSelection("t2v", "LUMA", "V1_5")}
        color={aiEngine === "LUMA" && menu === "t2v" && modelVersion["LUMA"] === "V1_5" ? "primary" : "default"}
        avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      {/* 高コストパフォーマンス */}
      <Chip
        label={t("juno.video.selection.costEffective")}
        onClick={() => handleSelection("t2v", "WAN", "V2_1")}
        color={aiEngine === "WAN" && menu === "t2v" && modelVersion["WAN"] === "V2_1" ? "primary" : "default"}
        avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      {/* リップシンク人気 */}
      <Chip
        label={t("juno.video.selection.popularLipSync")}
        onClick={() => handleSelection("lipsync", "BYTEDANCE", "LATENTSYNC")}
        color={aiEngine === "BYTEDANCE" && menu === "lipsync" ? "primary" : "default"}
        avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />

      {/* アップスケール */}
      <Chip
        label={t("juno.video.selection.upscale")}
        onClick={() => handleSelection("upscale", "TOPAZLABS", "TOPAZ_VIDEO_UPSCALE")}
        color={aiEngine === "TOPAZLABS" && menu === "upscale" ? "primary" : "default"}
        avatar={<TagIcon fontSize={"small"} sx={{ backgroundColor: "transparent!important" }} />}
        size="small"
        sx={{ px: 1 }}
      />
    </Stack>
  );
};

export default QuickSelection;

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Fade, Grid, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Images } from "../../../../assets";
import { Videos } from "../../../../assets";

const Section4 = () => {
  const { t } = useTranslation();
  const [playingVideo, setPlayingVideo] = useState(null);

  const handlePlayVideo = (value) => {
    setPlayingVideo(value);
  };

  const videos = [
    {
      value: "Google",
      label: t("juno.video.ai.google.title"),
      video: "",
      thumbnail: "",
    },
    {
      value: "HAILUO",
      label: t("juno.video.ai.hailuo.title"),
      video: Videos.juno.hailuo,
      thumbnail: Images.juno.companies.hailuoThumbnail,
    },
    {
      value: "HAIPER",
      label: t("juno.video.ai.haiper.title"),
      video: Videos.juno.haiper,
      thumbnail: Images.juno.companies.haiperThumbnail,
    },
    {
      value: "KLING",
      label: t("juno.video.ai.kling.title"),
      video: Videos.juno.kling,
      thumbnail: Images.juno.companies.klingThumbnail,
    },
    {
      value: "LUMA",
      label: t("juno.video.ai.luma.title"),
      video: Videos.juno.luma,
      thumbnail: Images.juno.companies.lumaThumbnail,
    },
    {
      value: "PIKA",
      label: t("juno.video.ai.pika.title"),
      video: Videos.juno.pika,
      thumbnail: Images.juno.companies.pikaThumbnail,
    },
    {
      value: "PIXVERSE",
      label: t("juno.video.ai.pixverse.title"),
      video: Videos.juno.pixverse,
      thumbnail: Images.juno.companies.pixverseThumbnail,
    },
    {
      value: "RUNWAY",
      label: t("juno.video.ai.runway.title"),
      video: Videos.juno.runway,
      thumbnail: Images.juno.companies.runwayThumbnail,
    },
    {
      value: "VIDU",
      label: t("juno.video.ai.vidu.title"),
      video: Videos.juno.vidu,
      thumbnail: Images.juno.companies.viduThumbnail,
    },
    {
      value: "WAN",
      label: t("juno.video.ai.wan.title"),
      video: Videos.juno.wan,
      thumbnail: Images.juno.companies.wanThumbnail,
    },
    {
      value: "ByteDance",
      label: t("juno.video.ai.bytedance.title"),
      video: "",
      thumbnail: "",
    },
    {
      value: "Sync",
      label: t("juno.video.ai.sync.title"),
      video: "",
      thumbnail: "",
    },
  ];

  return (
    <>
      <Box component={"section"} sx={{ width: "100%" }}>
        <Grid container spacing={{ xs: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant={"h4"}
              component={"h2"}
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              mb={2}
              textAlign={"center"}
            >
              {t("aboutJuno.section4.title")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant={"h6"}
              component={"h5"}
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              mb={2}
              textAlign={"center"}
            >
              {t("aboutJuno.section4.subtitle")}
            </Typography>
          </Grid>

          {videos.map((item) => (
            <Fade in={true} key={item.value} timeout={250}>
              <Grid item xs={6} md={2.4}>
                <Box
                  key={item.value}
                  sx={{
                    borderRadius: 2,
                    width: "100%",
                    height: 75,
                    backgroundImage: `url(${Images.company[item.value.toLowerCase()] || ""})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                  }}
                  aria-label={item.label}
                />
              </Grid>
            </Fade>
          ))}
          <Grid item xs={12} mt={2}>
            <Typography
              variant={"body1"}
              component={"p"}
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              mb={2}
              textAlign={"center"}
            >
              {t("aboutJuno.section4.description")}
            </Typography>
          </Grid>

          {/* sample videos */}
          {videos.map((video) => (
            <React.Fragment key={video.value}>
              {video.video && (
                <Grid item key={video.value} xs={6} md={4}>
                  <Box sx={{ borderRadius: 2, overflow: "hidden" }}>
                    {playingVideo === video.value ? (
                      <video width="100%" controls autoPlay style={{ borderRadius: "8px", overflow: "hidden" }}>
                        <source src={video.video} type="video/mp4" />
                        {t("juno.video.error")}
                      </video>
                    ) : (
                      <Box
                        style={{ position: "relative", cursor: "pointer" }}
                        onClick={() => handlePlayVideo(video.value)}
                      >
                        <img
                          src={video.thumbnail}
                          alt={video.value}
                          width="100%"
                          style={{ borderRadius: "8px", overflow: "hidden" }}
                        />
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -60%)",
                            color: "white",
                            fontSize: 60,
                          }}
                          size="large"
                          aria-label="play"
                        >
                          <PlayCircleOutlineIcon fontSize="large" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </>
  );
};
export default Section4;

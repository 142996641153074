import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CasinoIcon from "@mui/icons-material/Casino";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, CircularProgress, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import InputPreset from "./InputPreset";

interface PromptActionsProps {
  localPrompt: string;
  aiEngine: string;
  rotationDegree: number;
  generatingPrompt: boolean;
  onGenerateSampleText: () => void;
  onUpdatePrompt: () => void;
  onDelete: () => void;
  setLocalPrompt: (prompt: string) => void;
  showDiceOnly?: boolean;
  showImproveOnly?: boolean;
  showPresetOnly?: boolean;
  showDeleteOnly?: boolean;
  disabled?: boolean; // 追加
}

const GeneratingPrompt = () => {
  return (
    <div
      style={{
        width: 40,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={20} color="primary" />
    </div>
  );
};

const PromptActions: React.FC<PromptActionsProps> = ({
  localPrompt,
  aiEngine,
  rotationDegree,
  generatingPrompt,
  onGenerateSampleText,
  onUpdatePrompt,
  onDelete,
  setLocalPrompt,
  showDiceOnly,
  showImproveOnly,
  showPresetOnly,
  showDeleteOnly,
  disabled = false, // 追加
}) => {
  const { t } = useTranslation();

  // 特定のアイコンのみを表示するモード
  if (showDiceOnly) {
    return (
      <CasinoIcon
        sx={{
          transform: `rotate(${rotationDegree}deg)`,
          transition: "transform 1s ease-in-out",
        }}
      />
    );
  }

  if (showImproveOnly) {
    return <AutoFixHighIcon />;
  }

  if (showDeleteOnly) {
    return <DeleteIcon />;
  }

  if (showPresetOnly) {
    return <InputPreset prompt={localPrompt} setPrompt={setLocalPrompt} disabled={disabled} />;
  }

  // 通常モード（すべてのアクションを表示）
  const renderActionButton = () => {
    if (generatingPrompt) {
      return <GeneratingPrompt />;
    }

    const isEmpty = localPrompt.trim().length === 0;
    const isDisabled = aiEngine === "AuraSR" || generatingPrompt;

    if (isEmpty) {
      return (
        <Tooltip title={t("juno.history.dice")}>
          <span>
            <IconButton onClick={onGenerateSampleText} disabled={isDisabled}>
              <CasinoIcon
                sx={{
                  transform: `rotate(${rotationDegree}deg)`,
                  transition: "transform 1s ease-in-out",
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={t("juno.history.improve")}>
        <span>
          <IconButton onClick={onUpdatePrompt} disabled={isDisabled}>
            <AutoFixHighIcon />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
      {renderActionButton()}
      <InputPreset prompt={localPrompt} setPrompt={setLocalPrompt} />
      <Tooltip title={t("common.delete")}>
        <span>
          <IconButton onClick={onDelete} disabled={aiEngine === "AuraSR"}>
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Divider orientation="vertical" sx={{ height: "28px", mx: 0.5 }} variant="middle" />

      <Box sx={{ display: { xs: "none", sm: "block" }, alignItems: "center" }}>
        <Typography variant="body2" color="secondary" ml={1}>
          @{t(`juno.input.engine.${aiEngine}`)}
        </Typography>
      </Box>
    </Box>
  );
};

export default PromptActions;

import { alpha } from "@mui/material/styles";
import { motion } from "framer-motion";
import FadeInSection from "../../../../components/common/FadeInSection";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import WhatIs from "../components/WhatIs";

const WhatIsSection = () => {
  // アニメーションバリアント
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  return (
    <ColumnCenteredBox
      sx={(theme) => ({
        backgroundColor: alpha(theme.palette.secondary.contrastText, 0.9),
        backdropFilter: "blur(20px)",
      })}
    >
      <motion.div
        variants={sectionVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
      >
        <FadeInSection>
          <WhatIs />
        </FadeInSection>
      </motion.div>
    </ColumnCenteredBox>
  );
};

export default WhatIsSection;

import Konva from "konva";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { ImageObject, LineData } from "../../types/junoTypes";

interface EditorContextType {
  images: ImageObject[];
  setImages: React.Dispatch<React.SetStateAction<ImageObject[]>>;
  selectedImageId: string | null;
  setSelectedImageId: React.Dispatch<React.SetStateAction<string | null>>;
  brushSize: number;
  setBrushSize: React.Dispatch<React.SetStateAction<number>>;
  lines: LineData[];
  setLines: React.Dispatch<React.SetStateAction<LineData[]>>;
  stageRef: React.RefObject<Konva.Stage>;
}

const EditorContext = createContext<EditorContextType | undefined>(undefined);

export const useEditorContext = () => {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error("useEditorContext must be used within an EditorProvider");
  }
  return context;
};

interface EditorProviderProps {
  children: ReactNode;
}

export const EditorProvider: React.FC<EditorProviderProps> = ({ children }) => {
  const [images, setImages] = useState<ImageObject[]>([]);
  const [selectedImageId, setSelectedImageId] = useState<string | null>(null);
  const [brushSize, setBrushSize] = useState<number>(50);
  const [lines, setLines] = useState<LineData[]>([]);
  const stageRef = React.useRef<Konva.Stage>(null);

  return (
    <EditorContext.Provider
      value={{
        images,
        setImages,
        selectedImageId,
        setSelectedImageId,
        brushSize,
        setBrushSize,
        lines,
        setLines,
        stageRef,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

// Announcement.tsx
import EventIcon from "@mui/icons-material/Event";
import InfoIcon from "@mui/icons-material/Info";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import UpdateIcon from "@mui/icons-material/Update";
import { Box, Container, darken, Fade, lighten, Pagination, Stack, Typography, useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { styled } from "@mui/system";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Meta from "../../components/common/Meta";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import { apiRequest } from "../../utils/api";
import { RowBox } from "../../utils/styledBox";
import { localDate } from "../../utils/utils";

interface AnnouncementData {
  id: string;
  title: string;
  content: string;
  category: string;
  published_at: string;
}

interface PaginationData {
  current_page: number;
  total_pages: number;
  total_announcements: number;
  has_previous: boolean;
  has_next: boolean;
}

const Announcement: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [announcements, setAnnouncements] = useState<AnnouncementData[]>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    current_page: 1,
    total_pages: 1,
    total_announcements: 0,
    has_previous: false,
    has_next: false,
  });
  const uiLanguage = Cookies.get("ui_language") || "en";
  const location = useLocation();
  const navigate = useNavigate();

  // URLからページ番号を取得
  const query = new URLSearchParams(location.search);
  const currentPage = parseInt(query.get("page") || "1", 10);
  const limit = 10; // 1ページあたりのアイテム数

  const getAnnouncement = useCallback(async (page: number) => {
    try {
      const response = await apiRequest(`/api/v1/announcement/?page=${page}&limit=${limit}`);
      setAnnouncements(response.data.announcements);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error("Failed to fetch announcements:", error);
      setAnnouncements([]);
      setPagination({
        current_page: 1,
        total_pages: 1,
        total_announcements: 0,
        has_previous: false,
        has_next: false,
      });
    }
  }, []);

  useEffect(() => {
    getAnnouncement(currentPage);
  }, [getAnnouncement, currentPage]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    navigate(`?page=${value}`);
  };

  const borderColor = (category: string) => {
    switch (category) {
      case "important":
        return "#f44336"; // 赤
      case "update":
        return "#2196f3"; // 青
      case "info":
        return "#4caf50"; // 緑
      case "event":
        return "#ff9800"; // オレンジ
      default:
        return "#000"; // デフォルトは黒
    }
  };

  const getCategoryIcon = (category: string) => {
    switch (category) {
      case "important":
        return <NewReleasesIcon />;
      case "update":
        return <UpdateIcon />;
      case "info":
        return <InfoIcon />;
      case "event":
        return <EventIcon />;
      default:
        return <InfoIcon />;
    }
  };

  const TimelineContainer = styled("div")({
    position: "relative",
    marginLeft: 40,
    paddingLeft: 20,
  });

  const AnnouncementItem = styled("div")<{ category: string }>(({ theme, category }) => ({
    display: "flex",
    position: "relative",
    marginBottom: theme.spacing(4),
  }));

  const IconWrapper = styled("div")<{ category: string }>(({ theme, category }) => ({
    position: "absolute",
    left: -70,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: borderColor(category),
    color: "#fff",
    zIndex: 1,
  }));

  const Connector = styled("div")<{ category: string }>(({ theme, category }) => ({
    position: "absolute",
    top: 39,
    left: -54,
    width: 8,
    height: "calc(100% - 20px)",
    backgroundColor: borderColor(category),
    borderRadius: "0 0 4px 4px",
  }));

  const ContentBox = styled("div")(({ theme }) => ({
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      transition: "all 0.3s",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
    "& figure": {
      margin: "0!important",
    },
    "& figure img": {
      width: "100%!important",
      height: "100%!important",
      borderRadius: "8px",
    },
    "& table": {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
      border: `none`,
      borderRadius: "4px",
      overflow: "hidden",
      // すべてのtdに対するスタイルをここに移動
      "& td": {
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
        border: `none`,
      },
    },
    "& tr:first-of-type": {},
    // 最初の行のtdに対するスタイル
    "& tr:first-of-type td": {
      padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
      backgroundColor: theme.palette.primary.dark,
      color: "#fff",
      fontWeight: "bold",
      textAlign: "left",
      border: `none`,
    },
    // 偶数行のスタイル
    "& tr:nth-of-type(even)": {
      backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
    // 奇数行のスタイル（最初の行は除く）
    "& tr:nth-of-type(odd):not(:first-of-type)": {
      backgroundColor: lighten(theme.palette.background.paper, 0.15),
    },
    "& li::marker": {
      color: theme.palette.primary.main,
    },
    "& strong": {
      background: `linear-gradient(transparent 50%, ${alpha(theme.palette.secondary.main, 0.3)} 50%)`,
    },
  }));

  // メタデータ設定
  const meta = [
    { name: "robots", content: "index, follow" },
    { name: "description", content: t("meta.announcement.description") },
  ];

  return (
    <Box sx={{ py: { xs: 2, md: 4 } }}>
      <Meta title={t("meta.announcement.title")} meta={meta} />
      <Fade in={true} timeout={1500}>
        <Container maxWidth="md" sx={{ mb: 8 }}>
          <DrawerHeader />
          <Typography variant="h4" component="h1" sx={{ textAlign: "center", mb: 1 }}>
            {t("announcement.title")}
          </Typography>
          <Typography variant="body1" component="p" sx={{ textAlign: "center", mb: 8, fontWeight: 500 }}>
            {t("announcement.description")}
          </Typography>
          {announcements.length > 0 ? (
            <>
              <TimelineContainer>
                {announcements.map((announcement, index) => (
                  <AnnouncementItem key={announcement.id} category={announcement.category}>
                    <IconWrapper category={announcement.category}>{getCategoryIcon(announcement.category)}</IconWrapper>
                    <ContentBox>
                      <RowBox sx={{ justifyContent: "space-between" }}>
                        <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
                          {announcement.title}
                        </Typography>
                        <Typography variant="body2" component="p" sx={{ fontWeight: "bold" }}>
                          {localDate(announcement.published_at, uiLanguage, true)}
                        </Typography>
                      </RowBox>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: announcement.content.replace(/<a /g, '<a target="_blank" '),
                        }}
                      />
                    </ContentBox>
                    {/* 最後のアイテム以外に接続線を追加 */}
                    <Connector category={announcement.category} />
                  </AnnouncementItem>
                ))}
              </TimelineContainer>
              {/* ページネーションのUI */}
              <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 8 }}>
                <Pagination
                  count={pagination.total_pages}
                  page={pagination.current_page}
                  onChange={handlePageChange}
                  color="primary"
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </Stack>
            </>
          ) : (
            <Box sx={{ padding: 2, textAlign: "center", color: theme.palette.text.secondary }}>
              {t("announcement.noData", "No announcements available")}
            </Box>
          )}
        </Container>
      </Fade>
    </Box>
  );
};

export default React.memo(Announcement);

import { loadUserLanguageFromCookie } from "../i18n/i18n";

// ファイル名を生成する関数
export const getFileDateName = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const hours = `0${date.getHours()}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);

  const filename = `${year}${month}${day}${hours}${minutes}`;
  return filename;
};

// ローカルタイムに変換する関数
export const localDate = (utcTime: string, locale: string = "en", withoutTime = false): string => {
  // utcTimeが空文字列またはundefinedの場合は空文字列を返す
  if (!utcTime) {
    return "";
  }

  const date = new Date(utcTime);

  // 日付が無効な場合は空文字列を返す
  if (isNaN(date.getTime())) {
    return "";
  }

  // ローカルタイムゾーンに変換
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 月は0から始まるため+1
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");

  // 言語設定を取得i18n

  if (locale === "ja") {
    if (withoutTime) {
      return `${year}-${month}-${day}`;
    } else {
      return `${year}-${month}-${day} ${hour}:${minute}`;
    }
  } else {
    if (withoutTime) {
      return `${month}/${day}/${year}`;
    } else {
      return `${month}/${day}/${year} ${hour}:${minute}`;
    }
  }
};

// ファイルサイズをフォーマットする関数
export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

// リンク先に言語を追加する関数
export const customLink = (to: string) => {
  const language = loadUserLanguageFromCookie();
  return "/" + language + to;
};

// 自動でスクロール
export const scrollToBottom = (wait?: number) => {
  const doScroll = () => {
    // ページの最下部までの距離を計算
    const footer = document.getElementById("footer");

    // スムーズに最下部までスクロール
    window.scrollTo({
      top: footer ? footer.offsetTop : document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  if (wait) {
    setTimeout(() => {
      doScroll();
    }, wait);
  } else {
    doScroll();
  }
};

// HTMLからテキストを抽出する関数
export function extractTextFromHTML(htmlString: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
}

// src/assets/voices/index.ts
// 音声ファイルのインポート

// 英語音声
import enAlloy from "./en_alloy.mp3";
import enAsh from "./en_ash.mp3";
import enCoral from "./en_coral.mp3";
import enEcho from "./en_echo.mp3";
import enFable from "./en_fable.mp3";
import enNova from "./en_nova.mp3";
import enOnyx from "./en_onyx.mp3";
import enSage from "./en_sage.mp3";
import enShimmer from "./en_shimmer.mp3";

// 日本語音声
import jaAlloy from "./ja_alloy.mp3";
import jaAsh from "./ja_ash.mp3";
import jaCoral from "./ja_coral.mp3";
import jaEcho from "./ja_echo.mp3";
import jaFable from "./ja_fable.mp3";
import jaNova from "./ja_nova.mp3";
import jaOnyx from "./ja_onyx.mp3";
import jaSage from "./ja_sage.mp3";
import jaShimmer from "./ja_shimmer.mp3";

// エクスポート
export const Voices = {
  en: {
    alloy: enAlloy,
    ash: enAsh,
    coral: enCoral,
    echo: enEcho,
    fable: enFable,
    nova: enNova,
    onyx: enOnyx,
    sage: enSage,
    shimmer: enShimmer,
  },
  ja: {
    alloy: jaAlloy,
    ash: jaAsh,
    coral: jaCoral,
    echo: jaEcho,
    fable: jaFable,
    nova: jaNova,
    onyx: jaOnyx,
    sage: jaSage,
    shimmer: jaShimmer,
  },
};

// 個別エクスポート
export {
  enAlloy,
  enAsh,
  enCoral,
  enEcho,
  enFable,
  enNova,
  enOnyx,
  enSage,
  enShimmer,
  jaAlloy,
  jaAsh,
  jaCoral,
  jaEcho,
  jaFable,
  jaNova,
  jaOnyx,
  jaSage,
  jaShimmer,
};

const dashboard = {
  title: "Dashboard",
  greeting: "Hello, {{name}}.",
  welcome: "Welcome to Tenak AI.",
  search: "Search, Chat",
  addChat: "Create Chat",
  text: "Create Document",
  addText: "Blank document",
  addMindmap: "Blank mind map",
  mindmap: "Create Mindmap",
  misc: "MINI AI",
  library: "Library",
  tenak: {
    description: "AI-powered content creation support AI",
    writingAI: "Writing AI",
    voice: "Voice AI",
    mindmap: "Mindmap AI",
    color: "Color Palette AI",
    template: "Prompt Templates",
  },
  juno: {
    description: "Image / video generation AI platform",
    image: "Image Generation",
    video: "Video Generation",
    explore: "Explore everyone's work",
    t2i: "Text to Image",
    i2i: "Image to Image",
    upscale: "Image Upscale",
    models: "Model List",
  },
  notesNebula: {
    description: "Article posting platform using AI editor",
    read: "Find articles",
  },
};
export default dashboard;

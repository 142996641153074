import BrushIcon from "@mui/icons-material/Brush";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Button, Hidden, IconButton, Modal, Stack, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { alpha } from "@mui/material/styles";
import { styled } from "@mui/system";
import Konva from "konva";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAlert } from "../../../../../context/AlertContext";
import { useEditorContext } from "../../../../../context/juno/EditorContext";
import { useUploadImageContext } from "../../../../../context/juno/UploadImageContext";
import { useUpscaleContext } from "../../../../../context/juno/UpscaleContext";
import { ImageObject, ToolType } from "../../../../../types/junoTypes";
import { Images } from "../../../../../assets";
import { ColumnCenteredBox, RowBox } from "../../../../../utils/styledBox";
import EditorStage from "../editor/EditorStage";
import ImageLayers from "../editor/ImageLayers";
import Toolbar from "../editor/Toolbar";

const ImageEditor: FC = () => {
  const { t } = useTranslation();
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const { images, setImages, setSelectedImageId, stageRef } = useEditorContext();
  const {
    editorInputImage,
    setEditorInputImage,
    editorInputMask,
    setEditorInputMask,
    editorPreviewImage,
    setEditorPreviewImage,
  } = useUploadImageContext();
  const [stageWidth, setStageWidth] = useState<number>(0);
  const [stageHeight, setStageHeight] = useState<number>(0);
  const { setAlert } = useAlert();
  const { setUuidParent } = useUpscaleContext();

  // 現在のツール状態
  const [selectedTool, setSelectedTool] = useState<ToolType>("brush");

  const location = useLocation();
  const { outsideImage } = location.state || {};

  useEffect(() => {
    if (outsideImage) {
      setImageOutside(outsideImage);
      location.state = {};
    }
  }, [outsideImage]);

  const setImageOutside = (image) => {
    const img = new Image();
    img.crossOrigin = "anonymous"; // CORS 設定

    // Base64 か URL かで src を設定
    img.src = image;

    img.onload = () => {
      const scale = 800 / Math.max(img.width, img.height);
      const newImage: ImageObject = {
        image: img,
        x: 0,
        y: 0,
        scaleX: scale,
        scaleY: scale,
        id: Math.random().toString(36).substring(7),
        width: img.width,
        height: img.height,
      };

      setImages([newImage]);

      // アップロードした画像を選択
      setSelectedImageId(newImage.id);

      // 選択ツールを有効にする
      setSelectedTool("select");

      setOpenEditor(true);
    };
  };

  // DataURLをCanvasに描画してImageDataを取得
  const drawDataURLToImageData = (dataURL: string): Promise<ImageData> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = dataURL;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = stageWidth;
        canvas.height = stageHeight;
        const ctx = canvas.getContext("2d")!;
        ctx.drawImage(img, 0, 0, stageWidth, stageHeight);
        const imageData = ctx.getImageData(0, 0, stageWidth, stageHeight);
        resolve(imageData); // Canvas APIのImageData
      };
    });
  };

  // 画像レイヤーを黒に変換
  const createBlackImageMask = async (): Promise<ImageData> => {
    try {
      // クローンとレイヤー設定
      const cloned = stageRef.current!.clone();

      // トランスフォーマーの解除
      setSelectedImageId(null);

      const layers = cloned.getChildren();

      const backgroundLayer = layers[0] as Konva.Layer;
      const imagesLayer = layers[1] as Konva.Layer;
      const brushLayer = layers[2] as Konva.Layer;
      const brushSizeLayer = layers[3] as Konva.Layer;

      brushSizeLayer.hide();
      brushLayer.hide();
      backgroundLayer.hide();
      imagesLayer.show();

      // 画像レイヤ内の画像を全て5%小さくする
      imagesLayer.getChildren().forEach((node) => {
        if (node instanceof Konva.Image) {
          // スケールダウン
          const currentScale = node.scale() || { x: 1, y: 1 };
          const scaleFactor = 0.95;
          const newScale = {
            x: currentScale.x * scaleFactor,
            y: currentScale.y * scaleFactor,
          };

          // 縮小前の幅と高さ
          const originalWidth = node.width() * currentScale.x;
          const originalHeight = node.height() * currentScale.y;

          // 縮小後の幅と高さ
          const newWidth = node.width() * newScale.x;
          const newHeight = node.height() * newScale.y;

          // 差分の半分を計算
          const offsetX = (originalWidth - newWidth) / 2;
          const offsetY = (originalHeight - newHeight) / 2;

          // スケール設定
          node.scale(newScale);

          // 左と上にずらし、中央に配置
          node.x(node.x() + offsetX);
          node.y(node.y() + offsetY);
        }
      });
      const dataURL = cloned.toDataURL();
      cloned.destroy();

      const imageData = await drawDataURLToImageData(dataURL);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const a = data[i + 3];
        if (a > 0) {
          data[i] = 0;
          data[i + 1] = 0;
          data[i + 2] = 0;
        }
      }

      return imageData;
    } catch (error) {
      console.error("Error in createBlackImageMask:", error);
      throw error;
    }
  };

  // ブラシレイヤーを白に変換
  const createWhiteBrushMask = async (): Promise<ImageData> => {
    const cloned = stageRef.current!.clone();
    const layers = cloned.getChildren();
    const imagesLayer = layers[1] as Konva.Layer;
    const brushLayer = layers[2] as Konva.Layer;
    const brushSizeLayer = layers[3] as Konva.Layer;
    const backgroundLayer = layers[0] as Konva.Layer;

    // ブラシのみ表示
    backgroundLayer.hide();
    brushSizeLayer.hide();
    imagesLayer.hide();
    brushLayer.show();

    const dataURL = cloned.toDataURL();
    cloned.destroy();

    const imageData = await drawDataURLToImageData(dataURL);
    const data = imageData.data;

    // 不透明ピクセルを純白(アルファ255)で塗りつぶし
    for (let i = 0; i < data.length; i += 4) {
      const a = data[i + 3];
      if (a > 0) {
        data[i] = 255;
        data[i + 1] = 255;
        data[i + 2] = 255;
        data[i + 3] = 255; // 完全不透明
      }
    }

    return imageData;
  };

  // 黒画像と白ブラシを合成して最終マスクを生成
  const composeFinalMask = (blackImgData: ImageData, whiteBrushData: ImageData): string => {
    const canvas = document.createElement("canvas");
    canvas.width = stageWidth;
    canvas.height = stageHeight;
    const ctx = canvas.getContext("2d")!;

    // ステップ1: 白背景を塗りつぶす
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, stageWidth, stageHeight);

    // ステップ2: 黒画像を描画（透明部分を白に塗りつぶし）
    const blackData = blackImgData.data;
    for (let i = 0; i < blackData.length; i += 4) {
      const alpha = blackData[i + 3];
      if (alpha === 0) {
        // 完全透明なピクセルを白で設定
        blackData[i] = 255;
        blackData[i + 1] = 255;
        blackData[i + 2] = 255;
        blackData[i + 3] = 255;
      }
    }
    ctx.putImageData(blackImgData, 0, 0);

    // ステップ3: 白ブラシを上書き
    const whiteData = whiteBrushData.data;
    const blackCanvasData = ctx.getImageData(0, 0, stageWidth, stageHeight);
    const blackCanvasPixels = blackCanvasData.data;

    for (let i = 0; i < whiteData.length; i += 4) {
      const alpha = whiteData[i + 3];
      if (alpha > 0) {
        // 完全白で上書き
        blackCanvasPixels[i] = 255;
        blackCanvasPixels[i + 1] = 255;
        blackCanvasPixels[i + 2] = 255;
        blackCanvasPixels[i + 3] = 255; // 完全不透明
      }
    }

    ctx.putImageData(blackCanvasData, 0, 0);

    return canvas.toDataURL();
  };

  // previewImageを生成
  const createPreviewImage = (): string => {
    const clonedStage = stageRef.current!.clone();
    const clonedLayers = clonedStage.getChildren();
    const imageCrossLinesLayer = clonedLayers[4] as Konva.Layer;
    imageCrossLinesLayer.hide();
    const preview = clonedStage.toDataURL();
    clonedStage.destroy();
    return preview;
  };

  // editorInputImageを生成
  const createEditorImage = (): string => {
    const clonedStage = stageRef.current!.clone();
    const clonedLayers = clonedStage.getChildren();
    const imageBrushLayer = clonedLayers[2] as Konva.Layer;
    const imageBrushSizeLayer = clonedLayers[3] as Konva.Layer;
    const imageCrossLinesLayer = clonedLayers[4] as Konva.Layer;

    // ブラシとクロスラインを非表示
    imageBrushLayer.hide();
    imageBrushSizeLayer.hide();
    imageCrossLinesLayer.hide();

    const imageDataURL = clonedStage.toDataURL();
    clonedStage.destroy();
    return imageDataURL;
  };

  // マスクと画像をエクスポート
  const exportMaskAndImage = () => {
    if (!stageRef.current) return;

    // 画像レイヤーに画像がなければリターン
    if (images.length === 0) {
      setAlert("error", t("juno.input.imageEditor.noImage"));
      return;
    }

    // トランスフォーマーを非表示にする
    setSelectedImageId(null);

    // 状態更新後にエクスポート処理を行う
    setTimeout(async () => {
      try {
        // 手順:
        // 1. 画像レイヤーを黒に変換
        const blackImgData = await createBlackImageMask();

        // 2. ブラシレイヤーを白に変換
        const whiteBrushData = await createWhiteBrushMask();

        // 3. 最終マスクを生成
        const maskDataURL = composeFinalMask(blackImgData, whiteBrushData);
        setEditorInputMask(maskDataURL);

        // 4. Preview Imageを生成
        const preview = createPreviewImage();
        setEditorPreviewImage(preview);

        // 5. Editor Imageを生成
        const editor = createEditorImage();
        setEditorInputImage(editor);

        // エクスポート完了後にエディターを閉じる
        setOpenEditor(false);
      } catch (error) {
        console.error("Error while processing images:", error);
      }
    }, 0); // 状態更新を待つためにタイムアウトを設定
  };

  const handleExport = () => {
    exportMaskAndImage();
  };

  const PreviewBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    aspectRatio: "1 / 1",
    border: "1px solid",
    borderColor: "grey.500",
    borderRadius: 4,
    cursor: "pointer",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative",
  });

  const PreviewTypography = styled(Typography)(({ theme }) => ({
    position: "absolute",
    top: 8,
    left: 8,
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    padding: "4px 8px",
    borderRadius: 1,
  }));
  const [openImage, setOpenImage] = useState(false);

  const OpenEditor = () => {
    return (
      <ColumnCenteredBox gap={1}>
        <BrushIcon color={"secondary"} />
        <Typography variant={"body2"}>{t("juno.input.imageEditor.open")}</Typography>
      </ColumnCenteredBox>
    );
  };

  const deleteImages = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEditorInputImage("");
    setEditorInputMask("");
    setEditorPreviewImage("");
    setUuidParent("");
  };

  return (
    <>
      <Stack spacing={2}>
        <Typography variant={"body2"}>{t("juno.input.imageEditor.title")}</Typography>
        <PreviewBox
          sx={{
            backgroundImage: editorPreviewImage ? `url(${editorPreviewImage})` : "none",
          }}
          onClick={() => setOpenEditor(true)}
        >
          <PreviewTypography variant={"body2"}>PREVIEW</PreviewTypography>
          {!editorPreviewImage && <OpenEditor />}
          <IconButton
            sx={{
              position: "absolute",
              bottom: 8,
              right: 8,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: 1,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              },
            }}
            onClick={deleteImages}
          >
            <DeleteIcon />
          </IconButton>
        </PreviewBox>

        <Button
          variant={"text"}
          onClick={() => setOpenImage(!openImage)}
          endIcon={openImage ? <ExpandLess /> : <ExpandMore />}
        >
          {t("juno.input.imageEditor.openImage")}
        </Button>

        <Collapse in={openImage}>
          <Stack spacing={2}>
            <Typography variant={"caption"} whiteSpace={"pre-line"} textAlign={"center"}>
              {t("juno.input.imageEditor.openImageDescription")}
            </Typography>
            <PreviewBox
              sx={{
                backgroundImage: editorInputImage ? `url(${editorInputImage})` : "none",
              }}
              onClick={() => setOpenEditor(true)}
            >
              <PreviewTypography variant={"body2"}>IMAGE</PreviewTypography>
              {!editorInputImage && <OpenEditor />}
            </PreviewBox>
            <PreviewBox
              sx={{
                backgroundImage: editorInputMask ? `url(${editorInputMask})` : "none",
              }}
              onClick={() => setOpenEditor(true)}
            >
              <PreviewTypography variant={"body2"}>MASK</PreviewTypography>
              {!editorInputMask && <OpenEditor />}
            </PreviewBox>
          </Stack>
        </Collapse>
      </Stack>

      <Modal open={openEditor} onClose={() => setOpenEditor(false)}>
        <Box
          id="editor-container"
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid",
            borderColor: "primary.main",
            borderRadius: 1,
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            width: "130vh",
            maxWidth: 1400,
          }}
        >
          <IconButton onClick={() => setOpenEditor(false)} sx={{ position: "absolute", top: 8, right: 8, zIndex: 100 }}>
            <CloseIcon />
          </IconButton>

          <RowBox sx={{ position: "absolute", top: 16, left: 16 }}>
            <img src={Images.juno.mini2} style={{ width: 20, height: 20, marginRight: 8 }} alt="Juno" />
            <Hidden mdDown>
              <Typography variant="body2" component="p" sx={{ fontWeight: "bold" }} gutterBottom>
                STUDIO JUNO
              </Typography>
            </Hidden>
          </RowBox>

          <Toolbar
            selectedTool={selectedTool}
            setSelectedTool={setSelectedTool}
            setStageWidth={setStageWidth}
            setStageHeight={setStageHeight}
          />

          <Typography variant="caption" sx={{ color: "text.primary", mb: 2 }}>
            {t("juno.input.imageEditor.description")}
          </Typography>

          <EditorStage stageWidth={stageWidth} stageHeight={stageHeight} selectedTool={selectedTool} />

          <Box sx={{ position: "absolute", bottom: 20, right: 20 }}>
            <Box
              gap={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <ImageLayers />
              <ColumnCenteredBox gap={1}>
                <Button variant="outlined" color="primary" onClick={() => setOpenEditor(false)} sx={{ width: "100%" }}>
                  {t("common.close")}
                </Button>
                <Button variant="contained" color="primary" onClick={handleExport} sx={{ width: "100%" }}>
                  {t("juno.input.imageEditor.save")}
                </Button>
              </ColumnCenteredBox>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ImageEditor;

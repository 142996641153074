import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useCommonContext } from "../../../../context/juno/CommonContext";
import { apiRequest } from "../../../../utils/api";
import { ColumnBox } from "../../../../utils/styledBox";

const FilteredButton = () => {
  const { t } = useTranslation();
  const { filtered, setFiltered } = useCommonContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [tempSafetyLevel, setTempSafetyLevel] = useState<number>(1.0);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchUserSafetyLevel = async () => {
    setLoading(true);
    try {
      interface SafetyLevelData {
        safe_level: number;
      }
      const response = await apiRequest<SafetyLevelData>("/api/v1/juno/get-safety-level", "GET");
      if (response.status === 200) {
        const userData = response.data;
        if (userData.safe_level !== undefined) {
          // バックエンドの値（1-5）をフロントエンドの値（1-3）に変換
          let displayValue;
          if (userData.safe_level >= 3) {
            displayValue = 3;
          } else {
            displayValue = userData.safe_level;
          }
          console.log("Backend safe_level:", userData.safe_level, "Display value:", displayValue);
          setTempSafetyLevel(displayValue);
        }
      }
    } catch (error) {
      console.error("Failed to fetch user safety level:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    fetchUserSafetyLevel();
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = async () => {
    try {
      interface ApiResponse {
        success: boolean;
        safe_level?: number;
        error?: string;
      }

      const response = await apiRequest<ApiResponse>("/api/v1/juno/update-safety-level", "POST", {
        safe_level: tempSafetyLevel,
      });

      if (response.status === 200) {
        handleClose();

        // ページをリロードしてデータを再取得
        window.location.reload(); // ページをリロード
      } else {
        console.error("Failed to update safety level");
      }
    } catch (error) {
      console.error("Error updating safety level:", error);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <Box display="flex" flexDirection="row" alignItems="flex-end" gap={1}>
      <Tooltip title={t("juno.filterSettings")}>
        <IconButton onClick={handleClick} sx={{ position: "relative" }}>
          <VisibilityIcon sx={{ color: "textPrimary" }} />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: filtered ? "success.main" : "error.main",
              borderRadius: "50%",
              width: 16,
              height: 16,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {filtered ? <CheckIcon sx={{ p: 0.8, color: "white" }} /> : <CloseIcon sx={{ p: 0.8, color: "white" }} />}
          </Box>
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 3, width: 300 }}>
          <Typography variant="h6" gutterBottom>
            {t("juno.filterSettings")}
          </Typography>
          <ColumnBox>
            <FormControlLabel
              control={<Checkbox checked={filtered} onChange={() => setFiltered(!filtered)} />}
              label={t("juno.enableFilter")}
            />
            <Typography variant="caption" color="text.secondary">
              {t("juno.filter.description")}
            </Typography>
          </ColumnBox>
          <Box sx={{ mt: 3 }}>
            <Typography gutterBottom>{t("juno.safetyLevel.title")}</Typography>
            {loading ? (
              <LinearProgress />
            ) : (
              <Box sx={{ ml: 1.5, mr: 3 }}>
                <Slider
                  value={tempSafetyLevel}
                  min={1.1}
                  max={3}
                  step={0.1}
                  marks={[
                    { value: 1.1, label: "Safe" },
                    { value: 1.5, label: "Default" },
                    { value: 2.0, label: "Unsafe" },
                    { value: 3, label: "No Filter" },
                  ]}
                  onChange={(_, value) => setTempSafetyLevel(value as number)}
                  valueLabelDisplay="auto"
                />
              </Box>
            )}
            <Typography variant="caption" color="text.secondary">
              {t("juno.safetyLevel.description")}
            </Typography>
          </Box>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose} sx={{ mr: 1 }}>
              {t("common.cancel")}
            </Button>
            <Button variant="contained" onClick={handleApply} disableElevation>
              {t("common.apply")}
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default React.memo(FilteredButton);

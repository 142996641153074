import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Fade, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import ScrollableBox from "../../../../components/common/ScrollableBox";
import { useParamsContext } from "../../../../context/juno/ParamsContext";
import { useJunoModels } from "../../../../hooks/useJunoModels";
import AspectRatio from "./params/AspectRatio";
import Colors from "./params/Colors";
import ExpandPrompt from "./params/ExpandPrompt";
import GuidanceScale from "./params/GuidanceScale";
import Image from "./params/Image";
import ImageEditor from "./params/ImageEditor";
import ImageStrength from "./params/ImageStrength";
import InitialLatentSelection from "./params/InitialLatentSelection";
import ManualPlacementSelection from "./params/ManualPlacementSelection";
import NegativePrompt from "./params/NegativePrompt";
import RawMode from "./params/RawMode";
import Sample from "./params/Samples";
import Seed from "./params/Seed";
import SelectPublic from "./params/SelectPublic";
import Steps from "./params/Steps";
import Style from "./params/Style";
import StyleStrength from "./params/StyleStrength";
import SystemPrompt from "./params/SystemPrompt";
import Upscale from "./params/Upscale";
import { alpha } from "@mui/material/styles";
import ReportIcon from "@mui/icons-material/Report";

const ParamsManager = () => {
  const { t } = useTranslation();
  const { menu, aiEngine } = useParamsContext();
  const { getModelParams } = useJunoModels();
  const modelParams = getModelParams(aiEngine, menu);

  return (
    <ScrollableBox
      sx={{
        height: "100%",
        overflowY: "auto",
        borderRadius: 1,
        backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.5),
        backdropFilter: "blur(10px)",
        // border: (theme) => `1px solid ${theme.palette.grey[800]}`,
        // borderRightColor: (theme) => theme.palette.grey[900],
        // borderBottomColor: (theme) => theme.palette.grey[900],
      }}
      elevation={1}
    >
      {aiEngine ? (
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            p: 2,
          }}
        >
          {["GeminiFlashEdit", "Imagen3", "Imagen3Fast"].includes(aiEngine) && (
            <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"} sx={{ mb: 2 }}>
              <ReportIcon sx={{ mr: 1 }} color="warning" />
              <Typography variant={"caption"} component={"p"} color={"warning.main"}>
                {t("juno.input.engine.policyCheck")}
              </Typography>
            </Box>
          )}
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 2 }}>
            <SettingsSuggestIcon sx={{ mr: 1 }} />
            <Typography variant={"subtitle1"} component={"p"}>
              {t("juno.input.basicSettings")}
            </Typography>
          </Box>
          <Fade in={true} key={aiEngine + menu} timeout={500}>
            <Stack spacing={3}>
              {["txt2img", "img2img", "editor"].includes(menu!) && (
                <>
                  {modelParams.includes("sample") && <Sample />}
                  {modelParams.includes("aspectRatio") && <AspectRatio />}
                  {modelParams.includes("initImage") && <Image type="primary" />}
                  {modelParams.includes("initImage2") && <Image type="secondary" />}
                  {modelParams.includes("editor") && <ImageEditor />}
                  {modelParams.includes("imageStrength") && <ImageStrength type="primary" />}
                  {modelParams.includes("imageStrength2") && <ImageStrength type="secondary" />}
                  {modelParams.includes("style") && <Style />}
                  {modelParams.includes("styleWeight") && <StyleStrength />}
                  {modelParams.includes("systemPrompt") && <SystemPrompt />}
                  {modelParams.includes("colors") && <Colors />}
                  {modelParams.includes("rawMode") && <RawMode />}
                  {modelParams.includes("step") && <Steps />}
                  {modelParams.includes("revisedPrompt") && <ExpandPrompt />}
                  {modelParams.includes("guidanceScale") && <GuidanceScale />}
                  {modelParams.includes("negativePrompt") && <NegativePrompt />}
                  {modelParams.includes("seed") && <Seed />}
                  {modelParams.includes("manualPlacementSelection") && <ManualPlacementSelection />}
                  {modelParams.includes("initialLatent") && <InitialLatentSelection />}
                  <SelectPublic />
                </>
              )}
              {["upscale"].includes(menu!) && <Upscale />}
            </Stack>
          </Fade>

          <Box flexGrow={1} />
        </Box>
      ) : (
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}
    </ScrollableBox>
  );
};
export default ParamsManager;

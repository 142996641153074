import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { marked } from "marked";
import Container from "@mui/material/Container";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { Fade } from "@mui/material";
import i18n from "i18next";
import Meta from "../../components/common/Meta";
import { Documents } from "../../assets";

const Document = () => {
  const location = useLocation();
  const [document, setDocument] = useState("");
  const theme = useTheme();
  const [lang, setLang] = useState(i18n.language); // 現在の言語状態

  useEffect(() => {
    const fetchDocument = () => {
      // パスの解析
      let docName = location.pathname.substring(1);
      // デフォルトの言語
      let lang = i18n.language.startsWith("ja") ? "ja" : "en";

      // /ja/xxx or /en/xxx のようなパスの場合
      const pathParts = docName.split("/");
      if (pathParts.length > 1 && (pathParts[0] === "ja" || pathParts[0] === "en")) {
        lang = pathParts[0];
        docName = pathParts.slice(1).join("/");
      }

      console.log(`Processing document: ${docName} (lang: ${lang})`);

      try {
        // camelCaseに変換（例: terms-of-service → termsOfService）
        const camelCaseDocName = docName.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
        console.log(`Converted to camelCase: ${camelCaseDocName}`);

        // 型安全のために言語を"en" | "ja"として扱う
        const typedLang = lang as "en" | "ja";

        // Documentsオブジェクトからドキュメントを取得
        if (
          Documents[typedLang] &&
          Documents[typedLang][camelCaseDocName as keyof (typeof Documents)[typeof typedLang]]
        ) {
          console.log(`Document found in ${lang}: ${camelCaseDocName}`);
          // Webpackでインポートされたマークダウンファイルのパスを取得
          const markdownPath = Documents[typedLang][camelCaseDocName as keyof (typeof Documents)[typeof typedLang]];
          console.log(`Markdown path: ${markdownPath}`);

          // URLからマークダウンファイルを取得
          fetch(markdownPath)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.text();
            })
            .then((text) => {
              // マークダウンをHTMLに変換
              const html = marked(text);
              setDocument(html);
            })
            .catch((error) => {
              console.error("Failed to fetch markdown:", error);
              setDocument("<p>Error loading document.</p>");
            });
        } else {
          // ドキュメントが見つからない場合
          console.error(`Document not found: ${camelCaseDocName} (${lang})`);
          setDocument(
            `<p>ドキュメントが見つかりません: ${docName}</p><p>利用可能なドキュメント: legal-notice, privacy-policy, terms-of-service</p>`
          );
        }
      } catch (error) {
        console.error("Error processing document:", error);
        setDocument("<p>Error loading document.</p>");
      }
    };

    // 言語変更イベントのリスナーを設定
    const onLanguageChanged = (newLang) => {
      setLang(newLang);
      fetchDocument();
    };

    // 初回および言語変更時にドキュメントをフェッチ
    fetchDocument();

    // i18nextのlanguageChangedイベントにリスナーを登録
    i18n.on("languageChanged", onLanguageChanged);

    // コンポーネントアンマウント時にイベントリスナーをクリーンアップ
    return () => {
      i18n.off("languageChanged", onLanguageChanged);
    };
  }, [location.pathname, lang]); // 依存配列にlangを追加

  // タイトルは最初の行から取得
  const title = document ? document.replace(/<[^>]*>?/gm, "").split("\n")[0] : "Document";
  const meta = [
    { name: "robots", content: "index, follow" },
    { name: "description", content: "" },
  ];

  return (
    <>
      <Meta title={title} meta={meta} />
      <Container maxWidth={"md"} sx={{ py: 8 }}>
        <DrawerHeader />
        <Fade in={true} timeout={1500}>
          <Paper
            sx={{
              mb: 6,
              px: { xs: 2, sm: 6 },
              py: 8,
              bgcolor: theme.palette.background.paper,
              "& a": {
                color: theme.palette.primary.main,
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              },
              color: theme.palette.text.primary,
            }}
            elevation={0}
          >
            {document && <div dangerouslySetInnerHTML={{ __html: document }} />}
          </Paper>
        </Fade>
      </Container>
    </>
  );
};

export default Document;

import Crop169Icon from "@mui/icons-material/Crop169";
import CropDinIcon from "@mui/icons-material/CropDin";
import Crop32Icon from "@mui/icons-material/Crop32";

import { TextField, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../../context/juno/VideoContext";
import { ColumnBox } from "../../../../../utils/styledBox";
import AspectRatioIcons from "../AspectRatioIcons";
import ImageUpload from "../ImageUpload";
import ModelVersionSelectBox from "../ModelVersionSelectBox";

const ParamsPika = () => {
  const { t } = useTranslation();
  const {
    menu,
    updateParams,
    image1,
    setImage1,
    image2,
    setImage2,
    image3,
    setImage3,
    duration,
    modelVersion,
    negativePrompt,
    setNegativePrompt,
  } = useVideo();

  // PIKAでサポートするアスペクト比
  const aspectRatioList: AspectRatioIconType[] = [
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "3:2", icon: Crop32Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
    { ratio: "2:3", icon: Crop32Icon, rotate: "90deg" },
    { ratio: "1:1", icon: CropDinIcon },
  ];

  // 1080Pモデルの場合はデュレーションを5秒に制限
  const notV22 = modelVersion.PIKA != "V2_2_720P" && modelVersion.PIKA != "V2_2_1080P";

  // ユーザーがデュレーションを変更したときに、モデルによる制限を適用
  useEffect(() => {
    if (notV22 && duration.PIKA > 5) {
      updateParams("PIKA", "duration", 5);
    }
  }, [modelVersion.PIKA]);

  // デュレーションのマーク設定
  const durationMarks = [
    { value: 5, label: "5s" },
    { value: 10, label: "10s" },
  ];

  return (
    <ColumnBox sx={{ gap: 4 }}>
      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <ModelVersionSelectBox
          platform="PIKA"
          value={modelVersion.PIKA}
          onChange={(value) => updateParams("PIKA", "modelVersion", value)}
          menu={menu}
        />
      </ColumnBox>

      {menu === "i2v" && modelVersion.PIKA.includes("SCENES") ? (
        <ColumnBox sx={{ justifyContent: "center" }}>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")} - 01
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
          <Typography variant="body2" mb={1} mt={2}>
            {t("juno.video.params.upload")} - 02
          </Typography>
          <ImageUpload image={image2} setImage={setImage2} />
          <Typography variant="body2" mb={1} mt={2}>
            {t("juno.video.params.upload")} - 03
          </Typography>
          <ImageUpload image={image3} setImage={setImage3} />
        </ColumnBox>
      ) : menu === "i2v" ? (
        <ColumnBox>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")}
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
        </ColumnBox>
      ) : null}

      <ColumnBox>
        <AspectRatioIcons aspectRatioList={aspectRatioList} />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.duration")}
        </Typography>
        <Slider
          aria-label="duration"
          marks={durationMarks}
          min={5}
          max={10}
          step={5}
          value={duration.PIKA}
          onChange={(_, value) => updateParams("PIKA", "duration", value)}
          disabled={notV22}
        />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.negativePrompt")}
        </Typography>
        <TextField
          value={negativePrompt}
          onChange={(e) => setNegativePrompt(e.target.value)}
          variant="standard"
          fullWidth
          multiline
          rows={4}
          inputProps={{ maxLength: 500 }}
        />
      </ColumnBox>
    </ColumnBox>
  );
};

export default ParamsPika;

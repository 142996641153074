const junoImageEngine = {
  title: "AI ENGINE",
  description: "AIエンジンを選択してください。",
  policyCheck:
    "このモデルは、非常に強力なポリシーチェックに基づいているため、画像が生成されずエラーになる場合があります。",
  DallE3: "DALL·E 3",
  DallE3Description:
    "柔軟なプロンプトで、希望のが画像を簡単に生成でき、短い英単語の入力も可能です。初心者向けですが、最もクレジットを消費しやすいです。",
  LuminaV2: "Lumina Image V2",
  LuminaV2Description:
    "高解像度で多様なスタイルの画像生成が可能な、効率性と柔軟性を兼ね備えた次世代のオープンソースAI画像生成モデルです。",
  Flux11ProUltra: "FLUX1.1 Pro Ultra",
  Flux11ProUltraDescription:
    "FLUX.1 Proよりも高解像度の4MPの画像生成が可能です。また、RAWモードの選択も可能で自然な画像を生成可能。",
  Flux1Pro: "FLUX.1 Pro",
  Flux11Pro: "FLUX1.1 Pro",
  Flux1ProDescription:
    "FLUX.1シリーズの中で高解像度の画像生成が可能で、プロンプトの柔軟性が高い。また、MidjourneyV6やSD Ultraよりも高解像度の画像生成が可能とされています。",
  Flux1Dev: "FLUX.1 Dev",
  Flux1DevDescription: "FLUX.1 Proよりも画質が劣るが、FLUX.1 Proよりも高速に画像生成が可能です。",
  Flux1Schnell: "FLUX.1 Schnell",
  Flux1SchnellDescription: "FLUX.1 Devよりも画質が劣るが、FLUX.1 Devよりも高速に画像生成が可能です。",
  Flux1RealismLoRA: "FLUX.1 with Realism LoRA",
  Flux1RealismLoRADescription: "FLUX.1シリーズののリアリズムモデルです。リアリズムを重視した画像生成が可能です。",
  FluxJuno: "FLUX.1 DEV JUNO",
  FluxJunoDescription: "FLUXとSTUDIO JUNOのコラボモデルです。STUDIO JUNOの技術を活かしたスタイルを選択できます。",
  FluxPuLID: "PuLID FLUX",
  FluxPuLIDDescription:
    "FLUX.1 Dev をベースとし、元画像の顔の特徴を保持した画像生成が可能です。1枚の人物画像から生成が可能。",
  Flux11ProRedux: "FLUX1.1 Pro Redux",
  Flux11ProReduxDescription:
    "FLUX1.1 ProのReduxモデルです。入力画像をわずかに変化させたバリエーション画像を生成できます。高解像度出力と柔軟なアスペクト比を備えたFLUX1.1 Pro Ultraと組み合わせることで、より高品質な画像生成が可能です。",
  Flux1DevRedux: "FLUX.1 Dev Redux",
  Flux1DevReduxDescription:
    "FLUX.1 DevのReduxモデルです。入力画像をわずかに変化させたバリエーション画像を生成できます。開発者向けに、Flux Dev Licenseの下で提供されています。",
  Flux1SchnellRedux: "FLUX.1 Schnell Redux",
  Flux1SchnellReduxDescription:
    "FLUX.1 SchnellのReduxモデルです。入力画像をわずかに変化させたバリエーション画像を生成できます。より高解像度の画像生成が可能です。",
  Flux11ProUltraRedux: "FLUX1.1 Pro Ultra Redux",
  Flux11ProUltraReduxDescription:
    "FLUX1.1 Pro UltraのReduxモデルです。入力画像をわずかに変化させたバリエーション画像を生成できます。4メガピクセルの高解像度出力と柔軟なアスペクト比により、高品質な画像生成が可能です。",
  Flux1ProCanny: "FLUX.1 Pro Canny",
  Flux1ProCannyDescription:
    "FLUX.1 ProのCannyモデルです。入力画像から抽出されたCannyエッジを用いて、画像の構造を維持したままテキストガイドによる編集を可能にします。特に画像のテクスチャ変更に効果的です。",
  Flux1ProDepth: "FLUX.1 Pro Depth",
  Flux1ProDepthDescription:
    "FLUX.1 ProのDepthモデルです。入力画像から抽出された深度マップを用いて、画像の構造を維持したままテキストガイドによる編集を可能にします。より高解像度の画像生成が可能で、Midjourney ReTextureのような従来のモデルよりも高品質な結果を出力します。",
  Flux1DevCannyLoRA: "FLUX JUNO Canny",
  Flux1DevCannyLoRADescription:
    "FLUX.1 DevのCannyモデルとSTUDIO JUNOのコラボモデルです。入力画像から抽出されたCannyエッジを用いて、リアリズムを重視した画像生成が可能です。開発の容易化のために、FLUX.1 Devをベースにしています。",
  Flux1DevDepthLoRA: "FLUX JUNO Depth",
  Flux1DevDepthLoRADescription:
    "FLUX.1 DevのDepthモデルとSTUDIO JUNOのコラボモデルです。入力画像から抽出された深度マップを用いて、リアリズムを重視した画像生成が可能です。 開発の容易化のために、FLUX.1 Devをベースにしています。",
  Flux1ProJuno: "FLUX.1 Pro JUNO",
  Flux1ProJunoDescription:
    "FLUX.1 ProのJUNOモデルです。FLUX.1 Proの高解像度機能とSTUDIO JUNOの技術を組み合わせ、よりリアルで芸術的な表現が可能です。",
  Flux11ProUltraJuno: "FLUX1.1 Pro Ultra JUNO",
  Flux11ProUltraJunoDescription:
    "FLUX1.1 Pro UltraのJUNOモデルです。4MPの高解像度出力とSTUDIO JUNOの技術を組み合わせ、より優れた画質とアーティスティックな制御を実現します。",
  Flux1ProCannyJuno: "FLUX.1 Pro Canny JUNO",
  Flux1ProCannyJunoDescription:
    "FLUX.1 Pro CannyモデルにSTUDIO JUNOの技術を組み合わせたモデルです。Cannyエッジ検出とSTUDIO JUNOの技術を活用し、構造を維持しながらより正確でリアルな画像編集が可能です。",
  Flux1ProDepthJuno: "FLUX.1 Pro Depth JUNO",
  Flux1ProDepthJunoDescription:
    "FLUX.1 Pro DepthモデルにSTUDIO JUNOの技術を組み合わせたモデルです。深度マップ処理とSTUDIO JUNOの技術を組み合わせ、3D認識に優れた画像編集と生成が可能です。",
  Flux1ProFillJuno: "FLUX.1 Pro Fill JUNO",
  Flux1ProFillJunoDescription:
    "FLUX.1 Pro FillモデルにSTUDIO JUNOの技術を組み合わせたモデルです。高度なインペインティング機能とSTUDIO JUNOの技術を組み合わせ、より自然でコンテキストを意識した画像編集が可能です。",
  Flux1ProFill: "FLUX.1 Pro Fill",
  Flux1ProFillDescription:
    "FLUX.1 ProのFillモデルです。テキスト記述とバイナリマスクを用いて、既存の画像に自然に統合されるシームレスな編集を可能にする、高度なインペインティング機能を提供します。また、画像を元の境界を超えて拡張するアウトペインティングもサポートしています。",
  Flux1DevFill: "FLUX.1 Dev Fill JUNO",
  Flux1DevFillDescription:
    "FLUX.1 DevのFillモデルです。FLUX.1 Devの画像生成機能に、高度なインペインティング機能を追加したモデルです。テキスト記述とバイナリマスクを用いて、既存の画像に自然に統合されるシームレスな編集を可能にします。また、画像を元の境界を超えて拡張するアウトペインティングもサポートしています。",
  KolorsVTO: "Kolors Virtual Try-On",
  KolorsVTODescription:
    "Kolors Virtual Try-Onは、ファッションアイテムを画像に合成することができるモデルです。ファッションアイテムの色やデザインを変更することができます。",
  MinimaxImage: "Minimax Image",
  MinimaxImageDescription:
    "Minimax Imageは、動画生成AIのHailuoAIが提供するモデルです。非常に低コストで高品質な画像生成が可能です。",
  FluxJuggernautBase: "Juggernaut Flux Base",
  FluxJuggernautBaseDescription:
    "RunDiffusionが提供するFlux Devと同等のパラメータを持つJuggernaut Fluxのベースモデルです。バランスの取れた画質と生成速度を提供します。",
  FluxJuggernautLightning: "Juggernaut Flux Lightning",
  FluxJuggernautLightningDescription:
    "RunDiffusionが提供するFlux Schnellと同等のパラメータを持つ高速画像生成モデルです。迅速な結果が必要な場合に最適です。",
  FluxJuggernautPro: "Juggernaut Flux Pro",
  FluxJuggernautProDescription:
    "RunDiffusionが提供するFlux Proと同等のパラメータを持つ高品質画像生成モデルです。詳細な表現と高い画質を提供します。",
  FluxJuggernautJuno: "Juggernaut Flux Juno",
  FluxJuggernautJunoDescription:
    "RunDiffusionが提供するFlux.1 DevをベースとしたJuggernautモデルです。STUDIO JUNOの技術を活かし、高品質な画像生成が可能です。",
  RecraftV3: "Recraft V3",
  RecraftV3Description:
    "Recraft V3 は、プロンプトの理解力が非常に高く、ユーザーの意図を反映した精密で美しい画像生成が可能です。Midjourneyのように美観的な評価も高い。",
  IdeogramV2a: "Ideogram 2a",
  IdeogramV2aDescription:
    "文字入れが得意なモデル。マジックプロンプトを有効にすると、画像生成の際にプロンプトを大幅に省略し、画像プロンプトに沿った画像を生成することができます。",
  IdeogramV2aTurbo: "Ideogram 2a Turbo",
  IdeogramV2aTurboDescription:
    "Ideogram 2a を調整し、速度を向上させたモデル。マジックプロンプトを有効にすると、画像生成の際にプロンプトを大幅に省略し、画像プロンプトに沿った画像を生成することができます。",
  IdeogramV2Edit: "Ideogram V2 Edit",
  IdeogramV2EditDescription:
    "Ideogram V2 の編集モデル。テキスト記述とバイナリマスクを用いて、既存の画像に自然に統合されるシームレスな編集を可能にする、高度なインペインティング機能を提供します。",
  IdeogramV2TurboEdit: "Ideogram V2 Turbo Edit",
  IdeogramV2TurboEditDescription:
    "Ideogram V2 Edit を調整し、速度を向上させたモデル。テキスト記述とバイナリマスクを用いて、既存の画像に自然に統合されるシームレスな編集を可能にする。",
  Imagen3: "Imagen 3",
  Imagen3Description:
    "Imagen 3 は、Google が開発した画像生成モデルです。日本文化などにも精通しており、日本の風景やアニメキャラクター、人物などを生成するのに適しています。",
  Imagen3Fast: "Imagen 3 Fast",
  Imagen3FastDescription:
    "Imagen 3 Fast は、Imagen 3 よりも高速に画像生成が可能です。画像生成のスピードを重視する場合に適しています。",
  SD: "Stable Diffusion",
  SDDescription:
    "プロンプトの知識が必要となり、正確に入力しないと画像が破綻しやすく、難易度が高いです。しかし、モデルの種類が多く、他には出力できない種類の画像生成が可能となります。",
  SDXL: "Stable Diffusion XL",
  SDXLDescription:
    "従来のStable Diffusionよりも高解像度の画像生成が可能です。また、SDよりもプロンプトが柔軟になりましたが、モデルの種類がまだ少ないです。",
  AuraSR: "Aura SR",
  AuraSRDescription:
    "従来のアップスケーラーと異なり、次世代のアップスケーリング技術を使用し、元画像の類似性を保持しながら、画像の解像度を向上させます。",
  ClarityUpscaler: "Clarity Upscaler",
  ClarityUpscalerDescription:
    "元画像の類似性を保持しながら、画像を高画質化し、創造性を上げてそエンハンサーとしても素晴らしい性能を発揮します。",
  CreativeUpscaler: "Creative Upscaler",
  CreativeUpscalerDescription:
    "元画像をアップスケールし、欠落した部分を新しく生成することで、画像の解像度を向上させます。最大4,194,304px(約2,048pxの正方形)まで拡大可能です",
  RecraftClarityUpscaler: "Recraft Clarity Upscaler",
  RecraftClarityUpscalerDescription:
    "Recraftが提供するアップスケーラー。元画像の類似性を保持しながら、画像を高画質化し、創造性を上げてそエンハンサーとしても素晴らしい性能を発揮します。",
  RecraftCreativeUpscaler: "Recraft Creative Upscaler",
  RecraftCreativeUpscalerDescription:
    "Recraftが提供するアップスケーラー。元画像をアップスケールし、欠落した部分を新しく生成することで、画像の解像度を向上させます。最大4,194,304px(約2,048pxの正方形)まで拡大可能です",
  SD3: "Stable Diffusion 3 Large",
  SD3Description: "Stable Diffusionの最新バージョンです。プロンプトに忠実で、画像に対する文字入れを得意とします。",
  SD35: "Stable Diffusion 3.5 Large",
  SD35Description:
    "Stability AI 開発している最新モデル、Stable diffusion 3.5。80 億のパラメータ、優れた品質、迅速な順守を備えたモデルでFLUX.1 Devよりクオリティは劣るがプロンプト精度は高い。",
  SDUltra: "Stable Image Ultra",
  SDUltraDescription:
    "Stable Diffusion 3 を含む最も高度なモデルから作成された Ultra は、タイポグラフィ、複雑な構成、ダイナミックな照明、鮮やかな色彩、芸術作品の全体的なまとまりと構造に優れています。",
  REALESRGAN: "Real-ESRGAN",
  BriaBackgroundReplace: "Bria Background Replace",
  BriaBackgroundReplaceDescription: "Bria.aiが提供する、画像の背景を別の画像に置き換えることができるモデルです。",
  BriaProductShot: "Bria Product Shot",
  BriaProductShotDescription: "Bria.aiが提供する、商品写真を生成することができるモデルです。",
  IcLightV2: "IC-Light V2",
  IcLightV2Description: "与えられた写真の光源を変更し、写真の雰囲気を変えることができます。背景も同時に変更可能です。",
  GeminiFlashEdit: "Gemini Flash Edit",
  GeminiFlashEditDescription:
    "大規模言語モデルのGeminiと、Imagen3の技術を組み合わせたモデルです。これまでの画像生成とは異なり、指示口調で画像生成が可能です。",
};
export default junoImageEngine;

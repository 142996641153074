import React from "react";
import { useTranslation } from "react-i18next";
import Meta from "../../../components/common/Meta";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Top from "./components/Top";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Typography from "@mui/material/Typography";
import { ColumnCenteredBox } from "../../../utils/styledBox";
import Section3 from "./components/Section3";
import { useLoginModalContext } from "../../../context/LoginModalContext";
import { GradationButton } from "../../../utils/gradationButton";
import Section4 from "./components/Section4";

const AboutJuno = () => {
  const { t } = useTranslation();
  const loginContext = useLoginModalContext();
  const meta = [
    { name: "robots", content: "index, follow" },
    { name: "keywords", content: t("meta.juno.keywords") },
    { name: "description", content: t("meta.juno.description") },
  ];

  return (
    <>
      <Meta title={t("meta.juno.title")} meta={meta} />
      <Top />
      <Container maxWidth="lg">
        <Box sx={{ my: { xs: 2, md: 4 }, width: "100%" }}>
          <ColumnCenteredBox sx={{ width: "100%", gap: { xs: 8, md: 12 } }}>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />

            <GradationButton
              onClick={() => loginContext.setActiveModal("login")}
              sx={{ width: 200, alignSelf: "center", mb: 4 }}
            >
              {t("aboutJuno.try")}
            </GradationButton>
          </ColumnCenteredBox>
        </Box>
      </Container>
    </>
  );
};

export default AboutJuno;

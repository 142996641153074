import LoginIcon from "@mui/icons-material/Login";
import { Button, Hidden } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useLoginModalContext } from "../../../context/LoginModalContext";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { GradationButton } from "../../../utils/gradationButton";
import { JunoLogo } from "../../common/JunoLogo";
import { NotesNebulaLogo } from "../../common/NotesNebulaLogo";
import { TenakLogo } from "../../common/TenakLogo";
import ModalsManager from "../../login/ModalsManager";
import AccountPopup from "./AccountPopup";
import AnnouncementIcon from "./AnnouncementIcon";
import CenterContents from "./CenterContents";
import LanguageSwitcher from "./LanguageSwitcher";

interface DynamicBackgroundProps {
  isLoggedIn: boolean | null;
}

const DynamicBackgroundComponent: React.FC<DynamicBackgroundProps> = () => {
  return (
    <div
      style={{
        opacity: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "transparent",
        transition: "background-color 0.3s ease",
      }}
    />
  );
};

DynamicBackgroundComponent.displayName = "DynamicBackground";

const DynamicBackground = React.memo(DynamicBackgroundComponent);

const HeaderComponent: React.FC = () => {
  const theme = useTheme();
  const { isLoggedIn } = useLoginStatus();
  const { t } = useTranslation();
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(true);
  const navigate = useCustomNavigate();

  const activePath = useCallback(() => {
    const path = location.pathname.split("/")[2];
    switch (path) {
      case "notes-nebula":
        return "notes-nebula";
      case "juno":
        return "juno";
      default:
        return "tenak-ai";
    }
  }, [location.pathname]);

  useEffect(() => {
    // スクロール時のヘッダー表示制御
    if (window.innerWidth <= 768) {
      let lastScrollY = window.scrollY;
      const handleScroll = () => {
        if (
          window.scrollY < lastScrollY ||
          window.scrollY + window.innerHeight >= document.body.scrollHeight ||
          window.scrollY <= 100
        ) {
          setShowHeader(true);
        } else {
          setShowHeader(false);
        }
        lastScrollY = window.scrollY;
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setShowHeader(true);
    }
  }, []);

  const CustomToolbar = React.memo(
    styled(Toolbar)(() => ({
      minHeight: "56px!important",
      display: "flex",
      background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)",
      boxSizing: "content-box",
    }))
  );

  const loginModalContext = useLoginModalContext();
  const handleNavigate = useCallback((path) => () => navigate(path), [navigate]);
  const darkColor = theme.palette.text.primary;

  const renderLogo = () => {
    const currentPath = activePath();
    if (currentPath === "notes-nebula") {
      return <NotesNebulaLogo onClick={() => navigate("/notes-nebula")} />;
    }
    if (currentPath === "juno") {
      return <JunoLogo onClick={() => navigate("/juno")} />;
    }
    return <TenakLogo onClick={() => navigate("")} />;
  };

  return (
    <>
      <Slide appear={true} direction="down" in={showHeader} timeout={1000}>
        <MuiAppBar
          position="fixed"
          id={"header"}
          sx={{ backgroundColor: "transparent", width: "100%", zIndex: 1300, boxShadow: "none" }}
          elevation={0}
        >
          <DynamicBackground isLoggedIn={isLoggedIn} />
          <CustomToolbar>
            <Grid container sx={{ alignItems: "center", width: "100%" }}>
              {/* Left */}
              <Grid item xs={2}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: theme.palette.text.primary,
                    }}
                  >
                    {renderLogo()}
                  </Box>
                </Box>
              </Grid>

              {/* Center */}
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CenterContents />
              </Box>

              {/* Right */}
              <Grid item xs={10} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  <Box flexGrow={1} />
                  {activePath() !== "notes-nebula" && (
                    <Hidden smDown>
                      <Button
                        onClick={handleNavigate("/pricing")}
                        variant={"text"}
                        sx={{
                          color: location.pathname.split("/")[2] === "pricing" ? "primary.light" : darkColor,
                        }}
                      >
                        <Typography
                          fontWeight={location.pathname.split("/")[2] === "pricing" ? 700 : 400}
                          variant={"button"}
                        >
                          {t("pricing.title")}
                        </Typography>
                      </Button>
                    </Hidden>
                  )}

                  {isLoggedIn ? (
                    <Stack direction="row" spacing={theme.breakpoints.down("sm") ? 0 : 1}>
                      <LanguageSwitcher color={darkColor} />
                      <AnnouncementIcon color={darkColor} />
                      <AccountPopup />
                    </Stack>
                  ) : (
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                      <LanguageSwitcher color={darkColor} />
                      <AnnouncementIcon color={darkColor} />
                      <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={() => loginModalContext.setActiveModal("login")}
                        sx={{ display: { xs: "none", md: "flex" } }}
                        startIcon={<LoginIcon />}
                      >
                        {t("top.login")}
                      </Button>
                      <GradationButton
                        disableElevation
                        color="inherit"
                        onClick={() => loginModalContext.setActiveModal("signup")}
                      >
                        {t("top.signupHeader")}
                      </GradationButton>
                    </Stack>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CustomToolbar>
        </MuiAppBar>
      </Slide>
      <ModalsManager />
    </>
  );
};

HeaderComponent.displayName = "Header";

const Header = React.memo(HeaderComponent);

export default Header;

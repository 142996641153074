import TagIcon from "@mui/icons-material/Tag";
import { Autocomplete } from "@mui/material";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAlert } from "../../../../context/AlertContext";
import { setTags } from "../../../../redux/slices/fileSlice";
import { RootState } from "../../../../redux/store";
import StyledPaper from "./StyledPaper";

// カスタムフック: TextFieldのフォーカス状態を監視
const useTextFieldFocused = () => {
  const [focused, setFocused] = useState(false);
  return {
    focused,
    bind: {
      onFocus: () => setFocused(true),
      onBlur: () => setFocused(false),
    },
  };
};

function PublicSetting() {
  const { t } = useTranslation();
  const { fileUuid } = useParams();
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const { focused: focused3, bind: bind3 } = useTextFieldFocused();
  const tags = useSelector((state: RootState) => state.file.tags)[fileUuid!] || [];
  const [inputValue, setInputValue] = useState("");
  const [composing, setComposing] = useState(false);

  const handleChipDelete = (tagToDelete: string) => () => {
    dispatch(setTags({ uuid: fileUuid!, tags: tags.filter((tag) => tag !== tagToDelete) }));
  };

  const replaceTag = (input: string) => {
    // -やスペースをアンダーバーにする
    return input.trim().replace(/[-\s]/g, "_").replace(/#/g, "");
  };

  const handleAddTag = (newTag: string) => {
    if (tags.length >= 30) {
      setAlert("error", "You have reached the maximum number of tags.");
      return;
    }
    // tagsとnewTagsの重複は許可しない
    if (tags.includes(replaceTag(newTag))) {
      setAlert("warning", "The tag already exists.");
      return;
    }
    const newTags = [...tags, replaceTag(newTag)];
    dispatch(setTags({ uuid: fileUuid!, tags: newTags }));
  };

  // Autocomplete の renderInput で渡される TextField の onKeyDown イベントハンドラ
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !composing && inputValue.trim() !== "") {
      event.preventDefault(); // Enterキーのデフォルト動作を防止
      handleAddTag(inputValue.trim()); // 新しいタグを追加
    }
  };

  // ペーストイベントを処理する
  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const pasteText = event.clipboardData.getData("text");
    const newTags = pasteText.split(/\s+/).filter((tag) => replaceTag(tag) !== "");
    // tagsとnewTagsの重複は許可しない
    const newTagsSet = new Set(newTags);
    const tagsSet = new Set(tags);
    newTagsSet.forEach((tag) => tagsSet.add(replaceTag(tag)));
    const newTagsArray = Array.from(tagsSet);
    if (newTagsArray.length > 30) {
      newTagsArray.length = 30;
      setAlert("error", "You have reached the maximum number of tags.");
    }
    dispatch(setTags({ uuid: fileUuid!, tags: newTagsArray }));
    setInputValue("");
  };

  const [autocompleteTags, setAutocompleteTags] = useState<string[]>([]);

  const searchTags = (input: string) => {
    if (input === "") {
      setAutocompleteTags([]);
      return;
    }
    // タグを検索する
    return axios
      .get(`/api/v1/text-file/${fileUuid}/search-tags?query=${input}`)
      .then((res) => {
        setAutocompleteTags(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (inputValue) {
        searchTags(inputValue);
      } else {
        setAutocompleteTags([]);
      }
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  return (
    <StyledPaper>
      <Typography variant="body1" component="h4" color={focused3 ? "primary" : "textSecondary"} gutterBottom>
        {t("textEditor.sidebar.tags")}
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
        {t("textEditor.sidebar.tagsDescription")}
      </Typography>
      <Autocomplete
        multiple
        freeSolo
        id="tags-filled"
        options={autocompleteTags}
        getOptionLabel={(option) => `${option["tag"]} - ${option["count"]}`}
        value={tags}
        onChange={(event: FormEvent, newValue: any) => {
          const newTags = newValue.map((option) =>
            typeof option === "string" ? replaceTag(option) : replaceTag(option["tag"])
          );
          dispatch(setTags({ uuid: fileUuid!, tags: newTags }));
        }}
        clearOnEscape={false}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              icon={<TagIcon />}
              label={option}
              {...getTagProps({ index })}
              onDelete={handleChipDelete(option)}
              key={index}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder={t("textEditor.sidebar.tagsPlaceholder")}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            onCompositionStart={() => setComposing(true)}
            onCompositionEnd={() => setComposing(false)}
            {...bind3}
          />
        )}
      />
    </StyledPaper>
  );
}

export default PublicSetting;

import ArticleIcon from "@mui/icons-material/Article";
import CommentIcon from "@mui/icons-material/Comment";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import EventNoteIcon from "@mui/icons-material/EventNote";
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import TagIcon from "@mui/icons-material/Tag";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Avatar,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  Link,
  Paper,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ImageModal from "../../components/common/ImageModal";
import Meta from "../../components/common/Meta";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import { useAlert } from "../../context/AlertContext";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import "../../styles/quill.scss";
import { apiRequest } from "../../utils/api";
import { ColumnCenteredBox } from "../../utils/styledBox";
import { customLink, localDate } from "../../utils/utils";
import ActionButton from "./components/ActionButton";
import Comments from "./components/Comments";
import Latest from "./components/Latest";
import Toc from "./components/Toc";

const Article = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { user, isStaff } = useLoginStatus();
  const { setAlert } = useAlert();
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const fileUuid = location.pathname.split("/")[4];
  const publicUuid = location.pathname.split("/")[5] || "";
  interface Article {
    title: string;
    content: string;
    thumbnail?: string;
    description?: string;
    tags?: string[];
    user: string;
    introduction?: string;
    lang: string;
    avatar?: string;
    public_status: number;
    created_at: string;
    updated_at: string;
  }

  const [article, setArticle] = useState<Article>({
    title: "",
    content: "",
    user: "",
    lang: "ja",
    public_status: 1,
    created_at: "",
    updated_at: "",
  });
  const [toc, setToc] = useState<{ id: string; tag: string; text: string | null }[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedImg, setSelectedImg] = useState<{ src: string; alt: string } | null>(null);

  const get_article = useCallback(async () => {
    try {
      const public_path = publicUuid ? "/" + publicUuid : "";
      const res = await axios.get("/api/v1/notes-nebula/" + fileUuid + public_path);
      if (res.data.status === "success") {
        return res.data;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
    }
  }, [fileUuid, publicUuid]);

  useEffect(() => {
    setLoading(true);
    get_article().then((res) => {
      if (res?.status === "success") {
        setArticle(res.data);
        // 待機してからローディングを非表示にする
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [get_article]);

  useEffect(() => {
    if (article.content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(article.content, "text/html");
      const headers = doc.querySelectorAll("h2, h3, h4, h5, h6");
      const tocItems = Array.from(headers).map((header) => {
        // Django側で設定されたIDを使用
        const id = header.id;
        return {
          id,
          tag: header.tagName,
          text: header.textContent,
        };
      });

      setToc(tocItems);
    }
  }, [article.content]);

  const ArticleBox = styled(Box)(({ theme }) => ({
    "& img": {
      maxWidth: "100%",
      height: "auto",
      display: "block",
      margin: "0 auto",
      borderRadius: 4,
      cursor: "pointer", // Add cursor pointer to indicate the image is clickable
    },
    "& ol": {
      paddingLeft: 60,
    },
    "& div.ql-code-block-container": {
      padding: 16,
      backgroundColor: theme.palette.background.default,
      borderRadius: 4,
      marginBottom: 16,
      overflow: "auto",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
    '& li[data-list="bullet"] >.ql-ui:before': {
      fontSize: 10,
      alignItems: "center",
      display: "flex",
    },
    '& li[data-list="ordered"] >.ql-ui:before': {
      fontWeight: 600,
      fontSize: 14,
      alignItems: "center",
      display: "flex",
    },
    "& a": {
      color: theme.palette.info.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    "& blockquote": {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      paddingLeft: 16,
      margin: "16px 0",
    },
  }));

  interface MetaData {
    name?: string;
    property?: string;
    content: string;
  }

  const meta: MetaData[] = [
    { name: "twitter:card", content: article.thumbnail ? "summary_large_image" : "summary" },
    { name: "twitter:site", content: "@tenakai" },
    { name: "twitter:title", content: article.title + " | Notes Nebula" },
    { name: "twitter:image", content: article.thumbnail || "" },
    { name: "twitter:description", content: article.description || "" },
    { name: "keywords", content: article.tags?.join(", ") || "" },
    { name: "robots", content: article.public_status === 1 ? "index, follow" : "noindex, nofollow" },
    { name: "description", content: article.description || "" },
    { name: "author", content: article.user },
    { property: "og:title", content: article.title + " | Notes Nebula" },
    { property: "og:type", content: "article" },
    { property: "og:url", content: window.location.href },
    { property: "og:image", content: article.thumbnail || "" },
    { property: "og:description", content: article.description || "" },
    { property: "og:site_name", content: "Notes Nebula" },
    { property: "og:locale", content: article.lang },
    { property: "og:article:author", content: article.user },
    { property: "og:article:published_time", content: article.created_at },
    { property: "og:article:modified_time", content: article.updated_at },
    { property: "og:article:tag", content: article.tags?.join(", ") || "" },
    { property: "article:published_time", content: article.created_at },
    { property: "article:modified_time", content: article.updated_at },
    { property: "article:tag", content: article.tags?.join(", ") || "" },
    { property: "article:author", content: article.user },
    { property: "article:section", content: article.tags?.join(", ") || "" },
    { property: "article:published_first", content: article.created_at },
    { property: "article:published_last", content: article.updated_at },
    { property: "article:modified", content: article.updated_at },
    { property: "article:published", content: article.created_at },
    { property: "article:tag", content: article.tags?.join(", ") || "" },
    { property: "article:section", content: article.tags?.join(", ") || "" },
  ];

  const getStatusStyleAndMessage = (status: number) => {
    switch (status) {
      case 1:
        // 公開
        return {
          bgcolor: theme.palette.success.main,
          message: t("notesNebula.article.status.public"),
        };
      case 2:
        // 非公開
        return {
          bgcolor: theme.palette.info.main,
          message: t("notesNebula.article.status.private"),
        };
      case 3:
        // 限定公開
        return {
          bgcolor: theme.palette.info.main,
          message: t("notesNebula.article.status.shared"),
        };
      case 4:
        // ブロック
        return {
          bgcolor: theme.palette.error.main,
          message: t("notesNebula.article.status.blocked"),
        };
      default:
        // その他
        return {
          bgcolor: "transparent",
          message: "",
        };
    }
  };
  const { bgcolor, message } = getStatusStyleAndMessage(article["public_status"]);

  const [metaTitle, setMetaTitle] = useState("Loading Article...");

  useEffect(() => {
    if (loading) {
      setMetaTitle("Loading Article...");
    } else {
      if (article["title"]) {
        setMetaTitle(article["title"] + " | Notes Nebula");
      } else {
        setMetaTitle("No Article Found" + " | Notes Nebula");
      }
    }
  }, [loading, article["title"]]);

  return (
    <>
      <Meta title={metaTitle} route={"Notes Nebula"} meta={meta} lang={article["lang"]} />
      <DrawerHeader />
      <Fade in={true} timeout={1000}>
        {!loading ? (
          article["title"] ? (
            <Box
              sx={{
                minHeight: "100vh",
                mt: 2,
                bgcolor: "background.paper",
                borderRadius: 2,
                pb: 7,
                mx: { md: 2 },
              }}
            >
              <Container maxWidth={false}>
                <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
                  {/* タイトル */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      mt: 8,
                      mb: 4,
                    }}
                  >
                    <Typography
                      variant={"h4"}
                      component={"h1"}
                      fontFamily={"'Noto Serif JP', roboto"}
                      fontWeight={"bold"}
                    >
                      {article["title"]}
                    </Typography>
                  </Grid>

                  {/* 作成日、更新日 */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        alignItems: "center",
                        gap: { md: 4 },
                        mb: 4,
                      }}
                    >
                      <Box flexDirection={"row"} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <EventNoteIcon fontSize={"small"} />
                        <Typography variant={"body1"} component={"p"} className={"postDate"}>
                          <time dateTime={new Date(article["created_at"]).toISOString()}>
                            {localDate(article["created_at"], article["lang"])}
                          </time>
                        </Typography>
                      </Box>
                      <Box flexDirection={"row"} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <UpdateIcon fontSize={"small"} />
                        <Typography variant={"body1"} component={"p"} className={"modifiedDate"}>
                          <time dateTime={new Date(article["updated_at"]).toISOString()}>
                            {localDate(article["updated_at"], article["lang"])}
                          </time>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Tags */}
                  <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        mb: 4,
                        justifyContent: "flex-start",
                        pb: 1,
                        overflow: "auto",
                      }}
                    >
                      {article["tags"] &&
                        article["tags"].map((tag: string, index: number) => (
                          <Fade in={true} timeout={500 * index} key={index}>
                            <Chip
                              variant={"filled"}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <TagIcon fontSize={"small"} />
                                  <Typography variant={"subtitle2"} component={"p"}>
                                    {tag}
                                  </Typography>
                                </Box>
                              }
                              onClick={() => navigate("/tag/" + tag)}
                            />
                          </Fade>
                        ))}
                    </Box>
                  </Grid>

                  {/* いいね */}
                  <Grid item xs={12} md={0.5}>
                    {/* Share */}
                    <Box sx={{ position: { xs: "static", md: "sticky" }, top: 80 }}>
                      <ActionButton title={article["title"]} />
                    </Box>
                  </Grid>

                  {/* メインセクション */}
                  <Grid item xs={12} md={9} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {/* 通知 */}
                    {(user?.username === article["user"] || isStaff) && (
                      <Paper
                        sx={{
                          bgcolor,
                          py: 1,
                          px: 2,
                          width: "100%",
                          maxWidth: 720,
                          mb: 1,
                          position: "fixed",
                          bottom: 0,
                          zIndex: 1200,
                        }}
                        elevation={0}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <InfoIcon sx={{ color: "white" }} />
                          <Typography variant={"subtitle2"} component={"p"} whiteSpace={"break-spaces"} color={"white"}>
                            {message}
                          </Typography>
                          <Box flexGrow={1} />
                          <Box sx={{ display: "flex", gap: 1 }}>
                            {isStaff && (
                              <Button
                                disableElevation
                                variant={"contained"}
                                onClick={() => setBlockDialogOpen(true)}
                                color={"error"}
                                startIcon={<BlockIcon />}
                              >
                                {t("notesNebula.article.block")}
                              </Button>
                            )}
                            {user?.username === article["user"] && (
                              <Button
                                disableElevation
                                variant={"contained"}
                                onClick={() => navigate("/notes-nebula/files/" + fileUuid)}
                                color={"inherit"}
                                startIcon={<EditIcon />}
                              >
                                {t("notesNebula.article.edit")}
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </Paper>
                    )}

                    {/* 本文 */}
                    {article["content"] && (
                      <Box component={"article"}>
                        {article["thumbnail"] && (
                          <img
                            src={article["thumbnail"]}
                            alt={article["title"]}
                            style={{ width: "100%", borderRadius: 4, maxWidth: 720 }}
                          />
                        )}
                        <Box sx={{ my: 2 }}>
                          <Toc toc={toc} />
                        </Box>
                        <ArticleBox
                          sx={{ mb: 16, maxWidth: 720 }}
                          dangerouslySetInnerHTML={{ __html: article["content"] }}
                          onClick={(e) => {
                            const target = e.target as HTMLImageElement;
                            if (target.tagName === "IMG") {
                              setSelectedImg({ src: target.src, alt: target.alt });
                            }
                          }}
                        />

                        {/* コメントセクション */}
                        <Box sx={{ mb: 8, maxWidth: 720, width: "100%" }}>
                          <Divider sx={{ mb: 4 }}>
                            <Chip
                              icon={<CommentIcon fontSize="small" />}
                              label={t("notesNebula.article.comments")}
                              variant="outlined"
                              color="primary"
                              sx={{ px: 2 }}
                            />
                          </Divider>
                          <Comments textFileUuid={fileUuid} />
                        </Box>
                      </Box>
                    )}

                    {/* 著者情報 */}
                    <Hidden smDown>
                      <Paper
                        sx={{
                          bgcolor: "transparent",
                          border: 1,
                          borderColor: "divider",
                          maxWidth: 720,
                          p: 4,
                          width: "100%",
                          mb: 2,
                        }}
                        elevation={0}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 1,
                            mb: 1,
                          }}
                        >
                          <PersonIcon sx={{ color: theme.palette.primary.main }} fontSize={"small"} />
                          <Typography
                            variant={"subtitle1"}
                            component={"p"}
                            fontFamily={"Noto Serif JP"}
                            fontWeight={"bold"}
                          >
                            {t("notesNebula.article.author")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ mr: 2 }}>
                            {article["avatar"] ? (
                              <Avatar
                                sx={{ width: 96, height: 96 }}
                                src={article["avatar"] + "w300"}
                                variant={"rounded"}
                              />
                            ) : (
                              <Avatar color="primary" variant={"rounded"} sx={{ width: 96, height: 96 }} />
                            )}
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant={"subtitle1"} component={"p"}>
                              @{article["user"]}
                            </Typography>
                            <Typography variant={"body2"} component={"p"}>
                              {article["introduction"]}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Hidden>

                    <Paper
                      sx={{
                        bgcolor: "transparent",
                        border: 1,
                        borderColor: "divider",
                        p: 4,
                        width: "100%",
                        maxWidth: 720,
                        mb: 4,
                      }}
                      elevation={0}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                          mb: 1,
                        }}
                      >
                        <ArticleIcon sx={{ color: theme.palette.primary.main }} fontSize={"small"} />
                        <Typography
                          variant={"subtitle1"}
                          component={"p"}
                          fontFamily={"Noto Serif JP"}
                          fontWeight={"bold"}
                        >
                          {t("notesNebula.article.about")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 1,
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography variant={"body2"} component={"p"}>
                            {t("notesNebula.article.description")}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 1,
                          justifyContent: "center",
                        }}
                      >
                        <Link href={customLink("/")} underline={"none"}>
                          <Button
                            disableElevation
                            variant={"contained"}
                            color={"primary"}
                            startIcon={<EditIcon />}
                            size={"small"}
                          >
                            {t("notesNebula.article.goToTenak")}
                          </Button>
                        </Link>
                      </Box>
                    </Paper>
                  </Grid>

                  {/* サイドバー */}
                  <Grid
                    item
                    xs={12}
                    md={2.5}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Paper
                      sx={{
                        bgcolor: "transparent",
                        border: 1,
                        borderColor: "divider",
                        p: 4,
                        width: "100%",
                        mb: 4,
                      }}
                      elevation={0}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            variant={"subtitle1"}
                            component={"p"}
                            fontFamily={"Noto Serif JP"}
                            fontWeight={"bold"}
                          >
                            {t("notesNebula.article.author")}
                          </Typography>
                        </Box>

                        {article["avatar"] ? (
                          <Avatar sx={{ width: 64, height: 64 }} src={article["avatar"] + "w300"} />
                        ) : (
                          <Avatar color="primary" sx={{ width: 64, height: 64 }} />
                        )}
                        <Typography variant={"subtitle1"} component={"p"}>
                          @{article["user"]}
                        </Typography>
                        <Typography
                          variant={"body2"}
                          whiteSpace={"break-spaces"}
                          sx={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 6,
                            WebkitBoxOrient: "vertical",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {article["introduction"]}
                        </Typography>
                      </Box>
                    </Paper>

                    {/* 目次 */}
                    <Toc toc={toc} side={true} />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          ) : (
            // 記事が見つからない場合
            <Box sx={{ bgcolor: "background.custom1", mx: 2, borderRadius: 2 }}>
              <Container maxWidth={"lg"} sx={{ pb: 7 }}>
                <DrawerHeader />
                <Typography
                  variant={"h4"}
                  component={"h1"}
                  fontFamily={"'Noto Serif JP', roboto"}
                  sx={{ mb: 2 }}
                  fontWeight={"bold"}
                >
                  No Article Found
                </Typography>
                <Typography variant={"body1"} component={"p"} sx={{ mb: 4 }}>
                  The article you are looking for does not exist.
                </Typography>
                <Latest pageSize={15} view="latest" />
              </Container>
            </Box>
          )
        ) : (
          // ローディング中
          <ColumnCenteredBox sx={{ height: "100vh" }}>
            <CircularProgress size={36} thickness={4} sx={{ color: theme.palette.primary.main }} />
          </ColumnCenteredBox>
        )}
      </Fade>
      {selectedImg && (
        <ImageModal
          src={selectedImg.src}
          alt={selectedImg.alt}
          open={!!selectedImg}
          onClose={() => setSelectedImg(null)}
        />
      )}
      <Dialog
        open={blockDialogOpen}
        onClose={() => setBlockDialogOpen(false)}
        aria-labelledby="block-dialog-title"
        aria-describedby="block-dialog-description"
      >
        <DialogTitle id="block-dialog-title">{t("notesNebula.article.blockConfirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="block-dialog-description">
            {t("notesNebula.article.blockConfirmDescription")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBlockDialogOpen(false)} color="inherit">
            {t("common.cancel")}
          </Button>
          <Button
            onClick={async () => {
              try {
                await apiRequest("/api/v1/notes-nebula/block", "POST", { uuid: fileUuid });
                setAlert("success", t("notesNebula.article.blockSuccess"));
                window.location.reload();
              } catch (error) {
                const axiosError = error as AxiosError;
                console.error("Failed to block article:", axiosError.response?.data);
                setAlert("error", t("notesNebula.article.blockError"));
              }
              setBlockDialogOpen(false);
            }}
            color="error"
            variant="contained"
          >
            {t("notesNebula.article.block")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Article;

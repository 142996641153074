import AddIcon from "@mui/icons-material/Add";
import ChatIcon from "@mui/icons-material/Chat";
import { Box, Grid, Paper, useTheme } from "@mui/material";
import { alpha } from "@mui/system";
import ButtonBase from "@mui/material/ButtonBase";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../context/AlertContext";
import { useBrowsingMethods } from "../../../hooks/useBrowsingMethods";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { localDate } from "../../../utils/utils";

interface Chats {
  uuid: string;
  name: string;
  latest_history: string;
  updated_at: string;
}

interface BrowsingProps {
  data?: {
    chats: Chats[];
  };
  getData: () => Promise<void>;
}

const Browsing: FC<BrowsingProps> = ({ data, getData }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { setAlert } = useAlert();
  const { addNewChat } = useBrowsingMethods();
  const uiLanguage = Cookies.get("ui_language") || "en";

  const handleAddChat = async () => {
    try {
      const res = await addNewChat();
      if (!res) return;
      getData().then(() => {
        setAlert("success", t("browsing.createChat"));
        navigate(res.data.path);
      });
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h6"}
          component={"h3"}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            mb: 2,
            width: "100%",
          }}
        >
          <ChatIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
          {t("dashboard.search")}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {data?.chats?.map((ws, index) => {
          return (
            <Grid item xs={6} sm={4} key={index}>
              <ButtonBase
                onClick={() => navigate("/chat/" + ws.uuid)}
                sx={{
                  transition: "0.3s",
                  width: "100%",
                  "&:hover": {
                    transform: "translateY(-5px)",
                  },
                }}
              >
                <Grow in={true} timeout={(index + 1) * 500}>
                  <Paper
                    elevation={0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: 150,
                      overflow: "hidden",
                      backdropFilter: "blur(8px)",
                      backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.25),
                      borderRadius: 2,
                    }}
                  >
                    <Box
                      sx={{
                        height: "5px",
                        width: "100%",
                        background: theme.custom.gradient.main,
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: 140,
                        p: 1,
                        mt: 1,
                      }}
                    >
                      <Typography
                        variant={"subtitle2"}
                        component={"p"}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          mb: 0.5,
                          textAlign: "left",
                        }}
                      >
                        {ws.name}
                      </Typography>
                      <Typography
                        variant={"caption"}
                        component={"p"}
                        sx={{
                          mb: 0.5,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          textAlign: "left",
                        }}
                      >
                        {ws.latest_history?.replace(/#|\*|--|\|/g, "") ?? ""}
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <Typography
                        variant={"caption"}
                        component={"p"}
                        sx={{
                          mb: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          textAlign: "right",
                        }}
                      >
                        {localDate(ws.updated_at, uiLanguage)}
                      </Typography>
                    </Box>
                  </Paper>
                </Grow>
              </ButtonBase>
            </Grid>
          );
        })}
        {data?.chats && data.chats.length < 10 && (
          <Grid item xs={6} sm={4}>
            <ButtonBase
              onClick={handleAddChat}
              sx={{
                transition: "0.3s",
                width: "100%",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Grow in={true} timeout={500 * ((data?.chats?.length ?? 0) + 1)}>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 150,
                    border: `2px dashed ${alpha(theme.palette.primary.main, 0.5)}`,
                    backdropFilter: "blur(8px)",
                    backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.25),
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      m: 1,
                    }}
                  >
                    <Typography variant={"subtitle1"} textAlign={"center"} component={"p"} sx={{ mb: 1 }}>
                      Add a new Chat
                    </Typography>
                    <AddIcon sx={{ mb: 1, color: theme.palette.primary.main }} />
                    <Typography variant={"caption"} component={"p"}>
                      {t("dashboard.addChat")}
                    </Typography>
                  </Box>
                </Paper>
              </Grow>
            </ButtonBase>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default Browsing;

// src/assets/videos/index.ts
// 動画ファイルのインポート

// Juno関連動画
import junoTop from "./juno/top.mp4";
import junoHailuo from "./juno/hailuo.mp4";
import junoHaiper from "./juno/haiper.mp4";
import junoKling from "./juno/kling.mp4";
import junoLuma from "./juno/luma.mp4";
import junoPika from "./juno/pika.mp4";
import junoPixverse from "./juno/pixverse.mp4";
import junoRunway from "./juno/runway.mp4";
import junoVidu from "./juno/vidu.mp4";
import junoWan from "./juno/wan.mp4";

// Juno カメラ動画
import junoCameraPanLeft from "./juno/camera/pan-left.mp4";
import junoCameraPanRight from "./juno/camera/pan-right.mp4";
import junoCameraOrbitLeft from "./juno/camera/orbit-left.mp4";
import junoCameraOrbitRight from "./juno/camera/orbit-right.mp4";
import junoCameraCraneUp from "./juno/camera/crane-up.mp4";
import junoCameraCraneDown from "./juno/camera/crane-down.mp4";
import junoCameraMoveLeft from "./juno/camera/move-left.mp4";
import junoCameraMoveRight from "./juno/camera/move-right.mp4";
import junoCameraTiltUp from "./juno/camera/tilt-up.mp4";
import junoCameraTiltDown from "./juno/camera/tilt-down.mp4";
import junoCameraPushIn from "./juno/camera/push-in.mp4";
import junoCameraPullOut from "./juno/camera/pull-out.mp4";
import junoCameraZoomIn from "./juno/camera/zoom-in.mp4";
import junoCameraZoomOut from "./juno/camera/zoom-out.mp4";
import junoCameraTracking from "./juno/camera/tracking.mp4";
import junoCameraShake from "./juno/camera/shake.mp4";
import junoCameraStatic from "./juno/camera/static.mp4";
import junoCameraWalkLeft from "./juno/camera/walk-left.mp4";
import junoCameraWalkRight from "./juno/camera/walk-right.mp4";
import junoCameraTiltUpForward from "./juno/camera/tilt-up-forward.mp4";
import junoCameraTiltDownBack from "./juno/camera/tilt-down-back.mp4";
import junoCameraStageRight from "./juno/camera/stage-right.mp4";
import junoCameraStageLeft from "./juno/camera/stage-left.mp4";
import junoCameraScenic from "./juno/camera/scenic.mp4";

// トップページ関連動画
import topBrowsing from "./top/browsing.mp4";
import topEditor from "./top/editor.mp4";
import topFVZOAnimation from "./top/FVZO_animation.mp4";

// エクスポート
export const Videos = {
  juno: {
    top: junoTop,
    hailuo: junoHailuo,
    haiper: junoHaiper,
    kling: junoKling,
    luma: junoLuma,
    pika: junoPika,
    pixverse: junoPixverse,
    runway: junoRunway,
    vidu: junoVidu,
    wan: junoWan,
    camera: {
      "pan-left": junoCameraPanLeft,
      "pan-right": junoCameraPanRight,
      "orbit-left": junoCameraOrbitLeft,
      "orbit-right": junoCameraOrbitRight,
      "crane-up": junoCameraCraneUp,
      "crane-down": junoCameraCraneDown,
      "move-left": junoCameraMoveLeft,
      "move-right": junoCameraMoveRight,
      "tilt-up": junoCameraTiltUp,
      "tilt-down": junoCameraTiltDown,
      "push-in": junoCameraPushIn,
      "pull-out": junoCameraPullOut,
      "zoom-in": junoCameraZoomIn,
      "zoom-out": junoCameraZoomOut,
      tracking: junoCameraTracking,
      shake: junoCameraShake,
      static: junoCameraStatic,
      "walk-left": junoCameraWalkLeft,
      "walk-right": junoCameraWalkRight,
      "tilt-up-forward": junoCameraTiltUpForward,
      "tilt-down-back": junoCameraTiltDownBack,
      "stage-right": junoCameraStageRight,
      "stage-left": junoCameraStageLeft,
      scenic: junoCameraScenic,
    },
  },
  top: {
    browsing: topBrowsing,
    editor: topEditor,
    fvzoAnimation: topFVZOAnimation,
  },
};

// 個別エクスポート
export {
  junoTop,
  junoHailuo,
  junoHaiper,
  junoKling,
  junoLuma,
  junoPika,
  junoPixverse,
  junoRunway,
  junoVidu,
  junoWan,
  junoCameraPanLeft,
  junoCameraPanRight,
  junoCameraOrbitLeft,
  junoCameraOrbitRight,
  junoCameraCraneUp,
  junoCameraCraneDown,
  junoCameraMoveLeft,
  junoCameraMoveRight,
  junoCameraTiltUp,
  junoCameraTiltDown,
  junoCameraPushIn,
  junoCameraPullOut,
  junoCameraZoomIn,
  junoCameraZoomOut,
  junoCameraTracking,
  junoCameraShake,
  junoCameraStatic,
  junoCameraWalkLeft,
  junoCameraWalkRight,
  junoCameraTiltUpForward,
  junoCameraTiltDownBack,
  junoCameraStageRight,
  junoCameraStageLeft,
  junoCameraScenic,
  topBrowsing,
  topEditor,
  topFVZOAnimation,
};

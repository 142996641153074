const preference = {
  title: "Preferences",
  account: {
    title: "My Account",
    subtitle: "Manage my account",
    avatar: "Avatar",
    username: "Username",
    email: "Email Address (Private)",
    introduction: "Introduction",
    introductionPlaceholder: "Enter an introduction",
    introductionDescription: "Your introduction will be displayed on 'Notes Nebura' and 'Studio Juno'.",
    deleteDialog: {
      title: "Delete Account",
      description:
        "Deleting your account will remove your data, but some public data may remain anonymized. Are you sure you want to delete?",
      confirm: "Delete",
      error: {
        common: "Failed to delete the account. Please contact support.",
        subscription:
          "You cannot delete your account because you are subscribed to a paid plan. Please cancel your plan and then delete your account.",
      },
    },
  },
  settings: {
    title: "Settings",
    appearance: "Appearance",
    uiLanguage: "UI Language",
    uiLanguageDescription: "The language of the user interface",
    aiLanguage: "AI Language",
    aiLanguageButton: "Manage AI Language",
    aiLanguageDescription: "The response language of AI",
    aiEngine: "AI Engine Mode",
    aiEngineDescription:
      "Select the AI engine mode for text generation. You can switch between o3-mini, GPT-4o, Claude 3.7 Sonnet, and GPT-4o-mini as needed. This setting can be changed at any time from the user icon in the upper right corner.",
    country: "Country",
    countryDescription: "Select a country to optimize search results.",
    switcherLanguageDescription: "Please change the AI's response language from the settings.",
    urlMode: {
      title: "URL Reference Mode",
      description:
        "Selects the mode for AI to reference URLs. The 'strict' mode may consume a large amount of credits.",
      strict: "Strict",
      economy: "Economy",
    },
  },
  planAndBillings: {
    title: "Subscription and Billing",
    credit: "Credit Balance",
    totalCredit: "Total Credits",
    monthlyCredit: "Monthly Credits",
    dailyCredit: "Daily Credits",
    extraCredit: "Extra Credits",
    dailyRefresh: "Daily Refresh",
    subscriptionPeriod: "Subscription Period",
    currentPlan: "Subscription Plan",
    upgrade: "Upgrade",
    billings: "Subscription Billing History",
    cancel: "Cancel Subscription",
    reactivate: "Reactivate Subscription",
    invoice: "View invoices in customer portal",
    noInvoice: "No invoice available.",
    aboutCredit: "About Credits",
    checkoutHistory: "Purchase History",
    checkoutHistoryNote: "Upgrades and credit purchases (past 12 months)",
    receipt: "Receipt",
    document: "Document",
    selectHistory: "Select History",
    pleaseSelect: "Please select",
    resetDate: "Reset Date",
    upgradeModal: {
      title: "Upgrade Plan",
      description: "Upgrading your plan allows access to more features.",
      caution1: "Upgrading will immediately add credits and unlock features.",
      caution2: "If you are already subscribed to a paid plan, the difference will be charged.",
      caution3:
        "The next billing date (credit expiration date) will not change. The next billing date can be checked in the customer portal.",
      caution4:
        "By making a purchase, you are deemed to have agreed to the above terms, privacy policy and conditions, as well as the Terms of Service.",
      price: "Billing amount",
      nextPrice: "Next billing amount",
      newPlan: "New Plan",
      upgrade: "Agree and Upgrade",
      warningJCB: "",
    },
  },
  misc: {
    title: "MISC",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
    contact: "Contact",
    startContact: "Start Contact",
    confirm: "Confirm",
    contactModal: {
      title: "Contact Us",
      description: "Please enter your inquiry.",
      warn: "Please note that we cannot respond to general inquiries about AI usage or consultation, other than questions about Tenak AI.",
      subject: "Title",
      type: {
        description: "Please select the type of inquiry.",
        question: "Question",
        problem: "Report a Problem",
        payment: "Regarding Payment",
        request: "Feedback and Requests",
        other: "Other",
      },
      name: "Name",
      email: "Email Address",
      message: "Details of Inquiry",
      send: "Send",
      error: "Failed to send your inquiry. Please try again later.",
      success: "Your inquiry has been received. We will contact you shortly.",
    },
  },
};
export default preference;

import { Grid, Pagination, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledTypography } from "../../../utils/styledTypography";
import ArticleCard from "./ArticleCard";

interface PaginationData {
  total: number;
  page: number;
  page_size: number;
  total_pages: number;
}

interface Article {
  uuid: string;
  title: string;
  content: string;
  user: string;
  avatar: string;
  thumbnail: string;
  created_at: string;
  tags: string[];
}

const Official = ({ pageSize = 20 }: { pageSize?: number }) => {
  const { t } = useTranslation();
  const [latestArticles, setLatestArticles] = useState<Article[]>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    total: 0,
    page: 1,
    page_size: pageSize,
    total_pages: 0,
  });

  const get_articles = async (page: number) => {
    try {
      const res = await axios.get(`/api/v1/notes-nebula/?type=official&page=${page}&page_size=${pageSize}`);
      setLatestArticles(res.data.data.latest_articles);
      setPagination(res.data.data.pagination);

      res.data.data.latest_articles.map((item: Article) => {
        if (item["thumbnail"]) {
          const img = new Image();
          img.src = item["thumbnail"];
          img.onload = () => {
            setLoadedImages((loadedImages) => [...loadedImages, item["uuid"]]);
          };
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    get_articles(page);
  };

  useEffect(() => {
    get_articles(1);
  }, []);

  return (
    <>
      <StyledTypography variant={"h6"} sx={{ mb: 2 }}>
        {t("notesNebula.official")}
      </StyledTypography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {latestArticles.length > 0 ? (
          latestArticles.map((item: Article, index: number) => (
            <Grid item xs={12} md={4} lg={2.4} key={index}>
              <ArticleCard article={item} loadedImages={loadedImages} index={index} />
            </Grid>
          ))
        ) : (
          <Typography variant="subtitle1" sx={{ my: 2, ml: 4 }}>
            No articles found.
          </Typography>
        )}
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: 4 }}>
        <Pagination count={pagination.total_pages} page={pagination.page} onChange={handlePageChange} color="primary" />
      </Grid>
    </>
  );
};
export default Official;

import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CopyButtonOrIcon from "../../../../components/common/CopyButtonOrIcon";
import GeneratedImageModal from "../../../../components/common/GeneratedImageModal";
import UnsplashModal from "../../../../components/common/UnsplashModal";
import { useAlert } from "../../../../context/AlertContext";
import {
  setLanguage,
  setPublicStatus,
  setSaveTrigger,
  setThumbnailImage,
  setUpdateThumbnail,
} from "../../../../redux/slices/fileSlice";
import { RootState } from "../../../../redux/store";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import StyledPaper from "./StyledPaper";
import Tags from "./Tags";

// カスタムフック: TextFieldのフォーカス状態を監視
const useTextFieldFocused = () => {
  const [focused, setFocused] = useState(false);
  return {
    focused,
    bind: {
      onFocus: () => setFocused(true),
      onBlur: () => setFocused(false),
    },
  };
};

function PublicSetting() {
  const { t } = useTranslation();
  const { fileUuid } = useParams();
  const { setAlert } = useAlert();
  const publicUuid = useSelector((state: RootState) => state.file.publicUuid)[fileUuid!];
  const publicStatus = useSelector((state: RootState) => state.file.publicStatus)[fileUuid!] || 1;
  const language = useSelector((state: RootState) => state.file.language)[fileUuid!];
  const thumbnail = useSelector((state: RootState) => state.file.thumbnailImage)[fileUuid!];
  const dispatch = useDispatch();
  const { focused: focused1, bind: bind1 } = useTextFieldFocused();
  const { focused: focused2, bind: bind2 } = useTextFieldFocused();
  const [generatedImageModalOpen, setGeneratedImageModalOpen] = useState(false);
  const [unsplashModalOpen, setUnsplashModalOpen] = useState(false);

  const sharedUrl = `${window.location.origin}/${language}/notes-nebula/articles/${fileUuid}/${publicUuid}`;

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPublicStatus({ uuid: fileUuid!, status: Number(event.target.value) }));
  };

  const handleLanguageChange = (value) => {
    dispatch(setLanguage({ uuid: fileUuid!, language: value }));
  };
  const [saved, setSaved] = useState(false);
  const saveIcon = saved ? <CheckIcon /> : <SaveIcon />;

  useEffect(() => {
    // ボタンクリック後、2秒でsavedをfalseに戻す
    if (saved) {
      const timer = setTimeout(() => {
        setSaved(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [saved]);

  const [uploading, setUploading] = useState(false);

  const handleFileUpload = async (file: File) => {
    setUploading(true);

    // ファイルタイプチェック (JPGまたはPNG)
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      setAlert("error", t("juno.input.uploadError.type"));
      setUploading(false);
      return;
    }

    // ファイルサイズチェック
    const maxSizeInBytes = 20000000; // 20MB
    if (file.size > maxSizeInBytes) {
      setAlert("error", t("juno.input.uploadError.size", { size: maxSizeInBytes / 1000000 }));
      setUploading(false);
      return;
    }

    // FileReaderを使用してファイルをBase64エンコードする
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result as string;
      // Base64エンコードされた画像データをサムネイルとして設定
      dispatch(setThumbnailImage({ uuid: fileUuid!, url: base64Data }));
      setUploading(false);
      setAlert("success", t("juno.input.uploadSuccess"));
      dispatch(setUpdateThumbnail(true));
    };
    reader.onerror = () => {
      setAlert("error", "An error occurred while uploading the image.");
      setUploading(false);
    };
    reader.readAsDataURL(file);
  };

  const uploadImage = () => {
    try {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
      input.onchange = () => {
        const file = input.files![0];
        handleFileUpload(file);
      };
    } catch {
      setAlert("error", "An error occurred while uploading the image.");
      setUploading(false);
    }
  };

  const setThumbnailImageFromGeneratedImage = (image: string) => {
    // GeneratedImageModalから直接Blob URLを受け取るので、R2チェックは不要
    dispatch(setThumbnailImage({ uuid: fileUuid!, url: image }));
    dispatch(setUpdateThumbnail(true));
  };

  const setThumbnailImageFromUnsplash = (image: string) => {
    dispatch(setThumbnailImage({ uuid: fileUuid!, url: image }));
    dispatch(setUpdateThumbnail(true));
  };

  return (
    <Box my={2}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", my: 1 }}>
        <Typography variant={"caption"}>{t("textEditor.sidebar.publicSettingsDescription")}</Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box gap={1} display={"flex"} flexDirection={"column"}>
        {/* 公開設定 */}
        <StyledPaper>
          <Box>
            <FormControl sx={{ mb: 0.5 }}>
              <FormLabel sx={{ mb: 2 }}>
                <Typography component={"h4"}>{t("textEditor.sidebar.publicSettings")}</Typography>
              </FormLabel>
              <RadioGroup value={publicStatus} name="radio-buttons-group" onChange={handleStatusChange}>
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  sx={{ mb: 2 }}
                  label={
                    <>
                      <Typography variant="body2" gutterBottom>
                        {t("textEditor.sidebar.publicStatus.public")}
                      </Typography>
                      <Typography variant="body2" color={"textSecondary"}>
                        {t("textEditor.sidebar.publicStatus.publicDescription")}
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label={
                    <>
                      <Typography variant="body2" gutterBottom>
                        {t("textEditor.sidebar.publicStatus.private")}
                      </Typography>
                      <Typography variant="body2" color={"textSecondary"}>
                        {t("textEditor.sidebar.publicStatus.privateDescription")}
                      </Typography>
                    </>
                  }
                  sx={{ mb: 2 }}
                />
                <FormControlLabel
                  value={3}
                  control={<Radio />}
                  label={
                    <>
                      <Typography variant="body2" gutterBottom>
                        {t("textEditor.sidebar.publicStatus.shared")}
                      </Typography>
                      <Typography variant="body2" color={"textSecondary"}>
                        {t("textEditor.sidebar.publicStatus.sharedDescription")}
                      </Typography>
                    </>
                  }
                />
                {publicStatus === 4 && (
                  <Box sx={{ mt: 2, p: 2, bgcolor: "error.main", borderRadius: 1 }}>
                    <Typography variant="body2" color="white" gutterBottom>
                      {t("textEditor.sidebar.publicStatus.blocked")}
                    </Typography>
                    <Typography variant="body2" color="white" whiteSpace={"break-spaces"}>
                      {t("textEditor.sidebar.publicStatus.blockedDescription")}
                    </Typography>
                  </Box>
                )}
              </RadioGroup>
            </FormControl>

            {publicStatus === 3 && (
              <Box sx={{ ml: 4 }}>
                <TextField
                  fullWidth
                  value={sharedUrl}
                  variant={"standard"}
                  InputProps={{
                    readOnly: true,
                    // startAdornment: <LinkIcon sx={{mr: 1}}/>,
                    endAdornment: (
                      <Box ml={1}>
                        <CopyButtonOrIcon displayType={"icon"} textToCopy={sharedUrl} size={"small"} />
                      </Box>
                    ),
                  }}
                ></TextField>
              </Box>
            )}
          </Box>
        </StyledPaper>

        {/*  言語設定  */}
        <StyledPaper>
          <Typography variant="body1" component="h4" color={focused1 ? "primary" : "textSecondary"} gutterBottom>
            {t("textEditor.sidebar.language")}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
            {t("textEditor.sidebar.languageDescription")}
          </Typography>
          <Select
            variant="standard"
            sx={{ width: 250 }}
            value={language as "en" | "ja"}
            onChange={(event) => handleLanguageChange(event.target.value)}
            {...bind1}
          >
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"ja"}>Japanese (日本語)</MenuItem>
          </Select>
        </StyledPaper>

        {/*  サムネイル  */}
        <StyledPaper>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body1" component="h4" color={focused2 ? "primary" : "textSecondary"} gutterBottom>
                {t("textEditor.sidebar.thumbnail")}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                {t("textEditor.sidebar.thumbnailDescription")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
              <Button disableElevation variant={"contained"} size={"small"} {...bind2}>
                <Typography variant={"button"} onClick={uploadImage}>
                  {t("textEditor.sidebar.upload")}
                </Typography>
              </Button>
              <Button
                disableElevation
                variant={"contained"}
                size={"small"}
                {...bind2}
                onClick={() => setGeneratedImageModalOpen(true)}
              >
                <Typography variant={"button"}>{t("textEditor.sidebar.generated")}</Typography>
              </Button>
              <Button
                disableElevation
                variant={"contained"}
                size={"small"}
                {...bind2}
                onClick={() => setUnsplashModalOpen(true)}
              >
                <Typography variant={"button"}>{t("textEditor.sidebar.unsplash")}</Typography>
              </Button>
            </Box>

            {/* サムネイルアップローダー */}
            {uploading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 400,
                  height: 210,
                  bgcolor: "rgba(0, 0, 0, 0.2)",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                onClick={uploadImage}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  const file = e.dataTransfer.files[0];
                  handleFileUpload(file);
                }}
                sx={{
                  display: "flex",
                  maxWidth: 400,
                  backgroundImage: thumbnail ? `url(${thumbnail})` : "",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  height: 210,
                  position: "relative",
                  bgcolor: "rgba(0, 0, 0, 0.2)",
                  cursor: "pointer",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                {!thumbnail && (
                  <ColumnCenteredBox
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      bgcolor: "rgba(0, 0, 0, 0.2)",
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      fontSize: 20,
                      transition: "0.3s",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                      },
                    }}
                  >
                    <CloudUploadIcon fontSize={"large"} sx={{ mb: 2 }} />
                    <Typography variant={"body1"}>Upload an image</Typography>
                  </ColumnCenteredBox>
                )}
                <IconButton
                  sx={{
                    position: "absolute",
                    bottom: 8,
                    right: 8,
                    backdropFilter: "blur(5px)",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    transition: "0.3s",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.4)",
                    },
                  }}
                  disabled={!thumbnail}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setThumbnailImage({ uuid: fileUuid!, url: "" }));
                    dispatch(setUpdateThumbnail(true));
                  }}
                >
                  <DeleteIcon style={{ color: "white" }} />
                </IconButton>
              </Box>
            )}
          </Box>
        </StyledPaper>

        {/* tag */}
        <Tags />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mx: 4, my: 3 }}>
        <Button
          disableElevation
          disabled={saved}
          onClick={() => {
            setSaved(true);
            dispatch(setSaveTrigger(true));
          }}
          startIcon={saveIcon}
          variant={"contained"}
        >
          {t("common.save")}
        </Button>
      </Box>

      <GeneratedImageModal
        open={generatedImageModalOpen}
        setOpen={setGeneratedImageModalOpen}
        setImage={setThumbnailImageFromGeneratedImage}
        useBlobUrl={false}
      />
      <UnsplashModal open={unsplashModalOpen} setOpen={setUnsplashModalOpen} setImage={setThumbnailImageFromUnsplash} />
    </Box>
  );
}

export default PublicSetting;

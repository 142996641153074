import BoltIcon from "@mui/icons-material/Bolt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LaunchIcon from "@mui/icons-material/Launch";
import { Box, Button, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCrown } from "react-icons/fa6";
import { GradationButton } from "../../../../utils/gradationButton";

interface PermanentCreditModalProps {
  open: boolean;
  handleClose: () => void;
  productData: {
    id: string;
    name: string;
    credit_amount: number;
    unit_price: number;
    price: number;
    currency: string;
    original_unit_price?: number;
    original_price?: number;
  };
  handlePurchaseClick?: (productId: string) => void;
}

const PermanentCreditModal = ({ open, handleClose, productData, handlePurchaseClick }: PermanentCreditModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const purchaseAdditionalCredit = async () => {
    setLoading(true);
    try {
      // 親コンポーネントから渡された処理があれば使用
      if (handlePurchaseClick) {
        handlePurchaseClick(productData.id);
        return;
      }

      // デフォルトの処理
      const csrftoken = Cookies.get("csrftoken");
      const config = { headers: { "X-CSRFToken": csrftoken } };
      const data = { product_id: productData.id };
      const res = await axios.post("/api/v1/users/permanent-credit", data, config);
      const url = res.data.url;
      if (url) {
        window.location.href = url;
      } else {
        console.error("Failed to obtain checkout URL");
      }
    } catch (error) {
      console.error("Failed to purchase additional credit", error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 800,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mb: 4, gap: 4 }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography variant={"h5"} component={"h3"} color={"primary.main"}>
              <FaCrown style={{ marginRight: "16px" }} />
            </Typography>
            <Typography variant={"h5"} component={"h3"}>
              {t("pricing.permanentCredit.modalTitle")}
            </Typography>
          </Box>
          <Typography>{t("pricing.permanentCredit.modalDescription")}</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", mb: 4, gap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <CheckCircleIcon sx={{ mr: 2 }} />
            <Typography variant={"body2"}>{t("pricing.permanentCredit.modalCaution1")}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <CheckCircleIcon sx={{ mr: 2 }} />
            <Typography variant={"body2"}>{t("pricing.permanentCredit.modalCaution2")}</Typography>
          </Box>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
              <CheckCircleIcon sx={{ mr: 2 }} />
              <Typography variant={"body2"}>{t("pricing.permanentCredit.modalCaution3")}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", ml: 4 }}>
              <Link href={"/terms-of-service"} sx={{ mr: 2 }} target={"_blank"}>
                <Button disableElevation variant={"text"} color={"primary"} endIcon={<LaunchIcon fontSize={"small"} />}>
                  {t("preferences.misc.termsOfService")}
                </Button>
              </Link>
              <Link href={"/privacy-policy"} target={"_blank"}>
                <Button disableElevation variant={"text"} color={"primary"} endIcon={<LaunchIcon fontSize={"small"} />}>
                  {t("preferences.misc.privacyPolicy")}
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", mb: 4, gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "background.custom1",
              p: 4,
              borderRadius: 1,
            }}
          >
            {/* 商品名 */}
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
              <Typography variant={"subtitle1"} fontWeight={400} sx={{ mr: 1 }}>
                {t("pricing.permanentCredit.modalProduct")}:
              </Typography>
              <Typography variant={"h6"} component={"p"} sx={{ color: "secondary.main", mr: 4 }}>
                {productData.name}
              </Typography>
            </Box>

            {/* クレジット数 */}
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
              <Typography variant={"subtitle1"} fontWeight={400} sx={{ mr: 1 }}>
                {t("pricing.permanentCredit.modalCredits")}:
              </Typography>
              <Typography variant={"h6"} component={"p"} sx={{ color: "secondary.main", mr: 4 }}>
                {productData.credit_amount.toLocaleString()} {t("pricing.permanentCredit.credits")}
              </Typography>
            </Box>

            {/* 価格 */}
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }}>
              <Typography variant={"subtitle1"} component={"p"} fontWeight={400} sx={{ mr: 1 }}>
                {t("pricing.permanentCredit.modalPrice")}:
              </Typography>
              {productData.original_price && productData.original_price !== productData.price ? (
                <>
                  <Typography
                    variant={"h6"}
                    component={"p"}
                    sx={{ textDecoration: "line-through", color: "text.secondary", mr: 1 }}
                  >
                    ${productData.original_price.toFixed(2)}
                  </Typography>
                  <Typography variant={"h6"} component={"p"} sx={{ color: "secondary.main" }}>
                    ${productData.price.toFixed(2)}
                  </Typography>
                </>
              ) : (
                <Typography variant={"h6"} component={"p"} sx={{ color: "secondary.main" }}>
                  ${productData.price.toFixed(2)}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "flex-start" }}>
          <Button disableElevation onClick={handleClose} sx={{ mr: 2 }}>
            {t("common.close")}
          </Button>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, flexDirection: "column" }}>
            <GradationButton onClick={() => purchaseAdditionalCredit()} disabled={loading} sx={{ width: 300 }}>
              <BoltIcon sx={{ mr: 1 }} />
              {t("pricing.permanentCredit.purchase")}
            </GradationButton>
            <Button
              disableElevation
              onClick={() => {
                // 商品IDの末尾をJPYに変更
                const jpyProductId = productData.id.replace(/_usd$/, "_jpy");

                // 親コンポーネントから渡された処理があれば使用
                if (handlePurchaseClick) {
                  handlePurchaseClick(jpyProductId);
                  return;
                }

                // デフォルトの処理
                setLoading(true);
                try {
                  const csrftoken = Cookies.get("csrftoken");
                  const config = { headers: { "X-CSRFToken": csrftoken } };
                  const data = { product_id: jpyProductId };
                  axios
                    .post("/api/v1/users/permanent-credit", data, config)
                    .then((res) => {
                      const url = res.data.url;
                      if (url) {
                        window.location.href = url;
                      } else {
                        console.error("Failed to obtain checkout URL");
                      }
                    })
                    .catch((error) => {
                      console.error("Failed to purchase additional credit", error);
                    })
                    .finally(() => {
                      setLoading(false);
                      handleClose();
                    });
                } catch (error) {
                  console.error("Failed to purchase additional credit", error);
                  setLoading(false);
                  handleClose();
                }
              }}
              color="warning"
              sx={{ width: 300 }}
              startIcon={<CreditCardIcon />}
            >
              <Typography variant={"caption"}>{t("preferences.planAndBillings.upgradeModal.upgrade_jcb")}</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PermanentCreditModal;

export const aiEngineColor = (ai_engine) => {
  switch (ai_engine) {
    case "SDXL":
    case "SD3":
    case "SD35":
    case "SDUltra":
      return "#374579";
    case "FLUX":
    case "Flux1Pro":
    case "Flux11Pro":
    case "Flux11ProUltra":
    case "Flux11ProUltraRedux":
    case "Flux11ProRedux":
    case "Flux1ProCanny":
    case "Flux1ProDepth":
    case "Flux1ProFill":
      return "#703f86";
    case "FluxPuLID":
    case "Flux1Dev":
    case "Flux1Schnell":
    case "Flux1RealismLoRA":
    case "Flux1DevRedux":
    case "Flux1SchnellRedux":
    case "Flux1DevCannyLoRA":
    case "Flux1DevDepthLoRA":
    case "Flux1DevFill":
      return "#7050ac";
    case "FluxJuno":
    case "Flux1ProDepthJuno":
    case "Flux1ProCannyJuno":
    case "Flux11ProUltraJuno":
    case "Flux1ProJuno":
    case "Flux1ProFillJuno":
      return "#3b5f84";
    case "FluxJuggernautBase":
    case "FluxJuggernautLightning":
    case "FluxJuggernautPro":
    case "FluxJuggernautJuno":
      return "#84553b";
    case "RecraftV3":
      return "#793a37";
    case "IdeogramV2a":
    case "IdeogramV2aTurbo":
    case "IdeogramV2Edit":
    case "IdeogramV2TurboEdit":
      return "#b0922f";
    case "LuminaV2":
    case "DallE3":
    case "Imagen3":
    case "Imagen3Fast":
    case "GeminiFlashEdit":
    case "MinimaxImage":
      return "#37796f";
    case "KolorsVTO":
    case "BriaBackgroundReplace":
    case "BriaProductShot":
    case "IcLightV2":
      return "#5b7937";
    case "AuraSR":
    case "ClarityUpscaler":
    case "CreativeUpscaler":
    case "RecraftClarityUpscaler":
      return "#795237";
    default:
      return "#181818";
  }
};

import ArticleIcon from "@mui/icons-material/Article";
import FolderIcon from "@mui/icons-material/Folder";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import ShortTextIcon from "@mui/icons-material/ShortText";
import { Card, CardActionArea, CardContent, CardMedia, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DrawerHeader } from "../../../components/layout/Drawer/DrawerMain";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import WritingAiRouter from "../../../routes/WritingAiRouter";
import { Images } from "../../../assets";
import { GradientTypography } from "../../../utils/gradientTypography";
import { uiHeight } from "../../../utils/uiHeight";
import AIEngineSwitch from "../../../components/layout/Header/AIEngineSwitch";

const tabConfig = [
  {
    value: "article",
    icon: <ArticleIcon />,
    titleKey: "article.title",
    descriptionKey: "article.description",
    path: "/writing-ai/article",
    imagePath: Images.writing.article,
  },
  {
    value: "video-script",
    icon: <MovieFilterIcon />,
    titleKey: "videoScript.title",
    descriptionKey: "videoScript.description",
    path: "/writing-ai/video-script",
    imagePath: Images.writing.shortVideo,
  },
  {
    value: "summarizer",
    icon: <ShortTextIcon />,
    titleKey: "summarize.title",
    descriptionKey: "summarize.description",
    path: "/writing-ai/summarizer",
    imagePath: Images.writing.summary,
  },
] as const;

export default function Layout() {
  const { t } = useTranslation();
  const location = useLocation();
  const [tabValue, setTabValue] = useState("article");
  const navigate = useCustomNavigate();

  useEffect(() => {
    const currentTab = tabConfig.find((tab) => location.pathname.includes(tab.value));
    setTabValue(currentTab ? currentTab.value : "summarizer");
  }, [location.pathname]);

  return (
    <Container maxWidth={false}>
      <DrawerHeader />

      <Paper
        elevation={0}
        sx={{
          backgroundColor: "background.paper",
          width: "100%",
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={2.5}>
            <Paper
              elevation={0}
              sx={{
                backgroundColor: "background.custom1",
                minHeight: `calc(${uiHeight()} - 16px)`,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ p: 2 }}>
                <GradientTypography
                  variant={"h6"}
                  component={"h2"}
                  sx={{ ml: 1, display: "flex", alignItems: "center" }}
                >
                  Writing AI
                </GradientTypography>
              </Box>

              {tabConfig.map((tab) => (
                <Card
                  elevation={0}
                  key={tab.value}
                  sx={{
                    mx: 2,
                    mb: 2,
                    borderRadius: "1px 8px 8px px",
                    borderLeft: "solid 4px",
                    borderColor: tabValue === tab.value ? "primary.main" : "transparent",
                    transition: "border-color 0.3s",
                  }}
                >
                  <CardActionArea
                    onClick={() => navigate(tab.path)}
                    sx={{
                      height: 180,
                      position: "relative",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: tabValue === tab.value ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.5)",
                        transition: "background-color 0.3s",
                      },
                      "&:hover::after": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        transition: "background-color 0.3s",
                      },
                      "&:hover img": {
                        transform: "scale(1.1)",
                      },
                      "& img": {
                        transition: "transform 0.3s",
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={tab.imagePath}
                      alt={t(tab.titleKey)}
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        height: "100%",
                      }}
                    />
                    <CardContent sx={{ position: "relative", zIndex: 1, height: "100%" }}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="left"
                        justifyContent={"flex-end"}
                        height={"100%"}
                        gap={1}
                      >
                        <Typography color="white">{tab.icon}</Typography>{" "}
                        <Typography variant="subtitle1" color="white">
                          {t(tab.titleKey)}
                        </Typography>
                        <Typography variant="body2" color="white" fontWeight={500}>
                          {t(tab.descriptionKey)}
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}

              <Box
                sx={{ p: 2, width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", gap: 2 }}
              >
                <Button
                  disableElevation={true}
                  onClick={() => navigate("/notes-nebula/files?page=1&query=&sort=-updated_at")}
                  variant={"contained"}
                  fullWidth
                >
                  <FolderIcon sx={{ mr: 1 }} />
                  <Typography variant={"button"}>{t("article.library")}</Typography>
                </Button>

                <AIEngineSwitch />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={9.5} sx={{ overflow: "auto", height: `calc(${uiHeight()} - 16px)` }}>
            <Box sx={{ p: { xs: 2, sm: 4 } }}>
              <WritingAiRouter />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

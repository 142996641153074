import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type FileState = {
  fileTitle: { [fileUuid: string]: string };
  fileText: { [fileUuid: string]: string };
  unsplash: { [fileUuid: string]: string };
  generatedImage: { [fileUuid: string]: string };
  thumbnailImage: { [fileUuid: string]: string };
  selection: { [fileUuid: string]: { index: number; length: number; text: string } };
  shouldReplace: { [fileUuid: string]: boolean };
  publicStatus: { [fileUuid: string]: number };
  publicUuid: { [fileUuid: string]: string };
  language: { [fileUuid: string]: string };
  tags: { [fileUuid: string]: string[] };
  saveTrigger: boolean;
  updateThumbnail: boolean;
  sidebarGenerateImage: string;
  voiceGeneratorText: string;
};

const initialState: FileState = {
  fileText: {},
  fileTitle: {},
  unsplash: {},
  generatedImage: {},
  sidebarGenerateImage: "",
  thumbnailImage: {},
  selection: {},
  shouldReplace: {},
  publicStatus: {},
  publicUuid: {},
  language: {},
  tags: {},
  saveTrigger: false,
  updateThumbnail: false,
  voiceGeneratorText: "",
};

const FileSlice = createSlice({
  name: "File",
  initialState,
  reducers: {
    setFileText: (state, action: PayloadAction<{ uuid: string; content: string }>) => {
      const { uuid, content } = action.payload;
      state.fileText[uuid] = content;
    },
    setFileTitle: (state, action: PayloadAction<{ uuid: string; title: string }>) => {
      const { uuid, title } = action.payload;
      state.fileTitle[uuid] = title;
    },
    setSelection: (
      state,
      action: PayloadAction<{
        uuid: string;
        index: number;
        length: number;
        text: string;
      }>
    ) => {
      const { uuid, index, length, text } = action.payload;
      state.selection[uuid] = { index, length, text };
    },
    setUnsplash: (state, action: PayloadAction<{ uuid: string; url: string }>) => {
      const { uuid, url } = action.payload;
      state.unsplash[uuid] = url;
    },
    setGeneratedImage: (state, action: PayloadAction<{ uuid: string; url: string }>) => {
      const { uuid, url } = action.payload;
      state.generatedImage[uuid] = url;
    },
    setSidebarGenerateImage: (state, action: PayloadAction<string>) => {
      state.sidebarGenerateImage = action.payload;
    },
    setThumbnailImage: (state, action: PayloadAction<{ uuid: string; url: string }>) => {
      const { uuid, url } = action.payload;
      state.thumbnailImage[uuid] = url;
    },
    setReplacement: (
      state,
      action: PayloadAction<{
        uuid: string;
        index: number;
        length: number;
        text: string;
      }>
    ) => {
      const { uuid, index, length, text } = action.payload;
      state.selection[uuid] = { index, length, text };
    },
    setShouldReplace: (state, action: PayloadAction<{ uuid: string; shouldReplace: boolean }>) => {
      const { uuid, shouldReplace } = action.payload;
      state.shouldReplace[uuid] = shouldReplace;
    },
    setPublicStatus: (state, action: PayloadAction<{ uuid: string; status: number }>) => {
      const { uuid, status } = action.payload;
      state.publicStatus[uuid] = status;
    },
    setPublicUuid: (state, action: PayloadAction<{ uuid: string; publicUuid: string }>) => {
      const { uuid, publicUuid } = action.payload;
      state.publicUuid[uuid] = publicUuid;
    },
    setLanguage: (state, action: PayloadAction<{ uuid: string; language: string }>) => {
      const { uuid, language } = action.payload;
      state.language[uuid] = language;
    },
    setTags: (state, action: PayloadAction<{ uuid: string; tags: string[] }>) => {
      const { uuid, tags } = action.payload;
      state.tags[uuid] = tags;
    },
    setSaveTrigger: (state, action: PayloadAction<boolean>) => {
      state.saveTrigger = action.payload;
    },
    setUpdateThumbnail: (state, action: PayloadAction<boolean>) => {
      state.updateThumbnail = action.payload;
    },
    setVoiceGeneratorText: (state, action: PayloadAction<string>) => {
      state.voiceGeneratorText = action.payload;
    },
  },
});

export const {
  setFileText,
  setFileTitle,
  setSelection,
  setUnsplash,
  setGeneratedImage,
  setThumbnailImage,
  setReplacement,
  setShouldReplace,
  setPublicStatus,
  setPublicUuid,
  setLanguage,
  setTags,
  setSaveTrigger,
  setUpdateThumbnail,
  setSidebarGenerateImage,
  setVoiceGeneratorText,
} = FileSlice.actions;

export const FileReducer = FileSlice.reducer;

const pricing = {
  title: "Plans and Pricing",
  pricing: "PRICING",
  description: "Plans and pricing of Tenak AI",
  legalNotice: "Act on specified commercial transactions.",
  free: {
    title: "FREE",
    description: "Free Plan",
    price: "0",
    credit: "50",
  },
  basic: {
    title: "Basic",
    description: "Standard Plan",
    price: "18",
    credit: "900",
  },
  standard: {
    title: "Standard",
    description: "Business Plan",
    price: "36",
    credit: "1,800 + 200",
  },
  premium: {
    title: "Premium",
    description: "Premium Plan",
    price: "72",
    credit: "3,600 + 600",
  },
  perMonth: "Per Month",
  credit: "Credits",
  firstTimeOnly: "(First time only)",
  dailyCredit: "Daily Bonus Credits",
  aiEngine: "Powerful AI Engine",
  juno: "Image Generation",
  junoPrivate: "Private Generated Images",
  junoEngine: "All AI Engines (Images)",
  junoEnginePart: "Part of AI Engines (Images)",
  junoVideo: "Video Generation",
  junoSimultaneousLimit: "STUDIO JUNO Request Limit",
  mindmap: "Mindmap Generation",
  export: "Export Mindmap",
  template: "All AI Templates",
  textEditor: "AI Text Editor",
  voice: "Voice Generation",
  additionalCredits: "Purchase Extra Credits",
  support: "Support",
  supportPriority: "Priority Support",
  supportNo: "No Support",
  subscribe: "Subscribe",
  aboutCredit: {
    title: "About Credits",
    description:
      "Tenak AI plans use a credit system. Credits are the currency used to access AI features such as text, image, and voice generation. Daily bonuses are not carried over. Below are the estimated credit consumptions.",
    text: {
      title: "Text Generation",
      "openai-gpt4o-input": "Intelligence Mode (Input)",
      "openai-gpt4o-output": "Intelligence Mode (Output)",
      "openai-gpt4o-mini-input": "Speed Mode (Input)",
      "openai-gpt4o-mini-output": "Speed Mode (Output)",
      "openai-o3-mini-input": "Reasoning Model - Pro Mode (Input)",
      "openai-o3-mini-output": "Reasoning Model - Pro Mode (Output, Reasoning Tokens)",
      note: "NOTE: Text generation includes tokens used for research, web search, system prompts, reasoning processing, etc.",
    },
    image: {
      title: "Image Generation",
      "openai-dalle3-hd": "OPEN AI: DALL-E 3",
      sdapi: "Stable Diffusion (XL)",
      sd3: "Stable Diffusion 3.5",
      "sd-ultra": "Stable Image Ultra",
      "flux11-pro-ultra": "Flux 1.1 Pro Ultra",
      "flux11-pro-ultra-juno": "Flux 1.1 Pro Ultra JUNO",
      "flux11-pro-ultra-redux": "Flux 1.1 Pro Ultra Redux",
      "flux11-pro": "Flux 1.1 Pro",
      "flux1-pro": "Flux 1 Pro",
      "flux1-pro-juno": "Flux 1 Pro JUNO",
      "flux1-pro-fill": "Flux.1 Pro Fill",
      "flux1-pro-fill-juno": "Flux.1 Pro Fill JUNO",
      "flux11-pro-redux": "Flux 1 Pro Redux",
      "flux1-pro-canny": "Flux 1 Pro Canny",
      "flux1-pro-canny-juno": "Flux 1 Pro Canny JUNO",
      "flux1-pro-depth": "Flux 1 Pro Depth",
      "flux1-pro-depth-juno": "Flux 1 Pro Depth JUNO",
      "flux1-dev": "Flux.1 Dev",
      "flux1-dev-redux": "Flux 1 Dev Redux",
      "flux1-dev-fill": "Flux.1 Dev Fill",
      "flux1-dev-canny-lora": "Flux 1 Dev Canny",
      "flux1-dev-depth-lora": "Flux 1 Dev Depth",
      "flux1-schnell": "Flux.1 Schnell",
      "flux1-schnell-redux": "Flux 1 Schnell Redux",
      "flux1-realism-lora": "Flux.1 with Realism LoRA",
      "flux-juno": "Flux.1 Dev JUNO",
      "flux-palid": "Flux PuLID",
      "recraft-v3": "Recraft V3",
      "kolors-vto": "Kolors Vitrual Try-On",
      "lumina-v2": "Lumina Image v2",
      "ideogram-v2a": "Ideogram 2a",
      "ideogram-v2a-turbo": "Ideogram 2a Turbo",
      "ideogram-v2-edit": "Ideogram V2 Edit",
      "ideogram-v2-turbo-edit": "Ideogram V2 Turbo Edit",
      imagen3: "Imagen 3",
      "imagen3-fast": "Imagen 3 Fast",
      "minimax-image": "Minimax Image",
      "flux-juggernaut-base": "Juggernaut Flux Base",
      "flux-juggernaut-lightning": "Juggernaut Flux Lightning",
      "flux-juggernaut-pro": "Juggernaut Flux Pro",
      "flux-juggernaut-juno": "Juggernaut Flux Juno",
      "fal-aura-sr": "Upscaler AuraSR",
      "fal-clarity-upscaler": "Upscaler Clarity Upscaler",
      "fal-creative-upscaler": "Upscaler Creative Upscaler",
      "fal-recraft-creative-upscaler": "Recraft Creative Upscaler",
      "fal-recraft-clarity-upscaler": "Recraft Clarity Upscaler",
      "fal-bytedance-latentsync": "ByteDance LatentSync",
      "fal-sync-lipsync-19": "Sync Lipsync 1.9.0",
      "fal-video-upscaler": "RealESRGAN Video Upscaler",
      "bria-background-replace": "Bria Background Replace",
      "bria-product-shot": "Bria Product Shot",
      "iclight-v2": "IC-Light V2",
      "gemini-flash-edit": "Gemini Flash Edit",
    },
    video: {
      title: "Video Generation",
      "runway-gen3a-turbo": "Runway Gen3 Alpha Turbo",
      "fal-kling-v1.0-standard": "Kling v1.0 Standard",
      "fal-kling-v1.6-standard": "Kling v1.6 Standard",
      "fal-kling-v1.0-pro": "Kling v1.0 Pro",
      "fal-kling-v1.5-pro": "Kling v1.5 Pro",
      "fal-kling-v1.6-pro": "Kling v1.6 Pro",
      "fal-luma-dream-machine-v1.5": "v1.5",
      "fal-luma-dream-machine-ray2": "RAY 2",
      "fal-luma-dream-machine-ray2-1080p": "RAY 2 1080P",
      "fal-luma-dream-machine-ray-2-flash": "RAY 2 Flash",
      "fal-luma-dream-machine-ray-2-flash-1080p": "RAY 2 Flash 1080P",
      "fal-hailuo-mini-max": "Hailuo AI",
      "fal-haiper-v2.0": "Haiper v2.0",
      "fal-haiper-v2.5": "Haiper v2.5",
      "fal-pixverse-v3.5-720p": "PixVerse v3.5",
      "fal-pixverse-v3.5-1080p": "PixVerse v3.5 1080p",
      "fal-pixverse-v3.5-fast-720p": "PixVerse v3.5 Performance",
      "vidu-2.0-720p": "Vidu 2.0 720p",
      "vidu-2.0-1080p": "Vidu 2.0 1080p",
      "vidu-2.0-ref-720p": "Vidu 2.0 Reference",
      "fal-google-veo2": "Google VEO2",
      "fal-wan": "Wan v2.1",
      "fal-wan-pro": "Wan Pro v2.1",
      "fal-pika-v2.2-720p": "Pika v2.2 720P",
      "fal-pika-v2.2-1080p": "Pika v2.2 1080P",
      "fal-pika-v2.2-scenes-720p": "Pika v2.2 Scenes 720P",
      "fal-pika-v2.2-scenes-1080p": "Pika v2.2 Scenes 1080P",
      "fal-pika-v2-turbo-720p": "Pika v2 Turbo 720P",
      "fal-real-esrgan": "RealESRGAN Video Upscaler",
      "fal-topaz-video-upscale": "Topaz Video Upscale",
    },
    voice: {
      title: "Voice Generation",
      "google-tts": "Google TTS (1,000 characters)",
      "gpt-4o-mini-tts": "OPEN AI TTS (1,000 tokens)",
    },
    mindmap: {
      title: "Mindmap Generation",
      description: "When not using AI (depends on text generation when using AI generator)",
    },
    annotation:
      "* The above is a rough estimate and may vary depending on the content and usage. * The calculation will be rounded up to the second decimal place.",
    usageExamples: {
      title: "What Can You Do with Credits?",
      description:
        "Here's an estimate of what you can do with credits on each plan. The range varies depending on the AI model used.",
      category: "Category",
      basic: "Basic (900 credits)",
      standard: "Standard (2000 credits)",
      premium: "Premium (4200 credits)",
      textRange: "~ {{max}}M tokens",
      imageRange: "~ {{max}} images",
      videoRange: "~ {{max}} seconds",
      voiceRange: "~ {{max}}k characters",
      note: "* This is an estimate and may vary depending on the specific AI models and content.",
    },
  },
  tabs: {
    subscriptionPlans: "Individual Plans",
    businessPlans: "Business Plans",
    permanentCredits: "Extra Credits",
  },
  permanentCredit: {
    title: "Extra Credits",
    description:
      "Users with a standard plan or higher can purchase credits that can be used indefinitely. \nThe cost is higher than regular credits, but you can use them anytime with peace of mind.",
    credits: "credits",
    unitPrice: "Unit Price",
    neverExpire: "Never Expires",
    purchase: "Purchase",
    upgradeRequired: "Standard or higher plan is required to purchase Extra Credits.",
    discountRateStandard: "",
    discountRatePremium: "(Discounted)",
    modalTitle: "Purchase Extra Credits",
    modalDescription: "Extra Credits never expire. Once purchased, these credits can be used indefinitely.",
    modalCaution1: "Extra Credits will be added to your account immediately after purchase.",
    modalCaution2: "Extra Credits are non-refundable.",
    modalCaution3: "Please review the Terms of Service and Privacy Policy before purchasing.",
    modalProduct: "Product",
    modalCredits: "Credits",
    modalPrice: "Price",
    forStandardUsers: "For Standard Users",
    forPremiumUsers: "For Premium Users",
  },
  faq: {
    title: "Frequently Asked Questions",
    description: "Common questions about Tenak AIs plans and pricing",
    q1: "Can credits be carried over?",
    a1: "No. Credits other than 'Permanent Extra Credits' cannot be carried over.",
    q2: "If I upgrade, will the fee be prorated?",
    a2: "No. Since we use a credit system, the full amount will be charged. Then, the credits you have and the credits from the new plan will be combined, and one month from that day will be the expiration date for the credits and also the next billing date.",
    q3: "Can I purchase credits only?",
    a3: "Yes. Users with a Standard plan or higher can purchase 'Permanent Extra Credits'.",
    q4: "What happens to my credits if I cancel the plan?",
    a4: "If you cancel the plan, your credits will expire at the renewal month.",
    q5: "What should I do if I run out of credits?",
    a5: "If you run out of credits, please upgrade your plan or purchase Extra Credits.",
    q6: "When is the daily bonus reset?",
    a6: "It will be reset when you log in after 24 hours from the last receipt.",
    q7: "Can I downgrade the plan?",
    a7: "No. You will need to cancel the plan once and purchase the plan again after the period expires.",
  },
};
export default pricing;

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Fade } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ProPlanModal from "../../../../components/common/ProPlanModal";
import { useAlert } from "../../../../context/AlertContext";
import { useFileSidebarContext } from "../../../../context/FileSidebarContext";
import { ParamsProvider, useParamsContext } from "../../../../context/juno/ParamsContext";
import { useCheckCredit } from "../../../../hooks/useCreditCheck";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import { setSaveTrigger, setVoiceGeneratorText } from "../../../../redux/slices/fileSlice";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import { GradationButton } from "../../../../utils/gradationButton";
import { streamStop } from "../../../../utils/stream_stop";
import { RowCenteredBox } from "../../../../utils/styledBox";

function GenerateButtonInner({ handleCreate }: { handleCreate: () => void }) {
  const { t } = useTranslation();
  const { fileUuid } = useParams();
  const context = useFileSidebarContext();
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const { checkCredit } = useCheckCredit();
  const [openProDialog, setOpenProDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [generatingImage, setGeneratingImage] = React.useState(false);

  const handleNavigate = () => {
    dispatch(setSaveTrigger(true));
    navigate("/juno/voice-generator");
    dispatch(setVoiceGeneratorText(context.originalText));
  };
  const label = () => {
    return context.selectedButton === "createImages"
      ? t("textEditor.sidebar.generateImage")
      : t("textEditor.sidebar.generate");
  };

  const params = useParamsContext();

  // 画像生成APIへのリクエスト
  const handleGenerateImage = async (request_uuid?: string | null) => {
    setGeneratingImage(true);
    try {
      if (!(await checkCredit())) return;
      if (context.originalText === "") {
        setAlert("error", "Please input text.");
        return;
      }

      const url = "/api/v1/juno/generate";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      const default_data = {
        action: "txt2img",
        prompt: context.originalText,
        aiEngine: context.aiEngine,
        requestUuid: request_uuid,
        isPublic: true,
        needFixPrompt: true, // 常にtrueに固定
      };

      const allParams = params.getAllParams(context.aiEngine);
      allParams["aspectRatio"] = "16:9";

      const data = { ...default_data, ...allParams };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ data }),
      });
      await response.json().then((res) => {
        if (res.status === "success") {
          context.setGeneratedImageURL(res.url);
          dispatch(setCreditTrigger(true));
          setGeneratingImage(false);
        } else if (res.status === "temporary_success") {
          handleGenerateImage(res.request_uuid);
        } else {
          if (res.message === "NO_CREDITS") {
            setDialogMessage(t("juno.error.noCredits"));
            setOpenProDialog(true);
          } else if (res.message === "UPGRADE_PLAN") {
            setDialogMessage(t("juno.error.upgrade"));
            setOpenProDialog(true);
          } else {
            setAlert("error", res.message);
          }
        }
      });
    } catch {
      setAlert("error", "Something went wrong. Please try again.");
    }
  };

  return (
    // Generate Button
    <>
      <RowCenteredBox>
        {context.selectedButton === "createVoice" ? (
          <Button disableElevation variant={"outlined"} sx={{ minWidth: "211px", py: 1 }} onClick={handleNavigate}>
            {t("textEditor.sidebar.createVoiceButton")}
            <ExitToAppIcon fontSize={"small"} sx={{ ml: 1 }} />
          </Button>
        ) : context.selectedButton === "createImages" ? (
          <GradationButton
            onClick={() => {
              if (!generatingImage) {
                handleGenerateImage();
              }
            }}
            sx={{ minWidth: "211px", py: 1 }}
            disabled={generatingImage}
          >
            {generatingImage ? (
              <Fade in={generatingImage} timeout={500}>
                <RowCenteredBox>
                  <CircularProgress size={20} sx={{ mr: 1, ml: -2, color: "white" }} />
                  <Typography variant={"button"}>{t("common.generating")}</Typography>
                </RowCenteredBox>
              </Fade>
            ) : (
              <Fade in={!generatingImage} timeout={500}>
                <RowCenteredBox>
                  <KeyboardDoubleArrowDownIcon fontSize={"small"} sx={{ mr: 1 }} />
                  <Typography variant={"button"}>{t("textEditor.sidebar.generateImage")}</Typography>
                </RowCenteredBox>
              </Fade>
            )}
          </GradationButton>
        ) : (
          <GradationButton
            onClick={() => {
              if (context.processing) {
                streamStop(fileUuid!);
              } else {
                handleCreate();
              }
            }}
            sx={{ minWidth: "211px", py: 1 }}
          >
            {context.processing ? (
              <Fade in={context.processing} timeout={500}>
                <RowCenteredBox>
                  <CircularProgress size={20} sx={{ mr: 1, ml: -2, color: "white" }} />
                  <Typography variant={"button"}>{t("browsing.stopGenerating")}</Typography>
                </RowCenteredBox>
              </Fade>
            ) : (
              <Fade in={!context.processing} timeout={500}>
                <RowCenteredBox>
                  <KeyboardDoubleArrowDownIcon fontSize={"small"} sx={{ mr: 1 }} />
                  <Typography variant={"button"}>{label()}</Typography>
                </RowCenteredBox>
              </Fade>
            )}
          </GradationButton>
        )}
      </RowCenteredBox>
      <ProPlanModal open={openProDialog} setOpen={setOpenProDialog} message={dialogMessage} />
    </>
  );
}

export default function GenerateButton(props: { handleCreate: () => void }) {
  return (
    <ParamsProvider>
      <GenerateButtonInner {...props} />
    </ParamsProvider>
  );
}

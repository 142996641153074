import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import { InitialLatentEnum } from "../../../../../types/junoTypes";

const InitialLatentSelection: React.FC = () => {
  const { t } = useTranslation();
  const { aiEngine, initialLatent, setInitialLatent } = useParamsContext();
  const [selectedInitialLatent, setSelectedInitialLatent] = useState<InitialLatentEnum>("None");

  // 初期値の設定
  useEffect(() => {
    if (aiEngine === "IcLightV2") {
      const currentSelection = initialLatent[aiEngine];
      if (currentSelection) {
        setSelectedInitialLatent(currentSelection as InitialLatentEnum);
      } else {
        // デフォルト値
        setSelectedInitialLatent("None");
        setInitialLatent({
          ...initialLatent,
          [aiEngine]: "None",
        });
      }
    }
  }, [aiEngine, initialLatent, setInitialLatent]);

  // IcLightV2モデルの場合のみ表示
  if (aiEngine !== "IcLightV2") {
    return null;
  }

  // 選択肢の変更ハンドラ
  const handleChange = (value: InitialLatentEnum) => {
    setSelectedInitialLatent(value);
    setInitialLatent({
      ...initialLatent,
      [aiEngine]: value,
    });
  };

  const initialLatentOptions: InitialLatentEnum[] = ["None", "Left", "Right", "Top", "Bottom"];

  return (
    <Box sx={{ mb: 2 }}>
      <FormControl fullWidth variant="standard">
        <Typography variant="body2" mb={1}>
          {t("juno.input.initialLatent.title")}
        </Typography>
        <Select
          value={selectedInitialLatent}
          onChange={(e) => handleChange(e.target.value as InitialLatentEnum)}
          size="small"
        >
          {initialLatentOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {t(`juno.input.initialLatent.${option}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default InitialLatentSelection;

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import { useEditorContext } from "../../../../../context/juno/EditorContext";

// objectURLかどうかを判定（blobで始まるURLかどうか）
const isBlobUrl = (url: string) => url.startsWith("blob:");

const ImageLayers = () => {
  const { images, setImages } = useEditorContext();

  const deleteImage = (id: string) => {
    // 画像を削除する前に、BlobURLかどうかを確認
    const image = images.find((image) => image.id === id);
    const isBlobUrl = image && image.image.src.startsWith("blob:");

    // 画像を削除
    setImages((prev) => prev.filter((image) => image.id !== id));

    // BlobURLの場合、明示的に解放
    if (image && isBlobUrl) {
      URL.revokeObjectURL(image.image.src);
    }
  };

  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      {images.map((image) => {
        const imageSrc = image.image.src;

        return (
          <Box
            key={image.id}
            sx={{
              width: 100,
              height: 100,
              border: 2,
              borderRadius: 2,
              mb: 1,
              position: "relative",
              overflow: "hidden", // 画像がはみ出ないように
            }}
          >
            {/* img要素を使用して画像を表示 */}
            <img
              src={imageSrc}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              // blobURL以外の場合はcrossOrigin属性を設定
              {...(isBlobUrl(imageSrc) ? {} : { crossOrigin: "anonymous" })}
            />

            <IconButton
              sx={{
                position: "absolute",
                top: 2,
                right: 2,
                color: "white",
                backgroundColor: "rgba(0,0,0,0.3)", // 背景を少し暗くして見やすく
                padding: "2px",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.5)",
                },
              }}
              onClick={() => deleteImage(image.id)}
              size="small"
            >
              <CloseIcon fontSize={"small"} />
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
};

export default ImageLayers;

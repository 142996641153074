import junoImageEngine from "./junoImageEngine";
import junoPreset from "./junoPreset";
import junoStyle from "./junoStyle";
import junoVideo from "./junoVideo";

const juno = {
  title: "Studio Juno",
  description: "様々なモデルが扱える動画・画像生成AI",
  explore: "探検",
  goToGenerator: "画像生成",
  goToVideoGenerator: "動画生成",
  goToVoiceGenerator: "音声生成",
  models: "モデルリスト",
  myGallery: "マイイメージ",
  videos: "マイビデオ",
  likes: "Likes",
  searchResult: "検索結果：",
  noResult: "検索結果が見つかりませんでした。（正しい英語で入力しているか確認してください）",
  menu: {
    title: "MENU",
    txt2img: "テキストから\n画像",
    img2img: "画像から\n画像",
    upscale: "アップスケール",
    editor: "イメージエディタ",
  },
  input: {
    engine: junoImageEngine,
    category: {
      juno: "STUDIO JUNO オリジナルモデルです。当社のAI技術を活かし、画像生成の幅を広げます。",
      vary: "バリエーション生成モデル: 元画像から画像を再現し、画像の洗練を可能にします。画像の再スタイリング、プロンプト（一部モデルのみ）に合わせたバリエーションを生成",
      twoK: "2Kモデル: 高画質な2K解像度（4MP）の画像生成が可能",
      vector: "ベクターモデル: ベクターイラストを生成にも対応",
      controlNet:
        "ControlNet: 画像生成の制御が可能。元画像からCannyはエッジ検出、Depthは奥行きを生成し、それを下に新たな画像を生成",
      editor: "エディタモデル: 画像を編集し、新たな画像を生成。インペイント、アウトペイントに対応",
    },
    aspectRatio: {
      title: "アスペクト比",
      custom: "カスタム",
    },
    width: "横幅",
    height: "縦幅",
    step: "ステップ数",
    sample: "生成画像数",
    sampleDescription:
      "'横幅'、または'縦幅'が {{px}}px を超える場合、生成される枚数は1枚に制限されます。複数枚生成したい場合、{{px}}px 未満で生成してから、アップスケールすることを推奨します。",
    rawMode: "RAWモード",
    rawModeDescription:
      "人工的ではなく、より自然な美しさを持つ画像が生成されます。他のテキストから画像へのモデルと比較して、RAWモードは人間の被写体の多様性を大幅に高め、自然の写真のリアリティを高めます。",
    resolution: "高解像度の補正（自動適応）",
    resolutionCaution: "生成枚数が1枚に制限されます。",
    resolutionTooltip:
      "高解像度の補正を適用すると、生成された画像の解像度が向上します。横幅か縦幅の何れかが {{px}}px 以上の場合、自動的に適応されます。",
    guidanceScale: "プロンプトの重視度",
    seedSD: "シード値（同モデルのみ有効）",
    dallE3RevisedPrompt: "プロンプトをAI拡張",
    colorSelection: {
      title: "カラーパッレット",
    },
    style: junoStyle,
    basicSettings: "基本設定",
    advancedSettings: "詳細設定",
    negativePrompt: "ネガティブプロンプト",
    negativePromptHistory: "ネガティブプロンプト",
    negativePromptPlaceholder: "例: worst quality, nsfw, bad anatomy, out of focus",
    negativePromptDescription: "画像に含みたくない要素を指定してください。",
    negativePromptAI: "ネガティブプロンプトをAIに生成させる",
    negativePromptTitle: "プリセット名",
    negativePromptSave: "ネガティブプロンプトを保存します。",
    negativePromptList: "保存済みネガティブプロンプト",
    delete: {
      title: "ネガティブプロンプトを削除しますか？",
      description: "保存済みのネガティブプロンプトを削除します。この操作は取り消せません。",
      success: "ネガティブプロンプトが削除されました。",
    },
    systemPrompt: "システムプロンプト",
    systemPromptPlaceholder: "例: あなたはプロフォトグラファーです",
    image: "元画像",
    imageFace: "元画像（顔）",
    imageHuman: "元画像（人物）",
    imageVTO: "元画像（衣服）",
    imageMain: "メイン画像",
    imageBackground: "背景画像",
    imageProduct: "商品画像",
    imageStrength: "元画像の影響度",
    manualPlacementSelection: {
      title: "配置位置",
      original: "オリジナル",
      upper_left: "左上",
      upper_right: "右上",
      bottom_left: "左下",
      bottom_right: "右下",
      right_center: "右中央",
      left_center: "左中央",
      upper_center: "上中央",
      bottom_center: "下中央",
      center_vertical: "中央垂直",
      center_horizontal: "中央水平",
    },
    initialLatent: {
      title: "照明位置",
      None: "AUTO",
      Left: "左",
      Right: "右",
      Top: "上",
      Bottom: "下",
    },
    imageEditor: {
      title: "イメージエディタ",
      mask: "マスク",
      original: "オリジナル",
      open: "エディタを開く",
      openImage: "詳細画像",
      openImageDescription: "画像エディタから自動的にセットされます",
      save: "完了",
      brushSize: "マスクブラシサイズ",
      noImage: "最低1枚の画像をアップロードしてください。",
      reset: "全てをリセット",
      aspectRatio: "生成する画像のサイズ",
      eraser: "消しゴム",
      eraserSize: "消しゴムサイズ",
      select: "画像選択ツール: 移動、拡大、縮小\n（画像選択中にDELETEキーで削除）",
      upload: "画像をアップロード",
      generated: "生成画像からアップロード",
      unsplash: "Unsplashから画像を追加",
      description: "はじめに画像を挿入して下さい。余白とブラシ描画部分に新しい画像が生成されます。",
    },
    idWeight: "顔の特徴の影響度",
    alert: {
      imageType: "ファイルはJPGまたはPNG形式である必要があります。",
      imageSize: "ファイルサイズは10MB以下である必要があります。",
    },
    scale: "スケール",
    scaleInfo: "アップスケール後の拡大倍率",
    creativity: "創造性",
    creativityInfo: "アップスケール時、どの程度の創造性を許容するか指定できます。",
    detail: "テクスチャ",
    detailInfo: "アップスケール時、ディテールの強さを指定できます。",
    resemblance: "元画像の維持度",
    resemblanceInfo: "アップスケールで、どの程度元の画像に寄せるかを指定できます。",
    uploadSuccess: "画像がアップロードされました。",
    uploadError: {
      size: "ファイルサイズは {{size}}MB 以下である必要があります。",
      type: "ファイルは JPG または PNG 形式である必要があります。",
      scale: "画像の長辺は 2048px 以下である必要があります。",
    },
    filtered: "BLOCKED",
    filteredDescription: "ポリシーに違反している可能性があるため、表示されません。",
    show: "表示中 - ポリシーに違反している可能性がある画像",
    hide: "非表示中 - ポリシーに違反している可能性がある画像",
    publicStatus: "公開設定",
    publicDescription:
      "非公開設定はStandardプラン以上で利用可能です。公開を指定していても、非公開に変更される場合があります。",
    public: "公開",
    private: "非公開",
    warning: "※ ポリシーに反する画像は生成できず、その際のクレジットは保証されません。",
    submit: "画像を生成",
  },
  filterSettings: "フィルター設定",
  enableFilter: "フィルターを有効にする",
  filter: {
    description: "Level 2.0 以上の画像にぼかしフィルターが適用されます。",
  },
  safetyLevel: {
    title: "セーフティレベル",
    description: "高いレベルを選択すると、より多くのコンテンツが表示されます。（マイイメージには適応されません）",
  },

  history: {
    title: "最新の10件",
    input: "テキストを入力",
    dice: "【ランダムプロンプト生成】画像生成用のテキストのサンプルをランダムで生成します。　　※ 不正行為防止の為、0.1〜0.2クレジットを消費します。",
    improve:
      "【プロンプトの強化】入力されたテキスト（キーワード）からプロンプトを生成。また、英語に翻訳。　　※ 不正行為防止の為、0.1〜0.2クレジットを消費します。",
    prompt: "プロンプト",
    size: "サイズ",
    upscale: "アップスケール",
    edit: "編集",
    img2img: "画像から画像",
    img2vid: "画像から動画",
    useParams: "再生成",
    download: "ダウンロード",
    generating: "Generating Images",
    generatingDescription: "画像生成中です。しばらくお待ちください。\nページを離れても生成は続行されます。",
    delete: {
      title: "データを削除しますか？",
      description: "データを削除すると、元に戻すことはできません。",
      success: "データが削除されました。",
    },
    optionalPromptPlaceholder: "画像のプロンプトを入力してください（オプション）",
    notNeededPlaceholder: "画像のプロンプトは不要です",
    promptPlaceholder: "画像のプロンプトを入力してください（英語推奨）",
  },
  modelList: {
    title: "モデルリスト",
    description: "STUDIO JUNO は、画像生成のためのさまざまなモデルを提供しています。",
  },
  video: junoVideo,
  inputPreset: junoPreset,
  cameraMovementPreset: {
    icon: "カメラ操作プリセット",
    description: "クリックでカメラ操作をプロンプトに追加します。",
    title: "カメラ軌道",
    warning: "*モデルにより、カメラ操作が反映されない場合があります。",
    movement: {
      // Ray2 movements
      panleft: "左パン",
      panright: "右パン",
      orbitleft: "オービットレフト",
      orbitright: "オービットライト",
      craneup: "クレーンアップ",
      cranedown: "クレーンダウン",
      moveleft: "左移動",
      moveright: "右移動",
      moveup: "上移動",
      movedown: "下移動",
      pushin: "プッシュイン",
      pullout: "プルアウト",

      // Hailuo movements
      leftcirclingshot: "左回り込み",
      rightcirclingshot: "右回り込み",
      leftwalkingshot: "左歩き",
      rightwalkingshot: "右歩き",
      upwardtilt: "上向き傾け前進",
      downwardtilt: "下降上向き傾け",
      scenicshot: "風景ショット",
      stageshotright: "右パンズームイン",
      stageshotleft: "左パンズームイン",
      zoomin: "ズームイン",
      zoomout: "ズームアウト",
      tiltup: "チルトアップ",
      tiltdown: "チルトダウン",
      truckleft: "左トラック",
      truckright: "右トラック",
      pedestalup: "ペデスタルアップ",
      pedestaldown: "ペデスタルダウン",
      trackingshot: "トラッキングショット",
      shake: "シェイク",
      staticshot: "静止ショット",
    },
  },
  message: {
    delete: "画像が削除されました。",
  },
  error: {
    noCredit: "クレジットが不足しています。",
    upgrade: "このモデルを使用するには「ベーシックプラン」以上が必要です。",
    processingLimit:
      "処理中のリクエスト数が上限に達しています。現在{{current}}件のリクエスト（画像と動画の合計）が処理中です。あなたのプランでは最大{{limit}}件の同時リクエストが可能です。",
  },
  validation: {
    model: "モデルを選択してください。",
    image: "画像をアップロードしてください。",
    prompt: "1,000文字以内でプロンプトを入力してください。",
    negativePrompt: "1,000文字以内でネガティブプロンプトを入力してください。",
    editorImage:
      "編集画像、もしくはマスク画像がセットされていません。エディターを開いて必ず「完了」を押してセットしてください",
  },
  selection: {
    // 注目
    featured: "注目",
    new: "新モデル",
    popularModel: "人気モデル",
    highResolutionModel: "高解像度モデル",
    lowCostModel: "低コストモデル",
    highPrecisionModel: "高精度モデル",
    japanesePortrait: "日本人ポートレート",
    depthToImage: "深度から画像",
    outlineToImage: "アウトラインから画像",
    relighting: "照明変更",
    background: "背景変更",
    products: "商品画像",
    fixedPerson: "人物の固定",
    changeClothes: "衣服の変更",
    brush: "ブラシ",
  },
};
export default juno;

import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../context/AlertContext";
import { ColumnCenteredBox, RowCenteredBox } from "../../../utils/styledBox";

interface SupportFormModalProps {
  open: boolean;
  handleClose: () => void;
}

type ContactType = "QUESTION" | "PROBLEM" | "PAYMENT" | "REQUEST" | "OTHER";

const SupportFormModal = ({ open, handleClose }: SupportFormModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [contactType, setContactType] = useState<ContactType>("QUESTION");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { setAlert } = useAlert();
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    const validate = () => {
      return subject.length > 0 && message.length > 0;
    };
    setButtonDisabled(!validate());
  }, [subject, message]); // subject または message が変更されたときに実行

  const submit = async () => {
    setLoading(true);
    const csrftoken = Cookies.get("csrftoken");
    const config = { headers: { "X-CSRFToken": csrftoken } };
    const data = {
      contactType: contactType,
      subject: subject,
      message: message,
    };
    try {
      await axios.post("/api/v1/support/", data, config);
      setAlert("success", t("preferences.misc.contactModal.success"));
      setLoading(false);
      handleClose();
    } catch (error) {
      setAlert("error", t("preferences.misc.contactModal.error"));
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: { xs: 2, sm: 3, md: 4 },
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" component={"h3"} align="center" mb={2}>
            {t("preferences.misc.contactModal.title")}
          </Typography>

          <FormLabel>{t("preferences.misc.contactModal.type.description")}</FormLabel>
          <RadioGroup
            aria-label="contactType"
            name="contactType"
            value={contactType}
            onChange={(e) => setContactType(e.target.value as ContactType)}
            row
            sx={{ mb: 2 }}
          >
            <FormControlLabel
              value="QUESTION"
              control={<Radio />}
              label={t("preferences.misc.contactModal.type.question")}
            />
            <FormControlLabel
              value="PROBLEM"
              control={<Radio />}
              label={t("preferences.misc.contactModal.type.problem")}
            />
            <FormControlLabel
              value="PAYMENT"
              control={<Radio />}
              label={t("preferences.misc.contactModal.type.payment")}
            />
            <FormControlLabel
              value="REQUEST"
              control={<Radio />}
              label={t("preferences.misc.contactModal.type.request")}
            />
            <FormControlLabel value="OTHER" control={<Radio />} label={t("preferences.misc.contactModal.type.other")} />
          </RadioGroup>

          <ColumnCenteredBox>
            <TextField
              id="outlined-basic"
              required
              label={t("preferences.misc.contactModal.subject")}
              variant="outlined"
              fullWidth
              sx={{ mb: 1 }}
              onChange={(e) => setSubject(e.target.value)}
              inputProps={{ maxLength: 200 }}
            />
            <Typography variant="body2" color="textSecondary" align="right" sx={{ mb: 2, width: "100%" }}>
              {subject.length}/200
            </Typography>
            <TextField
              id="outlined-multiline-static"
              required
              label={t("preferences.misc.contactModal.message")}
              multiline
              rows={8}
              fullWidth
              variant="outlined"
              onChange={(e) => setMessage(e.target.value)}
              inputProps={{ maxLength: 2000 }}
              sx={{ mb: 1 }}
            />
            <Typography variant="body2" color="textSecondary" align="right" sx={{ mb: 1, width: "100%" }}>
              {message.length}/2000
            </Typography>
            <Typography variant="caption" color="warning.main" sx={{ mb: 4 }}>
              {t("preferences.misc.contactModal.warn")}
            </Typography>

            <RowCenteredBox gap={2}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon />}
                onClick={handleClose}
                sx={{ width: 200 }}
              >
                {t("common.close")}
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                startIcon={<SendIcon />}
                onClick={submit}
                disabled={loading || isButtonDisabled}
                sx={{ width: 200 }}
              >
                {t("preferences.misc.contactModal.send")}
              </Button>
            </RowCenteredBox>
          </ColumnCenteredBox>
        </Box>
      </Modal>
    </>
  );
};

export default SupportFormModal;

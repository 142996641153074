// ParamsContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { useJunoModels } from "../../hooks/useJunoModels";
import {
  AiEngineList,
  AiEngineNumber,
  AiEngineString,
  AspectRatios,
  MenuList,
  RecraftColors,
  Style,
} from "../../types/junoTypes";

interface ParamsContextType {
  menu: MenuList;
  setMenu: React.Dispatch<React.SetStateAction<MenuList>>;
  systemPrompt: string;
  setSystemPrompt: React.Dispatch<React.SetStateAction<string>>;
  aiEngine: AiEngineList;
  setAiEngine: React.Dispatch<React.SetStateAction<AiEngineList>>;
  imageCredit: number;
  setImageCredit: React.Dispatch<React.SetStateAction<number>>;
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  negativePrompt: string;
  setNegativePrompt: React.Dispatch<React.SetStateAction<string>>;
  revisedPrompt: boolean;
  setRevisedPrompt: React.Dispatch<React.SetStateAction<boolean>>;
  style: Style;
  setStyle: React.Dispatch<React.SetStateAction<Style>>;
  styleWeight: AiEngineNumber;
  setStyleWeight: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  width: AiEngineNumber;
  setWidthPartial: (key: AiEngineList, value: number) => void;
  height: AiEngineNumber;
  setHeightPartial: (key: AiEngineList, value: number) => void;
  aspectRatio: AspectRatios;
  setAspectRatio: React.Dispatch<React.SetStateAction<AspectRatios>>;
  guidanceScale: AiEngineNumber;
  setGuidanceScale: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  step: AiEngineNumber;
  setStep: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  sample: AiEngineNumber;
  setSample: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  seed: AiEngineNumber;
  setSeed: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  isPublic: boolean;
  setIsPublic: React.Dispatch<React.SetStateAction<boolean>>;
  colorsRecraft: RecraftColors[];
  setColorsRecraft: React.Dispatch<React.SetStateAction<RecraftColors[]>>;
  rawMode: boolean;
  setRawMode: React.Dispatch<React.SetStateAction<boolean>>;
  manualPlacementSelection: AiEngineString;
  setManualPlacementSelection: React.Dispatch<React.SetStateAction<AiEngineString>>;
  initialLatent: AiEngineString;
  setInitialLatent: React.Dispatch<React.SetStateAction<AiEngineString>>;
  sampleLimit: AiEngineNumber;
  fetchingCredit: boolean;
  getAllParams: (engine?: AiEngineList) => {
    width: number;
    height: number;
    negativePrompt: string;
    step: number | undefined;
    sample: number;
    guidanceScale: number | undefined;
    seed: number | null;
    revisedPrompt: boolean;
    aspectRatio: string | undefined;
    style: string | undefined;
    styleWeight: number | undefined;
    rawMode?: boolean;
    colors: RecraftColors[];
    initImage?: undefined;
    imageStrength?: undefined;
    initImage2?: undefined;
    imageStrength2?: undefined;
    aiEngine: AiEngineList;
  };
}

const ParamsContext = createContext<ParamsContextType | undefined>(undefined);
export const ParamsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [menu, setMenu] = useState<MenuList>("txt2img");
  const [systemPrompt, setSystemPrompt] = useState<string>("");
  const [aiEngine, setAiEngine] = useState<AiEngineList>("Flux1Dev");
  const [imageCredit, setImageCredit] = useState<number>(0);
  const [fetchingCredit, setFetchingCredit] = useState<boolean>(false);

  const calculateImageCredit = async (engine: AiEngineList, sampleCount: number) => {
    try {
      setFetchingCredit(true);
      const response = await fetch(`/api/v1/juno/image-credit?aiEngine=${engine}&sample=${sampleCount}`);
      const data = await response.json();
      if (data.status === "success") {
        setImageCredit(data.credit);
        setFetchingCredit(false);
      }
    } catch (error) {
      console.error("Error calculating image credit:", error);
      setFetchingCredit(false);
    }
  };

  const [prompt, setPrompt] = useState<string>("");
  const [negativePrompt, setNegativePrompt] = useState<string>("");
  const [revisedPrompt, setRevisedPrompt] = useState<boolean>(true);
  const [style, setStyle] = useState<Style>({
    DallE3: "vivid",
    Ideogram2: "GENERAL",
    Ideogram2Turbo: "GENERAL",
    Ideogram2Edit: "GENERAL",
    IdeogramV2a: "GENERAL",
    IdeogramV2aTurbo: "GENERAL",
    IdeogramV2Edit: "GENERAL",
    IdeogramV2TurboEdit: "GENERAL",
    FluxJuno: "ANIME",
    Flux1DevCannyLoRA: "GENERAL",
    Flux1DevDepthLoRA: "GENERAL",
    Flux1DevFill: "GENERAL",
    Flux1ProJuno: "ANIME_PRO",
    Flux11ProUltraJuno: "ANIME_PRO",
    Flux1ProCannyJuno: "ANIME_PRO",
    Flux1ProDepthJuno: "ANIME_PRO",
    Flux1ProFillJuno: "ANIME_PRO",
    FluxJuggernautJuno: "ANIME",
    RecraftV3: "any",
  });
  const [styleWeight, setStyleWeight] = useState<AiEngineNumber>({
    FluxJuno: 0.8,
    Flux1DevCannyLoRA: 0.8,
    Flux1DevDepthLoRA: 0.8,
    Flux1DevFill: 0.8,
    Flux1ProJuno: 0.8,
    Flux11ProUltraJuno: 0.8,
    Flux1ProCannyJuno: 0.8,
    Flux1ProDepthJuno: 0.8,
    Flux1ProFillJuno: 0.8,
    FluxJuggernautJuno: 0.8,
  });

  const [width, setWidth] = useState<AiEngineNumber>({
    DallE3: 1024,
  });
  const [height, setHeight] = useState<AiEngineNumber>({
    DallE3: 1024,
  });
  const [aspectRatio, setAspectRatio] = useState<AspectRatios>({
    SD35: "1:1",
    SDUltra: "1:1",
    Flux11ProUltra: "1:1",
    Flux11Pro: "1:1",
    Flux1Dev: "1:1",
    Flux1Schnell: "1:1",
    Flux1RealismLoRA: "1:1",
    FluxJuno: "1:1",
    FluxPuLID: "1:1",
    Flux11ProUltraRedux: "1:1",
    Flux11ProRedux: "1:1",
    Flux1ProCanny: "1:1",
    Flux1ProDepth: "1:1",
    Flux1DevRedux: "1:1",
    Flux1DevCannyLoRA: "1:1",
    Flux1DevDepthLoRA: "1:1",
    Flux1SchnellRedux: "1:1",
    Flux1ProFill: "1:1",
    Flux1DevFill: "1:1",
    Flux1ProJuno: "1:1",
    Flux11ProUltraJuno: "1:1",
    Flux1ProCannyJuno: "1:1",
    Flux1ProDepthJuno: "1:1",
    Flux1ProFillJuno: "1:1",
    RecraftV3: "1:1",
    Ideogram2: "1:1",
    Ideogram2Turbo: "1:1",
    Ideogram2Edit: "1:1",
    IdeogramV2a: "1:1",
    IdeogramV2aTurbo: "1:1",
    IdeogramV2Edit: "1:1",
    IdeogramV2TurboEdit: "1:1",
    LuminaV2: "1:1",
    Imagen3: "1:1",
    Imagen3Fast: "1:1",
    MinimaxImage: "1:1",
    FluxJuggernautBase: "1:1",
    FluxJuggernautLightning: "1:1",
    FluxJuggernautPro: "1:1",
    FluxJuggernautJuno: "1:1",
    BriaBackgroundReplace: "1:1",
    BriaProductShot: "1:1",
    IcLightV2: "1:1",
  });
  const [guidanceScale, setGuidanceScale] = useState<AiEngineNumber>({
    Flux1Dev: 3.5,
    Flux1Schnell: 3.5,
    Flux1RealismLoRA: 3.5,
    FluxJuno: 3.5,
    FluxPuLID: 4,
    Flux11ProUltraRedux: 3.5,
    Flux11ProRedux: 3.5,
    Flux1ProCanny: 3.5,
    Flux1ProDepth: 3.5,
    Flux1DevRedux: 3.5,
    Flux1DevCannyLoRA: 30,
    Flux1DevDepthLoRA: 10,
    Flux1SchnellRedux: 3.5,
    Flux1DevFill: 3.5,
    Flux1ProJuno: 2.5,
    Flux1ProCannyJuno: 30,
    Flux1ProDepthJuno: 15,
    LuminaV2: 4,
    FluxJuggernautBase: 3.5,
    FluxJuggernautLightning: 3.5,
    FluxJuggernautJuno: 3.5,
    FluxJuggernautPro: 3.5,
    IcLightV2: 1,
  });
  const [step, setStep] = useState<AiEngineNumber>({
    Flux1Dev: 28,
    Flux1Schnell: 4,
    Flux1RealismLoRA: 28,
    FluxJuno: 28,
    FluxPuLID: 20,
    Flux1ProCanny: 28,
    Flux1ProDepth: 28,
    Flux1DevRedux: 28,
    Flux1DevCannyLoRA: 28,
    Flux1DevDepthLoRA: 28,
    Flux1SchnellRedux: 4,
    Flux1DevFill: 28,
    Flux1ProJuno: 28,
    Flux1ProCannyJuno: 28,
    Flux1ProDepthJuno: 28,
    LuminaV2: 30,
    FluxJuggernautBase: 28,
    FluxJuggernautLightning: 4,
    FluxJuggernautJuno: 28,
    FluxJuggernautPro: 28,
    IcLightV2: 28,
  });
  const [sample, setSample] = useState<AiEngineNumber>({
    DallE3: 1,
    SD35: 1,
    SDUltra: 1,
    Flux11ProUltra: 1,
    Flux11Pro: 1,
    Flux1Dev: 1,
    Flux1Schnell: 1,
    Flux1RealismLoRA: 1,
    FluxJuno: 1,
    FluxPuLID: 1,
    Flux11ProUltraRedux: 1,
    Flux11ProRedux: 1,
    Flux1ProCanny: 1,
    Flux1ProDepth: 1,
    Flux1DevRedux: 1,
    Flux1DevCannyLoRA: 1,
    Flux1DevDepthLoRA: 1,
    Flux1SchnellRedux: 1,
    Flux1ProFill: 1,
    Flux1DevFill: 1,
    Flux1ProJuno: 1,
    Flux11ProUltraJuno: 1,
    Flux1ProCannyJuno: 1,
    Flux1ProDepthJuno: 1,
    Flux1ProFillJuno: 1,
    Ideogram2: 1,
    Ideogram2Turbo: 1,
    Ideogram2Edit: 1,
    IdeogramV2a: 1,
    IdeogramV2aTurbo: 1,
    IdeogramV2Edit: 1,
    IdeogramV2TurboEdit: 1,
    LuminaV2: 1,
    Imagen3: 1,
    Imagen3Fast: 1,
    MinimaxImage: 1,
    FluxJuggernautBase: 1,
    FluxJuggernautLightning: 1,
    FluxJuggernautPro: 1,
    FluxJuggernautJuno: 1,
    BriaBackgroundReplace: 1,
    BriaProductShot: 1,
    IcLightV2: 1,
  });
  const [seed, setSeed] = useState<AiEngineNumber>({
    SD35: 0,
    SDUltra: 0,
    Flux11ProUltra: 0,
    Flux11Pro: 0,
    Flux1Dev: 0,
    Flux1Schnell: 0,
    Flux1RealismLoRA: 0,
    FluxJuno: 0,
    FluxPuLID: 0,
    Ideogram2: 0,
    Ideogram2Turbo: 0,
    Ideogram2Edit: 0,
    IdeogramV2a: 0,
    IdeogramV2aTurbo: 0,
    IdeogramV2Edit: 0,
    IdeogramV2TurboEdit: 0,
    Flux11ProUltraRedux: 0,
    Flux11ProRedux: 0,
    Flux1ProCanny: 0,
    Flux1ProDepth: 0,
    Flux1DevRedux: 0,
    Flux1DevCannyLoRA: 0,
    Flux1DevDepthLoRA: 0,
    Flux1SchnellRedux: 0,
    Flux1ProFill: 0,
    Flux1DevFill: 0,
    Flux1ProJuno: 0,
    Flux11ProUltraJuno: 0,
    Flux1ProCannyJuno: 0,
    Flux1ProDepthJuno: 0,
    Flux1ProFillJuno: 0,
    LuminaV2: 0,
    Imagen3: 0,
    Imagen3Fast: 0,
    MinimaxImage: 0,
    FluxJuggernautBase: 0,
    FluxJuggernautLightning: 0,
    FluxJuggernautPro: 0,
    FluxJuggernautJuno: 0,
    BriaBackgroundReplace: 0,
    BriaProductShot: 0,
    IcLightV2: 0,
  });
  const [isPublic, setIsPublic] = useState<boolean>(true);

  const setWidthPartial = (key: AiEngineList, value: number) => {
    setWidth((prev) => ({ ...prev, [key]: value }));
  };
  const setHeightPartial = (key: AiEngineList, value: number) => {
    setHeight((prev) => ({ ...prev, [key]: value }));
  };

  const [colorsRecraft, setColorsRecraft] = useState<RecraftColors[]>([]);
  const [rawMode, setRawMode] = useState<boolean>(false);
  const [manualPlacementSelection, setManualPlacementSelection] = useState<AiEngineString>({
    BriaProductShot: "bottom_center",
  });

  const [initialLatent, setInitialLatent] = useState<AiEngineString>({
    IcLightV2: "None",
  });

  const [sampleLimit] = useState<AiEngineNumber>({
    SD35: 1,
    SDUltra: 1,
    Flux11ProUltra: 1,
    Flux11Pro: 1,
    Flux1Dev: 4,
    Flux1Schnell: 4,
    Flux1RealismLoRA: 1,
    FluxJuno: 4,
    FluxPuLID: 1,
    Ideogram2: 4,
    Ideogram2Turbo: 4,
    IdeogramV2a: 4,
    IdeogramV2aTurbo: 4,
    IdeogramV2Edit: 4,
    IdeogramV2TurboEdit: 4,
    Flux11ProUltraRedux: 1,
    Flux11ProRedux: 1,
    Flux1ProCanny: 1,
    Flux1ProDepth: 1,
    Flux1DevRedux: 1,
    Flux1DevCannyLoRA: 4,
    Flux1DevDepthLoRA: 4,
    Flux1SchnellRedux: 4,
    Flux1ProFill: 1,
    Flux1DevFill: 4,
    LuminaV2: 4,
    Imagen3: 4,
    Imagen3Fast: 4,
    MinimaxImage: 4,
    FluxJuggernautBase: 4,
    FluxJuggernautLightning: 4,
    FluxJuggernautPro: 4,
    FluxJuggernautJuno: 4,
    BriaBackgroundReplace: 1,
    BriaProductShot: 1,
    IcLightV2: 4,
    GeminiFlashEdit: 1,
  });

  const getAllParams = (engine?: AiEngineList) => {
    const currentEngine = engine ?? aiEngine;
    return {
      width: width[currentEngine] ?? 1024,
      height: height[currentEngine] ?? 1024,
      systemPrompt: systemPrompt ?? "",
      negativePrompt: negativePrompt ?? "",
      step: step[currentEngine],
      sample: sample[currentEngine] ?? 1,
      guidanceScale: guidanceScale[currentEngine],
      seed: seed[currentEngine] ?? null,
      revisedPrompt: revisedPrompt,
      aspectRatio: aspectRatio[currentEngine],
      style: style[currentEngine],
      styleWeight: styleWeight[currentEngine],
      rawMode: ["Flux11ProUltra", "Flux11ProUltraRedux", "Flux11ProUltraJuno"].includes(currentEngine)
        ? (rawMode ?? false)
        : undefined,
      manualPlacementSelection:
        currentEngine === "BriaProductShot" ? manualPlacementSelection[currentEngine] : undefined,
      initialLatent: currentEngine === "IcLightV2" ? initialLatent[currentEngine] : undefined,
      colors: colorsRecraft ?? [],
      initImage: undefined,
      imageStrength: undefined,
      initImage2: undefined,
      imageStrength2: undefined,
      aiEngine: currentEngine,
    };
  };

  useEffect(() => {
    if (aiEngine) {
      calculateImageCredit(aiEngine, sample[aiEngine] ?? 1);
    }
  }, [aiEngine, sample]);

  const { getImageModels } = useJunoModels();
  const options = getImageModels;
  const navigate = useCustomNavigate();

  // URLパラメータの変更を監視（許可された組み合わせのみ）
  useEffect(() => {
    // 現在のパスが/juno/image-generatorの場合のみ処理を実行
    if (!window.location.pathname.includes("/juno/image-generator")) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const menuParam = params.get("menu") as MenuList;
    const engineParam = params.get("ai_engine") as AiEngineList;

    // デフォルト値の設定
    const defaultMenu: MenuList = "txt2img";
    const defaultEngine: AiEngineList = "Flux1Dev";

    // パラメータが不足している場合、デフォルト値をセット
    if (!menuParam || !engineParam) {
      setMenu(defaultMenu);
      setAiEngine(defaultEngine);
    }

    // 組み合わせが許可されている場合、パラメータをセット
    if (menuParam && menuParam !== menu && ["txt2img", "img2img", "upscale", "editor"].includes(menuParam)) {
      if (engineParam && options[menuParam].some((option) => option.value === engineParam)) {
        setMenu(menuParam);
        setAiEngine(engineParam);
      } else {
        const newEngine = (
          menuParam === "txt2img" || menuParam === "img2img" ? defaultEngine : options[menuParam][0].value
        ) as AiEngineList;
        setMenu(menuParam);
        setAiEngine(newEngine);
        navigate(`/juno/image-generator?menu=${menuParam}&ai_engine=${newEngine}`);
      }
    } else if (engineParam && engineParam !== aiEngine) {
      if (options[menu].some((option) => option.value === engineParam)) {
        setAiEngine(engineParam);
      }
    }
  }, [window.location.search, options, navigate]);

  return (
    <ParamsContext.Provider
      value={{
        menu,
        setMenu,
        systemPrompt,
        setSystemPrompt,
        aiEngine,
        setAiEngine,
        imageCredit,
        setImageCredit,
        prompt,
        setPrompt,
        negativePrompt,
        setNegativePrompt,
        revisedPrompt,
        setRevisedPrompt,
        style,
        setStyle,
        styleWeight,
        setStyleWeight,
        width,
        setWidthPartial,
        height,
        setHeightPartial,
        aspectRatio,
        setAspectRatio,
        guidanceScale,
        setGuidanceScale,
        step,
        setStep,
        sample,
        setSample,
        seed,
        setSeed,
        isPublic,
        setIsPublic,
        colorsRecraft,
        setColorsRecraft,
        rawMode,
        setRawMode,
        manualPlacementSelection,
        setManualPlacementSelection,
        initialLatent,
        setInitialLatent,
        sampleLimit,
        fetchingCredit,
        getAllParams,
      }}
    >
      {children}
    </ParamsContext.Provider>
  );
};

export const useParamsContext = (): ParamsContextType => {
  const context = useContext(ParamsContext);
  if (!context) {
    throw new Error("useParamsContext must be used within a ParamsProvider");
  }
  return context;
};

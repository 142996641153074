import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useVoicePlayer } from "../../hooks/useVoicePlayer";
import type { Voice } from "../../types/voice";
import { GradientTypography } from "../../utils/gradientTypography";

interface GeneratedVoicesModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setAudio: (audio: string) => void;
}

const StyledTableCell = styled(TableCell)(() => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "300px",
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  whiteSpace: "nowrap",
}));

const GeneratedVoicesModal = ({ open, setOpen, setAudio }: GeneratedVoicesModalProps) => {
  const { t } = useTranslation();
  const [voices, setVoices] = useState<Voice[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const { playingUuid, loadingUuid, toggleAudio } = useVoicePlayer();
  const itemsPerPage = 10;

  const fetchVoices = async (currentPage: number) => {
    try {
      setLoading(true);
      const csrftoken = Cookies.get("csrftoken");
      const response = await fetch(`/api/v1/voice-generator/voices?page=${currentPage}&per_page=${itemsPerPage}`, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken!,
        },
      });
      const data = await response.json();
      if (data.success) {
        setVoices(data.data);
        setTotalPages(Math.ceil(data.total_count / itemsPerPage));
      }
    } catch (error) {
      console.error("Failed to fetch voices:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchVoices(page);
    }
  }, [open, page]);

  const handleVoiceSelect = async (voice: Voice) => {
    try {
      const csrftoken = Cookies.get("csrftoken");
      const response = await fetch(`/api/v1/voice-generator/download/${voice.uuid}`, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken!,
        },
      });
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        // MIMEタイプをaudio/mpegに変更
        const audioData = base64data.replace(/^data:.*?;base64,/, "data:audio/mpeg;base64,");
        setAudio(audioData);
        setOpen(false);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Failed to fetch voice:", error);
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          position: "relative",
          width: "90%",
          maxWidth: "1200px",
          height: "90vh",
          p: 3,
          bgcolor: (theme) => theme.palette.background.paper + "D9",
          backdropFilter: "blur(5px)",
        }}
      >
        <IconButton sx={{ position: "absolute", top: 8, right: 8 }} size="small" onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>

        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <GradientTypography variant="h6" component="h2">
            Generated Voices
          </GradientTypography>
        </Box>

        <TableContainer sx={{ mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledHeaderCell>No</StyledHeaderCell>
                <StyledHeaderCell>Text</StyledHeaderCell>
                <StyledHeaderCell align="right">Duration</StyledHeaderCell>
                <StyledHeaderCell align="right">Voice</StyledHeaderCell>
                <StyledHeaderCell align="right">Action</StyledHeaderCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>

        <Box sx={{ height: "calc(100% - 160px)", overflow: "auto" }}>
          <TableContainer>
            <Table>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress size={24} />
                    </TableCell>
                  </TableRow>
                ) : voices.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography variant="subtitle2">No voices found</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  voices.map((voice, index) => (
                    <TableRow
                      key={voice.uuid}
                      hover
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleVoiceSelect(voice)}
                    >
                      <TableCell>{(page - 1) * itemsPerPage + index + 1}</TableCell>
                      <StyledTableCell>{voice.text}</StyledTableCell>
                      <TableCell align="right">{Math.round(voice.seconds)}s</TableCell>
                      <TableCell align="right">
                        {voice.provider === "openai"
                          ? t(`voice.openaiVoiceLabel.${voice.voice_name}`)
                          : `${voice.language_label} - ${voice.voice_name}`}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleAudio(voice.uuid);
                          }}
                          size="small"
                        >
                          {playingUuid === voice.uuid ? (
                            <StopIcon color="error" />
                          ) : loadingUuid === voice.uuid ? (
                            <CircularProgress size={24} />
                          ) : (
                            <PlayArrowIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination count={totalPages} page={page} onChange={handleChangePage} />
        </Box>
      </Paper>
    </Modal>
  );
};

export default GeneratedVoicesModal;

import { Grid, Stack } from "@mui/material";
import History from "./components/History";
import ParamsManager from "./components/ParamsManager";
import SelectImageModel from "./components/SelectImageModel";

import { useTranslation } from "react-i18next";
import Meta from "../../../components/common/Meta";
import { uiHeight } from "../../../utils/uiHeight";
import QuickSelection from "./components/QuickSelection";

const ImageGenerator = () => {
  const { t } = useTranslation();

  return (
    <>
      <Meta
        title={t("juno.goToGenerator")}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
        route={"Studio JUNO by Tenak AI"}
      />
      <Grid
        container
        spacing={1}
        justifyContent="center"
        sx={{ height: { xs: "100%", sm: `calc(${uiHeight()} - 100px)` }, px: { xs: 0, md: 2 } }}
      >
        {/* Menu */}
        <Grid item xs={12} sx={{ height: 35, overflow: "auto" }}>
          <QuickSelection />
        </Grid>

        {/* Menu */}
        <Grid item xs={12} md={2.5} sx={{ height: "100%" }}>
          <Stack spacing={1} sx={{ height: "100%" }}>
            <SelectImageModel />
            <ParamsManager />
          </Stack>
        </Grid>

        {/* Main */}
        <Grid item xs={12} md={9.5} sx={{ height: "100%" }}>
          <History />
        </Grid>
      </Grid>
    </>
  );
};
export default ImageGenerator;

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { Box, Button, Paper, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import GeneratedVideoModal from "../../../../components/common/GeneratedVideoModal";
import { useAlert } from "../../../../context/AlertContext";
import { useVideo } from "../../../../context/juno/VideoContext";
import { alpha } from "@mui/material/styles";

interface VideoUploadProps {
  video: string;
  setVideo: (video: string) => void;
}

const VideoUpload = ({ video, setVideo }: VideoUploadProps) => {
  const { t } = useTranslation();
  const { video1Metadata } = useVideo();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const { setAlert } = useAlert();
  const [open, setOpen] = React.useState(false);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      const file = files[0];
      updateVideo(file);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      updateVideo(file);
    }
  };

  const updateVideo = (file: File) => {
    // ファイルタイプチェック (MP4のみ)
    if (file.type !== "video/mp4") {
      setAlert("error", t("juno.input.uploadError.type"));
      return;
    }

    // ファイルサイズチェック
    const maxSizeInBytes = 100000000; // 100MB
    if (file.size > maxSizeInBytes) {
      setAlert("error", t("juno.input.uploadError.size", { size: "100" }));
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        setVideo(e.target.result as string);
      }
    };
    reader.readAsDataURL(file);

    // ファイル選択をリセットすることで同じ動画を選択可能にする
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleAreaClick = () => {
    fileInputRef.current?.click();
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    setVideo("");
  };

  const handleOpenModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  return (
    <Box
      sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
    >
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 200,
          overflow: "hidden",
          backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.7),
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleAreaClick}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            aspectRatio: "1 / 1",
            "&:hover": {
              cursor: "pointer",
            },
            position: "relative",
          }}
          ref={boxRef}
        >
          {video ? (
            <>
              <video
                src={video}
                style={{ width: "100%", height: "100%", objectFit: "contain", pointerEvents: "none" }}
                controlsList="nodownload"
                disablePictureInPicture
                muted
              />

              {video1Metadata && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 8,
                    left: 8,
                    right: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      backdropFilter: "blur(5px)",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      color: "white",
                      padding: "4px 8px",
                      borderRadius: 1,
                    }}
                  >
                    {Math.floor(video1Metadata.fps * video1Metadata.duration)}フレーム /{" "}
                    {video1Metadata.duration.toFixed(1)}秒
                  </Typography>
                </Box>
              )}
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 8,
                  right: 8,
                  backdropFilter: "blur(5px)",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  transition: "0.3s",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                  },
                }}
                onClick={handleDelete}
              >
                <DeleteIcon style={{ color: "white" }} />
              </IconButton>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <CloudUploadIcon fontSize={"large"} sx={{ mb: 2 }} />
              Upload a video
            </Box>
          )}

          <input type="file" hidden onChange={handleChange} accept="video/mp4" ref={fileInputRef} />
        </Box>
      </Paper>
      <Button onClick={handleOpenModal} sx={{ mt: 1 }} startIcon={<VideoLibraryIcon />}>
        Generated Videos
      </Button>

      <GeneratedVideoModal open={open} setOpen={setOpen} setVideo={setVideo} />
    </Box>
  );
};

export default VideoUpload;

import { Grow } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useAlert } from "../../../context/AlertContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { refreshChatHistories, triggerChatFetch } from "../../../redux/slices/browsingSlice";

interface DeleteChatDialogProps {
  open: boolean;
  onClose: () => void;
  chatUuid?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

export default function DeleteChatDialog({ open, onClose, chatUuid: chatUuidFromProps }: DeleteChatDialogProps) {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const params = useParams();
  const chatUuid = chatUuidFromProps || params.chatUuid;
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();

  const handleDeleteChat = async () => {
    try {
      const csrftoken = Cookies.get("csrftoken");
      const headers = {
        "X-CSRFToken": csrftoken!,
      };
      const res = await axios.delete("/api/v1/chat/" + chatUuid, { headers: headers });
      if (res.data) {
        onClose();
        setAlert("success", t("browsing.deleteChat.success"));
        if (res.data.first_chat_uuid) {
          navigate("/chat/" + res.data.first_chat_uuid);
        } else {
          navigate("/chat");
        }
        dispatch(triggerChatFetch());
        dispatch(refreshChatHistories());
      }
    } catch {
      setAlert("error", t("common.error"));
    }
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={onClose} disableRestoreFocus={true}>
      <DialogTitle>{t("browsing.deleteChat.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("browsing.deleteChat.description")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disableElevation color={"primary"} onClick={onClose}>
          {t("common.cancel")}
        </Button>
        <Button disableElevation color={"error"} onClick={handleDeleteChat}>
          {t("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Quill from "quill";
import Delta from "quill-delta";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../context/AlertContext";
import { Images } from "../../../../assets";
import { RowCenteredBox } from "../../../../utils/styledBox";

interface QuillInterface extends Quill {
  import: (path: string) => any;
}

type LinkCardProps = {
  quill: Quill | null;
};

type Link = {
  url: string;
  title: string;
  description: string;
  image: string;
};

type Range = {
  index: number;
  length: number;
};

const QuillClass = Quill as unknown as QuillInterface;
const BlockEmbed = QuillClass.import("blots/block/embed");

export class LinkCardBlot extends BlockEmbed {
  static blotName = "link-card";
  static tagName = "div";
  static className = "link-card";

  static create(value: any) {
    const node = super.create();
    if (value.loading) {
      node.setAttribute("data-loading-id", value.loadingId);
      const loadingDiv = document.createElement("div");
      loadingDiv.classList.add("link-card-loading");
      loadingDiv.style.backgroundImage = `url('${Images.loading}')`;
      loadingDiv.style.backgroundRepeat = "no-repeat";
      loadingDiv.style.backgroundPosition = "center";
      loadingDiv.style.backgroundSize = "50px 50px";
      loadingDiv.style.width = "100%";
      loadingDiv.style.height = "150px";
      node.appendChild(loadingDiv);
    } else if (value as Link) {
      const { url, title, description, image } = value as Link;
      const card = document.createElement("a");
      card.setAttribute("href", url);
      card.style.display = "flex";
      const uniqueClass = `link-card-${Date.now()}-${Math.random()}`;
      card.classList.add(uniqueClass);

      if (image) {
        const imageDiv = document.createElement("div");
        imageDiv.classList.add("link-card-image");
        imageDiv.style.backgroundImage = `url(${image})`;
        imageDiv.style.minHeight = "150px";
        card.appendChild(imageDiv);
      }

      const content = document.createElement("div");
      content.classList.add("link-card-content");

      if (title) {
        const titleElement = document.createElement("div");
        titleElement.classList.add("link-card-title");
        titleElement.innerText = title;
        content.appendChild(titleElement);
      }

      if (description) {
        const descriptionElement = document.createElement("blockquote");
        descriptionElement.classList.add("link-card-description");
        descriptionElement.innerText = description;
        content.appendChild(descriptionElement);
      }

      const domain = document.createElement("div");
      domain.classList.add("link-card-domain");
      domain.innerText = new URL(url).hostname;
      content.appendChild(domain);

      card.appendChild(content);
      node.appendChild(card);
    }
    return node;
  }

  static value(node: HTMLElement) {
    const card = node.querySelector("a");
    const imageDiv = card?.querySelector(".link-card-image") as HTMLElement;
    const titleElement = card?.querySelector(".link-card-title") as HTMLElement;
    const descriptionElement = card?.querySelector(".link-card-description") as HTMLElement;

    return {
      url: card?.getAttribute("href"),
      title: titleElement?.innerText,
      description: descriptionElement?.innerText,
      image: imageDiv?.style.backgroundImage.slice(4, -1).replace(/"/g, ""),
    };
  }
}

const LinkCard: React.FC<LinkCardProps> = ({ quill }) => {
  const [linkUrl, setLinkUrl] = useState("");
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  const [savedRange, setSavedRange] = useState<Range | null>(null);
  const undoStateRef = useRef<{ index: number; length: number } | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (quill) {
      const range = quill.getSelection();
      if (range) {
        setSavedRange(range);
      }
    }
    setLinkModalOpen(true);
  };

  const checkImage = (url: string): Promise<string> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(url);
      img.onerror = () => resolve(Images.noImage);
      img.src = url;
    });
  };

  const insertLinkCard = () => {
    if (!savedRange || !quill) return;

    const index = savedRange.index;
    const loadingId = `loading-${Date.now()}-${Math.random()}`;

    // 操作の開始を記録
    quill.history.cutoff();

    // 初期のdeltaを作成
    const delta = new Delta().retain(index).insert({ "link-card": { loading: true, loadingId } });

    // deltaa適用
    quill.updateContents(delta, "user");

    fetchUrlMetadata(linkUrl).then((data) => {
      if (data.success === true) {
        checkImage(data.data.image).then((imageUrl) => {
          const currentPosition = getCurrentLoadingPosition(loadingId);
          if (currentPosition === null) return;

          // 新しいdeltaを作成
          const newDelta = new Delta()
            .retain(currentPosition)
            .delete(1)
            .insert({
              "link-card": {
                url: linkUrl,
                description: data.data.description,
                title: data.data.title,
                image: imageUrl,
              },
            });

          // 新しいdeltaを適用
          quill.updateContents(newDelta, "user");

          quill.setSelection(currentPosition + 1, 0);

          // 操作の終了を記録
          quill.history.cutoff();
        });
      } else {
        // エラー時の処理も同様に
        const currentPosition = getCurrentLoadingPosition(loadingId);
        if (currentPosition === null) return;

        const fallbackDelta = new Delta()
          .retain(currentPosition)
          .delete(1)
          .insert({
            "link-card": {
              url: linkUrl,
              title: "Title",
              description: linkUrl,
              image: Images.noImage,
            },
          });

        quill.updateContents(fallbackDelta, "user");

        quill.setSelection(currentPosition + 1, 0);

        // 操作の終了を記録
        quill.history.cutoff();
      }
    });

    setLinkUrl("");
    setLinkModalOpen(false);
  };

  const getCurrentLoadingPosition = (loadingId: string): number | null => {
    if (!quill) return null;

    let foundIndex: number | null = null;
    quill.scroll.descendants(LinkCardBlot, 0, quill.getLength()).forEach((blot) => {
      if (blot.domNode.getAttribute("data-loading-id") === loadingId) {
        foundIndex = quill.getIndex(blot);
      }
    });
    return foundIndex;
  };

  const removeLoadingElement = (loadingId: string) => {
    if (!quill) return;

    quill.scroll.descendants(LinkCardBlot, 0, quill.getLength()).forEach((blot) => {
      if (blot.domNode.getAttribute("data-loading-id") === loadingId) {
        const blotIndex = quill.getIndex(blot);
        quill.deleteText(blotIndex, 1);
      }
    });
  };

  const fetchUrlMetadata = async (url: string) => {
    const response = await fetch(`/api/v1/chat/meta?url=${url}`);
    const data = await response.json();
    return data;
  };

  return (
    <>
      <Tooltip title={t("textEditor.toolbars.linkCard")}>
        <button onClick={(e) => handleClick(e)}>
          <LinkIcon fontSize={"small"} className={"ql-fill"} />
        </button>
      </Tooltip>

      <Modal open={linkModalOpen} onClose={() => setLinkModalOpen(false)}>
        <Paper sx={{ p: 2, maxWidth: 400, margin: "auto", mt: 10, position: "relative" }}>
          <IconButton
            onClick={() => setLinkModalOpen(false)}
            sx={{ position: "absolute", top: 5, right: 5 }}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <RowCenteredBox mb={2}>
              <LinkIcon color="primary" fontSize={"large"} sx={{ mr: 1 }} />
              <Typography variant="h6">Link Card</Typography>
            </RowCenteredBox>
            <TextField
              label="URL"
              placeholder="https://example.com"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
              InputLabelProps={{ shrink: true }}
              autoFocus
            />
            <Button variant="contained" color="primary" onClick={insertLinkCard}>
              Insert Link Card
            </Button>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default LinkCard;

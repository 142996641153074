import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../../context/juno/VideoContext";
import { ColumnBox } from "../../../../../utils/styledBox";
import AspectRatioIcons from "../AspectRatioIcons";
import ImageUpload from "../ImageUpload";
import ModelVersionSelectBox from "../ModelVersionSelectBox";

const ParamsHaiper = () => {
  const { t } = useTranslation();
  const { menu, updateParams, image1, setImage1, duration, modelVersion } = useVideo();

  const aspectRatio: AspectRatioIconType[] = [{ ratio: "AUTO", icon: HdrAutoIcon }];

  const durationMarks = [
    {
      value: 4,
      label: "4s",
    },
    {
      value: 6,
      label: "6s",
    },
  ];

  return (
    <ColumnBox sx={{ gap: 4 }}>
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <ModelVersionSelectBox
          platform="HAIPER"
          value={modelVersion.HAIPER}
          onChange={(value) => updateParams("HAIPER", "modelVersion", value)}
          menu={menu}
        />
      </ColumnBox>

      <ColumnBox>
        {/* Duration */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.duration")}
        </Typography>
        <Slider
          aria-label="duration"
          marks={durationMarks}
          min={4}
          max={6}
          step={4}
          value={duration.HAIPER}
          onChange={(_, value) => updateParams("HAIPER", "duration", value)}
        />
      </ColumnBox>

      {menu === "i2v" && (
        <ColumnBox sx={{ justifyContent: "center" }}>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")}
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
        </ColumnBox>
      )}

      <ColumnBox>
        {/* aspect */}
        <AspectRatioIcons aspectRatioList={aspectRatio} />
      </ColumnBox>
    </ColumnBox>
  );
};
export default ParamsHaiper;

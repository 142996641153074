const junoVideo = {
  selection: {
    new: "New Model",
    popularModel: "Popular Model",
    highQualityModel: "High Quality",
    lowCostModel: "Low Cost",
    highPrecisionModel: "High Precision",
    cameraControl: "Camera Control",
    trending: "Trending",
    fastModel: "Fast",
    costEffective: "Cost Effective",
    popularLipSync: "Popular Lip Sync",
    imageToVideo: "Image to Video",
    videoEdit: "Video Edit",
    upscale: "Upscale",
    lipsync: "Lip Sync",
  },
  title: "VIDEO GENERATOR",
  caution: "Due to Google's pricing, the cost of VEO2 is extremely high. Please use with caution.",
  menu: {
    t2v: "Text to Video",
    i2v: "Image to Video",
    v2v: "Video to Video",
    library: "Library",
    upscale: "Upscale",
    lipsync: "Lip Sync",
  },
  ai: {
    select: "Please select an AI model",
    model: "Model",
    runway: {
      title: "RunwayML",
      description:
        "[Fast] Simplifies the video production process using AI and machine learning, catering to both professionals and beginners.",
      model: {
        GEN3_ALPHA_TURBO: "Gen3 Alpha Turbo",
        GEN3_ALPHA_TURBO_description: "Runway's fastest model. Optimal for quick iterations and previews.",
      },
    },
    kling: {
      title: "KLING AI",
      description:
        "[Slow] KLING AI generates realistic and natural-motion videos using advanced physics simulation and 3D technology.",
      model: {
        V1_0_STANDARD: "Standard v1.0",
        V1_6_STANDARD: "Standard v1.6",
        V1_0_PRO: "Pro v1.0",
        V1_5_PRO: "Pro v1.5",
        V1_6_PRO: "Pro v1.6",
        V1_0_STANDARD_description: "Initial KLING model. Generate videos at low cost.",
        V1_6_STANDARD_description:
          "Budget version of KLING high-end model. 720p but offers excellent quality and realism.",
        V1_0_PRO_description: "Initial KLING pro model. Generate high quality and long duration videos.",
        V1_5_PRO_description: "Pro version 1 with Full HD support. Improved quality and realism.",
        V1_6_PRO_description: "Pro version 1.6 with Full HD support. The most advanced KLING model.",
      },
    },
    luma: {
      title: "Luma",
      description: "[Fast] Luma is an AI model that generates high-quality and realistic videos from text or images.",
      model: {
        V1_5: "Dream MACHINE v1.5",
        RAY_2: "RAY 2",
        RAY_2_1080P: "RAY 2 1080P",
        V1_5_description: "Standard Luma model. Quality and speed are balanced.",
        RAY_2_description:
          "Luma model with double the computational precision of conventional models for enhanced realism.",
        RAY_2_1080P_description:
          "Luma model with double the computational precision of conventional models and 1080p resolution for higher quality.",
        RAY_2_FLASH: "RAY 2 Flash",
        RAY_2_FLASH_description: "Faster and more cost-effective version of RAY 2 model.",
        RAY_2_FLASH_1080P: "RAY 2 Flash 1080P",
        RAY_2_FLASH_1080P_description: "1080P resolution version of RAY 2 Flash model.",
      },
    },
    hailuo: {
      title: "Hailuo AI",
      description:
        "[Slow] Hailuo AI/Minimax excels in generating high-quality videos with accurate human motion reproduction.",
      model: {
        MINI_MAX: "VIDEO-01",
        MINI_MAX_LIVE: "VIDEO-01-LIVE",
        MINI_MAX_REF: "VIDEO-01-SUBJECT-REFFRENCE",
        MINI_MAX_DIRECTOR: "VIDEO-01-DIRECTOR",
        MINI_MAX_description: "Standard Hailuo model, balanced for quality and speed.",
        MINI_MAX_LIVE_description: "Hailuo model optimized for 2D animation style.",
        MINI_MAX_REF_description: "Hailuo model for subject reference, maintaining consistency.",
        MINI_MAX_DIRECTOR_description: "Hailuo model with detailed camera control.",
      },
    },
    haiper: {
      title: "Haiper AI",
      description: "[Slow] Haiper AI excels in naturally reproducing Japanese faces and poses.",
      model: {
        V2_0: "Haiper V2.0",
        V2_5: "Haiper V2.5",
        V2_0_description: "Initial version of Haiper with basic features.",
        V2_5_description: "Improved version with enhanced quality and features.",
      },
    },
    pixverse: {
      title: "PixVerse",
      description:
        "[Fast] PixVerse is a high-quality video generation AI that creates natural motion videos from text or images.",
      model: {
        V3_5: "V3.5",
        V3_5_1080P: "V3.5 1080P",
        V3_5_FAST: "V3.5 Peformance",
        V3_5_description: "720P resolution V3.5. Can generate high quality videos.",
        V3_5_1080P_description: "1080P resolution V3.5. Can generate higher quality videos.",
        V3_5_FAST_description:
          "Improved version of V3.5. Optimal for action scenes and scenes with lots of movement. Can express more dynamic and active movements.",
      },
      style: {
        default: "Default",
        anime: "Anime",
        "3d_animation": "3D Animation",
        clay: "Clay Art",
        comic: "Comic",
        cyberpunk: "Cyberpunk",
      },
    },
    vidu: {
      title: "Vidu",
      description: "[Fast] Vidu is an AI model that generates high-quality videos from text or images.",
      model: {
        VIDU_2_0_720P: "Vidu v2.0 720P",
        VIDU_2_0_1080P: "Vidu v2.0 1080P",
        VIDU_2_0_REF_720P: "Vidu v2.0 Reference 720P",
        VIDU_2_0_720P_description: "720P resolution Vidu 2.0. Can generate high quality videos.",
        VIDU_2_0_1080P_description: "1080P resolution Vidu 2.0. Can generate higher quality videos.",
        VIDU_2_0_REF_720P_description: "Can generate ideal videos by combining up to three reference images.",
      },
      movement: {
        auto: "Auto",
        small: "Small",
        medium: "Medium",
        large: "Large",
      },
    },
    bytedance: {
      title: "ByteDance",
      description: "[Fast] ByteDance generates natural movements by combining video and audio.",
      model: {
        LATENTSYNC: "LantentSync",
        LATENTSYNC_description:
          "AI model that detects faces and generates realistic movements. Video will not be generated if no face is detected.",
      },
    },
    sync: {
      title: "Sync",
      description: "[Fast] Sync synchronizes video and audio to create natural lip sync.",
      model: {
        LIPSYNC_190: "LipSync v1.9.0 Beta",
        LIPSYNC_190_description: "The latest lip sync model. Generates more natural mouth movements.",
      },
    },
    realesrgan: {
      title: "Real-ESRGAN 4K Upscaler",
      description:
        "Real-ESRGAN 4K is a video upscaling AI model that improves video resolution to create clearer images.",
      model: {
        REALESRGAN: "Real-ESRGAN 4K",
        REALESRGAN_description:
          "Real-ESRGAN 4K is a video upscaling AI model that improves video resolution to create clearer images.",
      },
    },
    google: {
      title: "Google",
      description: "[Fast] Google's video generation AI. High cost but provides quality and natural movement.",
      model: {
        VEO2: "VEO2",
        VEO2_description: "Google's video generation AI. High cost but provides quality and natural movement.",
      },
    },
    wan: {
      title: "Wan",
      description:
        "[Medium] Wan is a video generation AI model developed by Alibaba, a leading Chinese IT company. It can generate high-precision videos at a relatively low cost.",
      model: {
        V2_1: "Wan v2.1 720P",
        V2_1_description:
          "Latest version of WAN model that supports both text-to-video and image-to-video generation with high quality and natural motion.",
        V2_1_PRO: "Wan Pro v2.1 1080P",
        V2_1_PRO_description: "Pro version of WAN model that supports 1080P resolution and higher quality.",
      },
    },
    pika: {
      title: "Pika",
      description:
        "[Fast] Pika is a video generation AI model that generates high-quality videos from images and supports various aspect ratios.",
      model: {
        V2_2_720P: "Pika v2.2 720P",
        V2_2_1080P: "Pika v2.2 1080P",
        V2_2_SCENES_720P: "Pika Scenes v2.2 720P",
        V2_2_SCENES_1080P: "Pika Scenes v2.2 1080P",
        V2_TURBO_720P: "Pika Turbo v2 720P",
        V2_2_720P_description: "720P resolution Pika 2.2. Can generate high quality videos.",
        V2_2_1080P_description: "1080P resolution Pika 2.2. Can generate higher quality videos.",
        V2_2_SCENES_720P_description: "Can generate a scene from multiple images.",
        V2_2_SCENES_1080P_description: "Can generate a scene from multiple images.",
        V2_TURBO_720P_description: "Pika Turbo 720P optimized for fast generation.",
      },
    },
    topazlabs: {
      title: "Topaz Labs",
      description:
        "Topaz Labs provides high-quality video upscaling and frame rate interpolation using next-generation AI technology, supporting up to 4K 60FPS.",
      model: {
        TOPAZ_VIDEO_UPSCALE: "Upscale 4K 60FPS",
        TOPAZ_VIDEO_UPSCALE_description:
          "Topaz Video Upscale provides high-quality video upscaling and frame rate interpolation using AI technology, supporting up to 4K 60FPS.",
      },
    },
  },
  params: {
    title: "Video Settings",
    size: "Video Size",
    upload: "Upload Image",
    uploadFace: "Upload Image (Face)",
    uploadFaceVideo: "Upload Video (Face)",
    video: "Upload Video",
    audio: "Upload Audio",
    duration: "Video Duration",
    aspect: "Aspect Ratio",
    aspectTooltip:
      "When generating a video from an image, the original image may be stretched, cropped, or resized by the model.",
    loop: "Loop",
    loopMode: "Loop Mode",
    style: "Style",
    resolution: "Resolution",
    negativePrompt: "Negative Prompt",
    movement: "Movement Amplitude",
    guidanceScale: "Guidance Scale",
    guidanceScaleHelp: "Adjusts the balance between video quality and creativity",
    scale: "Scale",
    scaleHelp: "Set so that the long side does not exceed 3840px and the short side does not exceed 2160px",
    syncModel: "Lip Sync Model",
    syncMode: "Sync Mode",
    pingpong: "Ping Pong",
    loopType: "Loop Type",
    bounce: "Bounce",
    cutOff: "Cut Off",
    lipsync190: "LipSync v1.9.0 Beta",
    lipsync180: "LipSync v1.8.0",
    lipsync171: "LipSync v1.7.1",
    inferenceSteps: "Inference Steps",
    inferenceStepsHelp: "Higher values produce more accurate results but take longer to process",
    fps: "FPS",
    fpsHelp: "Sets the target frame rate for the output video",
    input: {
      label: "Prompt",
      placeholder: "Enter video prompt (English recommended)",
      notRequired: "Prompt is not required for this mode",
      warning: "Note: Videos that violate the policy cannot be generated, and credit is not guaranteed in such cases.",
      error: {
        button: "Having trouble?",
        tooltip: "If errors persist, there may be a problem on the provider side. Please wait and try again later.",
      },
    },
    submit: "Generate Video",
  },
  myVideo: "My Videos",
  processing: "Generating video...\nUsually completes within 5 minutes.",
  sampleVideo: "Sample Videos",
  error: {
    generate: "Failed to generate the video.\nPlease check for policy violations and try again later.",
    required: {
      image: "Please upload an image.",
      prompt: "Please enter a prompt.",
      onlyLastImage: "First image is required.",
    },
    max: {
      prompt: "Please enter a prompt within 512 characters.",
      resolution: "Video resolution must be 8.29 megapixels (4K equivalent) or lower.",
      videoSize: "Video file size must be 50MB or less.",
      videoDuration: "Video duration must be {{sec}} seconds or less.",
      audioSize: "Audio file size must be 30MB or less.",
      audioDuration: "Audio duration must be 60 seconds or less.",
      frames: "REALESRGAN cannot process videos with 1000 or more frames.",
    },
    processingLimit:
      "Processing limit exceeded. You have {{current}} requests in progress (images and videos combined). Your plan allows up to {{limit}} simultaneous requests.",
  },
  startGenerating: "Your video generation request has been added to the queue.",
  videoMenu: {
    download: "Download",
    delete: "Delete",
    lastFrame: "Generate from Last Frame",
  },
  message: {
    deleted: "Video has been deleted.",
  },
};
export default junoVideo;

import Crop169Icon from "@mui/icons-material/Crop169";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../../context/juno/VideoContext";
import { ColumnBox, RowCenteredBox } from "../../../../../utils/styledBox";
import AspectRatioIcons from "../AspectRatioIcons";
import ImageUpload from "../ImageUpload";
import ModelVersionSelectBox from "../ModelVersionSelectBox";

const ParamsRunway = () => {
  const { t } = useTranslation();
  const { menu, updateParams, image1, setImage1, image2, setImage2, duration, modelVersion } = useVideo();

  const aspectRatio: AspectRatioIconType[] = [
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
  ];

  const durationMarks = [
    {
      value: 5,
      label: "5s",
    },
    {
      value: 10,
      label: "10s",
    },
  ];

  const replaceImage = () => {
    const tmp = image1;
    setImage1(image2);
    setImage2(tmp);
  };

  return (
    <ColumnBox sx={{ gap: 4 }}>
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <ModelVersionSelectBox
          platform="RUNWAY"
          value={modelVersion.RUNWAY}
          onChange={(value) => updateParams("RUNWAY", "modelVersion", value)}
          menu={menu}
        />
      </ColumnBox>
      <ColumnBox>
        {/* Duration */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.duration")}
        </Typography>
        <Slider
          aria-label="duration"
          marks={durationMarks}
          min={5}
          max={10}
          step={5}
          value={duration.RUNWAY}
          onChange={(_, value) => updateParams("RUNWAY", "duration", value)}
        />
      </ColumnBox>

      {menu === "i2v" && (
        <ColumnBox sx={{ justifyContent: "center" }}>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")} - FIRST
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
          <RowCenteredBox sx={{ my: 2 }}>
            <IconButton onClick={() => replaceImage()}>
              <SwapVertIcon />
            </IconButton>
          </RowCenteredBox>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")} - LAST
          </Typography>
          <ImageUpload image={image2} setImage={setImage2} />
        </ColumnBox>
      )}

      <ColumnBox>
        {/* aspect */}
        <AspectRatioIcons aspectRatioList={aspectRatio} />
      </ColumnBox>
    </ColumnBox>
  );
};
export default ParamsRunway;

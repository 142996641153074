import { Box, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiEngineList, MenuList } from "../../../../context/juno/VideoContext";
import { RowBox } from "../../../../utils/styledBox";
import { getIcon } from "./utils";
import { Info } from "@mui/icons-material";

interface ModelVersionSelectBoxProps {
  platform: AiEngineList;
  value: string;
  onChange: (value: string) => void;
  menu: MenuList;
}

const ModelVersionSelectBox: React.FC<ModelVersionSelectBoxProps> = ({ platform, value, onChange, menu }) => {
  const { t } = useTranslation();

  // ラベルの取得
  const getLabel = (version: string) => {
    return t(`juno.video.ai.${platform.toLowerCase()}.model.${version}`);
  };

  // 選択不可の判定
  const isDisabled = (version: string): boolean => {
    switch (platform) {
      case "HAILUO":
        return menu === "t2v" && version === "MINI_MAX_REF";
      case "PIKA":
        return menu === "t2v" && (version === "V2_2_SCENES_720P" || version === "V2_2_SCENES_1080P");

      default:
        return false;
    }
  };

  // デフォルトバージョンの取得
  const getDefaultVersion = (currentVersion: string): string => {
    switch (platform) {
      case "HAILUO":
        if (menu === "t2v" && currentVersion === "MINI_MAX_REF") {
          return "MINI_MAX_LIVE";
        }
        break;
      case "PIKA":
        if (menu === "t2v" && (currentVersion === "V2_2_SCENES_720P" || currentVersion === "V2_2_SCENES_1080P")) {
          return "V2_2_1080P";
        }
        break;
      default:
        break;
    }
    return currentVersion;
  };

  // 初期値の自動変更
  useEffect(() => {
    const defaultVersion = getDefaultVersion(value);
    if (defaultVersion !== value) {
      onChange(defaultVersion);
    }
  }, [menu, value, onChange]);

  const renderMenuItem = (version: string) => {
    const icon = getIcon(version);
    const label = getLabel(version);
    const description = t(`juno.video.ai.${platform.toLowerCase()}.model.${version}_description`);

    return (
      <MenuItem key={version} value={version} disabled={isDisabled(version)}>
        <RowBox sx={{ gap: 1 }}>
          {icon}
          <Typography variant="body1">{label}</Typography>
          <Box flexGrow={1} />
          <Tooltip title={<Typography variant="body2">{description}</Typography>} placement="right" followCursor>
            <Info fontSize="small" />
          </Tooltip>
        </RowBox>
      </MenuItem>
    );
  };

  const getModelVersions = () => {
    switch (platform) {
      case "PIXVERSE":
        return ["V3_5", "V3_5_1080P", "V3_5_FAST"];
      case "HAILUO":
        return ["MINI_MAX_LIVE", "MINI_MAX_REF", "MINI_MAX_DIRECTOR", "MINI_MAX"];
      case "HAIPER":
        return ["V2_0", "V2_5"];
      case "KLING":
        return ["V1_0_STANDARD", "V1_0_PRO", "V1_5_PRO", "V1_6_STANDARD", "V1_6_PRO"];
      case "LUMA":
        return ["V1_5", "RAY_2", "RAY_2_1080P", "RAY_2_FLASH", "RAY_2_FLASH_1080P"];
      case "RUNWAY":
        return ["GEN3_ALPHA_TURBO"];
      case "VIDU":
        return ["VIDU_2_0_720P", "VIDU_2_0_1080P", "VIDU_2_0_REF_720P"];
      case "SYNC":
        return ["LIPSYNC_190"];
      case "BYTEDANCE":
        return ["LATENTSYNC"];
      case "REALESRGAN":
        return ["REALESRGAN"];
      case "GOOGLE":
        return ["VEO2"];
      case "WAN":
        return ["V2_1", "V2_1_PRO"];
      case "PIKA":
        return ["V2_2_720P", "V2_2_1080P", "V2_2_SCENES_720P", "V2_2_SCENES_1080P", "V2_TURBO_720P"];
      case "TOPAZLABS":
        return ["TOPAZ_VIDEO_UPSCALE"];
      default:
        return [];
    }
  };

  return (
    <Select value={value} onChange={(e) => onChange(e.target.value)} variant="standard" fullWidth>
      {getModelVersions().map((version) => renderMenuItem(version))}
    </Select>
  );
};

export default ModelVersionSelectBox;

import { Box, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNotice } from "../../../context/NoticeContext";
import { useCheckCredit } from "../../../hooks/useCreditCheck";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { setCreditTrigger } from "../../../redux/slices/triggerSlice";
import { RootState } from "../../../redux/store";
import { GradientTypography } from "../../../utils/gradientTypography";
import { ColumnBox, ColumnCenteredBox, RowBox } from "../../../utils/styledBox";
import { GradationButton } from "../../../utils/gradationButton";
import BoltIcon from "@mui/icons-material/Bolt";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const Credit = () => {
  const { t } = useTranslation();
  const { credits, dailyCredits, oneTimeCredits, totalCredits, fetchUserCredits, plan } = useCheckCredit();
  const { drawerCreditTrigger } = useSelector((state: RootState) => state.trigger);
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const { triggerNotice } = useNotice();
  const [loading, setLoading] = useState(true);
  const navigate = useCustomNavigate();

  // 未使用のスタイルコンポーネントを削除

  useEffect(() => {
    setLoading(true);
    const getCredit = async () => {
      await fetchUserCredits();
    };
    getCredit().then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (drawerCreditTrigger) {
      fetchUserCredits();
      dispatch(setCreditTrigger(false));
    }
  }, [drawerCreditTrigger]);

  useEffect(() => {
    // サブスクリプションの購入時に表示するメッセージ
    if (params?.get("subscription") === "success") {
      triggerNotice("success", t("message.subscription.success"));
    } else if (params?.get("subscription") === "cancel") {
      triggerNotice("info", t("message.subscription.cancel"));
    } else if (params?.get("subscription") === "error") {
      triggerNotice("error", t("message.subscription.error"));
    }
  }, [params]);

  const CreditInfoRow = ({ label, credit, plan }: { label: string; credit?: number; plan?: string }) => (
    <Stack direction={"row"} spacing={1}>
      <Box sx={{ width: 150, backgroundColor: (theme) => theme.palette.background.default, borderRadius: 1, p: 0.5 }}>
        <Typography variant="body2" fontWeight={600} gutterBottom sx={{ textAlign: "center" }}>
          {label}
        </Typography>
      </Box>
      <ColumnCenteredBox sx={{ width: 150 }}>
        {credit && (
          <Typography variant="body1" fontWeight={600} gutterBottom>
            {credit}
          </Typography>
        )}
        {plan && (
          <GradientTypography variant="body1" fontWeight={600} gutterBottom>
            {plan.toUpperCase()}
          </GradientTypography>
        )}
      </ColumnCenteredBox>
    </Stack>
  );

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={2}
      width={"100%"}
    >
      {loading ? (
        <ColumnCenteredBox sx={{ gap: 1, width: "100%" }}>
          <Skeleton variant="text" width={280} height={34} />
          <Skeleton variant="text" width={280} height={34} />
          <Skeleton variant="text" width={280} height={34} />
          <Skeleton variant="text" width={280} height={34} />
          <Skeleton variant="text" width={280} height={34} />
        </ColumnCenteredBox>
      ) : (
        <ColumnBox sx={{ gap: 1, width: "100%" }}>
          <span onClick={() => navigate("/preferences/plan-and-billings")} style={{ cursor: "pointer" }}>
            <CreditInfoRow label="Your Plan" plan={plan.replace("_jpy", "")} />
          </span>
          <Tooltip title={t("header.tooltip.totalCredits")} placement="left" arrow sx={{ zIndex: 100000 }}>
            <span>
              <CreditInfoRow label="Total Credits" credit={totalCredits} />
            </span>
          </Tooltip>
          <Tooltip title={t("header.tooltip.monthlyCredits")} placement="left" arrow sx={{ zIndex: 100000 }}>
            <span>
              <CreditInfoRow label="Monthly Credits" credit={credits} />
            </span>
          </Tooltip>
          <Tooltip title={t("header.tooltip.dailyCredits")} placement="left" arrow sx={{ zIndex: 100000 }}>
            <span>
              <CreditInfoRow label="Daily Credits" credit={dailyCredits} />
            </span>
          </Tooltip>
          <Tooltip title={t("header.tooltip.additionalCredits")} placement="left" arrow sx={{ zIndex: 100000 }}>
            <span>
              <CreditInfoRow label="Extra Credits" credit={oneTimeCredits} />
            </span>
          </Tooltip>
        </ColumnBox>
      )}

      <RowBox sx={{ gap: 1 }}>
        <GradationButton startIcon={<BoltIcon />} sx={{ py: 0.4, width: "100%" }} onClick={() => navigate("/pricing")}>
          {t("header.upgrade")}
        </GradationButton>
        <GradationButton
          startIcon={<AddCircleOutlineIcon />}
          sx={{ py: 0.4, width: "100%" }}
          onClick={() => navigate("/pricing?tab=credits")}
        >
          {t("header.addCredit")}
        </GradationButton>
      </RowBox>
    </Box>
  );
};
export default Credit;

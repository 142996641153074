import { Box, Grid } from "@mui/material";
import Meta from "../../../components/common/Meta";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Generator from "./components/Generator";
import Library from "./components/Library";

const VoiceGenerator = () => {
  const { t } = useTranslation();
  // 生成完了を通知するためのstate
  const [generationCompleted, setGenerationCompleted] = useState(false);
  return (
    <>
      <Meta title={t("voice.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Box sx={{ mx: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Generator generationCompleted={generationCompleted} setGenerationCompleted={setGenerationCompleted} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Library generationCompleted={generationCompleted} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default VoiceGenerator;

import LoginIcon from "@mui/icons-material/Login";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Meta from "../../../../components/common/Meta";
import { useLoginModalContext } from "../../../../context/LoginModalContext";
import { Images } from "../../../../assets";
import { Videos } from "../../../../assets";

const HeroSection = () => {
  const { t } = useTranslation();
  const { setActiveModal } = useLoginModalContext();
  const location = useLocation();

  const handleLogin = () => {
    setActiveModal("login");
  };

  // 現在のパスに基づいてメタタグを設定
  const meta = [
    { name: "description", content: t("meta.description") },
    // en か ja 以外なら noindex を適用
    {
      name: "robots",
      content:
        location.pathname.startsWith("/en") || location.pathname.startsWith("/ja")
          ? "index, follow"
          : "noindex, nofollow",
    },
  ];

  // アニメーションバリアント
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: 1.2,
      },
    },
    hover: {
      scale: 1.05,
      boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <>
      <Meta meta={meta} />

      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: { xs: 800, md: "100vh" },
          maxHeight: "1080px",
          mb: 0,
          overflow: "hidden",
        }}
      >
        {/* 背景動画 */}
        <motion.video
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
            zIndex: 0,
          }}
        >
          <source src={Videos.top.fvzoAnimation} type="video/mp4" />
          <img src={Images.top.fvzo} alt="FVZO" />
        </motion.video>

        {/* コンテンツ */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            textAlign: "center",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(0px)",
            zIndex: 1,
          }}
        >
          <Container maxWidth="lg">
            <motion.div variants={containerVariants} initial="hidden" animate="visible">
              <motion.div variants={itemVariants}>
                <Typography variant="h1" gutterBottom fontWeight={800}>
                  {t("top.fv.welcome")}
                </Typography>
              </motion.div>
              <motion.div variants={itemVariants}>
                <Typography variant="h4" component={"h2"} gutterBottom fontWeight={600}>
                  {t("top.fv.catchCopy")}
                </Typography>
              </motion.div>
              <motion.div variants={itemVariants}>
                <Typography variant="subtitle1" sx={{ mb: 4 }} gutterBottom fontWeight={600}>
                  {t("top.fv.description")}
                </Typography>
              </motion.div>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                onClick={handleLogin}
                size={"large"}
                startIcon={<LoginIcon />}
              >
                {t("top.login")}
              </Button>
            </motion.div>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default HeroSection;

import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, Fade, Grid, IconButton, Modal, Pagination, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API_STATUS } from "../../context/juno/VideoContext";
import { getActionIcon, getIcon } from "../../pages/Juno/Videos/components/utils";
import { GradientTypography } from "../../utils/gradientTypography";
import { ColumnCenteredBox, RowBox } from "../../utils/styledBox";
import ScrollableBox from "./ScrollableBox";

interface GeneratedVideoModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setVideo: (video: string) => void;
}

type VideoType = {
  uuid: string;
  status: number;
  params: {
    action: string;
    aiEngine: string;
    model: string;
    prompt: string;
    duration: number;
    aspectRatio: string;
    error_code?: number;
    error_detail?: string;
  };
  url: string;
};

const GeneratedVideoModal = ({ open, setOpen, setVideo }: GeneratedVideoModalProps) => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState<VideoType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const ITEMS_PER_PAGE = 9;

  const handleClose = () => {
    setOpen(false);
  };

  const fetchVideos = async (page: number) => {
    try {
      const response = await fetch(`/api/v1/juno/videos?page_size=${ITEMS_PER_PAGE}&page=${page}`);
      const data = await response.json();
      if (data.status === "error") {
        console.error("ビデオの取得中にエラーが発生しました:", data.message);
        return [];
      } else {
        setTotalPages(data.total_pages);
        return data.videos as VideoType[];
      }
    } catch (error) {
      console.error("フェッチエラー:", error);
      return [];
    }
  };

  useEffect(() => {
    const loadVideos = async () => {
      const data = await fetchVideos(currentPage);
      setVideos(data);
    };
    if (open) {
      loadVideos();
    }
  }, [currentPage, open]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const handleVideoSelect = async (video: VideoType) => {
    // すでに読み込み中または同じビデオが選択されている場合は処理をスキップ
    if (isLoading || selectedVideoId === video.uuid) {
      return;
    }

    try {
      setIsLoading(true);
      setSelectedVideoId(video.uuid);

      const response = await fetch(`/api/v1/juno/generate-video?generatedVideoUuid=${video.uuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const blob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64data = reader.result as string;
        setVideo(base64data);
        setOpen(false);
        // モーダルが閉じられた後に状態をリセット
        setTimeout(() => {
          setIsLoading(false);
          setSelectedVideoId(null);
        }, 300);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("ビデオの選択中にエラーが発生しました:", error);
      setIsLoading(false);
      setSelectedVideoId(null);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="generated-video-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          position: "relative",
          width: "90%",
          maxWidth: "1200px",
          height: "90vh",
          p: 3,
          bgcolor: (theme) => theme.palette.background.paper + "D9",
          backdropFilter: "blur(5px)",
        }}
      >
        <ColumnCenteredBox mb={2}>
          <GradientTypography variant="h6" component="h2">
            Generated Videos
          </GradientTypography>
        </ColumnCenteredBox>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          size="small"
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <ScrollableBox sx={{ height: "calc(100% - 90px)" }}>
          <Grid container spacing={2}>
            {videos
              .filter((video) => video.status === API_STATUS.SUCCESS)
              .map((video, index) => (
                <Grid item key={video.uuid} xs={12} sm={6} md={4}>
                  <Fade in={true} timeout={index * 500 > 2000 ? 2000 : index * 500}>
                    <Card
                      elevation={0}
                      sx={{
                        borderRadius: 2,
                        cursor: isLoading ? "not-allowed" : "pointer",
                        opacity: isLoading && selectedVideoId !== video.uuid ? 0.5 : 1,
                        filter: isLoading && selectedVideoId !== video.uuid ? "grayscale(100%)" : "none",
                        transition: "all 0.3s ease",
                        position: "relative",
                      }}
                      onClick={() => handleVideoSelect(video)}
                    >
                      {isLoading && selectedVideoId === video.uuid && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.3)",
                            zIndex: 2,
                            borderRadius: 2,
                          }}
                        >
                          <Typography variant="body1" color="white" fontWeight="bold">
                            LOADING...
                          </Typography>
                        </Box>
                      )}
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          overflow: "hidden",
                          height: 215,
                        }}
                      >
                        <Box sx={{ width: "100%", height: 160 }}>
                          <video
                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            crossOrigin="anonymous"
                            controlsList="nodownload"
                            disablePictureInPicture
                            autoPlay
                            loop
                            muted
                          >
                            <source src={video.url} type="video/mp4" />
                          </video>
                        </Box>

                        {/* AIモデルなどの表示 */}
                        <Box p={1}>
                          <RowBox justifyContent="space-between" mb={1}>
                            <RowBox>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 600,
                                  px: 1,
                                  py: 0.2,
                                  mr: 2,
                                  backgroundColor: (theme) => theme.palette.secondary.dark,
                                  borderRadius: "2px",
                                  display: "inline-flex",
                                }}
                                color="#fff"
                                component="span"
                              >
                                {getActionIcon(video.params.action)}
                              </Typography>
                              <Typography variant="body2" sx={{ fontWeight: 600, mr: 1 }} color="secondary">
                                {t(`juno.video.ai.${video.params.aiEngine.toLowerCase()}.title`)} -{" "}
                                {t(`juno.video.ai.${video.params.aiEngine.toLowerCase()}.model.${video.params.model}`)}
                              </Typography>
                              {getIcon(video.params.model)}
                            </RowBox>
                          </RowBox>
                        </Box>
                      </Box>
                    </Card>
                  </Fade>
                </Grid>
              ))}
            {videos.length === 0 && (
              <Grid item xs={12}>
                <Typography variant={"subtitle2"}>No videos found</Typography>
              </Grid>
            )}
          </Grid>
        </ScrollableBox>
        {/* Pagination */}
        <ColumnCenteredBox mt={2}>
          <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
        </ColumnCenteredBox>
      </Paper>
    </Modal>
  );
};

export default GeneratedVideoModal;

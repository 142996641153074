import ImageIcon from "@mui/icons-material/Image";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import { Divider, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Quill from "quill";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAlert } from "../../../../context/AlertContext";
import GeneratedImageModal from "../../../../components/common/GeneratedImageModal";
import LinkCard from "./LinkCard";
import Table from "./Table";
import UnsplashModal from "../../../../components/common/UnsplashModal";
import { setGeneratedImage, setUnsplash } from "../../../../redux/slices/fileSlice";
import { useDispatch } from "react-redux";
import Delta from "quill-delta";
import VideoEmbed from "./VideoEmbed";
import InstagramEmbed from "./InstagramEmbed";
import TwitterEmbed from "./TwitterEmbed"; // Deltaをインポート

type CustomToolbarProps = {
  quill: Quill | null;
};

const CustomToolbar: React.FC<CustomToolbarProps> = ({ quill }) => {
  const { t } = useTranslation();
  const { fileUuid } = useParams();
  const [openUnsplash, setOpenUnsplash] = React.useState(false);
  const [openGeneratedImageModal, setOpenGeneratedImageModal] = React.useState(false);
  const { setAlert } = useAlert();
  const dispatch = useDispatch();

  function handleUnsplash() {
    setOpenUnsplash(true);
  }

  const uploadImage = () => {
    try {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async () => {
        const file = input.files![0];

        // Check if the file size is less than or equal to 20MB (20 * 1024 * 1024 bytes)
        const maxSizeInBytes = 20 * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
          alert("File size exceeds 20MB. Please upload a smaller file.");
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e.target?.result as string;
          const range = quill?.getSelection(true);

          if (quill && range) {
            // Create a Delta instance
            const delta = new Delta()
              .retain(range.index)
              .insert({ image: { src: base64Image, "data-type": "temp-image", class: "uploaded" } })
              .retain(1); // Retain the cursor position after the image

            // Update the contents with the Delta
            quill.updateContents(delta, Quill.sources.USER);

            // Set the cursor position without affecting the history
            quill.setSelection(range.index + 1, 0, Quill.sources.SILENT);
          }
        };
        reader.readAsDataURL(file);
      };
    } catch (error) {
      setAlert("error", "Upload failed");
      console.error("An unknown error occurred:", error);
    }
  };

  const handleUndo = () => {
    quill?.history.undo();
  };

  const handleRedo = () => {
    quill?.history.redo();
  };

  const setImageFromGenerated = (url: string) => {
    // GeneratedImageModalから直接Blob URLを受け取るので、R2チェックは不要
    dispatch(setGeneratedImage({ uuid: fileUuid!, url }));
  };

  const setImageFromUnsplash = (url: string) => {
    dispatch(setUnsplash({ uuid: fileUuid!, url: url }));
  };

  return (
    <Box>
      {/* MODAL */}
      <UnsplashModal open={openUnsplash} setOpen={setOpenUnsplash} setImage={setImageFromUnsplash} />
      <GeneratedImageModal
        open={openGeneratedImageModal}
        setOpen={setOpenGeneratedImageModal}
        setImage={setImageFromGenerated}
        useBlobUrl={false}
      />

      {/* TOOLBAR */}
      <Paper
        elevation={1}
        sx={{
          borderRadius: 2,
          backgroundColor: "background.default",
          px: 0.5,
          py: 0.5,
        }}
      >
        <Box id="toolbar" sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 0.5 }}>
          {/*history*/}
          <Tooltip title={"Undo"}>
            <button onClick={handleUndo}>
              <UndoIcon fontSize={"small"} className={"ql-fill"} />
            </button>
          </Tooltip>
          <Tooltip title={"Redo"}>
            <button onClick={handleRedo}>
              <RedoIcon fontSize={"small"} className={"ql-fill"} />
            </button>
          </Tooltip>
          <Divider orientation={"vertical"} flexItem />
          <select className="ql-header">
            <option value="">{t("textEditor.toolbars.heading.normal")}</option>
            <option value="2">{t("textEditor.toolbars.heading.lg")}</option>
            <option value="3">{t("textEditor.toolbars.heading.md")}</option>
            <option value="4">{t("textEditor.toolbars.heading.sm")}</option>
          </select>
          <Divider orientation={"vertical"} flexItem />
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-strike"></button>
          <Divider orientation={"vertical"} flexItem />
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          <button className="ql-indent" value="-1"></button>
          <button className="ql-indent" value="+1"></button>
          <Divider orientation={"vertical"} flexItem />
          <select className="ql-align"></select>
          <Divider orientation={"vertical"} flexItem />
          <Table quill={quill} />
          <button className="ql-link"></button>
          <LinkCard quill={quill} />
          <button className="ql-blockquote"></button>
          <button className="ql-code-block"></button>
          <Divider orientation={"vertical"} flexItem />
          <Tooltip title={t("textEditor.toolbars.upload")}>
            <button onClick={uploadImage}>
              <ImageIcon fontSize={"small"} className={"ql-fill"} />
            </button>
          </Tooltip>
          <Tooltip title={t("textEditor.toolbars.unsplash")}>
            <button onClick={handleUnsplash}>
              <ImageSearchIcon fontSize={"small"} className={"ql-fill"} />
            </button>
          </Tooltip>
          <Tooltip title={t("textEditor.toolbars.generated")}>
            <button onClick={() => setOpenGeneratedImageModal(true)}>
              <PhotoFilterIcon fontSize={"small"} className={"ql-fill"} />
            </button>
          </Tooltip>
          <VideoEmbed quill={quill} />
          <InstagramEmbed quill={quill} />
          <TwitterEmbed quill={quill} />

          <Box flexGrow={1} />
        </Box>
      </Paper>
    </Box>
  );
};
export default CustomToolbar;

import React from "react";
import { ColumnBox, RowBox } from "../../../../utils/styledBox";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { GradationButton } from "../../../../utils/gradationButton";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Images } from "../../../../assets";

const Seamless = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  return (
    <Paper
      elevation={0}
      sx={{
        width: { xs: "100%" },
        borderRadius: 2,
        p: { xs: 2, sm: 3 },
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        height: "auto",
        gap: 4,
        backgroundImage: `url(${Images.common.bg01})`,
        backgroundSize: "100%",
        backgroundPosition: "-150px 0px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          cursor: "pointer",
          overflow: "hidden",
          borderRadius: "4px",
          "&:hover img": {
            transform: "scale(1.05)",
          },
        }}
        onClick={() => navigate("/juno")}
      >
        <Box
          component="img"
          src={Images.top.woman}
          alt="juno"
          sx={{
            width: "100%",
            maxWidth: 500,
            display: "block",
            aspectRatio: "1/1",
            objectFit: "cover",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Box>

      <ColumnBox>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <AutoAwesomeIcon fontSize="large" />
        </Box>
        <Box flexGrow={1} />
        <Typography variant={"body1"} mb={1} color={"text.secondary"}>
          May 30, 2024
        </Typography>
        <Typography variant={"h4"} component={"h3"} fontWeight={"bold"} fontFamily={"Noto serif JP"} mb={2}>
          {t("top.fv.seamless.title")}
        </Typography>
        <Typography paragraph>{t("top.fv.seamless.description")}</Typography>
        <RowBox sx={{ gap: 2, justifyContent: "center" }}>
          <GradationButton sx={{ px: 2, alignSelf: "flex-start" }} onClick={() => navigate("/juno")}>
            {t("top.fv.seamless.button")}
          </GradationButton>
        </RowBox>
      </ColumnBox>
    </Paper>
  );
};
export default Seamless;

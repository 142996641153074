import BoltIcon from "@mui/icons-material/Bolt";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {
  Box,
  Button,
  Fade,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { CustomSection } from "../../components/common/CustomSection";
import { useCheckCredit } from "../../hooks/useCreditCheck";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import useSubscription from "../../hooks/useSubscription";
import { GradationButton } from "../../utils/gradationButton";
import { localDate } from "../../utils/utils";
import { alpha } from "@mui/material/styles";

const PlanAndBillings = () => {
  const { t } = useTranslation();
  const { fetchUserCredits } = useCheckCredit();
  const [credits, setCredits] = useState<number>(0);
  const [dailyCredits, setDailyCredits] = useState<number>(0);
  const [oneTimeCredits, setOneTimeCredits] = useState<number>(0);
  const [totalCredits, setTotalCredits] = useState<number>(0);
  const [dailyCreditsGrantedAt, setDailyCreditsGrantedAt] = useState<string | null>(null);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState<{ start: string | null; end: string | null } | null>(
    null
  );
  const [currentPlan, setCurrentPlan] = useState<string | null>(null);
  const navigate = useCustomNavigate();
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [customerExists, setCustomerExists] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedCheckoutSession, setSelectedCheckoutSession] = useState<string>("");
  const {
    handleCreatePortal,
    getSubscription,
    checkoutSessions,
    isLoadingCheckoutSessions,
    getCheckoutHistory,
    openReceipt,
  } = useSubscription();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await fetchUserCredits();
        if (result) {
          setCredits(result.credits);
          setDailyCredits(result.dailyCredits);
          setOneTimeCredits(result.oneTimeCredits);
          setTotalCredits(result.totalCredits);
          setDailyCreditsGrantedAt(result.dailyCreditsGrantedAt);
          setSubscriptionPeriod(result.subscriptionPeriod);
          setCurrentPlan(result.plan);

          // サブスクリプション情報も取得
          const res = await getSubscription();
          if (res.status === "success") {
            setCancelSubscription(res.subscription?.cancel_at);
            setCustomerExists(true);

            // チェックアウト履歴を取得
            await getCheckoutHistory();
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Data fetching failed", error);
      }
    };

    fetchData();
  }, []);

  // チェックアウトセッションの選択が変更されたときの処理
  const handleCheckoutSessionChange = (event: SelectChangeEvent) => {
    setSelectedCheckoutSession(event.target.value);
  };

  return (
    <>
      <Box component="div">
        <Typography component={"h3"} variant="h5" gutterBottom my={2}>
          {t("preferences.planAndBillings.title")}
        </Typography>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="300px">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Fade in={!loading} timeout={500}>
            <Paper
              elevation={0}
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.7),
                backdropFilter: "blur(10px)",
                border: (theme) => `1px solid ${alpha(theme.palette.grey[500], 0.2)}`,
                borderRightColor: (theme) => alpha(theme.palette.grey[900], 0.5),
                borderBottomColor: (theme) => alpha(theme.palette.grey[900], 0.5),
              }}
            >
              <CustomSection>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="subtitle1">{t("preferences.planAndBillings.currentPlan")}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                      <Typography
                        variant="button"
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          borderRadius: 1,
                          height: 36.5,
                          px: 1,
                          border: "1px solid",
                          borderColor: "text.secondary",
                          backgroundColor: "background.default",
                        }}
                      >
                        {currentPlan
                          ? typeof currentPlan === "string"
                            ? currentPlan.replace("_jpy", "")
                            : currentPlan
                          : "Free"}{" "}
                        Plan
                      </Typography>
                      {(currentPlan && typeof currentPlan === "string"
                        ? currentPlan.replace("_jpy", "")
                        : currentPlan) !== "premium" && (
                        <GradationButton onClick={() => navigate("/pricing")} sx={{ ml: 1 }}>
                          <BoltIcon />
                          {t("preferences.planAndBillings.upgrade")}
                        </GradationButton>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </CustomSection>

              {/* サブスクリプション期間 */}
              {subscriptionPeriod && subscriptionPeriod.start && subscriptionPeriod.end && (
                <CustomSection>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={5}>
                      <Typography variant="subtitle1" ml={4}>
                        {t("preferences.planAndBillings.subscriptionPeriod")}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <Typography variant="subtitle1">
                          {localDate(subscriptionPeriod.start, Cookies.get("ui_language") || "ja", false)} ~{" "}
                          {localDate(subscriptionPeriod.end, Cookies.get("ui_language") || "ja", false)}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </CustomSection>
              )}

              {currentPlan && currentPlan !== "free" && (
                <CustomSection>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={5}>
                      <Typography variant="subtitle1" ml={4}>
                        {t("preferences.planAndBillings.cancel")}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "right",
                        }}
                      >
                        <Button
                          disableElevation
                          color={cancelSubscription ? "info" : "error"}
                          onClick={() => handleCreatePortal()}
                        >
                          {cancelSubscription
                            ? t("preferences.planAndBillings.reactivate")
                            : t("preferences.planAndBillings.cancel")}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </CustomSection>
              )}
              <CustomSection>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="subtitle1" ml={4}>
                      {t("preferences.planAndBillings.billings")}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                      {customerExists ? (
                        <Button disableElevation onClick={() => handleCreatePortal()}>
                          {t("preferences.planAndBillings.invoice")}
                        </Button>
                      ) : (
                        <Typography variant="body1">{t("preferences.planAndBillings.noInvoice")}</Typography>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </CustomSection>

              {/* チェックアウト履歴 */}
              {customerExists && checkoutSessions.length > 0 && (
                <CustomSection>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={5}>
                      <div>
                        <Typography variant="subtitle1" ml={4}>
                          {t("preferences.planAndBillings.checkoutHistory")}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" ml={4}>
                          {t("preferences.planAndBillings.checkoutHistoryNote")}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={7}>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                        {isLoadingCheckoutSessions ? (
                          <CircularProgress size={24} />
                        ) : (
                          <>
                            <FormControl sx={{ mr: 1, width: "100%" }}>
                              <InputLabel id="checkout-history-label" size="small">
                                {t("preferences.planAndBillings.selectHistory")}
                              </InputLabel>
                              <Select
                                labelId="checkout-history-label"
                                value={selectedCheckoutSession}
                                onChange={handleCheckoutSessionChange}
                                label={t("preferences.planAndBillings.selectHistory")}
                                size="small"
                              >
                                <MenuItem value="">
                                  <em>{t("preferences.planAndBillings.pleaseSelect")}</em>
                                </MenuItem>
                                {checkoutSessions.map((session) => (
                                  <MenuItem key={session.id} value={session.id}>
                                    {session.created_formatted} - {session.description} ({session.amount_formatted})
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <Button
                              startIcon={<ReceiptIcon />}
                              onClick={() => openReceipt(selectedCheckoutSession)}
                              variant="outlined"
                              color="primary"
                              disabled={!selectedCheckoutSession}
                              sx={{ width: 150 }}
                            >
                              {t("preferences.planAndBillings.receipt")}
                            </Button>
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </CustomSection>
              )}

              {/* 合計クレジット */}
              <CustomSection>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="subtitle1">{t("preferences.planAndBillings.totalCredit")}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                      <Typography variant="subtitle1">{totalCredits}</Typography>
                      <Button onClick={() => navigate("/pricing")} disableElevation color="info" sx={{ ml: 1 }}>
                        {t("preferences.planAndBillings.aboutCredit")}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CustomSection>

              {/* 月額クレジット */}
              <CustomSection>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="subtitle1" sx={{ ml: 4 }}>
                      {t("preferences.planAndBillings.monthlyCredit")}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                      <Typography variant="subtitle1">{credits}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </CustomSection>

              {/* デイリークレジット */}
              <CustomSection>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={5}>
                    <div>
                      <Typography variant="subtitle1" sx={{ ml: 4 }}>
                        {t("preferences.planAndBillings.dailyCredit")}
                      </Typography>
                      {dailyCreditsGrantedAt && (
                        <Typography variant="caption" color="text.secondary" sx={{ ml: 4 }}>
                          {t("preferences.planAndBillings.resetDate")}:{" "}
                          {localDate(dailyCreditsGrantedAt, Cookies.get("ui_language") || "ja", false)}
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                      <Typography variant="subtitle1">{dailyCredits}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </CustomSection>

              {/* 追加クレジット */}
              <CustomSection>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="subtitle1" sx={{ ml: 4 }}>
                      {t("preferences.planAndBillings.extraCredit")}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                      <Typography variant="subtitle1">{oneTimeCredits}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </CustomSection>
            </Paper>
          </Fade>
        )}
      </Box>
    </>
  );
};

export default PlanAndBillings;

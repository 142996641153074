import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  CircularProgress,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { GradationButton } from "../../../../../utils/gradationButton";
import { RowBox } from "../../../../../utils/styledBox";
import PromptActions from "./PromptActions";
import { alpha } from "@mui/material/styles";

interface PromptTextFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  placeholder: string;
  localPrompt: string;
  aiEngine: string;
  rotationDegree: number;
  generatingPrompt: boolean;
  generatingPromptType?: "dice" | "brush"; // 追加：どのアイコンが処理中かを示す
  waitingGenerate: boolean;
  imageCredit: number;
  fetchingCredit: boolean;
  onGenerateSampleText: () => void;
  onUpdatePrompt: () => void;
  onDelete: () => void;
  onSend: () => void;
  setLocalPrompt: (prompt: string) => void;
  requiredPrompt: "REQUIRED" | "OPTIONAL" | "NOT_NEEDED";
}

const PromptTextField: React.FC<PromptTextFieldProps> = ({
  value,
  onChange,
  disabled,
  placeholder,
  localPrompt,
  aiEngine,
  rotationDegree,
  generatingPrompt,
  generatingPromptType,
  waitingGenerate,
  imageCredit,
  fetchingCredit,
  onGenerateSampleText,
  onUpdatePrompt,
  onDelete,
  onSend,
  setLocalPrompt,
  requiredPrompt,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        borderRadius: 1,
        p: 2,
        m: 1,
        backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.3),
        // backdropFilter: "blur(10px)",
        // border: (theme) => `1px solid ${theme.palette.grey[800]}`,
        // borderRightColor: (theme) => theme.palette.grey[900],
        // borderBottomColor: (theme) => theme.palette.grey[900],
      }}
    >
      <RowBox sx={{ gap: 2, alignItems: "flex-end", mb: 1 }}>
        <TextField
          variant="standard"
          autoComplete="off"
          placeholder={placeholder}
          autoFocus
          value={value}
          onChange={onChange}
          disabled={disabled}
          multiline
          fullWidth
          maxRows={8}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="body2" color="secondary" sx={{ ml: 1, display: { xs: "none", sm: "block" } }}>
                  @{aiEngine}:
                </Typography>
              </InputAdornment>
            ),
            sx: {
              borderRadius: 2,
              backgroundColor: "transparent",
              overflow: "hidden",
            },
            inputProps: { maxLength: 1000 },
          }}
          sx={{
            "& .MuiInput-root": {
              "&:before, &:after": {
                border: "none",
                display: "none",
              },
            },
          }}
        />
      </RowBox>
      <RowBox sx={{ gap: 2, justifyContent: "space-between", mt: 0.5 }}>
        <Box sx={{ gap: 2, display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            {/* サイコロアイコン（常に表示、プロンプトが入力されている場合はdisabled） */}
            <Tooltip title={t("juno.history.dice")}>
              <span>
                <IconButton
                  onClick={onGenerateSampleText}
                  disabled={generatingPrompt || disabled || localPrompt.trim().length > 0}
                >
                  <PromptActions
                    localPrompt={localPrompt}
                    aiEngine={aiEngine}
                    rotationDegree={rotationDegree}
                    generatingPrompt={generatingPrompt}
                    onGenerateSampleText={onGenerateSampleText}
                    onUpdatePrompt={onUpdatePrompt}
                    onDelete={onDelete}
                    setLocalPrompt={setLocalPrompt}
                    showDiceOnly={true}
                  />
                </IconButton>
              </span>
            </Tooltip>

            {/* マジックブラシアイコン（処理中はプログレスアイコンを表示、それ以外は通常表示） */}
            <Tooltip title={t("juno.history.improve")}>
              <span>
                <IconButton
                  onClick={onUpdatePrompt}
                  disabled={generatingPrompt || disabled || localPrompt.trim().length === 0}
                >
                  {generatingPrompt && generatingPromptType === "brush" ? (
                    <CircularProgress size={24} color="primary" />
                  ) : (
                    <PromptActions
                      localPrompt={localPrompt}
                      aiEngine={aiEngine}
                      rotationDegree={rotationDegree}
                      generatingPrompt={generatingPrompt}
                      onGenerateSampleText={onGenerateSampleText}
                      onUpdatePrompt={onUpdatePrompt}
                      onDelete={onDelete}
                      setLocalPrompt={setLocalPrompt}
                      showImproveOnly={true}
                    />
                  )}
                </IconButton>
              </span>
            </Tooltip>

            <PromptActions
              localPrompt={localPrompt}
              aiEngine={aiEngine}
              rotationDegree={rotationDegree}
              generatingPrompt={generatingPrompt}
              onGenerateSampleText={onGenerateSampleText}
              onUpdatePrompt={onUpdatePrompt}
              onDelete={onDelete}
              setLocalPrompt={setLocalPrompt}
              showPresetOnly={true}
              disabled={disabled}
            />

            <Tooltip title={t("common.delete")}>
              <span>
                <IconButton onClick={onDelete} disabled={disabled}>
                  <PromptActions
                    localPrompt={localPrompt}
                    aiEngine={aiEngine}
                    rotationDegree={rotationDegree}
                    generatingPrompt={generatingPrompt}
                    onGenerateSampleText={onGenerateSampleText}
                    onUpdatePrompt={onUpdatePrompt}
                    onDelete={onDelete}
                    setLocalPrompt={setLocalPrompt}
                    showDeleteOnly={true}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <Typography variant="caption" color={localPrompt.length > 1000 ? "error" : "textSecondary"}>
            {localPrompt.length}/1000
          </Typography>
        </Box>

        <Typography variant="caption" sx={{ display: { xs: "none", sm: "block" } }}>
          {t("juno.input.warning")}
        </Typography>

        <GradationButton
          onClick={onSend}
          sx={{ minWidth: { xs: 40, sm: 240 }, px: 2 }}
          startIcon={waitingGenerate ? <CircularProgress size={20} /> : <SendIcon />}
          disabled={
            waitingGenerate || generatingPrompt || (requiredPrompt === "REQUIRED" && localPrompt.trim().length === 0)
          }
        >
          <Hidden smDown>{t("juno.input.submit")}</Hidden>
          {fetchingCredit ? <CircularProgress size={16} color="inherit" sx={{ ml: 1 }} /> : `(${imageCredit})`}
        </GradationButton>
      </RowBox>
    </Box>
  );
};

export default PromptTextField;

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLoginModalContext } from "../../context/LoginModalContext";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { useNotice } from "../../context/NoticeContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { validateLogin } from "../../utils/validation";
import ValidationTextField from "../common/ValidationTextField";
import GoogleButton from "./GoogleButton";
import { Images } from "../../assets";

export default function LoginModal() {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isRevealPassword, setIsRevealPassword] = React.useState(false);
  const navigate = useCustomNavigate();
  const loginStatus = useLoginStatus();
  const context = useLoginModalContext();
  const loginContext = useLoginModalContext();
  const { triggerNotice } = useNotice();

  type Inputs = { email: string; password: string };
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<Inputs>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const validationRules = validateLogin(t);

  const handleTogglePasswordVisibility = () => {
    setIsRevealPassword((prev) => !prev);
  };

  const handleLogin = async (data: any) => {
    const { email, password } = data;
    loginContext.setRequestPathToCookie(window.location.pathname);

    try {
      axios.defaults.withCredentials = true;
      const response = await axios.post("/api/v1/auth/login", {
        email,
        password,
      });

      if (response.status === 200 && response.data.success) {
        const res = await loginStatus.checkLoginStatus();
        navigate(loginContext.getRequestPathFromCookie());
        loginContext.setRequestPathToCookie("");
        handleClose();
        triggerNotice("success", `Hello, @${res.user?.username} ! You have successfully logged in.`);
      }
    } catch (error: any) {
      if (error.response) {
        console.error("An unknown error occurred:", error.response);
        setErrorMessage(`An unknown error occurred: ${error.response.data.message}`);
      }
    }
  };

  const handleClose = () => {
    context.setActiveModal(null);
    reset();
    setErrorMessage("");
  };

  return (
    <Modal open={context.activeModal === "login"} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>

        <Box justifyContent="center" display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h5" component="div" gutterBottom textAlign={"center"} fontWeight={600}>
            {t("top.login")}
          </Typography>
          <img src={Images.logo.main} width={"35%"} alt="logo" />
          <form onSubmit={handleSubmit(handleLogin)} noValidate>
            <Controller
              name="email"
              control={control}
              rules={validationRules.email}
              render={({ field }) => (
                <ValidationTextField
                  label="E-mail"
                  type="email"
                  value={field.value}
                  helperText={errors.email?.message ?? ""}
                  error={errors.email !== undefined}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  tooltipMessage={t("top.tooltip.email")}
                  fullWidth
                  sx={{ mb: 2 }}
                  autoComplete="email"
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={validationRules.password}
              render={({ field }) => (
                <ValidationTextField
                  label="Password"
                  type="password"
                  value={field.value}
                  helperText={errors.password?.message ?? ""}
                  error={errors.password !== undefined}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  isRevealPassword={isRevealPassword}
                  onTogglePasswordVisibility={handleTogglePasswordVisibility}
                  tooltipMessage={t("top.tooltip.password")}
                  fullWidth
                  autoComplete="current-password"
                />
              )}
            />

            <Box mt={2} flexDirection="row" display="flex" justifyContent="center">
              <Button
                disableElevation
                variant="text"
                sx={{ marginRight: 2 }}
                onClick={() => {
                  handleClose();
                }}
              >
                {t("common.cancel")}
              </Button>
              <Button disableElevation type={"submit"} variant="contained">
                {t("top.login")}
              </Button>
            </Box>
            <Box mt={2} flexDirection="row" display="flex" justifyContent="center">
              {errorMessage && (
                <Typography variant="body2" color="error">
                  {errorMessage}
                </Typography>
              )}
            </Box>
          </form>

          {/* SignupModalボタン */}
          <Box mt={2} flexDirection="row" display="flex" justifyContent="center">
            <Button
              variant="text"
              sx={{ marginRight: 1 }}
              size={"small"}
              color="secondary"
              onClick={() => {
                handleClose();
                context.setActiveModal("passwordReset");
              }}
            >
              {t("top.forgotPassword")}
            </Button>
            <Button
              variant="text"
              size={"small"}
              color="secondary"
              onClick={() => {
                handleClose();
                context.setActiveModal("signup");
              }}
            >
              {t("top.createAccount")}
            </Button>
          </Box>
          <GoogleButton handleClose={handleClose} />
          <Typography variant="caption" color="warning.light" mt={2}>
            {t("top.warn")}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}

import SendIcon from "@mui/icons-material/Send";
import StopIcon from "@mui/icons-material/Stop";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";

interface MessageInputProps {
  value: string;
  onChange: (value: string) => void;
  onSend: () => void;
  onStop: () => void;
  processing: boolean;
  error: boolean;
}

export const MessageInput: React.FC<MessageInputProps> = ({ value, onChange, onSend, onStop, processing, error }) => {
  const { t } = useTranslation();

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!processing && ((event.ctrlKey && event.key === "Enter") || (event.metaKey && event.key === "Enter"))) {
      onSend();
    }
  };

  return (
    <Box sx={{ mb: 1.5, width: "100%", display: "flex" }}>
      <TextField
        fullWidth
        value={value}
        onChange={(e) => onChange(e.target.value)}
        variant="standard"
        label={t("browsing.searchPlaceholder")}
        type="text"
        maxRows={20}
        autoComplete="off"
        autoFocus={true}
        color="primary"
        onKeyDown={handleKeyDown}
        size="small"
        multiline
        sx={{
          "& .MuiInput-root": {
            "&:before, &:after": {
              border: "none",
              display: "none",
            },
          },
        }}
        InputProps={{
          sx: {
            borderRadius: 2,
            backgroundColor: "transparent",
            overflow: "hidden",
          },
          endAdornment: (
            <Box sx={{ display: "flex", height: "100%", alignItems: "flex-end" }}>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (value.length > 0 && !processing) {
                    onSend();
                  }
                  if (processing) {
                    e.stopPropagation();
                    onStop();
                  }
                }}
              >
                {processing ? (
                  <Box sx={{ position: "relative", cursor: "pointer", display: "flex", alignItems: "center" }}>
                    <CircularProgress size={24} color="error" />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "14px",
                        left: "12px",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <StopIcon sx={{ width: 20, height: 20 }} color="error" />
                    </Box>
                  </Box>
                ) : (
                  <Tooltip title={t("browsing.send")} placement="top">
                    <SendIcon color={value.length > 0 ? "secondary" : "disabled"} />
                  </Tooltip>
                )}
              </IconButton>
            </Box>
          ),
        }}
        inputProps={{ maxLength: 10000 }}
        error={error}
      />
    </Box>
  );
};

import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import CopyButtonOrIcon from "../../../../components/common/CopyButtonOrIcon";
import { HistoryParamsType } from "../../../../types/junoTypes";
import { styleTranslate } from "../../../../utils/styleTranslate";

interface HistoryParamsProps {
  params: HistoryParamsType;
  grid: number;
}

interface ReadOnlyFieldProps {
  value: string | number;
  label: string;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
}

const ReadOnlyField: React.FC<ReadOnlyFieldProps> = ({ value, label, endAdornment, startAdornment }) => (
  <TextField
    value={String(value)}
    variant="standard"
    label={label}
    InputProps={{
      readOnly: true,
      endAdornment,
      startAdornment,
    }}
    InputLabelProps={{ shrink: true }}
    size="small"
    fullWidth
  />
);

const HistoryParams: React.FC<HistoryParamsProps> = ({ params, grid }) => {
  const { t } = useTranslation();

  const renderField = (condition: boolean, element: React.ReactNode) => {
    return condition ? (
      <Grid item xs={12} sm={grid}>
        {element}
      </Grid>
    ) : null;
  };

  return (
    <Grid container spacing={2}>
      {renderField(
        Boolean(params?.width && params?.height),
        <ReadOnlyField value={`W: ${params?.width}  x  H: ${params?.height}`} label={t("juno.history.size")} />
      )}

      {renderField(
        Boolean(params?.aspect_ratio),
        <ReadOnlyField value={params?.aspect_ratio || ""} label={t("juno.input.aspectRatio.title")} />
      )}

      {params?.action === "upscale" && (
        <>
          {renderField(
            Boolean(params?.factor),
            <ReadOnlyField
              value={params?.factor || 0}
              label={t("juno.input.scale")}
              startAdornment={
                <InputAdornment position="start" color="secondary">
                  x
                </InputAdornment>
              }
            />
          )}

          {renderField(
            Boolean(params?.creativity),
            <ReadOnlyField value={params?.creativity || ""} label={t("juno.input.creativity")} />
          )}

          {renderField(
            Boolean(params?.detail),
            <ReadOnlyField value={params?.detail || ""} label={t("juno.input.detail")} />
          )}

          {renderField(
            Boolean(params?.resemblance),
            <ReadOnlyField value={params?.resemblance || ""} label={t("juno.input.resemblance")} />
          )}
        </>
      )}

      {renderField(
        Boolean(params?.style),
        <ReadOnlyField value={t(styleTranslate(params?.style || ""))} label={t("juno.input.style.title")} />
      )}

      {renderField(
        ["Flux11ProUltra", "Flux11ProUltraRedux", "Flux11ProUltraJuno"].includes(params?.ai_engine || ""),
        <ReadOnlyField value={params?.raw ? "ON" : "OFF"} label={t("juno.input.rawMode")} />
      )}

      {renderField(
        params?.style_weight !== undefined && params?.style_weight !== null,
        <ReadOnlyField value={params?.style_weight || 0} label={t("juno.input.style.weight")} />
      )}

      {renderField(
        Boolean(params?.image_strength),
        <ReadOnlyField value={params?.image_strength || 0} label={t("juno.input.imageStrength")} />
      )}

      {renderField(
        Boolean(params?.image_prompt_strength),
        <ReadOnlyField value={params?.image_prompt_strength || 0} label={t("juno.input.imageStrength")} />
      )}

      {renderField(
        Boolean(params?.lora?.name),
        <ReadOnlyField value={params?.lora_strength || 0} label={t("juno.input.loraStrength")} />
      )}

      {renderField(Boolean(params?.step), <ReadOnlyField value={params?.step || 0} label={t("juno.input.step")} />)}

      {renderField(
        Boolean(params?.guidance_scale),
        <ReadOnlyField value={params?.guidance_scale || 0} label={t("juno.input.guidanceScale")} />
      )}

      {renderField(
        Boolean(params?.initial_latent),
        <ReadOnlyField
          value={t(`juno.input.initialLatent.${params?.initial_latent}`) || ""}
          label={t("juno.input.initialLatent.title")}
        />
      )}

      {renderField(
        Boolean(params?.manual_placement_selection),
        <ReadOnlyField
          value={t(`juno.input.manualPlacementSelection.${params?.manual_placement_selection}`) || ""}
          label={t("juno.input.manualPlacementSelection.title")}
        />
      )}

      {renderField(
        (params?.seed || 0) > 0,
        <ReadOnlyField
          value={String(params?.seed || 0)}
          label={t("juno.input.seedSD")}
          endAdornment={
            <InputAdornment position="end" color="secondary">
              <CopyButtonOrIcon displayType="icon" textToCopy={String(params?.seed || 0)} size="small" />
            </InputAdornment>
          }
        />
      )}
    </Grid>
  );
};

export default HistoryParams;

import LoginIcon from "@mui/icons-material/Login";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import FadeInSection from "../../../../components/common/FadeInSection";
import { useLoginModalContext } from "../../../../context/LoginModalContext";
import { Images } from "../../../../assets";
import { GradationButton } from "../../../../utils/gradationButton";
import { ColumnCenteredBox } from "../../../../utils/styledBox";

const CTASection = () => {
  const { t } = useTranslation();
  const { setActiveModal } = useLoginModalContext();

  // アニメーションバリアント
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
    hover: {
      scale: 1.05,
      boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
      transition: {
        duration: 0.3,
      },
    },
  };

  const logoVariants = {
    hidden: { opacity: 0, scale: 0.5, rotate: -10 },
    visible: {
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
    hover: {
      scale: 1.1,
      rotate: 5,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };

  return (
    <ColumnCenteredBox
      sx={{
        backgroundImage: `url(${Images.common.bg01})`,
        backgroundSize: "cover",
        backgroundPosition: "0px 0px",
        borderRadius: 2,
        backgroundRepeat: "no-repeat",
        p: { xs: 2, sm: 3 },
      }}
    >
      <FadeInSection>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          <ColumnCenteredBox sx={{ py: { xs: 4, sm: 8 }, px: 2 }} gap={4}>
            <motion.div variants={itemVariants}>
              <ColumnCenteredBox>
                <Typography variant={"h4"} component={"h2"} fontFamily={"'Noto Serif JP', roboto"} gutterBottom>
                  {t("top.fv.cta.title")}
                </Typography>
                <Typography>{t("top.fv.cta.description")}</Typography>
              </ColumnCenteredBox>
            </motion.div>

            <motion.img
              variants={logoVariants}
              whileHover="hover"
              src={Images.tenak.small}
              alt={"tenakai"}
              style={{ width: "64px", height: "64px" }}
            />

            <Box display={"flex"} justifyContent={"center"} flexDirection={{ xs: "column", md: "row" }} gap={2}>
              <motion.div variants={buttonVariants} whileHover="hover">
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => setActiveModal("login")}
                  sx={{ width: 200 }}
                  startIcon={<LoginIcon />}
                >
                  {t("top.fv.cta.loginButton")}
                </Button>
              </motion.div>

              <motion.div variants={buttonVariants} whileHover="hover">
                <GradationButton startIcon={<LoginIcon />} sx={{ width: 200 }} onClick={() => setActiveModal("signup")}>
                  {t("top.fv.cta.signupButton")}
                </GradationButton>
              </motion.div>
            </Box>
          </ColumnCenteredBox>
        </motion.div>
      </FadeInSection>
    </ColumnCenteredBox>
  );
};

export default CTASection;

import { Fade, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactFlow, { Background, Controls, useEdgesState, useNodesState } from "reactflow";
import "reactflow/dist/style.css";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import Meta from "../../components/common/Meta";
import { uiHeight } from "../../utils/uiHeight";
import CustomNode from "./components/CustomNode";

const nodeTypes = { custom: CustomNode };

const MindMapPublicView = () => {
  const [title, setTitle] = useState("");
  const { mindMapUuid, publicUuid } = useParams();
  const [status, setStatus] = useState();
  const { t } = useTranslation();
  const theme = useTheme();

  const getMindMap = async () => {
    try {
      const res = await axios.get("/api/v1/mind-map/" + mindMapUuid + "/" + publicUuid);
      if (res.data) {
        setTitle(res.data.title);
        setStatus(res.data.status);
        setNodes(res.data.nodes);
        setEdges(res.data.edges);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  useEffect(() => {
    getMindMap();
  }, [mindMapUuid]);

  const styles = {
    padding: 0,
  };

  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const onLoad = (reactFlowInstance) => {
    // reactFlowInstance.setInteractive(false);
    reactFlowInstance.fitView();
  };
  const onNodesChange = useCallback((changes) => {}, []);
  const onEdgesChange = useCallback((changes) => {}, []);
  const onConnect = useCallback((connection) => {}, []);

  return (
    <>
      <Meta
        title={title + " | " + t("library.mindmap.title")}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
      />
      <Fade in={true} timeout={1000}>
        <Box>
          {status !== 3 && (
            <Box>
              <DrawerHeader />
              <Box sx={{ maxWidth: "100%!important", px: 1 }}>
                <Paper
                  elevation={1}
                  sx={{
                    width: "100%",
                    height: { xs: `calc(${uiHeight()} - 16px)`, position: "relative" },
                  }}
                >
                  <Typography
                    variant={"subtitle1"}
                    sx={{
                      position: "absolute",
                      top: 8,
                      left: 16,
                      zIndex: 1000,
                    }}
                  >
                    {title}
                  </Typography>
                  <ReactFlow
                    nodes={nodes}
                    nodeTypes={nodeTypes}
                    edges={edges}
                    onInit={onLoad}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    fitView
                    maxZoom={1.5}
                    minZoom={0.1}
                    nodesDraggable={true}
                    nodesConnectable={true}
                    elementsSelectable={false}
                    proOptions={{ hideAttribution: true }}
                    style={styles}
                  >
                    <Background />
                    <Controls showInteractive={false} />
                  </ReactFlow>
                </Paper>
              </Box>
            </Box>
          )}
        </Box>
      </Fade>
    </>
  );
};
export default MindMapPublicView;

import Crop169Icon from "@mui/icons-material/Crop169";
import Crop32Icon from "@mui/icons-material/Crop32";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../../context/juno/VideoContext";
import { ColumnBox, RowCenteredBox } from "../../../../../utils/styledBox";
import AspectRatioIcons from "../AspectRatioIcons";
import ImageUpload from "../ImageUpload";
import ModelVersionSelectBox from "../ModelVersionSelectBox";

const ParamsLuma = () => {
  const { t } = useTranslation();
  const { menu, updateParams, image1, setImage1, duration, modelVersion, setLumaLoop, lumaLoop, image2, setImage2 } =
    useVideo();

  const aspectRatio: AspectRatioIconType[] = [
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "4:3", icon: Crop32Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
    { ratio: "3:4", icon: Crop32Icon, rotate: "90deg" },
  ];

  const durationMarks = [
    {
      value: 5,
      label: "5s",
    },
    {
      value: 9,
      label: "9s",
    },
  ];

  useEffect(() => {
    if (
      (duration.LUMA !== 5 &&
        (modelVersion.LUMA === "RAY_2" || modelVersion.LUMA === "RAY_2_FLASH") &&
        menu === "i2v") ||
      (modelVersion.LUMA === "RAY_2_1080P" && menu === "i2v")
    ) {
      updateParams("LUMA", "duration", 5);
    }
  }, [modelVersion.LUMA, duration.LUMA, menu]);

  // RAY_2モデルで2枚の画像がセットされている場合、Loopを無効化
  const isLoopDisabled = modelVersion.LUMA.includes("RAY") && menu === "i2v" && image1 !== "" && image2 !== "";

  useEffect(() => {
    if (isLoopDisabled) {
      setLumaLoop(false);
    }
  }, [isLoopDisabled, setLumaLoop]);

  const replaceImage = () => {
    const tmp = image1;
    setImage1(image2);
    setImage2(tmp);
  };

  return (
    <ColumnBox sx={{ gap: 4 }}>
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <ModelVersionSelectBox
          platform="LUMA"
          value={modelVersion.LUMA}
          onChange={(value) => updateParams("LUMA", "modelVersion", value)}
          menu={menu}
        />
      </ColumnBox>

      <ColumnBox>
        {/* Duration */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.duration")}
        </Typography>
        <Slider
          aria-label="duration"
          marks={durationMarks}
          min={5}
          max={9}
          step={4}
          value={duration.LUMA}
          onChange={(_, value) => updateParams("LUMA", "duration", value)}
          disabled={
            modelVersion.LUMA === "V1_5" ||
            (modelVersion.LUMA === "RAY_2" && menu === "i2v") ||
            (modelVersion.LUMA === "RAY_2_1080P" && menu === "i2v") ||
            (modelVersion.LUMA === "RAY_2_FLASH" && menu === "i2v") ||
            (modelVersion.LUMA === "RAY_2_FLASH_1080P" && menu === "i2v")
          }
        />
      </ColumnBox>

      {menu === "i2v" && modelVersion.LUMA == "V1_5" && (
        <ColumnBox sx={{ justifyContent: "center" }}>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")}
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
        </ColumnBox>
      )}
      {menu === "i2v" &&
        (modelVersion.LUMA === "RAY_2" ||
          modelVersion.LUMA === "RAY_2_1080P" ||
          modelVersion.LUMA === "RAY_2_FLASH" ||
          modelVersion.LUMA === "RAY_2_FLASH_1080P") && (
          <ColumnBox sx={{ justifyContent: "center" }}>
            <Typography variant="body2" mb={1}>
              {t("juno.video.params.upload")} - FIRST
            </Typography>
            <ImageUpload image={image1} setImage={setImage1} />
            <RowCenteredBox sx={{ my: 2 }}>
              <IconButton onClick={() => replaceImage()}>
                <SwapVertIcon />
              </IconButton>
            </RowCenteredBox>
            <Typography variant="body2" mb={1}>
              {t("juno.video.params.upload")} - LAST
            </Typography>
            <ImageUpload image={image2} setImage={setImage2} />
          </ColumnBox>
        )}

      <ColumnBox>
        {/* aspect */}
        <AspectRatioIcons aspectRatioList={aspectRatio} />
      </ColumnBox>
      <ColumnBox>
        {/* loop */}
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={lumaLoop} onChange={() => setLumaLoop(!lumaLoop)} disabled={isLoopDisabled} />}
            label={t("juno.video.params.loop")}
          />
        </FormGroup>{" "}
      </ColumnBox>
    </ColumnBox>
  );
};
export default ParamsLuma;

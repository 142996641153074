import { Grid, Pagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiRequest } from "../../../utils/api";
import { StyledTypography } from "../../../utils/styledTypography";
import ArticleCard from "./ArticleCard";

interface BookmarksProps {
  view: string;
}

const Bookmarks = ({ view }: BookmarksProps) => {
  const { t } = useTranslation();
  interface BookmarkArticle {
    uuid: string;
    title: string;
    content: string;
    user: string;
    avatar: string;
    thumbnail: string;
    created_at: string;
  }

  interface PaginationData {
    total: number;
    page: number;
    page_size: number;
    total_pages: number;
  }

  const [bookmarks, setBookmarks] = useState<BookmarkArticle[]>([]);
  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    total: 0,
    page: 1,
    page_size: 20,
    total_pages: 0,
  });

  const getBookmarks = async (page: number) => {
    try {
      const response = await apiRequest(
        `/api/v1/notes-nebula/?type=bookmarks&page=${page}&page_size=${pagination.page_size}`
      );
      if (response.data.status === "success") {
        setBookmarks(response.data.data.bookmarks);
        setPagination(response.data.data.pagination);

        response.data.data.bookmarks.map((item: BookmarkArticle) => {
          if (item["thumbnail"]) {
            const img = new Image();
            img.src = item["thumbnail"];
            img.onload = () => {
              setLoadedImages((loadedImages) => [...loadedImages, item.uuid]);
            };
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    getBookmarks(page);
  };

  useEffect(() => {
    if (view === "bookmarks") {
      setPagination((prev) => ({ ...prev, page: 1 }));
      getBookmarks(1);
    }
  }, [view]);

  return (
    <>
      <StyledTypography variant={"h6"} sx={{ mb: 2 }}>
        {t("notesNebula.bookmarks")}
      </StyledTypography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {bookmarks.length > 0 ? (
          bookmarks.map((item: BookmarkArticle, index: number) => (
            <Grid item xs={12} md={4} lg={2.4} key={`${item.uuid}-${view}`}>
              <ArticleCard article={item} loadedImages={loadedImages} index={index} key={`${item.uuid}-${view}`} />
            </Grid>
          ))
        ) : (
          <Typography variant="subtitle1" sx={{ my: 2, ml: 4 }}>
            No bookmarks found.
          </Typography>
        )}
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: 4 }}>
        <Pagination count={pagination.total_pages} page={pagination.page} onChange={handlePageChange} color="primary" />
      </Grid>
    </>
  );
};

export default Bookmarks;

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import LastPageIcon from "@mui/icons-material/LastPage";
import PhotoSizeSelectSmallIcon from "@mui/icons-material/PhotoSizeSelectSmall";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { Box, Button, Fade, Grid, IconButton, Modal, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollableBox from "../../../../components/common/ScrollableBox";
import { API_STATUS } from "../../../../context/juno/VideoContext";
import { useVideoOperations } from "../../../../hooks/useVideoOperations";
import { ColumnCenteredBox, RowBox } from "../../../../utils/styledBox";
import { getActionIcon, getIcon } from "./utils";
import { alpha } from "@mui/material/styles";

// --------------------------------------------------
// 型定義
// --------------------------------------------------
type VideoType = {
  uuid: string;
  status: number;
  params: {
    action: string;
    aiEngine: string;
    model: string;
    prompt: string;
    duration: number;
    aspectRatio: string;
    style?: string;
    steps?: number;
    negativePrompt?: string;
    fps?: number;
  };
  url: string;
};

interface VideoModalProps {
  open: boolean;
  onClose: () => void;
  videos: VideoType[];
  currentIndex: number; // 現在の動画Index
  handleNextVideo: () => void; // 次の動画へ
  handlePrevVideo: () => void; // 前の動画へ
  // 親側の同じ削除ダイアログを開く関数
  handleOpenDeleteDialog: (uuid: string) => void;
  // 動画ダウンロード
  handleVideoDownload: (uuid: string) => Promise<void>;
}

/**
 * MUI Modal で動画を表示し、
 * 左右ボタンでの動画切り替えと削除・ダウンロードを行うコンポーネント
 */
const VideoModal: React.FC<VideoModalProps> = ({
  open,
  onClose,
  videos,
  currentIndex,
  handleNextVideo,
  handlePrevVideo,
  handleOpenDeleteDialog,
  handleVideoDownload,
}) => {
  // videos から currentIndex で動画を取得
  const modalVideo = videos[currentIndex] ?? null;
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoSize, setVideoSize] = useState<string>("");
  const videoOperations = useVideoOperations();

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.5),
          backdropFilter: "blur(20px)",
          boxShadow: 24,
          p: 2,
          outline: "none",
          borderRadius: 2,
          maxHeight: { xs: "calc(85vh)", sm: "calc(80vh + 200px)" },
        }}
        overflow={"auto"}
      >
        {/* 閉じるボタン */}
        <IconButton onClick={onClose} sx={{ position: "absolute", top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>

        {modalVideo && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* 動画のAIエンジン情報など */}
              <RowBox alignItems="center" justifyContent="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    px: 1,
                    py: 0.2,
                    mr: 2,
                    backgroundColor: (theme) => theme.palette.secondary.dark,
                    borderRadius: "2px",
                    display: "inline-flex",
                  }}
                  color="#fff"
                  component="span"
                >
                  {getActionIcon(modalVideo.params.action)}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 600, mr: 1 }} color="secondary">
                  {t(`juno.video.ai.${modalVideo.params.aiEngine.toLowerCase()}.title`)} -{" "}
                  {t(`juno.video.ai.${modalVideo.params.aiEngine.toLowerCase()}.model.${modalVideo.params.model}`)}
                </Typography>
                {getIcon(modalVideo.params.model)}
              </RowBox>
            </Grid>
            <Grid item xs={12} sm={10}>
              {/* 動画再生部分 */}
              <Fade in={true} timeout={500} key={modalVideo.url}>
                <Box
                  sx={{
                    width: "100%",
                    height: "80vh",
                    backgroundColor: "#000",
                    borderRadius: 2,
                    overflow: "hidden",
                  }}
                >
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    ref={videoRef}
                    controls
                    controlsList="nodownload"
                    disablePictureInPicture
                    loop
                    autoPlay
                    crossOrigin="anonymous"
                    muted
                    preload="metadata"
                    key={modalVideo.url}
                    // onLoadStart={() => setVideoSize("")}
                    onLoadedMetadata={(e) => {
                      const video = e.currentTarget;
                      if (video.videoWidth && video.videoHeight) {
                        setVideoSize(`W: ${video.videoWidth} x H: ${video.videoHeight}`);
                      }
                    }}
                  >
                    <source src={modalVideo.url} type="video/mp4" key={modalVideo.url} />
                  </video>
                </Box>
              </Fade>
            </Grid>
            <Grid item xs={12} sm={2}>
              <ScrollableBox gap={2} sx={{ height: { xs: "100%", sm: "80vh" }, overflow: "auto" }}>
                <ColumnCenteredBox gap={2} sx={{ pr: 2, pt: 1 }}>
                  {videoSize && (
                    <TextField
                      label={t("juno.video.params.size")}
                      value={videoSize}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  )}
                  {modalVideo.params.prompt && (
                    <TextField
                      label={t("juno.video.params.input.label")}
                      value={modalVideo.params.prompt}
                      fullWidth
                      multiline
                      rows={20}
                      InputProps={{ readOnly: true }}
                    />
                  )}
                  {!!modalVideo.params.duration && (
                    <TextField
                      label={t("juno.video.params.duration")}
                      value={modalVideo.params.duration}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  )}
                  {!!modalVideo.params.steps && (
                    <TextField
                      label={t("juno.video.params.inferenceSteps")}
                      value={modalVideo.params.steps}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  )}
                  {modalVideo.params.aspectRatio && (
                    <TextField
                      label={t("juno.video.params.aspect")}
                      value={modalVideo.params.aspectRatio}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  )}
                  {modalVideo.params.fps && (
                    <TextField
                      label={t("juno.video.params.fps")}
                      value={modalVideo.params.fps}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  )}
                  {modalVideo.params.style && (
                    <TextField
                      label={t("juno.video.params.style")}
                      value={t(
                        `juno.video.ai.${modalVideo.params.aiEngine.toLowerCase()}.style.${modalVideo.params.style}`
                      )}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  )}
                  {modalVideo.params.negativePrompt && (
                    <TextField
                      label={t("juno.video.params.negativePrompt")}
                      value={modalVideo.params.negativePrompt}
                      rows={20}
                      multiline
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  )}
                </ColumnCenteredBox>
              </ScrollableBox>
            </Grid>

            {/* 下部ボタン類 */}
            <Grid item xs={12}>
              <RowBox gap={1} justifyContent="space-between">
                {/* 前へ */}
                <IconButton onClick={handlePrevVideo}>
                  <ArrowBackIosNewIcon />
                </IconButton>

                {/* 削除・ダウンロード・閉じる */}
                <RowBox gap={1} justifyContent="center" flexDirection={{ xs: "column", sm: "row" }}>
                  <Button
                    variant="outlined"
                    disableElevation
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      // 親の同じダイアログを開く関数を呼び出す
                      handleOpenDeleteDialog(modalVideo.uuid);
                    }}
                  >
                    {t("common.delete")}
                  </Button>

                  <Button
                    variant="outlined"
                    startIcon={<LastPageIcon />}
                    onClick={() => {
                      if (videoRef.current) {
                        // videoRefからURLを取得してフック関数を呼び出す
                        const url = videoRef.current.currentSrc;
                        videoOperations.handleLastFrame(url, undefined, onClose);
                      }
                    }}
                    disabled={modalVideo.status !== API_STATUS.SUCCESS}
                    disableElevation
                  >
                    {t("juno.video.videoMenu.lastFrame")}
                  </Button>

                  <Button
                    variant="outlined"
                    startIcon={<RecordVoiceOverIcon />}
                    onClick={() => {
                      videoOperations.handleLipSync(modalVideo.uuid, onClose);
                    }}
                    disableElevation
                    disabled={modalVideo.status !== API_STATUS.SUCCESS}
                  >
                    {t("juno.video.menu.lipsync")}
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<PhotoSizeSelectSmallIcon />}
                    onClick={() => {
                      videoOperations.handleUpscale(modalVideo.uuid, onClose);
                    }}
                    disabled={modalVideo.status !== API_STATUS.SUCCESS}
                    disableElevation
                  >
                    {t("juno.video.menu.upscale")}
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    disabled={modalVideo.status !== API_STATUS.SUCCESS}
                    onClick={() => handleVideoDownload(modalVideo.uuid)}
                    disableElevation
                  >
                    {t("common.download")}
                  </Button>

                  <Button variant="contained" onClick={onClose} disableElevation>
                    {t("common.close")}
                  </Button>
                </RowBox>

                {/* 次へ */}
                <IconButton onClick={handleNextVideo}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </RowBox>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default VideoModal;

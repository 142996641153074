import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Voices } from "../../../../assets";
import sampleVoice from "../../../../json/sampleVoice.json";
import { alpha } from "@mui/material/styles";

type DescriptionModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  provider: string;
};

export default function DescriptionModal({ open, setOpen, provider }: DescriptionModalProps) {
  const { t } = useTranslation();
  const sampleText = sampleVoice;
  const locale = useTranslation().i18n.language;
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [playingUrl, setPlayingUrl] = useState<string | null>(null);

  const stopAudio = () => {
    if (audio) {
      audio.pause();
      setAudio(null);
      setPlayingUrl(null);
    }
  };

  const toggleAudio = (url: string) => {
    // 同じURLが既に再生中の場合は停止
    if (playingUrl === url) {
      if (audio) {
        audio.pause();
        setAudio(null);
        setPlayingUrl(null);
      }
      return;
    }

    // 別の音声を再生中の場合は、現在の音声を停止してから新しい音声を再生
    if (audio) {
      audio.pause();
    }
    const newAudio = new Audio(url);
    newAudio.play();
    setAudio(newAudio);
    setPlayingUrl(url);
    newAudio.addEventListener("ended", () => {
      setAudio(null);
      setPlayingUrl(null);
    });
  };

  // コンポーネントがアンマウントされる時に音声を停止
  useEffect(() => {
    return () => {
      if (audio) {
        audio.pause();
      }
    };
  }, [audio]);

  const audioItems = [
    { label: t("voice.openaiVoiceLabel.Alloy"), gender: t("voice.gender.male"), url: "alloy.mp3" },
    { label: t("voice.openaiVoiceLabel.Ash"), gender: t("voice.gender.male"), url: "ash.mp3" },
    { label: t("voice.openaiVoiceLabel.Echo"), gender: t("voice.gender.male"), url: "echo.mp3" },
    { label: t("voice.openaiVoiceLabel.Coral"), gender: t("voice.gender.female"), url: "coral.mp3" },
    { label: t("voice.openaiVoiceLabel.Fable"), gender: t("voice.gender.male"), url: "fable.mp3" },
    { label: t("voice.openaiVoiceLabel.Onyx"), gender: t("voice.gender.male"), url: "onyx.mp3" },
    { label: t("voice.openaiVoiceLabel.Nova"), gender: t("voice.gender.female"), url: "nova.mp3" },
    { label: t("voice.openaiVoiceLabel.Sage"), gender: t("voice.gender.female"), url: "sage.mp3" },
    { label: t("voice.openaiVoiceLabel.Shimmer"), gender: t("voice.gender.female"), url: "shimmer.mp3" },
  ];

  return (
    <Modal
      open={open}
      onClose={() => {
        stopAudio();
        setOpen(false);
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Paper
          sx={{
            p: 2,
            minWidth: { xs: "90vw", sm: "70vw", md: "40vw" },
            maxHeight: "90vh",
            backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.5),
            backdropFilter: "blur(10px)",
          }}
        >
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={1}>
            <GraphicEqIcon />
            <Typography variant={"subtitle1"} gutterBottom>
              {t("voice.typeDescription")}
            </Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          {provider === "google" && (
            <>
              <List>
                <ListItem>
                  <ListItemText
                    primary={t("voice.googleVoiceLabel.neural")}
                    secondary={t("voice.voiceDescription.neural")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t("voice.googleVoiceLabel.standard")}
                    secondary={t("voice.voiceDescription.standard")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t("voice.googleVoiceLabel.wavenet")}
                    secondary={t("voice.voiceDescription.wavenet")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t("voice.googleVoiceLabel.studio")}
                    secondary={t("voice.voiceDescription.studio")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t("voice.googleVoiceLabel.journey")}
                    secondary={t("voice.voiceDescription.journey")}
                  />
                </ListItem>
              </List>
            </>
          )}

          {/* open ai */}
          {provider === "openai" && (
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} py={1}>
              <TextField
                label={t("voice.openaiVoiceLabel.description")}
                variant={"outlined"}
                sx={{ mb: 2 }}
                fullWidth
                multiline
                value={sampleText[locale]}
                size={"small"}
                autoFocus
              ></TextField>
              <TableContainer
                component={Paper}
                elevation={0}
                sx={{
                  maxHeight: "60vh",
                }}
              >
                <Table sx={{ width: "100%" }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Gender</TableCell>
                      <TableCell align="center">English</TableCell>
                      <TableCell align="center">Japanese</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {audioItems.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row">
                          {item.label}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {item.gender}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() =>
                              toggleAudio(Voices.en[item.url.replace(".mp3", "") as keyof typeof Voices.en])
                            }
                          >
                            {playingUrl === Voices.en[item.url.replace(".mp3", "") as keyof typeof Voices.en] ? (
                              <StopIcon color={"error"} />
                            ) : (
                              <PlayArrowIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() =>
                              toggleAudio(Voices.ja[item.url.replace(".mp3", "") as keyof typeof Voices.ja])
                            }
                          >
                            {playingUrl === Voices.ja[item.url.replace(".mp3", "") as keyof typeof Voices.ja] ? (
                              <StopIcon color={"error"} />
                            ) : (
                              <PlayArrowIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"} p={2}>
            <Button
              disableElevation
              variant={"contained"}
              onClick={() => {
                stopAudio();
                setOpen(false);
              }}
            >
              {t("common.close")}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}

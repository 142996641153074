import React from "react";
import { useTranslation } from "react-i18next";
import { AiEngineList, AiParams, MenuList } from "../types/junoTypes";

export type ImageModel = {
  value: AiEngineList;
  label: string;
  description: string;
  category: string[];
};

export type ImageModels = {
  txt2img: ImageModel[];
  img2img: ImageModel[];
  editor: ImageModel[];
  upscale: ImageModel[];
};

export const useJunoModels = () => {
  const { t } = useTranslation();

  const getModelParams = (aiEngine: AiEngineList, menu: MenuList) => {
    let params: AiParams[] = [];
    switch (aiEngine) {
      case "DallE3":
        params.push("prompt", "height", "width", "aspectRatio", "style", "revisedPrompt");
        break;
      case "SD35":
      case "SDUltra":
        params.push("prompt", "aspectRatio", "negativePrompt", "seed");
        break;
      case "Flux11Pro":
        params.push("sample", "prompt", "aspectRatio", "seed");
        break;
      case "Flux11ProRedux":
        params.push("prompt", "aspectRatio", "seed");
        break;
      case "Flux11ProUltra":
        params.push("sample", "prompt", "aspectRatio", "rawMode", "seed");
        break;
      case "Flux11ProUltraRedux":
        params.push("prompt", "aspectRatio", "rawMode", "seed");
        break;
      case "Flux1ProCanny":
      case "Flux1ProDepth":
        params.push("prompt", "aspectRatio", "seed", "step", "guidanceScale");
        break;
      case "Flux1ProCannyJuno":
      case "Flux1ProDepthJuno":
        params.push("prompt", "aspectRatio", "style", "styleWeight", "seed", "step", "guidanceScale");
        break;
      case "Flux1ProJuno":
        params.push("sample", "prompt", "aspectRatio", "style", "styleWeight", "guidanceScale", "seed");
        break;
      case "Flux11ProUltraJuno":
        params.push("sample", "prompt", "aspectRatio", "style", "styleWeight", "rawMode", "seed");
        break;
      case "Flux1Dev":
        params.push("sample", "aspectRatio", "seed", "step", "guidanceScale");
        break;
      case "Flux1DevRedux": // Samplesが動かないので一時的に削除
      case "Flux1SchnellRedux":
        params.push("aspectRatio", "seed", "step", "guidanceScale");
        break;
      case "Flux1Schnell":
        params.push("prompt", "sample", "aspectRatio", "seed", "step", "guidanceScale");
        break;
      case "FluxPuLID":
        params.push("aspectRatio", "seed", "step", "guidanceScale", "negativePrompt");
        break;
      case "Flux1RealismLoRA":
        params.push("prompt", "aspectRatio", "seed", "step", "guidanceScale");
        break;
      case "FluxJuno":
        params.push("sample", "prompt", "aspectRatio", "style", "styleWeight", "seed", "step", "guidanceScale");
        break;
      case "Flux1DevCannyLoRA":
      case "Flux1DevDepthLoRA":
        params.push("sample", "prompt", "aspectRatio", "style", "styleWeight", "seed", "step", "guidanceScale");
        break;
      case "Flux1ProFill":
        params.push("prompt", "editor", "seed");
        break;
      case "Flux1ProFillJuno":
        params.push("prompt", "editor", "style", "styleWeight", "seed");
        break;
      case "Flux1DevFill":
        params.push("prompt", "editor", "style", "styleWeight", "seed", "step", "sample");
        break;
      case "GeminiFlashEdit":
        params.push("prompt");
        break;
      case "KolorsVTO":
        params.push("prompt");
        break;
      case "BriaBackgroundReplace":
        params.push("prompt", "negativePrompt", "seed", "sample");
        break;
      case "BriaProductShot":
        params.push("prompt", "sample", "aspectRatio", "manualPlacementSelection");
        break;
      case "IcLightV2":
        params.push("sample", "prompt", "aspectRatio", "seed", "step", "guidanceScale", "initialLatent");
        break;
      case "LuminaV2":
        params.push("sample", "prompt", "systemPrompt", "aspectRatio", "seed", "step", "guidanceScale");
        break;
      case "Imagen3":
      case "Imagen3Fast":
        params.push("sample", "prompt", "negativePrompt", "aspectRatio", "seed");
        break;
      case "RecraftV3":
        params.push("prompt", "aspectRatio", "style", "colors");
        break;
      case "IdeogramV2a":
      case "IdeogramV2aTurbo":
        params.push("prompt", "aspectRatio", "revisedPrompt", "seed"); // "style",
        break;
      case "IdeogramV2Edit":
      case "IdeogramV2TurboEdit":
        params.push("prompt", "editor", "revisedPrompt", "seed"); // "style",
        break;
      case "MinimaxImage":
        params.push("prompt", "aspectRatio", "sample", "revisedPrompt");
        break;
      case "FluxJuggernautBase":
      case "FluxJuggernautPro":
        params.push("sample", "prompt", "aspectRatio", "seed", "step", "guidanceScale");
        break;
      case "FluxJuggernautLightning":
        params.push("prompt", "sample", "aspectRatio", "seed", "step");
        break;
      case "FluxJuggernautJuno":
        params.push("sample", "prompt", "aspectRatio", "style", "styleWeight", "seed", "step", "guidanceScale");
        break;
      // case "AuraSR":
      //   params.push("image", "scale");
      //   break;
      // case "ClarityUpscaler":
      //   params.push("image", "prompt", "scale", "creativity", "resemblance");
      //   break;
      // case "CreativeUpscaler":
      //   params.push("image", "prompt", "scale", "creativity", "resemblance", "texture");
      //   break;
      default:
        break;
    }

    // I2Iの場合は画像パラメータを追加
    if (["img2img", "editor"].includes(menu)) {
      if (
        [
          "SD35",
          "FluxJuno",
          "FluxPuLID",
          "Flux1Dev",
          "Flux11ProUltra",
          "Flux11ProRedux",
          "Flux11ProUltraRedux",
          "Flux1ProCanny",
          "Flux1ProDepth",
          "Flux1DevRedux",
          "Flux1DevCannyLoRA",
          "Flux1DevDepthLoRA",
          "Flux1SchnellRedux",
          "Flux1ProCannyJuno",
          "Flux1ProDepthJuno",
          "KolorsVTO",
          "IdeogramV2a",
          "IdeogramV2aTurbo",
          "BriaBackgroundReplace",
          "BriaProductShot",
          "IcLightV2",
          "GeminiFlashEdit",
        ].includes(aiEngine)
      ) {
        params.push("initImage");
      }

      if (["IdeogramV2a", "IdeogramV2aTurbo"].includes(aiEngine)) {
        params = params.filter((param) => param !== "negativePrompt");
      }

      if (["KolorsVTO", "BriaBackgroundReplace", "BriaProductShot"].includes(aiEngine)) {
        params.push("initImage2");
      }

      // I2Iの場合は画像強度パラメータを追加
      if (
        [
          "SD35",
          "FluxJuno",
          "Flux1Dev",
          "FluxPuLID",
          "Flux11ProUltra",
          "Flux11ProUltraRedux",
          "IdeogramV2a",
          "IdeogramV2aTurbo",
        ].includes(aiEngine)
      ) {
        params.push("imageStrength");
      }

      // I2Iの場合に特定のパラメータ（例えばアスペクト比）があれば削除
      if (["Flux1Dev"].includes(aiEngine)) {
        params = params.filter((param) => param !== "aspectRatio");
      }
    }
    return params;
  };

  const getImageModels = React.useMemo<ImageModels>(() => {
    return {
      txt2img: [
        {
          value: "DallE3",
          label: t("juno.input.engine.DallE3"),
          description: t("juno.input.engine.DallE3Description"),
          category: [],
        },
        {
          value: "Flux11ProUltra",
          label: t("juno.input.engine.Flux11ProUltra"),
          description: t("juno.input.engine.Flux11ProUltraDescription"),
          category: ["pro", "2k"],
        },
        {
          value: "Flux11ProUltraJuno",
          label: t("juno.input.engine.Flux11ProUltraJuno"),
          description: t("juno.input.engine.Flux11ProUltraJunoDescription"),
          category: ["juno", "2k", "pro"],
        },
        {
          value: "Flux11Pro",
          label: t("juno.input.engine.Flux11Pro"),
          description: t("juno.input.engine.Flux1ProDescription"),
          category: [],
        },
        {
          value: "Flux1ProJuno",
          label: t("juno.input.engine.Flux1ProJuno"),
          description: t("juno.input.engine.Flux1ProJunoDescription"),
          category: ["juno"],
        },
        {
          value: "Flux1Dev",
          label: t("juno.input.engine.Flux1Dev"),
          description: t("juno.input.engine.Flux1DevDescription"),
          category: [],
        },
        {
          value: "FluxJuno",
          label: t("juno.input.engine.FluxJuno"),
          description: t("juno.input.engine.FluxJunoDescription"),
          category: ["juno"],
        },
        {
          value: "Flux1RealismLoRA",
          label: t("juno.input.engine.Flux1RealismLoRA"),
          description: t("juno.input.engine.Flux1RealismLoRADescription"),
          category: [],
        },
        {
          value: "Flux1Schnell",
          label: t("juno.input.engine.Flux1Schnell"),
          description: t("juno.input.engine.Flux1SchnellDescription"),
          category: [],
        },
        {
          value: "FluxJuggernautLightning",
          label: t("juno.input.engine.FluxJuggernautLightning"),
          description: t("juno.input.engine.FluxJuggernautLightningDescription"),
          category: [],
        },
        {
          value: "FluxJuggernautBase",
          label: t("juno.input.engine.FluxJuggernautBase"),
          description: t("juno.input.engine.FluxJuggernautBaseDescription"),
          category: [],
        },
        {
          value: "FluxJuggernautPro",
          label: t("juno.input.engine.FluxJuggernautPro"),
          description: t("juno.input.engine.FluxJuggernautProDescription"),
          category: ["pro"],
        },
        {
          value: "FluxJuggernautJuno",
          label: t("juno.input.engine.FluxJuggernautJuno"),
          description: t("juno.input.engine.FluxJuggernautJunoDescription"),
          category: ["juno"],
        },
        {
          value: "IdeogramV2a",
          label: t("juno.input.engine.IdeogramV2a"),
          description: t("juno.input.engine.IdeogramV2aDescription"),
          category: ["pro"],
        },
        {
          value: "IdeogramV2aTurbo",
          label: t("juno.input.engine.IdeogramV2aTurbo"),
          description: t("juno.input.engine.IdeogramV2aTurboDescription"),
          category: [],
        },
        {
          value: "Imagen3",
          label: t("juno.input.engine.Imagen3"),
          description: t("juno.input.engine.Imagen3Description"),
          category: [],
        },
        {
          value: "Imagen3Fast",
          label: t("juno.input.engine.Imagen3Fast"),
          description: t("juno.input.engine.Imagen3FastDescription"),
          category: [],
        },
        {
          value: "LuminaV2",
          label: t("juno.input.engine.LuminaV2"),
          description: t("juno.input.engine.LuminaV2Description"),
          category: [],
        },
        {
          value: "MinimaxImage",
          label: t("juno.input.engine.MinimaxImage"),
          description: t("juno.input.engine.MinimaxImageDescription"),
          category: [],
        },
        {
          value: "RecraftV3",
          label: t("juno.input.engine.RecraftV3"),
          description: t("juno.input.engine.RecraftV3Description"),
          category: ["vector"],
        },
        {
          value: "SD35",
          label: t("juno.input.engine.SD35"),
          description: t("juno.input.engine.SD35Description"),
          category: [],
        },
        {
          value: "SDUltra",
          label: t("juno.input.engine.SDUltra"),
          description: t("juno.input.engine.SDUltraDescription"),
          category: [],
        },
      ],
      img2img: [
        {
          value: "Flux11ProUltraRedux",
          label: t("juno.input.engine.Flux11ProUltraRedux"),
          description: t("juno.input.engine.Flux11ProUltraReduxDescription"),
          category: ["pro", "vary", "2k"],
        },
        {
          value: "Flux11ProRedux",
          label: t("juno.input.engine.Flux11ProRedux"),
          description: t("juno.input.engine.Flux11ProReduxDescription"),
          category: ["vary"],
        },
        {
          value: "Flux1ProCanny",
          label: t("juno.input.engine.Flux1ProCanny"),
          description: t("juno.input.engine.Flux1ProCannyDescription"),
          category: ["pro", "controlNet"],
        },
        {
          value: "Flux1ProCannyJuno",
          label: t("juno.input.engine.Flux1ProCannyJuno"),
          description: t("juno.input.engine.Flux1ProCannyJunoDescription"),
          category: ["juno", "pro", "controlNet"],
        },
        {
          value: "Flux1ProDepth",
          label: t("juno.input.engine.Flux1ProDepth"),
          description: t("juno.input.engine.Flux1ProDepthDescription"),
          category: ["pro", "controlNet"],
        },
        {
          value: "Flux1ProDepthJuno",
          label: t("juno.input.engine.Flux1ProDepthJuno"),
          description: t("juno.input.engine.Flux1ProDepthJunoDescription"),
          category: ["juno", "pro", "controlNet"],
        },
        {
          value: "Flux1Dev",
          label: t("juno.input.engine.Flux1Dev"),
          description: t("juno.input.engine.Flux1DevDescription"),
          category: [],
        },
        {
          value: "Flux1DevRedux",
          label: t("juno.input.engine.Flux1DevRedux"),
          description: t("juno.input.engine.Flux1DevReduxDescription"),
          category: ["vary"],
        },
        {
          value: "FluxJuno",
          label: t("juno.input.engine.FluxJuno"),
          description: t("juno.input.engine.FluxJunoDescription"),
          category: [],
        },
        {
          value: "Flux1DevCannyLoRA",
          label: t("juno.input.engine.Flux1DevCannyLoRA"),
          description: t("juno.input.engine.Flux1DevCannyLoRADescription"),
          category: ["controlNet"],
        },
        {
          value: "Flux1DevDepthLoRA",
          label: t("juno.input.engine.Flux1DevDepthLoRA"),
          description: t("juno.input.engine.Flux1DevDepthLoRADescription"),
          category: ["controlNet"],
        },
        {
          value: "FluxPuLID",
          label: t("juno.input.engine.FluxPuLID"),
          description: t("juno.input.engine.FluxPuLIDDescription"),
          category: [],
        },
        {
          value: "Flux1SchnellRedux",
          label: t("juno.input.engine.Flux1SchnellRedux"),
          description: t("juno.input.engine.Flux1SchnellReduxDescription"),
          category: ["vary"],
        },
        {
          value: "KolorsVTO",
          label: t("juno.input.engine.KolorsVTO"),
          description: t("juno.input.engine.KolorsVTODescription"),
          category: ["editor"],
        },
        {
          value: "IdeogramV2a",
          label: t("juno.input.engine.IdeogramV2a"),
          description: t("juno.input.engine.IdeogramV2aDescription"),
          category: ["pro"],
        },
        {
          value: "IdeogramV2aTurbo",
          label: t("juno.input.engine.IdeogramV2aTurbo"),
          description: t("juno.input.engine.IdeogramV2aTurboDescription"),
          category: [],
        },
        {
          value: "SD35",
          label: t("juno.input.engine.SD35"),
          description: t("juno.input.engine.SD35Description"),
          category: [],
        },
        {
          value: "BriaBackgroundReplace",
          label: t("juno.input.engine.BriaBackgroundReplace"),
          description: t("juno.input.engine.BriaBackgroundReplaceDescription"),
          category: [],
        },
        {
          value: "BriaProductShot",
          label: t("juno.input.engine.BriaProductShot"),
          description: t("juno.input.engine.BriaProductShotDescription"),
          category: [],
        },
        {
          value: "IcLightV2",
          label: t("juno.input.engine.IcLightV2"),
          description: t("juno.input.engine.IcLightV2Description"),
          category: [],
        },
      ],
      editor: [
        {
          value: "Flux1ProFill",
          label: t("juno.input.engine.Flux1ProFill"),
          description: t("juno.input.engine.Flux1ProFillDescription"),
          category: ["pro", "editor"],
        },
        {
          value: "Flux1ProFillJuno",
          label: t("juno.input.engine.Flux1ProFillJuno"),
          description: t("juno.input.engine.Flux1ProFillJunoDescription"),
          category: ["pro", "editor", "juno"],
        },
        {
          value: "Flux1DevFill",
          label: t("juno.input.engine.Flux1DevFill"),
          description: t("juno.input.engine.Flux1DevFillDescription"),
          category: ["editor", "juno"],
        },
        {
          value: "GeminiFlashEdit",
          label: t("juno.input.engine.GeminiFlashEdit"),
          description: t("juno.input.engine.GeminiFlashEditDescription"),
          category: ["editor"],
        },
        {
          value: "IdeogramV2Edit",
          label: t("juno.input.engine.IdeogramV2Edit"),
          description: t("juno.input.engine.IdeogramV2EditDescription"),
          category: ["pro", "editor"],
        },
        {
          value: "IdeogramV2TurboEdit",
          label: t("juno.input.engine.IdeogramV2TurboEdit"),
          description: t("juno.input.engine.IdeogramV2TurboEditDescription"),
          category: ["editor"],
        },
      ],
      upscale: [
        {
          value: "AuraSR",
          label: t("juno.input.engine.AuraSR"),
          description: t("juno.input.engine.AuraSRDescription"),
          category: [],
        },
        {
          value: "ClarityUpscaler",
          label: t("juno.input.engine.ClarityUpscaler"),
          description: t("juno.input.engine.ClarityUpscalerDescription"),
          category: [],
        },
        {
          value: "CreativeUpscaler",
          label: t("juno.input.engine.CreativeUpscaler"),
          description: t("juno.input.engine.CreativeUpscalerDescription"),
          category: [],
        },
        {
          value: "RecraftClarityUpscaler",
          label: t("juno.input.engine.RecraftClarityUpscaler"),
          description: t("juno.input.engine.RecraftClarityUpscalerDescription"),
          category: [],
        },
        // コスト高すぎるため削除
        // {
        //   value: "RecraftCreativeUpscaler",
        //   label: t("juno.input.engine.RecraftCreativeUpscaler"),
        //   description: t("juno.input.engine.RecraftCreativeUpscalerDescription"),
        //   category: [],
        // },
      ],
    };
  }, [t]);

  const getVideoModels = React.useMemo(() => {
    return [
      {
        value: "GOOGLE",
        label: t("juno.video.ai.google.title"),
        description: t("juno.video.ai.google.description"),
        disabled: ["v2v", "upscale", "lipsync"],
      },
      {
        value: "TOPAZLABS",
        label: t("juno.video.ai.topazlabs.title"),
        description: t("juno.video.ai.topazlabs.description"),
        disabled: ["t2v", "i2v", "lipsync"],
      },
      {
        value: "HAILUO",
        label: t("juno.video.ai.hailuo.title"),
        description: t("juno.video.ai.hailuo.description"),
        disabled: ["v2v", "upscale", "lipsync"],
      },
      {
        value: "HAIPER",
        label: t("juno.video.ai.haiper.title"),
        description: t("juno.video.ai.haiper.description"),
        disabled: ["v2v", "upscale", "lipsync"],
      },
      {
        value: "KLING",
        label: t("juno.video.ai.kling.title"),
        description: t("juno.video.ai.kling.description"),
        disabled: ["v2v", "upscale", "lipsync"],
      },
      {
        value: "LUMA",
        label: t("juno.video.ai.luma.title"),
        description: t("juno.video.ai.luma.description"),
        disabled: ["v2v", "upscale", "lipsync"],
      },
      {
        value: "PIKA",
        label: t("juno.video.ai.pika.title"),
        description: t("juno.video.ai.pika.description"),
        disabled: ["v2v", "upscale", "lipsync"],
      },
      {
        value: "PIXVERSE",
        label: t("juno.video.ai.pixverse.title"),
        description: t("juno.video.ai.pixverse.description"),
        disabled: ["v2v", "upscale", "lipsync"],
      },
      {
        value: "RUNWAY",
        label: t("juno.video.ai.runway.title"),
        description: t("juno.video.ai.runway.description"),
        disabled: ["t2v", "v2v", "upscale", "lipsync"],
      },
      {
        value: "VIDU",
        label: t("juno.video.ai.vidu.title"),
        description: t("juno.video.ai.vidu.description"),
        disabled: ["t2v", "v2v", "upscale", "lipsync"],
      },
      {
        value: "WAN",
        label: t("juno.video.ai.wan.title"),
        description: t("juno.video.ai.wan.description"),
        disabled: ["v2v", "upscale", "lipsync"],
      },
      {
        value: "BYTEDANCE",
        label: t("juno.video.ai.bytedance.title"),
        description: t("juno.video.ai.bytedance.description"),
        disabled: ["t2v", "i2v", "upscale"],
      },
      {
        value: "SYNC",
        label: t("juno.video.ai.sync.title"),
        description: t("juno.video.ai.sync.description"),
        disabled: ["t2v", "i2v", "upscale"],
      },
      {
        value: "REALESRGAN",
        label: t("juno.video.ai.realesrgan.title"),
        description: t("juno.video.ai.realesrgan.description"),
        disabled: ["t2v", "i2v", "lipsync"],
      },
    ];
  }, [t]);

  return {
    getModelParams,
    getImageModels,
    getVideoModels,
  };
};

import ReportIcon from "@mui/icons-material/Report";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Fade, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageInfo } from "../../../types/junoTypes";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import ImageModal from "./components/imageModal/ImageModal";

import Meta from "../../../components/common/Meta";
import { useCommonContext } from "../../../context/juno/CommonContext";
import { aiEngineColor } from "../../../utils/aiEngineColor";
import { uiHeight } from "../../../utils/uiHeight";
import Like from "./components/imageModal/Like";

export default function Explore() {
  const { t } = useTranslation();
  const [generatedImages, setGeneratedImages] = useState<ImageInfo[]>([]);
  const { filtered, setFiltered } = useCommonContext();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = urlParams.get("page");
  const queryParam = urlParams.get("q") ?? "";
  const modelParam = urlParams.get("model");
  const loraParam = urlParams.get("lora");
  const userParam = urlParams.get("user"); //image_request__uuidを挿入することで、ユーザーの画像を取得
  const aiEngineParam = urlParams.get("ai_engine");
  const actionParam = urlParams.get("action");
  const uuid = urlParams.get("uuid");
  const navigate = useCustomNavigate();
  const [page, setPage] = useState(pageParam ? parseInt(pageParam) : 1);
  const [inputPage, setInputPage] = useState<number>(pageParam ? parseInt(pageParam) : 1);
  const [totalPages, setTotalPages] = useState(1);
  const [gridNum, setGridNum] = useState(3);

  // 画像一覧取得 & 検索
  const fetchImageList = async (pageNum: number) => {
    try {
      const height = containerRef.current?.offsetHeight;
      const width = containerRef.current?.offsetWidth;
      let num = 0;

      if (height && width) {
        if (width > 600) {
          const widthNum = Math.floor(width / 230);
          const heightNum = Math.floor(height / 210);
          setGridNum(12 / widthNum);
          num = widthNum * heightNum;
        } else {
          setGridNum(4);
          num = 12;
        }
      }

      const params = new URLSearchParams();
      params.append("num", num.toString());

      if (uuid) {
        params.append("uuid", uuid);
      } else {
        params.append("page", pageNum.toString());
        if (queryParam) params.append("q", queryParam);
        if (modelParam) params.append("model", modelParam);
        if (loraParam) params.append("lora", loraParam);
        if (aiEngineParam) params.append("ai_engine", aiEngineParam);
        if (userParam) params.append("user", userParam);
        if (actionParam) params.append("action", actionParam);
      }

      const url = `/api/v1/juno/images?${params.toString()}`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({ "Content-Type": "application/json", "X-CSRFToken": csrftoken! });
      const response = await fetch(url, { method: "GET", headers: headers });
      const newImages = await response.json();
      setSelectedImages(newImages);
      setTotalPages(newImages[0].total_pages);
      return newImages;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  // ページ変更時の処理
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    const newParams = new URLSearchParams(urlParams);
    newParams.set("page", newPage.toString());
    navigate(`/juno?${newParams.toString()}`);
  };

  const [selectedImages, setSelectedImages] = useState<ImageInfo[]>([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});

  // 画像URLの取得
  useEffect(() => {
    const urls = {};
    generatedImages.forEach((req) => {
      urls[req.uuid] = req.is_r2 ? req.url : `${req.url}public`;
    });
    setImageUrls(urls);
  }, [generatedImages]);

  // URLパラメーターによる画像一覧取得
  useEffect(() => {
    setLoading(true);
    setGeneratedImages([]);
    setPage(Number(pageParam) || 1);
    fetchImageList(Number(pageParam) || 1).then((newImages) => {
      if (newImages && newImages.length > 0) {
        setGeneratedImages(newImages);
      }
      setLoading(false);
    });
  }, [uuid, modelParam, loraParam, queryParam, aiEngineParam, actionParam, pageParam]);

  useEffect(() => {
    if (uuid && generatedImages.length > 0) {
      setSelectedImages(generatedImages);
      setOpenModal(true);
    }
  }, [uuid, generatedImages]);

  // 画像の読み込み状態を管理するステート
  const [loaded, setLoaded] = useState({});

  // 画像の読み込み
  useEffect(() => {
    generatedImages.forEach((req) => {
      const img = new Image();
      img.src = imageUrls[req.uuid] ?? `${req.url}public`;
      img.onload = () => {
        setLoaded((prev) => ({
          ...prev,
          [req.uuid]: true,
        }));
      };
    });
  }, [imageUrls]);

  // ページが変更されたときにinputPageも更新
  useEffect(() => {
    setInputPage(page);
  }, [page]);

  const height = queryParam ? 145 : 115;
  const [hover, setHover] = useState<number | null>(null);

  return (
    <>
      <Meta
        title={t("juno.explore")}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
        route={"Studio JUNO by Tenak AI"}
      />
      {queryParam !== "" && (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 1, alignItems: "center" }}>
          <Typography variant={"body1"} component={"p"}>
            {t("juno.searchResult")}
            <strong> {queryParam}</strong>
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: {
            xs: 600,
            sm: `calc(${uiHeight()} - ${height}px)`,
          },
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", height: "100%", mx: 1 }} ref={containerRef}>
          <Grid container spacing={1} sx={{ px: 1, width: "100%" }}>
            {loading && generatedImages.length === 0 ? (
              // ロード中のインジケーターを表示
              <Box sx={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center" }}>
                <CircularProgress />
              </Box>
            ) : generatedImages.length > 0 ? (
              generatedImages.map((image, index) => (
                <Grid item xs={gridNum} key={`${page}-${index}`}>
                  <Grow in={true} timeout={Math.min(index * 100, 1500)}>
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          boxSizing: "border-box",
                          maxHeight: { xs: 150, sm: 300 },
                          overflow: "hidden",
                          transition: "transform 0.5s ease",
                          borderRadius: 1,
                        }}
                        onClick={() => {
                          setActiveIndex(index);
                          setOpenModal(true);
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                          e.currentTarget.style.zIndex = "100";
                          e.currentTarget.style.outline = `solid 2px ${theme.palette.primary.main}`;
                          setHover(index);
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.zIndex = "1";
                          e.currentTarget.style.outline = "none";
                          setHover(null);
                        }}
                      >
                        <Fade in={hover === index} timeout={300}>
                          <Box sx={{ position: "absolute", bottom: 8, right: 8, zIndex: 200 }}>
                            <Like uuid={image.uuid} initialLiked={image.is_liked} />
                          </Box>
                        </Fade>
                        <Fade in={hover === index} timeout={300}>
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 12,
                              left: 12,
                              px: 0.6,
                              py: 0.2,
                              background: alpha(aiEngineColor(image["params"]["ai_engine"]), 0.8),
                              borderRadius: 1,
                              zIndex: 2,
                            }}
                          >
                            <Typography variant={"caption"} component={"p"} sx={{ color: "white" }}>
                              {t(`juno.input.engine.${image["params"]["ai_engine"]}` as const)}
                            </Typography>
                          </Box>
                        </Fade>
                        <img
                          src={imageUrls[image.uuid] ?? `${image.url}public`}
                          alt=""
                          loading="lazy"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            filter: image.safe_level > 2 && filtered ? "blur(20px)" : "none",
                            transform: image.safe_level > 2 && filtered ? "scale(1.5)" : "none",
                            transition: "transform 0.2s ease, filter 0.2s ease",
                            display: loaded[image.uuid] ? "block" : "none",
                          }}
                        />
                        {loaded[image.uuid] ? (
                          image.safe_level > 2 &&
                          filtered && (
                            <Box
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "white",
                                zIndex: 100,
                                width: "100%",
                                p: 4,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    mb: 2,
                                  }}
                                >
                                  <ReportIcon sx={{ mr: 1 }} color={"warning"} />
                                  <Typography variant={"body1"}>{t("juno.input.filtered")}</Typography>
                                </Box>
                                <Typography variant={"caption"} whiteSpace={"break-spaces"}>
                                  {t("juno.input.filteredDescription")}
                                </Typography>
                              </Box>
                            </Box>
                          )
                        ) : (
                          <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
                        )}

                        {image.safe_level > 2 && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setFiltered(!filtered);
                            }}
                            sx={{ position: "absolute", top: 8, right: 8, color: "white", zIndex: 200 }}
                            aria-label="toggle blur"
                          >
                            {filtered ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Grow>
                </Grid>
              ))
            ) : queryParam.length > 0 ? (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Fade in={true} timeout={1000}>
                  <Typography sx={{ mt: 1 }} variant={"body1"} component={"p"} color={"error"}>
                    {t("juno.noResult")}
                  </Typography>
                </Fade>
              </Box>
            ) : (
              // ロード中のインジケーターを表示
              <Box sx={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center" }}>
                <Typography variant={"body1"} fontWeight={"500"} sx={{ mb: 2 }}>
                  No Images Found
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
      {page > 0 && (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 1, px: 2 }}>
          <Box sx={{ width: "100px" }} /> {/* 左側のスペーサー */}
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, newPage) => handleChangePage(newPage)}
            siblingCount={3}
          />
          <Box sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center", width: "100px" }}>
            <TextField
              size="small"
              inputProps={{ min: 1, max: totalPages }}
              value={inputPage === 0 ? "" : inputPage}
              autoComplete="off"
              onChange={(e) => {
                const value = e.target.value === "" ? 0 : Number(e.target.value);
                setInputPage(value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && inputPage >= 1 && inputPage <= totalPages) {
                  handleChangePage(inputPage);
                }
              }}
              sx={{ width: "100px" }}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      if (inputPage >= 1 && inputPage <= totalPages) {
                        handleChangePage(inputPage);
                      }
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Box>
      )}
      <ImageModal
        images={selectedImages}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        open={openModal}
        setOpen={setOpenModal}
      />
    </>
  );
}

import { useCustomNavigate } from "./useCustomNavigate";
import { useVideo } from "../context/juno/VideoContext";
import { getFileDateName } from "../utils/utils";

/**
 * ビデオ操作のための共通関数を提供するカスタムフック
 */
export const useVideoOperations = () => {
  const navigate = useCustomNavigate();
  const { setVideo1, setMenu, setAiEngine, setImage1, updateParams } = useVideo();

  /**
   * ビデオの最終フレームを取得してi2vモードに設定する
   * @param videoUrl ビデオURL
   * @param videoUuid ビデオUUID
   * @param onComplete 処理完了後のコールバック関数（オプション）
   */
  const handleLastFrame = async (videoUrl?: string, videoUuid?: string, onComplete?: () => void): Promise<void> => {
    try {
      let blob: Blob;

      // URLかUUIDのどちらかが必要
      if (!videoUrl && !videoUuid) {
        throw new Error("videoUrl または videoUuid が必要です");
      }

      // UUIDが指定されている場合はAPIからビデオを取得
      if (videoUuid) {
        const response = await fetch(`/api/v1/juno/generate-video?generatedVideoUuid=${videoUuid}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        blob = await response.blob();
      } else if (videoUrl) {
        // URLからビデオを取得
        const response = await fetch(videoUrl);
        blob = await response.blob();
      } else {
        return;
      }

      // 動画要素を作成して最終フレームを取得
      const video_el = document.createElement("video");
      video_el.muted = true;

      // blobからURLを作成
      const url = URL.createObjectURL(blob);
      video_el.src = url;

      // 動画がロードされたら最終フレームをキャプチャ
      video_el.onloadedmetadata = () => {
        // 最終フレームに移動
        video_el.currentTime = video_el.duration;

        // 時間位置が更新されたら描画
        video_el.onseeked = () => {
          // キャンバスを作成してフレームをキャプチャ
          const canvas = document.createElement("canvas");
          canvas.width = video_el.videoWidth;
          canvas.height = video_el.videoHeight;
          const ctx = canvas.getContext("2d");

          if (ctx) {
            // フレームをキャンバスに描画
            ctx.drawImage(video_el, 0, 0, canvas.width, canvas.height);

            // Base64形式で画像を取得
            const base64data = canvas.toDataURL("image/jpeg");

            // i2vモードに設定
            setImage1(base64data);
            setMenu("i2v");
            setAiEngine("KLING");
            updateParams("KLING", "modelVersion", "V1_6_PRO");

            if (onComplete) {
              onComplete();
            }

            navigate("/juno/video-generator");
          }

          // 作成したURLを解放
          URL.revokeObjectURL(url);
        };
      };
    } catch (error) {
      console.error("最終フレーム取得エラー:", error);
    }
  };

  /**
   * ビデオをリップシンク用に設定する
   * @param videoUuid ビデオUUID
   * @param onComplete 処理完了後のコールバック関数（オプション）
   */
  const handleLipSync = async (videoUuid: string, onComplete?: () => void): Promise<void> => {
    try {
      const response = await fetch(`/api/v1/juno/generate-video?generatedVideoUuid=${videoUuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const blob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64data = reader.result as string;
        setVideo1(base64data);
        setMenu("lipsync");
        setAiEngine("BYTEDANCE");

        if (onComplete) {
          onComplete();
        }
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("リップシンク設定エラー:", error);
    }
  };

  /**
   * ビデオをアップスケール用に設定する
   * @param videoUuid ビデオUUID
   * @param onComplete 処理完了後のコールバック関数（オプション）
   */
  const handleUpscale = async (videoUuid: string, onComplete?: () => void): Promise<void> => {
    try {
      const response = await fetch(`/api/v1/juno/generate-video?generatedVideoUuid=${videoUuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const blob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64data = reader.result as string;
        setVideo1(base64data);
        setMenu("upscale");
        setAiEngine("TOPAZLABS");

        if (onComplete) {
          onComplete();
        }

        navigate("/juno/video-generator");
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("アップスケール設定エラー:", error);
    }
  };

  /**
   * ビデオをダウンロードする
   * @param videoUuid ビデオUUID
   */
  const handleVideoDownload = async (videoUuid: string): Promise<void> => {
    try {
      const blob = await fetch(`/api/v1/juno/generate-video?generatedVideoUuid=${videoUuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => response.blob());

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "juno-video-" + getFileDateName() + ".mp4";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("ダウンロードエラー:", error);
    }
  };

  return {
    handleLastFrame,
    handleLipSync,
    handleUpscale,
    handleVideoDownload,
  };
};

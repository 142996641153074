import { Route, Routes } from "react-router-dom";
import Explore from "../pages/Juno/Images/Explore";
import ImageGenerator from "../pages/Juno/Images/ImageGenerator";
import GalleryAndLikes from "../pages/Juno/Images/GalleryAndLikes";
import Models from "../pages/Juno/Images/Models";
import React from "react";
import VideoGenerator from "../pages/Juno/Videos/VideoGenerator";
import MyVideos from "../pages/Juno/Videos/MyVideos";
import VoiceGenerator from "../pages/Juno/Voices/index";

const JunoRouter = () => {
  return (
    <Routes>
      <Route path="" element={<Explore />} />
      <Route path="image-generator" element={<ImageGenerator />} />
      <Route path="video-generator" element={<VideoGenerator />} />
      <Route path="voice-generator" element={<VoiceGenerator />} />
      <Route path="gallery" element={<GalleryAndLikes />} />
      <Route path="videos" element={<MyVideos />} />
      <Route path="likes" element={<GalleryAndLikes />} />
      <Route path="models" element={<Models />} />
    </Routes>
  );
};
export default JunoRouter;

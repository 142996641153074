// Waveファイルに変換する関数
export function audioBufferToWav(buffer: AudioBuffer): ArrayBuffer {
  const numChannels = buffer.numberOfChannels;
  const sampleRate = buffer.sampleRate;
  const format = 1; // PCM
  const bitDepth = 16;
  const bytesPerSample = bitDepth / 8;
  const blockAlign = numChannels * bytesPerSample;
  const numSamples = buffer.length;
  const dataSize = numSamples * blockAlign;
  const bufferSize = 44 + dataSize;
  const arrayBuffer = new ArrayBuffer(bufferSize);
  const view = new DataView(arrayBuffer);
  const channels: Float32Array[] = [];

  // WAVヘッダーの書き込み
  let pos = 0;

  // "RIFF"チャンク
  writeString("RIFF"); // ChunkID
  view.setUint32(pos, 36 + dataSize, true); // ChunkSize
  pos += 4;
  writeString("WAVE"); // Format

  // "fmt "サブチャンク
  writeString("fmt "); // Subchunk1ID
  view.setUint32(pos, 16, true); // Subchunk1Size (16 for PCM)
  pos += 4;
  view.setUint16(pos, format, true); // AudioFormat (1 for PCM)
  pos += 2;
  view.setUint16(pos, numChannels, true); // NumChannels
  pos += 2;
  view.setUint32(pos, sampleRate, true); // SampleRate
  pos += 4;
  view.setUint32(pos, sampleRate * blockAlign, true); // ByteRate
  pos += 4;
  view.setUint16(pos, blockAlign, true); // BlockAlign
  pos += 2;
  view.setUint16(pos, bitDepth, true); // BitsPerSample
  pos += 2;

  // "data"サブチャンク
  writeString("data"); // Subchunk2ID
  view.setUint32(pos, dataSize, true); // Subchunk2Size
  pos += 4;

  // 波形データの取得
  for (let i = 0; i < numChannels; i++) {
    channels.push(buffer.getChannelData(i));
  }

  // 波形データの書き込み
  let offset = pos;
  let sample = 0;
  for (let i = 0; i < numSamples; i++) {
    for (let channel = 0; channel < numChannels; channel++) {
      // float32を16bitの整数に変換
      sample = Math.max(-1, Math.min(1, channels[channel][i]));
      sample = sample < 0 ? sample * 0x8000 : sample * 0x7fff;
      view.setInt16(offset, sample, true);
      offset += 2;
    }
  }

  function writeString(str: string) {
    for (let i = 0; i < str.length; i++) {
      view.setUint8(pos + i, str.charCodeAt(i));
    }
    pos += str.length;
  }

  return arrayBuffer;
}

export async function webmToWav(webmBlob: Blob): Promise<Blob> {
  const audioContext = new AudioContext();
  const arrayBuffer = await webmBlob.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  const wavBuffer = audioBufferToWav(audioBuffer);
  return new Blob([wavBuffer], { type: "audio/wav" });
}

import { useEffect, useState } from "react";

interface WindowSize {
  width: number;
  height: number;
}

/**
 * ウィンドウサイズを取得するためのカスタムフック
 * @returns {WindowSize} 現在のウィンドウの幅と高さ
 */
export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // ウィンドウサイズが変更されたときにステートを更新する関数
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // イベントリスナーを追加
    window.addEventListener("resize", handleResize);

    // クリーンアップ関数
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;

import Cookies from "js-cookie";
import { useCallback, useState } from "react";

export const useVoicePlayer = () => {
  const [playingAudio, setPlayingAudio] = useState<HTMLAudioElement | null>(null);
  const [playingUuid, setPlayingUuid] = useState<string | null>(null);
  const [loadingUuid, setLoadingUuid] = useState<string | null>(null);
  const [audioCache, setAudioCache] = useState<{ [key: string]: string }>({});

  const csrftoken = Cookies.get("csrftoken");
  const headers = new Headers({
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken!,
  });

  const toggleAudio = async (uuid: string) => {
    if (playingUuid === uuid) {
      if (playingAudio) {
        await new Promise<void>((resolve) => {
          playingAudio.addEventListener(
            "pause",
            () => {
              resolve();
            },
            { once: true }
          );
          playingAudio.pause();
        });
        setPlayingAudio(null);
        setPlayingUuid(null);
      }
    } else {
      await playAudio(uuid);
    }
  };

  const playAudio = async (uuid: string) => {
    try {
      setLoadingUuid(uuid);

      if (playingAudio) {
        await new Promise<void>((resolve) => {
          playingAudio.addEventListener(
            "pause",
            () => {
              resolve();
            },
            { once: true }
          );
          playingAudio.pause();
        });
        setPlayingAudio(null);
        setPlayingUuid(null);
      }

      let url = audioCache[uuid];

      if (!url) {
        const response = await fetch(`/api/v1/voice-generator/download/${uuid}`, {
          method: "GET",
          headers,
        });
        const blob = await response.blob();
        url = window.URL.createObjectURL(blob);

        setAudioCache((prevState) => ({
          ...prevState,
          [uuid]: url,
        }));
      }

      const audio = new Audio(url);
      await audio.play();

      setPlayingAudio(audio);
      setPlayingUuid(uuid);
      setLoadingUuid(null);

      audio.onended = () => {
        setPlayingAudio(null);
        setPlayingUuid(null);
      };
    } catch (error: unknown) {
      console.error("音声再生エラー:", error instanceof Error ? error.message : "An unknown error occurred");
      setLoadingUuid(null);
      setPlayingAudio(null);
      setPlayingUuid(null);
    }
  };

  const downloadVoice = async (uuid: string, filename: string) => {
    try {
      const response = await fetch(`/api/v1/voice-generator/download/${uuid}`, {
        method: "GET",
        headers,
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download voice:", error);
    }
  };

  // 音声を停止する関数を追加
  const stopAudio = useCallback(async () => {
    if (playingAudio) {
      await new Promise<void>((resolve) => {
        playingAudio.addEventListener(
          "pause",
          () => {
            resolve();
          },
          { once: true }
        );
        playingAudio.pause();
      });
      setPlayingAudio(null);
      setPlayingUuid(null);
    }
  }, [playingAudio]);

  return {
    playingUuid,
    loadingUuid,
    toggleAudio,
    downloadVoice,
    stopAudio, // 新しい関数をエクスポート
  };
};

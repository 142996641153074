import React, { createContext, useContext, useState } from "react";

export const API_STATUS = {
  SUCCESS: 1,
  ERROR: 2,
  PROCESSING: 3,
} as const;

export type AiEngineList =
  | "KLING"
  | "RUNWAY"
  | "LUMA"
  | "HAILUO"
  | "HAIPER"
  | "PIXVERSE"
  | "VIDU"
  | "BYTEDANCE"
  | "SYNC"
  | "REALESRGAN"
  | "GOOGLE"
  | "WAN"
  | "PIKA"
  | "TOPAZLABS";
export type MenuList = "t2v" | "i2v" | "v2v" | "upscale" | "lipsync";

export type AiEngineNumber = Record<AiEngineList, number>;
export type AiEngineString = Record<AiEngineList, string>;
export type AspectRatio = "AUTO" | "1:1" | "16:9" | "4:3" | "3:4" | "9:16" | "4:5" | "5:4" | "3:2" | "2:3";
export type AspectRatios = Record<AiEngineList, AspectRatio>;
export type AspectRatioIconType = { ratio: AspectRatio; icon: React.ElementType; rotate?: string };
export type PixVerseStyle = "default" | "anime" | "3d_animation" | "clay" | "comic" | "cyberpunk";
export type ViduMovement = "auto" | "small" | "medium" | "large";
export type Movements = Record<AiEngineList, ViduMovement>;
export type Styles = Record<AiEngineList, PixVerseStyle>;
export type RunwayModel = "GEN3_ALPHA_TURBO";
export type PixVerseModel = "V3_5" | "V3_5_1080P" | "V3_5_FAST";
export type LumaModel = "V1_5" | "RAY_2" | "RAY_2_1080P" | "RAY_2_FLASH" | "RAY_2_FLASH_1080P";
export type KlingModel = "V1_0_STANDARD" | "V1_0_PRO" | "V1_5_PRO" | "V1_6_STANDARD" | "V1_6_PRO";
export type HailuoModel = "MINI_MAX" | "MINI_MAX_LIVE" | "MINI_MAX_REF" | "MINI_MAX_DIRECTOR";
export type HaiperModel = "V2_0" | "V2_5";
export type ViduModel = "VIDU_2_0_720P" | "VIDU_2_0_1080P" | "VIDU_2_0_REF_720P";
export type LoopMode = "pingpong" | "loop";
export type SyncMode = "cut_off" | "loop" | "bounce";
export type SyncModel = "LIPSYNC_190";
export type BytedanceModel = "LATENTSYNC";
export type REALESRGANModel = "REALESRGAN";

export type GoogleModel = "VEO2";
export type WanModel = "V2_1" | "V2_1_PRO";
export type PikaModel = "V2_2_720P" | "V2_2_1080P" | "V2_2_SCENES_720P" | "V2_2_SCENES_1080P" | "V2_TURBO_720P";
export type TopazLabsModel = "TOPAZ_VIDEO_UPSCALE";
export type ModelVersion = Record<
  AiEngineList,
  | RunwayModel
  | LumaModel
  | KlingModel
  | HailuoModel
  | HaiperModel
  | PixVerseModel
  | ViduModel
  | BytedanceModel
  | SyncModel
  | REALESRGANModel
  | GoogleModel
  | WanModel
  | PikaModel
  | TopazLabsModel
>;
export type KlingDuration = 5 | 10;
export type LumaDuration = 5 | 9;
export type RunwayDuration = 5 | 10;
export type HailuoDuration = 5;
export type HaiperDuration = 4 | 6;
export type PixVerseDuration = 5 | 8;
export type ViduDuration = 4 | 8;
export type BytedanceDuration = 0;
export type SyncDuration = 0;
export type REALESRGANDuration = 0;

export type Duration = Record<
  AiEngineList,
  | KlingDuration
  | LumaDuration
  | RunwayDuration
  | HailuoDuration
  | HaiperDuration
  | PixVerseDuration
  | ViduDuration
  | BytedanceDuration
  | SyncDuration
  | REALESRGANDuration
>;

export type InferenceSteps = Record<AiEngineList, number>;

interface VideoContextType {
  fetchVideoTrigger: boolean;
  setFetchVideoTrigger: (fetchVideoTrigger: boolean) => void;
  prompt: string;
  setPrompt: (prompt: string) => void;
  negativePrompt: string;
  setNegativePrompt: (negativePrompt: string) => void;
  menu: MenuList;
  setMenu: (menu: MenuList) => void;
  aiEngine: AiEngineList;
  setAiEngine: (aiEngine: AiEngineList) => void;
  processing: boolean;
  setProcessing: (processing: boolean) => void;
  image1: string;
  setImage1: (image: string) => void;
  image2: string;
  setImage2: (image: string) => void;
  image3: string;
  setImage3: (image: string) => void;
  // Video and Audio
  video1: string;
  setVideo1: (video: string) => void;
  audio1: string;
  setAudio1: (audio: string) => void;
  // Bytedance params
  guidanceScale: number;
  setGuidanceScale: (scale: number) => void;
  loopMode: LoopMode;
  setLoopMode: (mode: LoopMode) => void;
  // Sync params
  syncMode: SyncMode;
  setSyncMode: (mode: SyncMode) => void;
  // REALESRGAN params
  scale: number;
  setScale: (scale: number) => void;
  // Topaz Video Upscale params
  fps: number;
  setFps: (fps: number) => void;
  // Video Metadata
  video1Metadata: { width: number; height: number; fps: number; duration: number } | null;
  setVideo1Metadata: (metadata: { width: number; height: number; fps: number; duration: number } | null) => void;
  modelVersion: ModelVersion;
  aspectRatios: AspectRatios;
  duration: Duration;
  styles: Styles;
  movements: Movements;
  inferenceSteps: InferenceSteps;
  lumaLoop: boolean;
  setLumaLoop: (loop: boolean) => void;
  updateParams: (
    aiEngine: AiEngineList,
    state: "modelVersion" | "aspectRatio" | "duration" | "style" | "movement" | "inferenceSteps",
    value: string | number | number[]
  ) => void;
}

const VideoContext = createContext<VideoContextType>({
  fetchVideoTrigger: false,
  setFetchVideoTrigger: () => {},
  prompt: "",
  setPrompt: () => {},
  negativePrompt: "",
  setNegativePrompt: () => {},
  menu: "" as MenuList,
  setMenu: () => {},
  aiEngine: "" as AiEngineList,
  setAiEngine: () => {},
  processing: false,
  setProcessing: () => {},
  image1: "",
  setImage1: () => {},
  image2: "",
  setImage2: () => {},
  image3: "",
  setImage3: () => {},
  // Video and Audio
  video1: "",
  setVideo1: () => {},
  audio1: "",
  setAudio1: () => {},
  // Bytedance params
  guidanceScale: 1,
  setGuidanceScale: () => {},
  loopMode: "loop" as LoopMode,
  setLoopMode: () => {},
  // Sync params
  syncMode: "cut_off" as SyncMode,
  setSyncMode: () => {},
  // REALESRGAN params
  scale: 2,
  setScale: () => {},
  // Topaz Video Upscale params
  fps: 30,
  setFps: () => {},
  // Video Metadata
  video1Metadata: null,
  setVideo1Metadata: () => {},
  // Audio Duration
  modelVersion: {} as ModelVersion,
  aspectRatios: {} as AspectRatios,
  duration: {} as Duration,
  styles: {} as Styles,
  movements: {} as Movements,
  inferenceSteps: {} as InferenceSteps,
  lumaLoop: false,
  setLumaLoop: () => {},
  updateParams: () => {},
});

export const useVideo = () => useContext(VideoContext);

export const VideoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [fetchVideoTrigger, setFetchVideoTrigger] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");
  const [negativePrompt, setNegativePrompt] = useState<string>(
    "blurry, low quality, low resolution, pixelated, noisy, grainy, out of focus, poorly lit, poorly exposed, poorly composed, poorly framed, poorly cropped, poorly color corrected, poorly color graded"
  );
  const [menu, setMenu] = useState<MenuList>("i2v");
  const [processing, setProcessing] = useState<boolean>(false);
  const [image1, setImage1] = useState<string>("");
  const [image2, setImage2] = useState<string>("");
  const [image3, setImage3] = useState<string>("");
  const [aiEngine, setAiEngine] = useState<AiEngineList>("LUMA");

  // Video and Audio
  const [video1, setVideo1] = useState<string>("");
  const [audio1, setAudio1] = useState<string>("");

  // Bytedance params
  const [guidanceScale, setGuidanceScale] = useState<number>(1);
  const [loopMode, setLoopMode] = useState<LoopMode>("loop");

  // Sync params
  const [syncMode, setSyncMode] = useState<SyncMode>("cut_off");

  // REALESRGAN params
  const [scale, setScale] = useState<number>(2);
  // Topaz Video Upscale params
  const [fps, setFps] = useState<number>(30);
  // Video Metadata
  const [video1Metadata, setVideo1Metadata] = useState<{
    width: number;
    height: number;
    fps: number;
    duration: number;
  } | null>(null);
  // Audio Duration
  const [modelVersion, setModelVersion] = useState<ModelVersion>({
    KLING: "V1_6_PRO",
    RUNWAY: "GEN3_ALPHA_TURBO",
    LUMA: "RAY_2",
    HAILUO: "MINI_MAX_LIVE",
    HAIPER: "V2_5",
    PIXVERSE: "V3_5",
    VIDU: "VIDU_2_0_1080P",
    BYTEDANCE: "LATENTSYNC",
    SYNC: "LIPSYNC_190",
    REALESRGAN: "REALESRGAN",
    GOOGLE: "VEO2",
    WAN: "V2_1_PRO",
    PIKA: "V2_2_720P",
    TOPAZLABS: "TOPAZ_VIDEO_UPSCALE",
  });
  const [aspectRatios, setAspectRatios] = useState<AspectRatios>({
    KLING: "16:9",
    RUNWAY: "16:9",
    LUMA: "16:9",
    HAILUO: "AUTO",
    HAIPER: "AUTO",
    PIXVERSE: "16:9",
    VIDU: "16:9",
    BYTEDANCE: "AUTO",
    SYNC: "AUTO",
    REALESRGAN: "AUTO",
    GOOGLE: "16:9",
    WAN: "16:9",
    PIKA: "16:9",
    TOPAZLABS: "AUTO",
  });
  const [duration, setDuration] = useState<Duration>({
    KLING: 5,
    RUNWAY: 5,
    LUMA: 5,
    HAILUO: 5,
    HAIPER: 4,
    PIXVERSE: 5,
    VIDU: 4,
    BYTEDANCE: 0,
    SYNC: 0,
    REALESRGAN: 0,
    GOOGLE: 5,
    WAN: 5,
    PIKA: 5,
    TOPAZLABS: 0,
  });
  const [styles, setStyles] = useState<Styles>({
    KLING: "default",
    RUNWAY: "default",
    LUMA: "default",
    HAILUO: "default",
    HAIPER: "default",
    PIXVERSE: "default",
    VIDU: "default",
    BYTEDANCE: "default",
    SYNC: "default",
    REALESRGAN: "default",
    GOOGLE: "default",
    WAN: "default",
    PIKA: "default",
    TOPAZLABS: "default",
  });

  const [movements, setMovements] = useState<Movements>({
    KLING: "auto",
    RUNWAY: "auto",
    LUMA: "auto",
    HAILUO: "auto",
    HAIPER: "auto",
    PIXVERSE: "auto",
    VIDU: "auto",
    BYTEDANCE: "auto",
    SYNC: "auto",
    REALESRGAN: "auto",
    GOOGLE: "auto",
    WAN: "auto",
    PIKA: "auto",
    TOPAZLABS: "auto",
  });
  const [lumaLoop, setLumaLoop] = useState<boolean>(false);

  const [inferenceSteps, setInferenceSteps] = useState<InferenceSteps>({
    KLING: 0,
    RUNWAY: 0,
    LUMA: 0,
    HAILUO: 0,
    HAIPER: 0,
    PIXVERSE: 0,
    VIDU: 0,
    BYTEDANCE: 0,
    SYNC: 0,
    REALESRGAN: 0,
    GOOGLE: 0,
    WAN: 40,
    PIKA: 0,
    TOPAZLABS: 0,
  });

  const updateParams = (
    aiEngine: AiEngineList,
    state: "modelVersion" | "aspectRatio" | "duration" | "style" | "movement" | "inferenceSteps",
    value: string | number | number[]
  ) => {
    switch (state) {
      case "modelVersion":
        setModelVersion((prev) => ({ ...prev, [aiEngine]: value }));
        break;
      case "aspectRatio":
        setAspectRatios((prev) => ({ ...prev, [aiEngine]: value }));
        break;
      case "duration":
        setDuration((prev) => ({ ...prev, [aiEngine]: value }));
        break;
      case "style":
        setStyles((prev) => ({ ...prev, [aiEngine]: value }));
        break;
      case "movement":
        setMovements((prev) => ({ ...prev, [aiEngine]: value }));
        break;
      case "inferenceSteps":
        setInferenceSteps((prev) => ({ ...prev, [aiEngine]: value }));
        break;
      default:
        break;
    }
  };

  return (
    <VideoContext.Provider
      value={{
        fetchVideoTrigger,
        setFetchVideoTrigger,
        prompt,
        setPrompt,
        negativePrompt,
        setNegativePrompt,
        menu,
        setMenu,
        aiEngine,
        setAiEngine,
        processing,
        image1,
        setImage1,
        image2,
        setImage2,
        image3,
        setImage3,
        setProcessing,
        modelVersion,
        aspectRatios,
        duration,
        styles,
        movements,
        inferenceSteps,
        lumaLoop,
        setLumaLoop,
        updateParams,
        // Video and Audio
        video1,
        setVideo1,
        audio1,
        setAudio1,
        // Bytedance params
        guidanceScale,
        setGuidanceScale,
        loopMode,
        setLoopMode,
        // Sync params
        syncMode,
        setSyncMode,
        // REALESRGAN params
        scale,
        setScale,
        // Topaz Video Upscale params
        fps,
        setFps,
        // Video Metadata
        video1Metadata,
        setVideo1Metadata,
        // Audio Duration
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

import ExploreIcon from "@mui/icons-material/Explore";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import FolderIcon from "@mui/icons-material/Folder";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import { Box, Grid, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { Images } from "../../../assets";
import ButtonAI from "./ButtonAI";
import React from "react";
import { RowBox } from "../../../utils/styledBox";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import path from "path";

const Image = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const ImageAIs = [
    {
      title: t("dashboard.juno.explore"),
      icon: <ExploreIcon />,
      path: "/juno",
      image: Images.dashBoard.explore,
    },
    {
      title: t("dashboard.juno.t2i"),
      icon: <FontDownloadIcon />,
      path: "/juno/image-generator?menu=txt2img&ai_engine=Flux1Dev",
      image: Images.dashBoard.t2i,
    },
    {
      title: t("dashboard.juno.i2i"),
      icon: <ImageIcon />,
      path: "/juno/image-generator?menu=img2img&ai_engine=Flux1Dev",
      image: Images.dashBoard.i2i,
    },
    {
      title: t("dashboard.juno.models"),
      icon: <FlashOnIcon />,
      path: "/juno/models",
      image: Images.juno.aboutTop,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        {/* 画像生成 */}
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant={"h6"}
            component={"h3"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ImageIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
            {t("dashboard.juno.image")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title={t("dashboard.library")} placement="left">
            <IconButton onClick={() => navigate("/juno/gallery")} sx={{ color: theme.palette.primary.main }}>
              <FolderIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        {ImageAIs.map((ai, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <ButtonAI title={ai.title} icon={ai.icon} path={ai.path} image={ai.image} />
            </Grid>
          );
        })}

        {/* 動画生成 */}
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Typography
            variant={"h6"}
            component={"h3"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <MovieIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
            {t("dashboard.juno.video")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title={t("dashboard.library")} placement="left">
            <IconButton onClick={() => navigate("/juno/videos")} sx={{ color: theme.palette.primary.main }}>
              <FolderIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <ButtonAI
            title={t("dashboard.juno.video")}
            icon={<MovieIcon />}
            path={"/juno/video-generator"}
            image={Images.dashBoard.video}
          />
        </Grid>

        {/* 音声生成 */}
        <Grid item xs={12} sm={12}>
          <RowBox sx={{ height: 48 }}>
            <Typography
              variant={"h6"}
              component={"h3"}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <GraphicEqIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
              {t("dashboard.tenak.voice")}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip title={t("dashboard.library")} placement="left">
              <IconButton onClick={() => navigate("/juno/voice-generator")} sx={{ color: theme.palette.primary.main }}>
                <FolderIcon />
              </IconButton>
            </Tooltip>
          </RowBox>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <ButtonAI
            title={t("dashboard.tenak.voice")}
            icon={<GraphicEqIcon />}
            path={"/juno/voice-generator"}
            image={Images.dashBoard.voice}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default Image;

import { Box, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";

const SystemPrompt = () => {
  const { t } = useTranslation();
  const { systemPrompt, setSystemPrompt } = useParamsContext();

  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {t("juno.input.systemPrompt")}
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={3}
        value={systemPrompt}
        onChange={(e) => setSystemPrompt(e.target.value)}
        placeholder={t("juno.input.systemPromptPlaceholder")}
      />
    </Box>
  );
};

export default SystemPrompt;

const junoImageEngine = {
  title: "AI ENGINE",
  description: "Please select an AI engine.",
  policyCheck:
    "This model is based on very strict policy checks, so images may not be generated and may result in an error.",
  DallE3: "DALL·E 3",
  DallE3Description:
    "Easily generate desired images with flexible prompts, and even short English words can be used. Suitable for beginners, but consumes credits most quickly.",
  LuminaV2: "Lumina Image V2",
  LuminaV2Description:
    "A next-generation open-source AI image generation model that combines efficiency and flexibility, capable of generating high-resolution images in various styles.",
  Flux11ProUltra: "FLUX1.1 Pro Ultra",
  Flux11ProUltraDescription:
    "Capable of generating 4MP images with higher resolution than FLUX.1 Pro. RAW mode can also be selected to generate natural images.",
  Flux1Pro: "FLUX.1 Pro",
  Flux11Pro: "FLUX1.1 Pro",
  Flux1ProDescription:
    "Capable of generating high-resolution images within the FLUX.1 series with high prompt flexibility. It is also said to be capable of generating higher resolution images than MidjourneyV6 and SD Ultra.",
  Flux1Dev: "FLUX.1 Dev",
  Flux1DevDescription: "Lower image quality than FLUX.1 Pro, but capable of generating images faster than FLUX.1 Pro.",
  Flux1Schnell: "FLUX.1 Schnell",
  Flux1SchnellDescription:
    "Lower image quality than FLUX.1 Dev, but capable of generating images faster than FLUX.1 Dev.",
  Flux1RealismLoRA: "FLUX.1 with Realism LoRA",
  Flux1RealismLoRADescription:
    "A realism model of the FLUX.1 series. Capable of generating images with an emphasis on realism.",
  FluxJuno: "FLUX.1 DEV JUNO",
  FluxJunoDescription:
    "A collaboration model between FLUX and STUDIO JUNO. You can select styles that leverage STUDIO JUNO's technology.",
  FluxPuLID: "PuLID FLUX",
  FluxPuLIDDescription:
    "Based on FLUX.1 Dev, it can generate images while preserving the facial features of the original image. Generation is possible from a single portrait image.",
  Flux11ProRedux: "FLUX1.1 Pro Redux",
  Flux11ProReduxDescription:
    "The Redux model of FLUX1.1 Pro. It can generate variation images that slightly alter the input image. Combined with FLUX1.1 Pro Ultra, which features high-resolution output and flexible aspect ratios, it enables higher quality image generation.",
  Flux1DevRedux: "FLUX.1 Dev Redux",
  Flux1DevReduxDescription:
    "The Redux model of FLUX.1 Dev. It can generate variation images that slightly alter the input image. It is provided under the Flux Dev License for developers.",
  Flux1SchnellRedux: "FLUX.1 Schnell Redux",
  Flux1SchnellReduxDescription:
    "The Redux model of FLUX.1 Schnell. It can generate variation images that slightly alter the input image. Higher resolution image generation is possible.",
  Flux11ProUltraRedux: "FLUX1.1 Pro Ultra Redux",
  Flux11ProUltraReduxDescription:
    "The Redux model of FLUX1.1 Pro Ultra. It can generate variation images that slightly alter the input image. High-quality image generation is possible with 4-megapixel high-resolution output and flexible aspect ratios.",
  Flux1ProCanny: "FLUX.1 Pro Canny",
  Flux1ProCannyDescription:
    "The Canny model of FLUX.1 Pro. It enables text-guided editing while maintaining the structure of the image using Canny edges extracted from the input image. Particularly effective for changing image textures.",
  Flux1ProDepth: "FLUX.1 Pro Depth",
  Flux1ProDepthDescription:
    "The Depth model of FLUX.1 Pro. It enables text-guided editing while maintaining the structure of the image using depth maps extracted from the input image. Higher resolution image generation is possible, producing higher quality results than conventional models like Midjourney ReTexture.",
  Flux1DevCannyLoRA: "FLUX JUNO Canny",
  Flux1DevCannyLoRADescription:
    "A collaboration model between the Canny model of FLUX.1 Dev and STUDIO JUNO. It enables image generation with an emphasis on realism using Canny edges extracted from the input image. It is based on FLUX.1 Dev for ease of development.",
  Flux1DevDepthLoRA: "FLUX JUNO Depth",
  Flux1DevDepthLoRADescription:
    "A collaboration model between the Depth model of FLUX.1 Dev and STUDIO JUNO. It enables image generation with an emphasis on realism using depth maps extracted from the input image. It is based on FLUX.1 Dev for ease of development.",
  Flux1ProJuno: "FLUX.1 Pro JUNO",
  Flux1ProJunoDescription:
    "The JUNO model of FLUX.1 Pro. It combines the high-resolution capabilities of FLUX.1 Pro with STUDIO JUNO's technology, enabling more realistic and artistic expressions.",
  Flux11ProUltraJuno: "FLUX1.1 Pro Ultra JUNO",
  Flux11ProUltraJunoDescription:
    "The JUNO model of FLUX1.1 Pro Ultra. It combines 4MP high-resolution output with STUDIO JUNO's technology, achieving superior image quality and artistic control.",
  Flux1ProCannyJuno: "FLUX.1 Pro Canny JUNO",
  Flux1ProCannyJunoDescription:
    "A model that combines the FLUX.1 Pro Canny model with STUDIO JUNO's technology. It enables more accurate and realistic image editing while maintaining structure by utilizing Canny edge detection and STUDIO JUNO's technology.",
  Flux1ProDepthJuno: "FLUX.1 Pro Depth JUNO",
  Flux1ProDepthJunoDescription:
    "A model that combines the FLUX.1 Pro Depth model with STUDIO JUNO's technology. It enables 3D-aware image editing and generation by combining depth map processing with STUDIO JUNO's technology.",
  Flux1ProFillJuno: "FLUX.1 Pro Fill JUNO",
  Flux1ProFillJunoDescription:
    "A model that combines the FLUX.1 Pro Fill model with STUDIO JUNO's technology. It enables more natural and context-aware image editing by combining advanced inpainting capabilities with STUDIO JUNO's technology.",
  Flux1ProFill: "FLUX.1 Pro Fill",
  Flux1ProFillDescription:
    "The Fill model of FLUX.1 Pro. It provides advanced inpainting capabilities that enable seamless editing that naturally integrates into existing images using text descriptions and binary masks. It also supports outpainting, which extends images beyond their original boundaries.",
  Flux1DevFill: "FLUX.1 Dev Fill JUNO",
  Flux1DevFillDescription:
    "The Fill model of FLUX.1 Dev. It is a model that adds advanced inpainting capabilities to the image generation capabilities of FLUX.1 Dev. It enables seamless editing that naturally integrates into existing images using text descriptions and binary masks. It also supports outpainting, which extends images beyond their original boundaries.",
  KolorsVTO: "Kolors Virtual Try-On",
  KolorsVTODescription:
    "Kolors Virtual Try-On is a model that can composite fashion items onto images. You can change the color and design of fashion items.",
  MinimaxImage: "Minimax Image",
  MinimaxImageDescription:
    "Minimax Image is a model provided by HailuoAI, which develops video generation AI. It enables high-quality image generation at a very low cost.",
  FluxJuggernautBase: "Juggernaut Flux Base",
  FluxJuggernautBaseDescription:
    "The base model of Juggernaut Flux provided by RunDiffusion, with parameters equivalent to Flux Dev. It provides balanced image quality and generation speed.",
  FluxJuggernautLightning: "Juggernaut Flux Lightning",
  FluxJuggernautLightningDescription:
    "A high-speed image generation model provided by RunDiffusion with parameters equivalent to Flux Schnell. Ideal when quick results are needed.",
  FluxJuggernautPro: "Juggernaut Flux Pro",
  FluxJuggernautProDescription:
    "A high-quality image generation model provided by RunDiffusion with parameters equivalent to Flux Pro. It provides detailed expression and high image quality.",
  FluxJuggernautJuno: "Juggernaut Flux Juno",
  FluxJuggernautJunoDescription:
    "A Juggernaut model based on Flux.1 Dev provided by RunDiffusion. It enables high-quality image generation leveraging STUDIO JUNO's technology.",
  RecraftV3: "Recraft V3",
  RecraftV3Description:
    "Recraft V3 has a very high understanding of prompts and can generate precise and beautiful images that reflect the user's intentions. It also has high aesthetic evaluations like Midjourney.",
  IdeogramV2a: "Ideogram 2a",
  IdeogramV2aDescription:
    "A model that excels at text insertion. When Magic Prompt is enabled, you can greatly simplify prompts during image generation and generate images that follow the image prompt.",
  IdeogramV2aTurbo: "Ideogram 2a Turbo",
  IdeogramV2aTurboDescription:
    "A model that adjusts Ideogram 2a to improve speed. When Magic Prompt is enabled, you can greatly simplify prompts during image generation and generate images that follow the image prompt.",
  IdeogramV2Edit: "Ideogram V2 Edit",
  IdeogramV2EditDescription:
    "The editing model of Ideogram V2. It provides advanced inpainting capabilities that enable seamless editing that naturally integrates into existing images using text descriptions and binary masks.",
  IdeogramV2TurboEdit: "Ideogram V2 Turbo Edit",
  IdeogramV2TurboEditDescription:
    "A model that adjusts Ideogram V2 Edit to improve speed. It enables seamless editing that naturally integrates into existing images using text descriptions and binary masks.",
  Imagen3: "Imagen 3",
  Imagen3Description:
    "Imagen 3 is an image generation model developed by Google. It is familiar with Japanese culture and is suitable for generating Japanese landscapes, anime characters, people, etc.",
  Imagen3Fast: "Imagen 3 Fast",
  Imagen3FastDescription:
    "Imagen 3 Fast can generate images faster than Imagen 3. It is suitable when image generation speed is prioritized.",
  SD: "Stable Diffusion",
  SDDescription:
    "It requires knowledge of prompts, and images can easily break down if not entered accurately, making it high in difficulty. However, there are many types of models, enabling the generation of types of images that cannot be output by others.",
  SDXL: "Stable Diffusion XL",
  SDXLDescription:
    "It can generate higher resolution images than conventional Stable Diffusion. Also, prompts have become more flexible than SD, but there are still few types of models.",
  AuraSR: "Aura SR",
  AuraSRDescription:
    "Unlike conventional upsamplers, it uses next-generation upscaling technology to improve image resolution while preserving the similarity of the original image.",
  ClarityUpscaler: "Clarity Upscaler",
  ClarityUpscalerDescription:
    "It enhances image quality while preserving the similarity of the original image, and also performs excellently as an enhancer that increases creativity.",
  CreativeUpscaler: "Creative Upscaler",
  CreativeUpscalerDescription:
    "It upscales the original image and improves image resolution by generating missing parts anew. It can enlarge up to 4,194,304px (approximately a 2,048px square).",
  RecraftClarityUpscaler: "Recraft Clarity Upscaler",
  RecraftClarityUpscalerDescription:
    "An upscaler provided by Recraft. It enhances image quality while preserving the similarity of the original image, and also performs excellently as an enhancer that increases creativity.",
  RecraftCreativeUpscaler: "Recraft Creative Upscaler",
  RecraftCreativeUpscalerDescription:
    "An upscaler provided by Recraft. It upscales the original image and improves image resolution by generating missing parts anew. It can enlarge up to 4,194,304px (approximately a 2,048px square).",
  SD3: "Stable Diffusion 3 Large",
  SD3Description:
    "The latest version of Stable Diffusion. It is faithful to prompts and excels at text insertion in images.",
  SD35: "Stable Diffusion 3.5 Large",
  SD35Description:
    "The latest model being developed by Stability AI, Stable diffusion 3.5. A model with 8 billion parameters, excellent quality, and prompt compliance, though its quality is inferior to FLUX.1 Dev but with higher prompt accuracy.",
  SDUltra: "Stable Image Ultra",
  SDUltraDescription:
    "Created from the most advanced models including Stable Diffusion 3, Ultra excels in typography, complex compositions, dynamic lighting, vibrant colors, and the overall coherence and structure of artwork.",
  REALESRGAN: "Real-ESRGAN",
  BriaBackgroundReplace: "Bria Background Replace",
  BriaBackgroundReplaceDescription:
    "A model provided by Bria.ai that can replace the background of an image with another image.",
  BriaProductShot: "Bria Product Shot",
  BriaProductShotDescription: "A model provided by Bria.ai that can generate product photos.",
  IcLightV2: "IC-Light V2",
  IcLightV2Description:
    "It can change the light source of a given photo, changing the atmosphere of the photo. The background can also be changed simultaneously.",
  GeminiFlashEdit: "Gemini Flash Edit",
  GeminiFlashEditDescription:
    "A model that combines the large language model Gemini and the technology of Imagen3. Unlike previous image generation models, image generation is possible in an instructional tone.",
};
export default junoImageEngine;

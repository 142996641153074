import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CopyButtonOrIcon from "../../../../components/common/CopyButtonOrIcon";
import { useFileSidebarContext } from "../../../../context/FileSidebarContext";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import {
  setReplacement,
  setSaveTrigger,
  setShouldReplace,
  setSidebarGenerateImage,
} from "../../../../redux/slices/fileSlice";
import { RootState } from "../../../../redux/store";
import { ColumnCenteredBox, RowBox } from "../../../../utils/styledBox";
import ImageWithSkeleton from "../../../../components/common/ImageWithSkelton";

interface SidebarAnswerBoxProps {
  chatResponse: string;
}

const SidebarAnswerBox: React.FC<SidebarAnswerBoxProps> = ({ chatResponse }) => {
  const { t } = useTranslation();
  const { fileUuid } = useParams();
  const dispatch = useDispatch();
  const selection = useSelector((state: RootState) => state.file.selection)[fileUuid!] || { text: "" };
  const { selectedButton, inputRef, generatedImageURL } = useFileSidebarContext();
  const navigate = useCustomNavigate();
  const theme = useTheme();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [rotationDegree, setRotationDegree] = useState(0);
  const [maxRows, setMaxRows] = useState(8);

  const replaceContent = () => {
    setRotationDegree(rotationDegree + 360);
    dispatch(
      setReplacement({
        uuid: fileUuid!,
        index: selection.index || 0,
        length: selection.length || 0,
        text: chatResponse || "",
      })
    );
    dispatch(setShouldReplace({ uuid: fileUuid!, shouldReplace: true }));
  };

  const updateMaxRows = useCallback(() => {
    if (inputRef.current) {
      const inputHeight = inputRef.current.offsetHeight;
      const newHeight = window.innerHeight - inputHeight - 270;
      const rowHeight = 23;
      const newMaxRows = Math.floor(newHeight / rowHeight);
      setMaxRows(newMaxRows > 6 ? newMaxRows : 6);
    }
  }, [inputRef]);

  useEffect(() => {
    if (textAreaRef.current) {
      const element = textAreaRef.current;
      const isNearBottom = element.scrollTop + element.clientHeight >= element.scrollHeight - 50;
      if (isNearBottom) element.scrollTop = element.scrollHeight;
    }
  }, [chatResponse]);

  useEffect(() => {
    updateMaxRows();
    window.addEventListener("resize", updateMaxRows);
    return () => window.removeEventListener("resize", updateMaxRows);
  }, [updateMaxRows]);

  useEffect(() => {
    if (inputRef.current) {
      const observer = new ResizeObserver(updateMaxRows);
      observer.observe(inputRef.current);
      return () => observer.disconnect();
    }
  }, [inputRef, updateMaxRows]);

  const handleNavigate = (url) => {
    dispatch(setSaveTrigger(true));
    setTimeout(() => navigate(url), 1000);
  };

  const insertImage = () => {
    dispatch(setSidebarGenerateImage(generatedImageURL));
  };

  return (
    <>
      {/* AI ANSWER */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          width: "100%",
          height: "100%",
          px: 2,
        }}
      >
        {selectedButton === "createImages"
          ? generatedImageURL && (
              <Paper elevation={1} sx={{ p: 2, overflow: "auto", maxHeight: "100%", borderRadius: 2 }}>
                <ColumnCenteredBox sx={{ gap: 1 }}>
                  <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                    <ImageWithSkeleton
                      src={generatedImageURL}
                      alt={"Generated Image"}
                      style={{ width: "100%", borderRadius: 8, height: "100%" }}
                      skeltonStyle={{ width: "100%", borderRadius: 2, height: 400 }}
                    />
                    <RowBox sx={{ position: "absolute", bottom: 8, right: 8, gap: 1, justifyContent: "flex-end" }}>
                      <Button
                        disableElevation
                        startIcon={<ExitToAppIcon />}
                        variant={"contained"}
                        onClick={() => handleNavigate("/juno")}
                      >
                        {t("textEditor.sidebar.imageAnswer.goJuno")}
                      </Button>
                      <Button
                        disableElevation
                        startIcon={<ChangeCircleIcon />}
                        variant={"contained"}
                        onClick={insertImage}
                      >
                        {t("textEditor.sidebar.imageAnswer.insert")}
                      </Button>
                    </RowBox>
                  </Box>
                  <Typography variant={"caption"}>{t("textEditor.sidebar.imageAnswer.info")}</Typography>
                  <Typography variant={"caption"}>{t("textEditor.sidebar.imageAnswer.info2")}</Typography>
                </ColumnCenteredBox>
              </Paper>
            )
          : chatResponse.length > 0 && (
              <Paper elevation={1} sx={{ p: 2, overflow: "auto", maxHeight: "100%", borderRadius: 2 }}>
                <TextField
                  variant={"standard"}
                  value={chatResponse.trim()}
                  fullWidth
                  multiline
                  maxRows={maxRows}
                  inputRef={textAreaRef}
                  InputProps={{ readOnly: true }}
                  sx={{ mb: 1 }}
                />
                <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"} gap={1}>
                  <CopyButtonOrIcon textToCopy={chatResponse} displayType={"button"} variant={"outlined"} />
                  <Button disableElevation variant={"outlined"} sx={{ alignItems: "center" }} onClick={replaceContent}>
                    <Typography variant={"button"} sx={{ display: "flex", alignItems: "center" }}>
                      <ChangeCircleIcon
                        fontSize={"medium"}
                        sx={{
                          mr: 1,
                          transform: `rotate(${rotationDegree}deg)`,
                          transition: "transform 1s ease-in-out",
                        }}
                      />
                      {t("textEditor.sidebar.replace")}
                    </Typography>
                  </Button>
                </Box>
              </Paper>
            )}
        <Box flexGrow={1} />
      </Box>
    </>
  );
};
export default SidebarAnswerBox;

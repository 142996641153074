import { Box, Button, Fade, Hidden, Paper, Stack } from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { Images } from "../../../assets";
import CenterContentsManager from "./CenterContentsManager";

const CenterContents = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const location = useLocation();
  const darkColor = theme.palette.text.primary;
  const { isLoggedIn } = useLoginStatus();
  const [activeMenu, setActiveMenu] = useState<string>("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const leaveTimeout = useRef<NodeJS.Timeout | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    const handleBlur = () => {
      setIsMenuOpen(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("blur", handleBlur);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("blur", handleBlur);
      if (leaveTimeout.current) {
        clearTimeout(leaveTimeout.current);
      }
    };
  }, []);

  const getPathPart = (index: number) => location.pathname.split("/")[index];

  const StyledSwitchButtonContainer = styled(Stack)({
    backdropFilter: "blur(8px)",
    padding: "4px 4px",
    borderRadius: "18px",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    height: 36,
    gap: 1,
    color: theme.palette.text.primary,
  });

  interface ButtonConfig {
    path: string;
    label: string;
    img: React.ReactElement;
  }

  const buttons: ButtonConfig[] = [
    {
      path: "",
      label: "title",
      img: <img src={Images.tenak.small} style={{ width: 18, height: 18, marginLeft: -4 }} alt="Tenak.ai" />,
    },
    {
      path: "juno",
      label: "juno.title",
      img: <img src={Images.juno.mini2} style={{ width: 18, height: 18, marginLeft: -4 }} alt="STUDIO JUNO" />,
    },
    {
      path: "notes-nebula",
      label: "notesNebula.title",
      img: <img src={Images.notesNebula.png} style={{ width: 18, height: 18, marginLeft: -4 }} alt="Notes Nebula" />,
    },
  ];

  const handleMouseEnter = () => {
    if (leaveTimeout.current) {
      clearTimeout(leaveTimeout.current);
      leaveTimeout.current = null;
    }
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    leaveTimeout.current = setTimeout(() => {
      setIsMenuOpen(false);
    }, 100);
  };

  const CustomButton: React.FC<ButtonConfig> = ({ path, label, img }) => {
    const currentPath = getPathPart(2);

    const isActive = () => {
      if (path === "") {
        return !["juno", "notes-nebula"].includes(currentPath);
      }
      return path === currentPath;
    };

    return (
      <Button
        onClick={() => navigate(path === "" ? "" : `/${path}`)}
        variant="text"
        onMouseEnter={() => {
          setActiveMenu(path);
          handleMouseEnter();
        }}
        sx={{
          color: isActive() ? "primary.light" : darkColor,
          backgroundColor: isActive() ? alpha(theme.palette.primary.main, 0.2) : "transparent",
          borderRadius: "14px",
          width: 160,
          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
          transition: "background-color 0.5s",
          gap: 1,
        }}
      >
        {img}
        <Typography fontWeight={"bold"} variant={"caption"}>
          {t(label)}
        </Typography>
      </Button>
    );
  };

  return (
    <Hidden mdDown>
      {getPathPart(3) === "articles" && !isLoggedIn ? null : (
        <Box
          ref={containerRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{ position: "relative" }}
        >
          <StyledSwitchButtonContainer direction="row">
            {buttons.map((button, index) => (
              <CustomButton key={index} {...button} />
            ))}
          </StyledSwitchButtonContainer>
          <Fade in={isLoggedIn! && isMenuOpen} timeout={200}>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                top: 40,
                transform: "translate(-50%, -0%)",
                width: "100%",
                zIndex: 10,
                backdropFilter: isMenuOpen ? "blur(8px)" : "none",
                transition: "backdrop-filter 0.5s ease", // `Fade`と同期
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  padding: "4px 4px",
                  borderRadius: 2,
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: alpha(theme.palette.background.paper, 0.8),
                  gap: 1,
                  color: theme.palette.text.primary,
                  p: 1,
                  minWidth: 200,
                }}
              >
                <CenterContentsManager activeMenu={activeMenu} />
              </Paper>
            </Box>
          </Fade>
        </Box>
      )}
    </Hidden>
  );
};

export default CenterContents;

import { Slider, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../../context/AlertContext";
import { useVideo } from "../../../../../context/juno/VideoContext";
import { ColumnBox } from "../../../../../utils/styledBox";
import ModelVersionSelectBox from "../ModelVersionSelectBox";
import VideoUpload from "../VideoUpload";
import { IconButton, Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";
import { RowBox } from "../../../../../utils/styledBox";

const ParamsTopazVideoUpscale = () => {
  const { t } = useTranslation();
  const { scale, setScale, fps, setFps, video1, setVideo1, modelVersion, updateParams, setVideo1Metadata } = useVideo();
  const { setAlert } = useAlert();
  const [localScale, setLocalScale] = useState<number>(1);

  const [maxScale, setMaxScale] = useState<number>(4);

  // メタデータの取得
  useEffect(() => {
    if (video1) {
      const fetchMetadata = async () => {
        const csrftoken = Cookies.get("csrftoken");
        const response = await fetch("/api/v1/juno/video-metadata", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken!,
          },
          body: JSON.stringify({
            video1,
          }),
        });

        const data = await response.json();
        if (data.status === "success" && data.metadata) {
          setVideo1Metadata(data.metadata);

          // バリデーション
          try {
            // 解像度チェック
            if (data.metadata.width * data.metadata.height > 8294400) {
              setVideo1("");
              setAlert("error", t("juno.video.error.max.resolution"));
              return;
            }

            // 動画の長さをチェック
            if (data.metadata.duration > 30) {
              setVideo1("");
              setAlert("error", t("juno.video.error.max.videoDuration", { sec: 30 }));
              return;
            }

            // アスペクト比を考慮したスケール計算
            // 長辺が3840px、短辺が2160pxを超えないようにする
            const widthScale = 3840 / data.metadata.width;
            const heightScale = 2160 / data.metadata.height;
            // 小さい方を採用（両方の制約を満たす必要があるため）
            const aspectAwareScale = Math.min(widthScale, heightScale);
            console.log(aspectAwareScale);
            // 小数点第1位までに制限（4以下）
            const finalMaxScale = Math.min(Math.floor(aspectAwareScale * 10) / 10, 4);
            setMaxScale(finalMaxScale);

            // スケール値の制限
            if (scale > finalMaxScale) {
              setScale(finalMaxScale);
            }
          } catch (error) {
            console.error(error);
            setVideo1("");
            setAlert("error", t("juno.error.validation"));
            return;
          }
        }
      };

      fetchMetadata().catch(console.error);
    } else {
      setVideo1Metadata(null);
      setMaxScale(4);
    }
  }, [video1]);

  useEffect(() => {
    if (localScale !== scale) {
      setLocalScale(scale);
    }
  }, [scale]);

  useEffect(() => {
    if (localScale !== scale) {
      setLocalScale(scale);
    }
  }, [scale]);

  // スライダーのマークを計算
  const scaleMarks = useMemo(() => {
    const baseMarks = [
      { value: 1, label: "1x" },
      { value: 2, label: "2x" },
      { value: 3, label: "3x" },
      { value: 4, label: "4x" },
    ];
    return baseMarks.filter((mark) => mark.value <= maxScale);
  }, [maxScale]);

  // FPSスライダーのマーク
  const fpsMarks = [
    { value: 24, label: "24" },
    { value: 30, label: "30" },
    { value: 48, label: "48" },
    { value: 60, label: "60" },
  ];

  return (
    <ColumnBox sx={{ gap: 4 }}>
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <ModelVersionSelectBox
          platform="TOPAZLABS"
          value={modelVersion.TOPAZLABS}
          onChange={(value) => updateParams("TOPAZLABS", "modelVersion", value)}
          menu={"upscale"}
        />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.video")}
        </Typography>
        <VideoUpload video={video1} setVideo={setVideo1} />
      </ColumnBox>

      <ColumnBox>
        <RowBox justifyContent={"space-between"}>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.scale")}
          </Typography>
          <Tooltip title={t("juno.video.params.scaleHelp")} placement="top">
            <IconButton size="small">
              <Info fontSize="small" />
            </IconButton>
          </Tooltip>
        </RowBox>

        <Slider
          value={localScale}
          onChange={(_, value) => {
            setLocalScale(value as number);
          }}
          onChangeCommitted={(_, value) => {
            // ローカルのスケール値を更新
            const newScale = value as number;
            if (newScale > maxScale) {
              setScale(maxScale);
            } else {
              setScale(newScale);
            }
          }}
          min={1}
          max={maxScale}
          step={0.1}
          valueLabelDisplay="auto"
          marks={scaleMarks}
        />
      </ColumnBox>

      <ColumnBox>
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.fps")}
        </Typography>
        <Slider
          value={fps}
          onChange={(_, value) => {
            setFps(value as number);
          }}
          min={24}
          max={60}
          step={2}
          valueLabelDisplay="auto"
          marks={fpsMarks}
        />
      </ColumnBox>
    </ColumnBox>
  );
};

export default ParamsTopazVideoUpscale;

// src/assets/images/index.ts
// よく使用される画像のインポート

// エラーページと共通画像
import error404 from "./error_404.jpg";
import loading from "./loading.svg";
import maintenance from "./maintenance.jpg";
import noImage from "./no_image.jpg";
import oversizedImage from "./oversized-image.jpg";

// Pricing画像
import pricingTop from "./pricing/top.jpg";

// Top画像
import topBrowsing from "./top/browsing.jpg";
import topEditor from "./top/editor.jpg";
import topFVZO from "./top/FVZO.png";
import topMindmap from "./top/mindmap.png";
import topWoman from "./top/woman.jpg";
import topWhatIs from "./top/what_is.jpg";
import topPromptTemplate from "./top/prompt-template.jpg";
import topAstronaut from "./top/astronaut.png";

// 共通背景画像
import bg01 from "./common/bg-01.png";

// ダッシュボード画像
import dashBoardColor from "./dashBoard/color.jpg";
import dashBoardPrompt from "./dashBoard/prompt.jpg";
import dashBoardExplore from "./dashBoard/explore.jpg";
import dashBoardT2i from "./dashBoard/t2i.jpg";
import dashBoardI2i from "./dashBoard/i2i.jpg";
import dashBoardUpscale from "./dashBoard/upscale.jpg";
import dashBoardVideo from "./dashBoard/video.jpg";
import dashBoardMindmap from "./dashBoard/mindmap.jpg";
import dashBoardVoice from "./dashBoard/voice.jpg";
import dashBoardWriting from "./dashBoard/writing.jpg";

// Notes Nebula画像
import notesNebulaTop from "./notes-nebula/top.jpg";

// Template画像
import templateTop from "./template/template_1.jpg";
import template0 from "./template/template_0.jpg";
import template1 from "./template/template_1.jpg";
import template2 from "./template/template_2.jpg";
import template3 from "./template/template_3.jpg";
import template4 from "./template/template_4.jpg";
import template5 from "./template/template_5.jpg";
import template6 from "./template/template_6.jpg";
import template7 from "./template/template_7.jpg";
import template8 from "./template/template_8.jpg";
import template9 from "./template/template_9.jpg";
import template10 from "./template/template_10.jpg";
import template11 from "./template/template_11.jpg";
import template12 from "./template/template_12.jpg";
import template13 from "./template/template_13.jpg";
import template14 from "./template/template_14.jpg";
import template15 from "./template/template_15.jpg";
import template16 from "./template/template_16.jpg";
import template17 from "./template/template_17.jpg";
import template18 from "./template/template_18.jpg";
import template19 from "./template/template_19.jpg";
import template20 from "./template/template_20.jpg";
import template21 from "./template/template_21.jpg";
import template22 from "./template/template_22.jpg";
import template23 from "./template/template_23.jpg";
import template24 from "./template/template_24.jpg";
import template25 from "./template/template_25.jpg";
import template26 from "./template/template_26.jpg";
import template27 from "./template/template_27.jpg";
import template28 from "./template/template_28.jpg";
import template29 from "./template/template_29.jpg";
import template30 from "./template/template_30.jpg";
import template31 from "./template/template_31.jpg";
import template32 from "./template/template_32.jpg";
import template33 from "./template/template_33.jpg";

// Juno画像
import junoAboutTop from "./juno/about/top.jpg";
import junoAboutT2iBefore from "./juno/about/t2i-before.jpg";
import junoAboutT2iAfter from "./juno/about/t2i-after.jpg";
import junoAboutI2iBefore from "./juno/about/i2i-before.jpg";
import junoAboutI2iAfter from "./juno/about/i2i-after.jpg";
import junoAboutSample01 from "./juno/about/sample01.jpg";
import junoAboutBefore01 from "./juno/about/before01.jpg";
import junoAboutAfter01 from "./juno/about/after01.jpg";
import junoAboutEditSample from "./juno/about/edit-sample.jpg";
import junoAboutEditBefore from "./juno/about/edit-before.jpg";
import junoAboutEditAfter from "./juno/about/edit-after.jpg";
import junoAboutRelightBefore from "./juno/about/relight-before.jpg";
import junoAboutRelightAfter from "./juno/about/relight-after.jpg";
import junoAboutProductBefore from "./juno/about/product-before.jpg";
import junoAboutProductAfter from "./juno/about/product-after.jpg";
import junoAboutSection21 from "./juno/about/section2-1.jpg";
import junoAboutSection22 from "./juno/about/section2-2.jpg";
import junoAboutSection23 from "./juno/about/section2-3.jpg";

// Writing AI画像
import writingArticle from "./writing/article.jpg";
import writingShortVideo from "./writing/short_video.jpg";
import writingSummary from "./writing/summary.jpg";

// Juno AI Engine画像
import junoAiEngineT2i from "./juno/ai-engine/t2i.png";
import junoAiEngineI2i from "./juno/ai-engine/i2i.png";
import junoAiEngineUpscale from "./juno/ai-engine/upscale.png";
import junoAiEngineEditor from "./juno/ai-engine/editor.png";
import junoAiEngineAuraSR from "./juno/ai-engine/AuraSR.png";
import junoAiEngineBriaBackgroundReplace from "./juno/ai-engine/BriaBackgroundReplace.png";
import junoAiEngineBriaProductShot from "./juno/ai-engine/BriaProductShot.png";
import junoAiEngineClarityUpscaler from "./juno/ai-engine/ClarityUpscaler.png";
import junoAiEngineCreativeUpscaler from "./juno/ai-engine/CreativeUpscaler.png";
import junoAiEngineDallE3 from "./juno/ai-engine/DallE3.png";
import junoAiEngineFlux1Dev from "./juno/ai-engine/Flux1Dev.png";
import junoAiEngineFlux1DevCannyLoRA from "./juno/ai-engine/Flux1DevCannyLoRA.png";
import junoAiEngineFlux1DevDepthLoRA from "./juno/ai-engine/Flux1DevDepthLoRA.png";
import junoAiEngineFlux1DevFill from "./juno/ai-engine/Flux1DevFill.png";
import junoAiEngineFlux1DevRedux from "./juno/ai-engine/Flux1DevRedux.png";
import junoAiEngineFlux1Pro from "./juno/ai-engine/Flux1Pro.png";
import junoAiEngineFlux1ProCanny from "./juno/ai-engine/Flux1ProCanny.png";
import junoAiEngineFlux1ProCannyJuno from "./juno/ai-engine/Flux1ProCannyJuno.png";
import junoAiEngineFlux1ProDepth from "./juno/ai-engine/Flux1ProDepth.png";
import junoAiEngineFlux1ProDepthJuno from "./juno/ai-engine/Flux1ProDepthJuno.png";
import junoAiEngineFlux1ProFill from "./juno/ai-engine/Flux1ProFill.png";
import junoAiEngineFlux1ProFillJuno from "./juno/ai-engine/Flux1ProFillJuno.png";
import junoAiEngineFlux1ProJuno from "./juno/ai-engine/Flux1ProJuno.png";
import junoAiEngineFlux1RealismLoRA from "./juno/ai-engine/Flux1RealismLoRA.png";
import junoAiEngineFlux1Schnell from "./juno/ai-engine/Flux1Schnell.png";
import junoAiEngineFlux1SchnellRedux from "./juno/ai-engine/Flux1SchnellRedux.png";
import junoAiEngineFlux11Pro from "./juno/ai-engine/Flux11Pro.png";
import junoAiEngineFlux11ProRedux from "./juno/ai-engine/Flux11ProRedux.png";
import junoAiEngineFlux11ProUltra from "./juno/ai-engine/Flux11ProUltra.png";
import junoAiEngineFlux11ProUltraJuno from "./juno/ai-engine/Flux11ProUltraJuno.png";
import junoAiEngineFlux11ProUltraRedux from "./juno/ai-engine/Flux11ProUltraRedux.png";
import junoAiEngineFluxJuggernautBase from "./juno/ai-engine/FluxJuggernautBase.png";
import junoAiEngineFluxJuggernautJuno from "./juno/ai-engine/FluxJuggernautJuno.png";
import junoAiEngineFluxJuggernautLightning from "./juno/ai-engine/FluxJuggernautLightning.png";
import junoAiEngineFluxJuggernautPro from "./juno/ai-engine/FluxJuggernautPro.png";
import junoAiEngineFluxJuno from "./juno/ai-engine/FluxJuno.png";
import junoAiEngineFluxPuLID from "./juno/ai-engine/FluxPuLID.png";
import junoAiEngineGeminiFlashEdit from "./juno/ai-engine/GeminiFlashEdit.png";
import junoAiEngineIcLightV2 from "./juno/ai-engine/IcLightV2.png";
import junoAiEngineIdeogramV2a from "./juno/ai-engine/IdeogramV2a.png";
import junoAiEngineIdeogramV2aTurbo from "./juno/ai-engine/IdeogramV2aTurbo.png";
import junoAiEngineIdeogramV2Edit from "./juno/ai-engine/IdeogramV2Edit.png";
import junoAiEngineIdeogramV2TurboEdit from "./juno/ai-engine/IdeogramV2TurboEdit.png";
import junoAiEngineImagen3 from "./juno/ai-engine/Imagen3.png";
import junoAiEngineImagen3Fast from "./juno/ai-engine/Imagen3Fast.png";
import junoAiEngineKolorsVTO from "./juno/ai-engine/KolorsVTO.png";
import junoAiEngineLuminaV2 from "./juno/ai-engine/LuminaV2.png";
import junoAiEngineMinimaxImage from "./juno/ai-engine/MinimaxImage.png";
import junoAiEngineRecraftClarityUpscaler from "./juno/ai-engine/RecraftClarityUpscaler.png";
import junoAiEngineRecraftCreativeUpscaler from "./juno/ai-engine/RecraftCreativeUpscaler.png";
import junoAiEngineRecraftV3 from "./juno/ai-engine/RecraftV3.png";
import junoAiEngineSD from "./juno/ai-engine/SD.png";
import junoAiEngineSD3 from "./juno/ai-engine/SD3.png";
import junoAiEngineSD35 from "./juno/ai-engine/SD35.png";
import junoAiEngineSDUltra from "./juno/ai-engine/SDUltra.png";
import junoAiEngineSDXL from "./juno/ai-engine/SDXL.png";

// Juno会社サムネイル画像
import junoCompanyLumaThumbnail from "./juno/companies/luma-thumbnail.jpg";
import junoCompanyKlingThumbnail from "./juno/companies/kling-thumbnail.jpg";
import junoCompanyPikaThumbnail from "./juno/companies/pika-thumbnail.jpg";
import junoCompanyPixverseThumbnail from "./juno/companies/pixverse-thumbnail.jpg";
import junoCompanyHailuoThumbnail from "./juno/companies/hailuo-thumbnail.jpg";
import junoCompanyHaiperThumbnail from "./juno/companies/haiper-thumbnail.jpg";
import junoCompanyRunwayThumbnail from "./juno/companies/runway-thumbnail.jpg";
import junoCompanyViduThumbnail from "./juno/companies/vidu-thumbnail.jpg";
import junoCompanyWanThumbnail from "./juno/companies/wan-thumbnail.jpg";

// ロゴ画像
import logo from "./logo/logo.png";
import logoSvg from "./logo/logo.svg";
import logoBanner from "./logo/logo_banner.png";
import junoLogoMini from "./logo/juno-logo-mini.png";
import junoLogoMini2 from "./logo/juno-logo-mini2.png";
import junoLogo from "./logo/juno-logo.png";
import logoZISmall from "./logo/logoZI-small.png";
import logoZI from "./logo/logoZI.png";
import logoZISvg from "./logo/logoZI.svg";
import nnIco from "./logo/NN.ico";
import nnPng from "./logo/NN.png";
import diocodeBlack from "./logo/Diocode-2-black-high-res.png";
import diocodeWhite from "./logo/Diocode-2-white-high-res.png";

// 会社ロゴ画像
import companyOpenAI from "./top/companies/openai.png";
import companyGoogle from "./top/companies/google.png";
import companyLuma from "./juno/companies/luma.png";
import companyKling from "./juno/companies/kling.png";
import companyPika from "./juno/companies/pika.png";
import companyPixverse from "./juno/companies/pixverse.png";
import companyHailuo from "./juno/companies/hailuo.png";
import companyHaiper from "./juno/companies/haiper.png";
import companyRunway from "./juno/companies/runway.png";
import companyVidu from "./juno/companies/vidu.png";
import companyWan from "./juno/companies/wan.png";
import companyBytedance from "./juno/companies/bytedance.png";
import companySync from "./juno/companies/sync.png";
import companyRealesrgan from "./juno/companies/realesrgan.png";
import companyTopazlabs from "./juno/companies/topazlabs.png";

// よく使用されるOG画像
import ogJuno from "./og_juno.jpg";
import ogNotesNebula from "./og_notes_nebula.jpg";
import ogTenakAi from "./og_tenak_ai.jpg";

// エクスポート
export const Images = {
  // エラーと共通
  error404,
  loading,
  maintenance,
  noImage,
  oversizedImage,

  // 共通背景
  common: {
    bg01,
  },

  // ダッシュボード
  dashBoard: {
    color: dashBoardColor,
    prompt: dashBoardPrompt,
    explore: dashBoardExplore,
    t2i: dashBoardT2i,
    i2i: dashBoardI2i,
    upscale: dashBoardUpscale,
    video: dashBoardVideo,
    mindmap: dashBoardMindmap,
    voice: dashBoardVoice,
    writing: dashBoardWriting,
  },

  // Writing AI
  writing: {
    article: writingArticle,
    shortVideo: writingShortVideo,
    summary: writingSummary,
  },

  // ロゴ
  logo: {
    main: logo,
    svg: logoSvg,
    banner: logoBanner,
  },
  juno: {
    logo: junoLogo,
    mini: junoLogoMini,
    mini2: junoLogoMini2,
    aboutTop: junoAboutTop,
    about: {
      top: junoAboutTop,
      t2iBefore: junoAboutT2iBefore,
      t2iAfter: junoAboutT2iAfter,
      i2iBefore: junoAboutI2iBefore,
      i2iAfter: junoAboutI2iAfter,
      sample01: junoAboutSample01,
      before01: junoAboutBefore01,
      after01: junoAboutAfter01,
      editSample: junoAboutEditSample,
      editBefore: junoAboutEditBefore,
      editAfter: junoAboutEditAfter,
      relightBefore: junoAboutRelightBefore,
      relightAfter: junoAboutRelightAfter,
      productBefore: junoAboutProductBefore,
      productAfter: junoAboutProductAfter,
      section21: junoAboutSection21,
      section22: junoAboutSection22,
      section23: junoAboutSection23,
    },
    // AI Engine
    aiEngine: {
      t2i: junoAiEngineT2i,
      i2i: junoAiEngineI2i,
      upscale: junoAiEngineUpscale,
      editor: junoAiEngineEditor,
      AuraSR: junoAiEngineAuraSR,
      BriaBackgroundReplace: junoAiEngineBriaBackgroundReplace,
      BriaProductShot: junoAiEngineBriaProductShot,
      ClarityUpscaler: junoAiEngineClarityUpscaler,
      CreativeUpscaler: junoAiEngineCreativeUpscaler,
      DallE3: junoAiEngineDallE3,
      Flux1Dev: junoAiEngineFlux1Dev,
      Flux1DevCannyLoRA: junoAiEngineFlux1DevCannyLoRA,
      Flux1DevDepthLoRA: junoAiEngineFlux1DevDepthLoRA,
      Flux1DevFill: junoAiEngineFlux1DevFill,
      Flux1DevRedux: junoAiEngineFlux1DevRedux,
      Flux1Pro: junoAiEngineFlux1Pro,
      Flux1ProCanny: junoAiEngineFlux1ProCanny,
      Flux1ProCannyJuno: junoAiEngineFlux1ProCannyJuno,
      Flux1ProDepth: junoAiEngineFlux1ProDepth,
      Flux1ProDepthJuno: junoAiEngineFlux1ProDepthJuno,
      Flux1ProFill: junoAiEngineFlux1ProFill,
      Flux1ProFillJuno: junoAiEngineFlux1ProFillJuno,
      Flux1ProJuno: junoAiEngineFlux1ProJuno,
      Flux1RealismLoRA: junoAiEngineFlux1RealismLoRA,
      Flux1Schnell: junoAiEngineFlux1Schnell,
      Flux1SchnellRedux: junoAiEngineFlux1SchnellRedux,
      Flux11Pro: junoAiEngineFlux11Pro,
      Flux11ProRedux: junoAiEngineFlux11ProRedux,
      Flux11ProUltra: junoAiEngineFlux11ProUltra,
      Flux11ProUltraJuno: junoAiEngineFlux11ProUltraJuno,
      Flux11ProUltraRedux: junoAiEngineFlux11ProUltraRedux,
      FluxJuggernautBase: junoAiEngineFluxJuggernautBase,
      FluxJuggernautJuno: junoAiEngineFluxJuggernautJuno,
      FluxJuggernautLightning: junoAiEngineFluxJuggernautLightning,
      FluxJuggernautPro: junoAiEngineFluxJuggernautPro,
      FluxJuno: junoAiEngineFluxJuno,
      FluxPuLID: junoAiEngineFluxPuLID,
      GeminiFlashEdit: junoAiEngineGeminiFlashEdit,
      IcLightV2: junoAiEngineIcLightV2,
      IdeogramV2a: junoAiEngineIdeogramV2a,
      IdeogramV2aTurbo: junoAiEngineIdeogramV2aTurbo,
      IdeogramV2Edit: junoAiEngineIdeogramV2Edit,
      IdeogramV2TurboEdit: junoAiEngineIdeogramV2TurboEdit,
      Imagen3: junoAiEngineImagen3,
      Imagen3Fast: junoAiEngineImagen3Fast,
      KolorsVTO: junoAiEngineKolorsVTO,
      LuminaV2: junoAiEngineLuminaV2,
      MinimaxImage: junoAiEngineMinimaxImage,
      RecraftClarityUpscaler: junoAiEngineRecraftClarityUpscaler,
      RecraftCreativeUpscaler: junoAiEngineRecraftCreativeUpscaler,
      RecraftV3: junoAiEngineRecraftV3,
      SD: junoAiEngineSD,
      SD3: junoAiEngineSD3,
      SD35: junoAiEngineSD35,
      SDUltra: junoAiEngineSDUltra,
      SDXL: junoAiEngineSDXL,
    },
    // 会社サムネイル
    companies: {
      lumaThumbnail: junoCompanyLumaThumbnail,
      klingThumbnail: junoCompanyKlingThumbnail,
      pikaThumbnail: junoCompanyPikaThumbnail,
      pixverseThumbnail: junoCompanyPixverseThumbnail,
      hailuoThumbnail: junoCompanyHailuoThumbnail,
      haiperThumbnail: junoCompanyHaiperThumbnail,
      runwayThumbnail: junoCompanyRunwayThumbnail,
      viduThumbnail: junoCompanyViduThumbnail,
      wanThumbnail: junoCompanyWanThumbnail,
    },
  },
  tenak: {
    logo: logoZI,
    small: logoZISmall,
    svg: logoZISvg,
  },
  notesNebula: {
    ico: nnIco,
    png: nnPng,
    top: notesNebulaTop,
  },
  template: {
    top: templateTop,
    template0,
    template1,
    template2,
    template3,
    template4,
    template5,
    template6,
    template7,
    template8,
    template9,
    template10,
    template11,
    template12,
    template13,
    template14,
    template15,
    template16,
    template17,
    template18,
    template19,
    template20,
    template21,
    template22,
    template23,
    template24,
    template25,
    template26,
    template27,
    template28,
    template29,
    template30,
    template31,
    template32,
    template33,
  },
  diocode: {
    black: diocodeBlack,
    white: diocodeWhite,
  },

  // 会社ロゴ
  company: {
    openai: companyOpenAI,
    google: companyGoogle,
    luma: companyLuma,
    kling: companyKling,
    pika: companyPika,
    pixverse: companyPixverse,
    hailuo: companyHailuo,
    haiper: companyHaiper,
    runway: companyRunway,
    vidu: companyVidu,
    wan: companyWan,
    bytedance: companyBytedance,
    sync: companySync,
    realesrgan: companyRealesrgan,
    topazlabs: companyTopazlabs,
  },

  // OG画像
  og: {
    juno: ogJuno,
    notesNebula: ogNotesNebula,
    tenakAi: ogTenakAi,
  },

  // Pricing
  pricing: {
    top: pricingTop,
  },

  // Top
  top: {
    browsing: topBrowsing,
    editor: topEditor,
    fvzo: topFVZO,
    mindmap: topMindmap,
    woman: topWoman,
    whatIs: topWhatIs,
    promptTemplate: topPromptTemplate,
    astronaut: topAstronaut,
  },
};

// 個別エクスポート（特定の画像を直接インポートしたい場合のため）
export {
  error404,
  loading,
  maintenance,
  noImage,
  oversizedImage,
  bg01,
  dashBoardColor,
  dashBoardPrompt,
  dashBoardExplore,
  dashBoardT2i,
  dashBoardI2i,
  dashBoardUpscale,
  dashBoardVideo,
  dashBoardMindmap,
  dashBoardVoice,
  dashBoardWriting,
  writingArticle,
  writingShortVideo,
  writingSummary,
  junoAiEngineT2i,
  junoAiEngineI2i,
  junoAiEngineUpscale,
  junoAiEngineEditor,
  junoAiEngineAuraSR,
  junoAiEngineBriaBackgroundReplace,
  junoAiEngineBriaProductShot,
  junoAiEngineClarityUpscaler,
  junoAiEngineCreativeUpscaler,
  junoAiEngineDallE3,
  junoAiEngineFlux1Dev,
  junoAiEngineFlux1DevCannyLoRA,
  junoAiEngineFlux1DevDepthLoRA,
  junoAiEngineFlux1DevFill,
  junoAiEngineFlux1DevRedux,
  junoAiEngineFlux1Pro,
  junoAiEngineFlux1ProCanny,
  junoAiEngineFlux1ProCannyJuno,
  junoAiEngineFlux1ProDepth,
  junoAiEngineFlux1ProDepthJuno,
  junoAiEngineFlux1ProFill,
  junoAiEngineFlux1ProFillJuno,
  junoAiEngineFlux1ProJuno,
  junoAiEngineFlux1RealismLoRA,
  junoAiEngineFlux1Schnell,
  junoAiEngineFlux1SchnellRedux,
  junoAiEngineFlux11Pro,
  junoAiEngineFlux11ProRedux,
  junoAiEngineFlux11ProUltra,
  junoAiEngineFlux11ProUltraJuno,
  junoAiEngineFlux11ProUltraRedux,
  junoAiEngineFluxJuggernautBase,
  junoAiEngineFluxJuggernautJuno,
  junoAiEngineFluxJuggernautLightning,
  junoAiEngineFluxJuggernautPro,
  junoAiEngineFluxJuno,
  junoAiEngineFluxPuLID,
  junoAiEngineGeminiFlashEdit,
  junoAiEngineIcLightV2,
  junoAiEngineIdeogramV2a,
  junoAiEngineIdeogramV2aTurbo,
  junoAiEngineIdeogramV2Edit,
  junoAiEngineIdeogramV2TurboEdit,
  junoAiEngineImagen3,
  junoAiEngineImagen3Fast,
  junoAiEngineKolorsVTO,
  junoAiEngineLuminaV2,
  junoAiEngineMinimaxImage,
  junoAiEngineRecraftClarityUpscaler,
  junoAiEngineRecraftCreativeUpscaler,
  junoAiEngineRecraftV3,
  junoAiEngineSD,
  junoAiEngineSD3,
  junoAiEngineSD35,
  junoAiEngineSDUltra,
  junoAiEngineSDXL,
  junoCompanyLumaThumbnail,
  junoCompanyKlingThumbnail,
  junoCompanyPikaThumbnail,
  junoCompanyPixverseThumbnail,
  junoCompanyHailuoThumbnail,
  junoCompanyHaiperThumbnail,
  junoCompanyRunwayThumbnail,
  junoCompanyViduThumbnail,
  junoCompanyWanThumbnail,
  notesNebulaTop,
  templateTop,
  template0,
  template1,
  template2,
  template3,
  template4,
  template5,
  template6,
  template7,
  template8,
  template9,
  template10,
  template11,
  template12,
  template13,
  template14,
  template15,
  template16,
  template17,
  template18,
  template19,
  template20,
  template21,
  template22,
  template23,
  template24,
  template25,
  template26,
  template27,
  template28,
  template29,
  template30,
  template31,
  template32,
  template33,
  junoAboutTop,
  junoAboutT2iBefore,
  junoAboutT2iAfter,
  junoAboutI2iBefore,
  junoAboutI2iAfter,
  junoAboutSample01,
  junoAboutBefore01,
  junoAboutAfter01,
  junoAboutEditSample,
  junoAboutEditBefore,
  junoAboutEditAfter,
  junoAboutRelightBefore,
  junoAboutRelightAfter,
  junoAboutProductBefore,
  junoAboutProductAfter,
  junoAboutSection21,
  junoAboutSection22,
  junoAboutSection23,
  logo,
  logoSvg,
  logoBanner,
  junoLogoMini,
  junoLogoMini2,
  junoLogo,
  logoZISmall,
  logoZI,
  logoZISvg,
  nnIco,
  nnPng,
  diocodeBlack,
  diocodeWhite,
  companyOpenAI,
  companyGoogle,
  companyLuma,
  companyKling,
  companyPika,
  companyPixverse,
  companyHailuo,
  companyHaiper,
  companyRunway,
  companyVidu,
  companyWan,
  companyBytedance,
  companySync,
  companyRealesrgan,
  companyTopazlabs,
  ogJuno,
  ogNotesNebula,
  ogTenakAi,
  pricingTop,
  topBrowsing,
  topEditor,
  topFVZO,
  topMindmap,
  topWoman,
  topWhatIs,
  topPromptTemplate,
  topAstronaut,
};

const header = {
  // Credit tooltips
  tooltip: {
    totalCredits: "Total credits (Monthly credits + Daily credits + Extra Credits)",
    monthlyCredits: "Credits obtained with subscription plan (reset on update day)",
    dailyCredits: "50 credits obtained daily upon login (reset after 24 hours from last receipt)",
    additionalCredits: "Purchased Extra Credits (indefinite)",
  },
  upgrade: "Upgrade",
  addCredit: "Add Credits",
};
export default header;

const drawer = {
  home: "Tenak.ai",
  notesNebula: "Notes Nebula",
  notesNebulaDescription: "Article posting platform",
  juno: "STUDIO JUNO",
  junoDescription: "Image and video generation AI",
  browsing: "Browsing",
  library: "Library",
  images: "Image",
  videos: "Video",
  text: "Text",
  mindmap: "Mind map",
  voice: "Voice",
  writingAI: "Writing AI",
  AIs: "Tenak AI",
  explore: "Explore",
  myImages: "My Images",
  myVideos: "My Videos",
  myVoices: "My Voices",
  models: "All Models",
  imageTemplates: "Image Templates",
  imageGenerator: "Image Generator",
  videoGenerator: "Video Generator",
  voiceGenerator: "Voice Generator",
  colorGenerator: "Color Palette Generator",
  mindmapGenerator: "Mind Map Generator",
  templates: "Prompt Templates",
  aiEditor: "AI Text Editor",
  createText: "Create Text",
  bookmark: "Bookmark",
  articles: "Articles",
  speedMode: "Speed Mode",
  intelligentMode: "Intelligent Mode",
  modeDescription:
    "You can change the AI mode of Tenak AI text generation. Speed mode optimizes AI processing speed and reduces credits, while Intelligent mode prioritizes intelligence.",
};
export default drawer;

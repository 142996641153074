import { Box, Grid, Pagination, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { StyledTypography } from "../../../utils/styledTypography";
import ArticleCard from "./ArticleCard";

interface PaginationData {
  total: number;
  page: number;
  page_size: number;
  total_pages: number;
}

interface LatestProps {
  pageSize?: number;
  view: string;
}

const Latest = ({ pageSize = 20, view }: LatestProps) => {
  const { t } = useTranslation();
  interface Article {
    uuid: string;
    title: string;
    content: string;
    user: string;
    avatar: string;
    thumbnail: string;
    created_at: string;
    tags: string[];
  }

  const [latestArticles, setLatestArticles] = useState<Article[]>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    total: 0,
    page: 1,
    page_size: pageSize,
    total_pages: 0,
  });
  const location = useLocation();
  const lang = location.pathname.split("/")[1];

  const get_articles = async (page: number) => {
    try {
      const res = await axios.get(`/api/v1/notes-nebula/?type=latest&lang=${lang}&page=${page}&page_size=${pageSize}`);
      setLatestArticles(res.data.data.latest_articles);
      setPagination(res.data.data.pagination);

      res.data.data.latest_articles.map((item: Article) => {
        if (item["thumbnail"]) {
          const img = new Image();
          img.src = item["thumbnail"];
          img.onload = () => {
            setLoadedImages((loadedImages) => [...loadedImages, item["uuid"]]);
          };
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    get_articles(page);
  };

  useEffect(() => {
    if (view === "latest") {
      get_articles(1);
    }
  }, [location.pathname, view]);

  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  return (
    <Box>
      <StyledTypography variant={"h6"} sx={{ mb: 2 }}>
        {t("notesNebula.latest")}
      </StyledTypography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {latestArticles.length > 0 ? (
          latestArticles.map((item: Article, index: number) => (
            <Grid item xs={12} md={4} lg={2.4} key={index}>
              <ArticleCard article={item} loadedImages={loadedImages} index={index} />
            </Grid>
          ))
        ) : (
          <Typography variant="subtitle1" sx={{ my: 2, ml: 4 }}>
            No articles found.
          </Typography>
        )}
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: 4 }}>
        <Pagination count={pagination.total_pages} page={pagination.page} onChange={handlePageChange} color="primary" />
      </Grid>
    </Box>
  );
};
export default Latest;

import React from "react";
import Meta from "../../../components/common/Meta";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import LatestVideoList from "./components/LatestVideoList";
import useWindowSize from "../../../hooks/useWindowSize";
import { uiHeight } from "../../../utils/uiHeight";

const MyVideos = () => {
  const { t } = useTranslation();
  const { height } = useWindowSize();

  const calculatedSize = Math.floor(((height - 200) / 270) * 5);
  const dynamicSize = Math.max(5, Math.floor(calculatedSize / 5) * 5);
  return (
    <>
      <Meta
        title={t("juno.videos")}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
        route={"Studio JUNO by Tenak AI"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          px: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            px: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <LatestVideoList grid={2.4} size={dynamicSize} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default MyVideos;

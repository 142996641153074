import { Box, Paper, TextField, Typography, useTheme } from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import PreviewImageModal from "../../../../components/common/ImageModal";
import { useMindMap } from "../../../../context/MindmapContext";
import ImageModal from "../ImageModal";
import LinkModal from "../LinkModal";
import { NodeImages } from "./NodeImages";
import { NodeLinks } from "./NodeLinks";
import { NodeColor } from "./types";

const CustomNode: React.FC<NodeProps> = ({ id, data, selected }) => {
  const theme = useTheme();
  const nodeRef = useRef<HTMLDivElement>(null);
  const [label, setLabel] = useState(data.label);
  const [color, setColor] = useState<NodeColor>({
    name: "",
    lightColor: theme.palette.grey[300],
    darkColor: theme.palette.grey[800],
  });
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);

  const links = data.url || [];
  const images = data.images || [];
  const { setNodes } = useMindMap();

  const nodeColor = useMemo(
    () => ({
      name: data.color || "",
      lightColor: data.lightColor || theme.palette.grey[300],
      darkColor: data.darkColor || theme.palette.grey[800],
    }),
    [data.color, data.lightColor, data.darkColor, theme.palette.grey]
  );

  useEffect(() => {
    setLabel(data.label);
    setColor(nodeColor);
  }, [data.label, nodeColor]);

  useEffect(() => {
    if (nodeRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0];
        if (entry) {
          const { width, height } = entry.contentRect;
          data.size = { width, height };
        }
      });

      resizeObserver.observe(nodeRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [id, setNodes]);

  const handleImagePreview = useCallback((index: number) => {
    setSelectedImageIndex(index);
    setOpenImagePreview(true);
  }, []);

  return (
    <>
      <Paper
        elevation={0}
        ref={nodeRef}
        sx={{
          position: "relative",
          borderRadius: 1,
          border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${color.darkColor}`,
          width: 400,
          overflow: "hidden",
        }}
      >
        <Box sx={{ background: color.darkColor, height: 5 }}></Box> {/* ダークモードは常に有効 */}
        <Box sx={{ display: "flex", justifyContent: "center", py: 1, flexDirection: "column" }}>
          {selected ? (
            <TextField
              multiline
              fullWidth
              value={label}
              variant="standard"
              onChange={(e) => {
                setLabel(e.target.value);
                data.label = e.target.value;
              }}
              sx={{ px: 2 }}
            />
          ) : (
            <Typography variant="body1" sx={{ px: 2, whiteSpace: "break-spaces" }}>
              {label}
            </Typography>
          )}

          <NodeImages
            images={images}
            onDeleteImage={(index) => {
              const updatedImages = images.filter((_, i) => i !== index);
              data.images = updatedImages;
              // 親コンポーネントの状態を更新
              setNodes((nds) =>
                nds.map((node) => {
                  if (node.id === id) {
                    return {
                      ...node,
                      data: {
                        ...node.data,
                        images: updatedImages,
                      },
                    };
                  }
                  return node;
                })
              );
            }}
            onPreviewImage={handleImagePreview}
            selected={selected}
          />

          <NodeLinks
            links={links}
            onUpdateLinks={(updatedLinks) => {
              data.url = updatedLinks;
              // 親コンポーネントの状態を更新
              setNodes((nds) =>
                nds.map((node) => {
                  if (node.id === id) {
                    return {
                      ...node,
                      data: {
                        ...node.data,
                        url: updatedLinks,
                      },
                    };
                  }
                  return node;
                })
              );
            }}
            selected={selected}
          />
        </Box>
        {id !== "1" && <Handle type="target" position={Position.Left} />}
        <Handle type="source" position={Position.Right} />
      </Paper>

      <LinkModal
        isLinkModalOpen={isLinkModalOpen}
        setIsLinkModalOpen={setIsLinkModalOpen}
        links={links}
        setLinks={(updatedLinks) => {
          data.url = updatedLinks;
          setNodes((nds) =>
            nds.map((node) => {
              if (node.id === id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    url: updatedLinks,
                  },
                };
              }
              return node;
            })
          );
        }}
        data={data}
      />
      <ImageModal
        isImageModalOpen={isImageModalOpen}
        setIsImageModalOpen={setIsImageModalOpen}
        images={images}
        setImages={(updatedImages) => {
          data.images = updatedImages;
          setNodes((nds) =>
            nds.map((node) => {
              if (node.id === id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    images: updatedImages,
                  },
                };
              }
              return node;
            })
          );
        }}
        data={data}
      />
      <PreviewImageModal
        src={images[selectedImageIndex || 0]}
        alt="image"
        open={openImagePreview}
        onClose={() => setOpenImagePreview(false)}
      />
    </>
  );
};

export default memo(CustomNode);

import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { motion } from "framer-motion";
import FadeInSection from "../../../../components/common/FadeInSection";
import { MindMapProvider } from "../../../../context/MindmapContext";
import { Images } from "../../../../assets";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import Browsing from "../components/Browsing";
import Editor from "../components/Editor";
import MindMap from "../components/MindMap";
import Misc from "../components/Misc";
import Seamless from "../components/Seamless";
import WritingAI from "../components/WritingAI";
import { alpha, Theme } from "@mui/material/styles";

interface FeatureSectionProps {
  backgroundColor: string | ((theme: Theme) => string);
  innerBackgroundColor: string | ((theme: Theme) => string);
  component: React.ReactNode;
  additionalSx?: Record<string, unknown>;
  backgroundImage?: string;
}

const FeaturesSection = () => {
  // アニメーションバリアント
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  // 標準的なセクションをレンダリングする関数
  const renderSection = ({
    backgroundColor,
    innerBackgroundColor,
    component,
    additionalSx = {},
    backgroundImage,
  }: FeatureSectionProps) => {
    return (
      <ColumnCenteredBox
        sx={(theme) => ({
          backgroundColor: typeof backgroundColor === "function" ? backgroundColor(theme) : backgroundColor,
          ...(backgroundImage && {
            position: "relative",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              // filter: "blur(5px)",
              opacity: 0.6,
              transform: "scale(1.1)", // スケールを少し大きくしてぼかし時の端のずれを防ぐ
            },
            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              // backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 1,
            },
          }),
        })}
      >
        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
          >
            <Grid container spacing={0} my={8}>
              <Grid item xs={12} md={12}>
                <motion.div variants={itemVariants}>
                  <FadeInSection>
                    <ColumnCenteredBox
                      sx={(theme) => ({
                        backgroundColor:
                          typeof innerBackgroundColor === "function"
                            ? innerBackgroundColor(theme)
                            : innerBackgroundColor,
                        borderRadius: 2,
                        ...additionalSx,
                      })}
                    >
                      {component}
                    </ColumnCenteredBox>
                  </FadeInSection>
                </motion.div>
              </Grid>
            </Grid>
          </motion.div>
        </Container>
      </ColumnCenteredBox>
    );
  };

  return (
    <>
      <ColumnCenteredBox
        sx={{
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${Images.common.bg01})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: 0.8,
            zIndex: 0,
          },
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
          >
            <Box
              sx={{
                borderRadius: 2,
                my: 8,
                p: 4,
                position: "relative",
                zIndex: 1,
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <motion.div variants={itemVariants}>
                    <FadeInSection>
                      <ColumnCenteredBox
                        sx={{
                          borderRadius: 2,
                          overflow: "hidden",
                          mb: 2,
                        }}
                      >
                        <Browsing />
                      </ColumnCenteredBox>
                    </FadeInSection>
                  </motion.div>
                  <motion.div variants={itemVariants}>
                    <FadeInSection>
                      <ColumnCenteredBox
                        sx={{
                          borderRadius: 2,
                          overflow: "hidden",
                        }}
                      >
                        <Editor />
                      </ColumnCenteredBox>
                    </FadeInSection>
                  </motion.div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <motion.div variants={itemVariants} style={{ height: "100%" }}>
                    <FadeInSection>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "100%",
                          backgroundImage: `url(${Images.top.astronaut})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: 2,
                        }}
                      />
                    </FadeInSection>
                  </motion.div>
                </Grid>
              </Grid>
            </Box>
          </motion.div>
        </Container>
      </ColumnCenteredBox>

      {/* Juno */}
      {renderSection({
        backgroundColor: (theme) => alpha(theme.palette.secondary.contrastText, 0.9),
        innerBackgroundColor: "",
        component: <Seamless />,
        backgroundImage: Images.juno.about.top,
      })}

      {/* マインドマップ */}
      {renderSection({
        backgroundColor: (theme) => theme.palette.background.paper,
        innerBackgroundColor: (theme) => theme.palette.background.paper,
        component: (
          <MindMapProvider>
            <MindMap />
          </MindMapProvider>
        ),
      })}

      {/* ライティングAI */}
      {renderSection({
        backgroundColor: "",
        innerBackgroundColor: "",
        component: <WritingAI />,
        backgroundImage: Images.common.bg01,
      })}

      {/* その他のAI */}
      {renderSection({
        backgroundColor: (theme) => theme.palette.background.paper,
        innerBackgroundColor: (theme) => alpha(theme.palette.secondary.contrastText, 0.9),
        component: (
          <>
            <Misc />
            <Box flexGrow={1} />
          </>
        ),
        additionalSx: { height: "100%" },
      })}
    </>
  );
};

export default FeaturesSection;

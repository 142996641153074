import { Fade, Hidden } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { AvatarProvider } from "../../context/AvatarContext";
import { EventProvider } from "../../context/EventContext";
import { LoginModalProvider } from "../../context/LoginModalContext";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { NoticeProvider } from "../../context/NoticeContext";
import store from "../../redux/store";
import MainRouter from "../../routes/MainRouter";
import { Images } from "../../assets";
import Notice from "../common/Notice";
import ScrollToTopButton from "../common/ScrollToTopButton";
import DrawerMain from "./Drawer/DrawerMain";
import Footer from "./Footer";
import HamburgerMenu from "./Header/HamburgerMenu";
import Header from "./Header/Header";

const Layout = () => {
  return (
    <>
      <LoginModalProvider>
        <AvatarProvider>
          <EventProvider>
            <NoticeProvider>
              <Provider store={store}>
                <Header />
                <Contents />
                <Notice />
              </Provider>
            </NoticeProvider>
          </EventProvider>
        </AvatarProvider>
      </LoginModalProvider>
    </>
  );
};
export default Layout;

const Contents = () => {
  const { isLoggedIn, checkLoginStatus } = useLoginStatus();

  // ログイン状態の初期化
  useEffect(() => {
    let mounted = true;

    const initialize = async () => {
      try {
        await checkLoginStatus();
        // コンポーネントがアンマウントされていたら処理を中止
        if (!mounted) return;
      } catch (error) {
        console.error("Failed to initialize:", error);
      }
    };

    initialize();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <>
        {isLoggedIn ? (
          <Fade in={true} timeout={1500}>
            <Box flexGrow={1}>
              <DrawerMain />
              <StyledDiv id={"main"}>
                <MainRouter />
                <Hidden smDown>
                  <ScrollToTopButton />
                </Hidden>
              </StyledDiv>
            </Box>
          </Fade>
        ) : (
          <Fade in={true} timeout={1500}>
            <div>
              <Hidden lgUp>
                <HamburgerMenu />
              </Hidden>
              <StyledDiv id={"main"}>
                <MainRouter />
                <ScrollToTopButton />
              </StyledDiv>
            </div>
          </Fade>
        )}
      </>
      <Box
        component="footer"
        sx={{
          width: "100%",
        }}
      >
        <Footer />
      </Box>
    </>
  );
};

const StyledDiv = styled("div")(({ theme }) => ({
  transition: theme.transitions.create(["margin", "transform", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  width: "100vw",
  backgroundImage: `url(${Images.common.bg01})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "100vh",
}));

import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useLoginStatus } from "../context/LoginStatusContext";

// チェックアウトセッションの型定義
export interface CheckoutSession {
  id: string;
  created: number;
  created_formatted: string;
  amount_total: number;
  amount_formatted: string;
  currency: string;
  checkout_type: string;
  description: string;
  product_id?: string;
  credit_amount?: string;
  before_plan?: string;
  new_price_id?: string;
}

const useSubscription = () => {
  const [isFree, setIsFree] = useState(true);
  const [checkoutSessions, setCheckoutSessions] = useState<CheckoutSession[]>([]);
  const [isLoadingCheckoutSessions, setIsLoadingCheckoutSessions] = useState(false);
  const { isLoggedIn } = useLoginStatus();

  const getSubscription = async () => {
    try {
      const csrftoken = Cookies.get("csrftoken");
      const config = { headers: { "X-CSRFToken": csrftoken } };
      const res = await axios.get("/api/v1/users/plan", config);
      return res.data;
    } catch (error) {
      console.error("Failed to obtain customer:", error);
      return undefined;
    }
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    const checkPlan = async () => {
      const res = await getSubscription();
      setIsFree(res.subscription?.lookup_key === "free");
    };
    checkPlan();
  }, []);

  const handleCreatePortal = async () => {
    try {
      const csrftoken = Cookies.get("csrftoken");
      const config = { headers: { "X-CSRFToken": csrftoken } };
      const res = await axios.get("/api/v1/users/stripe-portal", config);
      window.location.href = res.data.url;
    } catch (error) {
      console.error("Failed to cancel subscription:", error);
    }
  };

  // チェックアウト履歴を取得する
  const getCheckoutHistory = async () => {
    try {
      setIsLoadingCheckoutSessions(true);
      const csrftoken = Cookies.get("csrftoken");
      const config = { headers: { "X-CSRFToken": csrftoken } };
      const res = await axios.get("/api/v1/users/checkout-history", config);
      setCheckoutSessions(res.data.checkout_sessions);
      return res.data.checkout_sessions;
    } catch (error) {
      console.error("Failed to get checkout history:", error);
      return [];
    } finally {
      setIsLoadingCheckoutSessions(false);
    }
  };

  // 領収書または請求書のURLを取得する
  const getCheckoutDocument = async (sessionId: string, documentType: "receipt" | "invoice") => {
    try {
      const csrftoken = Cookies.get("csrftoken");
      const config = { headers: { "X-CSRFToken": csrftoken } };
      const res = await axios.get(
        `/api/v1/users/checkout-document?session_id=${sessionId}&type=${documentType}`,
        config
      );
      return res.data.url;
    } catch (error) {
      console.error(`Failed to get ${documentType}:`, error);
      return null;
    }
  };

  // 領収書を開く
  const openReceipt = async (sessionId: string) => {
    const url = await getCheckoutDocument(sessionId, "receipt");
    if (url) {
      window.open(url, "_blank");
    }
  };

  // 請求書を開く
  const openInvoice = async (sessionId: string) => {
    const url = await getCheckoutDocument(sessionId, "invoice");
    if (url) {
      window.open(url, "_blank");
    }
  };

  return {
    isFree,
    getSubscription,
    handleCreatePortal,
    checkoutSessions,
    isLoadingCheckoutSessions,
    getCheckoutHistory,
    openReceipt,
    openInvoice,
  };
};

export default useSubscription;

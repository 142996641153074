import { Box, TextField, Typography } from "@mui/material";
import React from "react";

interface ContentInputProps {
  content: string;
  onContentChange: (content: string) => void;
  maxLength?: number;
}

export const ContentInput: React.FC<ContentInputProps> = ({ content, onContentChange, maxLength = 1000 }) => {
  return (
    <Box>
      <TextField
        label="Content"
        fullWidth
        multiline
        value={content}
        onChange={(e) => onContentChange(e.target.value)}
        autoFocus
        inputProps={{ maxLength }}
        minRows={5}
        maxRows={20}
        variant="filled"
      />
      <Typography
        variant="caption"
        sx={{
          mt: 1,
          justifyContent: "flex-end",
          display: "flex",
          mb: 2,
        }}
      >
        {content.length}/ {maxLength}
      </Typography>
    </Box>
  );
};

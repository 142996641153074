import { Box, CircularProgress, Fade, Grid, Grow, Paper, SelectChangeEvent, Stack } from "@mui/material";
import Cookies from "js-cookie";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../redux/store";
import { useAlert } from "../../../../context/AlertContext";
import { useLoginStatus } from "../../../../context/LoginStatusContext";
import { useCheckCredit } from "../../../../hooks/useCreditCheck";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import { GradientTypography } from "../../../../utils/gradientTypography";
import { ColumnCenteredBox, RowBox } from "../../../../utils/styledBox";
import { getFileDateName } from "../../../../utils/utils";
import { ContentInput } from "./ContentInput";
import DescriptionModal from "./DescriptionModal";
import { GenerateButton } from "./GenerateButton";
import { ProviderSelector } from "./ProviderSelector/ProviderSelector";
import { PitchControl } from "./VoiceControls/PitchControl";
import { SpeedControl } from "./VoiceControls/SpeedControl";
import { VoiceSelector } from "./VoiceControls/VoiceSelector";
import { LanguageOption, Provider, VoiceData, VoiceOption } from "../types";
import { GraphicEq as GraphicEqIcon } from "@mui/icons-material";
import { alpha } from "@mui/material/styles";
import { setVoiceGeneratorText } from "../../../../redux/slices/fileSlice";

interface GeneratorProps {
  generationCompleted: boolean;
  setGenerationCompleted: Dispatch<SetStateAction<boolean>>;
}

export default function Generator({ setGenerationCompleted }: GeneratorProps) {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const [processing, setProcessing] = useState(false);
  const [content, setContent] = useState("");
  const [language, setLanguage] = useState("");
  const [voiceName, setVoiceName] = useState("");
  const [speed, setSpeed] = useState(1);
  const [pitch, setPitch] = useState(0);
  const [languageList, setLanguageList] = useState<LanguageOption[]>([]);
  const [googleVoiceList, setGoogleVoiceList] = useState<VoiceOption[]>([]);
  const [openAIVoiceList, setOpenAIVoiceList] = useState<VoiceOption[]>([]);
  const { user } = useLoginStatus();
  const [open, setOpen] = useState(false);
  const [voiceType, setVoiceType] = useState("");
  const [gender, setGender] = useState("");
  const [voiceIndexNumber, setVoiceIndexNumber] = useState(0);
  const [provider, setProvider] = useState<Provider>("openai");
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const voiceGeneratorText = useSelector((state: RootState) => state.file.voiceGeneratorText);
  const [loading, setLoading] = useState(true);
  const [openaiInstructions, setOpenaiInstructions] = useState("");

  const csrftoken = Cookies.get("csrftoken");
  const headers = new Headers({
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken!,
  });

  useEffect(() => {
    if (voiceGeneratorText) {
      setContent(voiceGeneratorText);
      dispatch(setVoiceGeneratorText(""));
    }
  }, [voiceGeneratorText]);

  const fetchJson = async (url: string, method: string, body: unknown = {}) => {
    try {
      const options: RequestInit = {
        method,
        headers,
      };
      if (method !== "GET") {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchVoiceNames = () =>
    fetchJson("/api/v1/voice-generator/list-voices", "POST", {
      provider: provider,
      language_code: language,
    });
  const fetchLanguageList = () => fetchJson("/api/v1/voice-generator/list-languages", "GET");

  // Generate Voice
  // Extracted method for generating downloadable link
  const downloadBlobAsMP3 = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `voice-${getFileDateName()}.mp3`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const generateVoice = async () => {
    try {
      if (!(await checkCredit())) return;
      setProcessing(true);

      let voiceData: VoiceData = {
        text: content,
        voice_name: voiceName,
        speed: speed,
        provider: provider,
      };

      if (provider === "google") {
        voiceData = {
          ...voiceData,
          language_code: language,
          gender: gender,
          pitch: pitch,
          number: voiceIndexNumber,
          type: voiceType,
        };
      }

      if (provider === "openai" && openaiInstructions) {
        voiceData = {
          ...voiceData,
          instructions: openaiInstructions,
        };
      }

      const json = await fetchJson("/api/v1/voice-generator/generate", "POST", voiceData);

      if (!json.success) {
        throw new Error();
      }
      const generated_voice_uuid = await json.data;

      // Download generated voice
      const downloadResponse = await fetch(`/api/v1/voice-generator/download/${generated_voice_uuid}`, {
        method: "GET",
      });
      if (!downloadResponse.ok) {
        throw new Error();
      }
      const blob = await downloadResponse.blob();
      downloadBlobAsMP3(blob);
      dispatch(setCreditTrigger(true));
      setAlert("success", t("voice.message.success"));
      // 生成完了を通知
      setGenerationCompleted((prev) => !prev);
    } catch (error) {
      setAlert("error", t("common.error.somethingWentWrong"));
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    fetchLanguageList().then((json) => {
      setLanguageList(json.data);
      // languageListが更新された後に言語を設定
      if (user?.ai_language === "ja") {
        setLanguage("ja-JP");
      } else {
        setLanguage("en-US");
      }
    });
  }, []);

  useEffect(() => {
    const updateVoiceData = async () => {
      if (!language) return;

      setLoading(true);
      try {
        const response = await fetchVoiceNames();
        const voiceData = response.data;

        if (provider === "openai") {
          setOpenAIVoiceList(voiceData);
          // ボイスリストの長さが変わったため、最初の要素を使用
          setVoiceName(voiceData[0]?.value || "");
          setVoiceType(voiceData[0]?.type || "");
          setVoiceIndexNumber(voiceData[0]?.voice_number || 0);
        } else {
          setGoogleVoiceList(voiceData);
          setVoiceName(voiceData[0]?.value || "");
          setGender(voiceData[0]?.gender || "");
          setVoiceType(voiceData[0]?.type || "");
          setVoiceIndexNumber(voiceData[0]?.voice_number || 0);
        }
      } catch (error) {
        console.error("Failed to fetch voice data:", error);
      } finally {
        setLoading(false);
      }
    };

    updateVoiceData();
  }, [provider, language]);

  const handleVoiceNameChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setVoiceName(value);
    const voiceList = provider === "openai" ? openAIVoiceList : googleVoiceList;
    const selectedVoice = voiceList.find((item) => item.value === value);
    if (selectedVoice) {
      setVoiceType(selectedVoice.type);
      setGender(selectedVoice.gender);
      setVoiceIndexNumber(selectedVoice.voice_number);
    }
  };

  return (
    <>
      <Grow in={true}>
        <Paper
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.5),
            backdropFilter: "blur(10px)",
            display: "flex",
            height: "100%",
            alignItems: "flex-start",
          }}
        >
          <ColumnCenteredBox
            sx={{
              position: "relative",
              p: { xs: 2, md: 4 },
              width: "100%",
            }}
          >
            <RowBox sx={{ justifyContent: "space-between", width: "100%", mb: 4 }}>
              <RowBox sx={{ alignItems: "center", justifyContent: "flex-start" }}>
                <GraphicEqIcon sx={{ mr: 1, color: "white" }} />
                <GradientTypography variant={"h5"} component={"h1"}>
                  {t("voice.title")}
                </GradientTypography>
              </RowBox>
            </RowBox>

            <Grid container spacing={4} sx={{ height: "auto" }}>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  flexDirection: "column",
                }}
              >
                <Box mb={4}>
                  <ProviderSelector provider={provider} onProviderChange={(p) => setProvider(p)} />
                </Box>

                <Box
                  sx={{
                    display: "block",
                    m: "auto",
                    height: 260,
                    width: "100%",
                  }}
                >
                  <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
                    <Fade
                      in={loading}
                      timeout={300}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        zIndex: loading ? 2 : 1,
                      }}
                      unmountOnExit
                    >
                      <ColumnCenteredBox sx={{ height: "100%" }}>
                        <CircularProgress size={24} />
                      </ColumnCenteredBox>
                    </Fade>
                    <Fade
                      in={!loading}
                      timeout={500}
                      style={{
                        opacity: loading ? 0 : 1,
                        position: "absolute",
                        width: "100%",
                      }}
                    >
                      <Stack spacing={2}>
                        <VoiceSelector
                          provider={provider}
                          voiceName={voiceName}
                          voiceList={provider === "openai" ? openAIVoiceList : googleVoiceList}
                          languageList={languageList}
                          language={language}
                          onLanguageChange={(value) => setLanguage(value)}
                          openaiInstructions={openaiInstructions}
                          onVoiceNameChange={handleVoiceNameChange}
                          onInstructionsChange={setOpenaiInstructions}
                          onInfoClick={() => setOpen(true)}
                        />

                        <SpeedControl speed={speed} onSpeedChange={setSpeed} />

                        {provider === "google" && <PitchControl pitch={pitch} onPitchChange={setPitch} />}
                      </Stack>
                    </Fade>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ height: "auto" }}>
                <ContentInput content={content} onContentChange={setContent} />
                <GenerateButton
                  processing={processing}
                  disabled={content.trim().length === 0}
                  onGenerate={generateVoice}
                  onGenerationComplete={() => setGenerationCompleted((prev) => !prev)}
                />
              </Grid>
            </Grid>
          </ColumnCenteredBox>
          <Box flexGrow={1} />
        </Paper>
      </Grow>
      <DescriptionModal open={open} setOpen={setOpen} provider={provider} />
    </>
  );
}

import { FilterList as FilterListIcon, Search as SearchIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import { Search, SearchIconWrapper, StyledInputBase } from "../../../../utils/searchBox";
import FilterMenu from "./FilterMenu";

type SearchBoxProps = {
  onSearch: (query: string) => void;
};

const SearchBox = ({ onSearch }: SearchBoxProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (value: string) => {
    onSearch(value);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search Images…"
          inputProps={{ "aria-label": "search" }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.nativeEvent.isComposing) {
              handleSearch((e.target as HTMLInputElement).value);
            }
          }}
        />
        <IconButton
          onClick={handleFilterClick}
          sx={{
            position: "absolute",
            right: 8,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <FilterListIcon />
        </IconButton>
      </Search>
      <FilterMenu anchorEl={anchorEl} onClose={handleFilterClose} />
    </Box>
  );
};

export default SearchBox;

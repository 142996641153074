const notesNebula = {
  title: "Notes Nebula",
  bookmarks: "ブックマーク",
  description: "AIエディターで簡単に記事を投稿するためのプラットフォーム",
  message: "学んだ知識、見つけた情報、ノーツネビュラで世界に共有しよう。",
  post: "記事を投稿",
  latest: "最新の記事",
  popular: "人気の記事",
  all: "人気・新着の記事",
  official: "Tenak AI / Notes Nebula 公式記事",
  popularDescription: "Tenak AIを使って投稿された人気の記事を見る",
  readMore: "もっと読む",
  article: {
    like: "いいね",
    bookmark: "ブックマーク",
    comment: "コメント",
    comments: "コメント",
    x: "Xでシェア",
    facebook: "Facebookでシェア",
    hatena: "はてなブックマーク",
    whatsapp: "WhatsAppでシェア",
    line: "LINEでシェア",
    toc: "目次",
    author: "著者",
    status: {
      public: "この記事は公開されています。\nこのメッセージはあなた以外に表示されません。",
      private: "この記事は非公開です。\nこのメッセージはあなた以外に表示されません。",
      shared: "この記事はURLを知っているユーザーにのみ公開されています。\nこのメッセージはあなた以外に表示されません。",
      blocked: "この記事はブロックされています。\n",
    },
    edit: "編集",
    block: "この記事をブロック",
    blockConfirm: "この記事をブロックしますか？",
    blockConfirmDescription: "この操作は取り消すことができません。",
    blockSuccess: "記事がブロックされました",
    blockError: "ブロックに失敗しました",
    about: "この記事について",
    description:
      "この記事は、無料でご利用いただける記事投稿サービス「Notes Nebula」を通じて公開されています。Notes Nebulaでは、最新のAI技術を搭載したテキストエディター「Tenak AI」を活用して、記事の作成が可能です。AI技術を使用していることから、情報の正確性については慎重な確認が必要です。",
    goToTenak: "Tenak AI で記事を書く",
  },
  comments: {
    title: "コメント",
    placeholder: "コメントを書く...",
    submit: "投稿",
    edit: "編集",
    delete: "削除",
    like: "いいね",
    unlike: "いいね取り消し",
    hide: "非表示",
    show: "表示",
    hiddenMessage: "このコメントはあなたとコメント投稿者以外に表示されません。",
    edited: "編集済み",
    noComments: "まだコメントはありません。最初のコメントを投稿しましょう！",
  },
};
export default notesNebula;

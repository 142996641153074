import { Close as CloseIcon } from "@mui/icons-material";
import { Box, Button, IconButton, Modal, Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAnnouncement } from "../../context/AnnouncementContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { GradientTypography } from "../../utils/gradientTypography";
import { RowBox, RowCenteredBox } from "../../utils/styledBox";
const LoginAnnouncement: React.FC = () => {
  const { showModal, currentAnnouncement, closeModal } = useAnnouncement();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();

  if (!currentAnnouncement) {
    return null;
  }

  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      aria-labelledby="announcement-modal-title"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          position: "relative",
          width: "90%",
          maxWidth: 1000,
          maxHeight: "90vh",
          pt: 4,
          pb: 2,
          bgcolor: (theme) => theme.palette.background.paper + "D9",
          backdropFilter: "blur(5px)",
        }}
      >
        <RowBox gap={3} sx={{ px: 3.5 }} alignItems="center">
          <GradientTypography variant="h5" component="h2" id="announcement-modal-title" gutterBottom>
            {currentAnnouncement.title}
          </GradientTypography>
        </RowBox>
        <Box sx={{ height: 1, overflow: "auto", maxHeight: "calc(90vh - 200px)", mb: 2 }}>
          <IconButton
            onClick={closeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ mb: 2, overflow: "auto", borderRadius: 2 }}>
            <Box
              sx={{
                mt: 2,
                mb: 4,
                px: 4,
                "& img": {
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderRadius: "8px",
                },
                "& figure": {
                  margin: 0,
                },
              }}
              dangerouslySetInnerHTML={{ __html: currentAnnouncement.content }}
            />
          </Box>
        </Box>
        <RowCenteredBox gap={1}>
          <Button variant="outlined" onClick={() => navigate("/announcements")} sx={{ width: 160 }} disableElevation>
            {t("announcement.title")}
          </Button>
          <Button variant="contained" onClick={closeModal} sx={{ width: 160 }} disableElevation>
            OK
          </Button>
        </RowCenteredBox>
      </Paper>
    </Modal>
  );
};

export default LoginAnnouncement;

import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";

export const MarkdownContentBox = ({ children }: { children: string }) => {
  // コピー時のイベントハンドラを追加
  const handleCopy = (e: React.ClipboardEvent) => {
    // デフォルトのコピー動作を防止
    e.preventDefault();

    // 選択されたテキストを取得
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;

    // 選択範囲のHTMLを取得
    const range = selection.getRangeAt(0);
    const fragment = range.cloneContents();

    // 一時的な要素を作成
    const div = document.createElement("div");
    div.appendChild(fragment);

    // スタイルをリセットし、文字色だけ黒に設定
    const resetStyles = (element: Element) => {
      if (element instanceof HTMLElement) {
        // 文字色だけ黒に設定
        element.style.color = "#000000";
      }

      // 子要素にも同じ処理を適用
      Array.from(element.children).forEach((child) => {
        resetStyles(child);
      });
    };

    // スタイルをリセット
    resetStyles(div);

    // HTMLとプレーンテキストの両方をクリップボードに設定
    e.clipboardData.setData("text/html", div.innerHTML);
    e.clipboardData.setData("text/plain", selection.toString());
  };
  const theme = useTheme();
  const colorCodeRegex = /#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})\b/g;

  const renderTextWithColorPreview = (text: string): (string | JSX.Element)[] => {
    const parts: (string | JSX.Element)[] = [];
    let lastEnd = 0;

    text.replace(colorCodeRegex, (match, p1, offset) => {
      parts.push(text.slice(lastEnd, offset));
      parts.push(
        <span key={offset} style={{ display: "inline-flex", alignItems: "center" }}>
          {match}
          <span
            style={{
              display: "inline-block",
              width: "16px",
              height: "16px",
              backgroundColor: match,
              marginLeft: "4px",
              border: "1px solid #ddd",
              borderRadius: "50%",
            }}
          ></span>
        </span>
      );
      lastEnd = offset + match.length;
      return match; // 返り値は使用されないが、型エラーを避けるために必要
    });

    // 最後のテキスト片を追加
    parts.push(text.slice(lastEnd));
    return parts;
  };

  interface CodeBlockProps {
    language: string;
    value: string;
  }

  const CodeBlock: React.FC<CodeBlockProps> = ({ language, value }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = () => {
      setIsCopied(true);
    };

    useEffect(() => {
      if (isCopied) {
        const timer = setTimeout(() => setIsCopied(false), 2000);
        return () => clearTimeout(timer);
      }
    }, [isCopied]);

    return (
      <Box>
        <Box display={"flex"} flexDirection={"column"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            sx={{
              backgroundColor: theme.palette.grey[900],
              mb: -1,
              py: 0.5,
              px: 2,
              borderRadius: "4px 4px 0 0",
            }}
          >
            <Box justifyContent={"flex-start"}>
              <Typography variant={"body2"}>{language}</Typography>
            </Box>
            <Box flexGrow={1} />
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              sx={{
                backgroundColor: theme.palette.grey[900],
              }}
            >
              <CopyToClipboard text={value} onCopy={handleCopyClick}>
                <IconButton size="small">
                  {isCopied ? <CheckIcon fontSize="inherit" /> : <ContentCopyIcon fontSize="inherit" />}
                </IconButton>
              </CopyToClipboard>
            </Box>
          </Box>
          <Box sx={{ overflow: "auto", fontSize: "0.9rem" }}>
            <SyntaxHighlighter
              style={oneDark}
              language={language}
              customStyle={{
                borderRadius: "0 0 4px 4px",
              }}
            >
              {value}
            </SyntaxHighlighter>
          </Box>
        </Box>
      </Box>
    );
  };

  const CustomMarkdown = {
    code({
      inline,
      className,
      children,
      ...props
    }: {
      inline?: boolean;
      className?: string;
      children: React.ReactNode;
      [key: string]: unknown;
    }) {
      const match = /language-(\w+)/.exec(className || "");
      const codeString = String(children).replace(/\n$/, "");

      return !inline && match ? (
        <CodeBlock language={match[1]} value={codeString} />
      ) : (
        <code
          className={className}
          style={
            inline
              ? {
                  overflow: "auto",
                  backgroundColor: theme.palette.grey[800],
                  padding: "2px 8px",
                  margin: "0 4px",
                  borderRadius: "4px",
                  fontWeight: 500,
                  lineHeight: 1.8,
                }
              : {}
          }
          {...props}
        >
          {children}
        </code>
      );
    },
    a({ href, children, ...props }: { href?: string; children: React.ReactNode; [key: string]: unknown }) {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
          {children}
        </a>
      );
    },
    text: ({ children }: { children: React.ReactNode }) => {
      // children が string 型であることを確認
      if (typeof children === "string") {
        // children が string の場合、renderTextWithColorPreview を呼び出し
        return <span>{renderTextWithColorPreview(children)}</span>;
      } else {
        // children が string でない場合、children をそのままレンダリング
        return <span>{children}</span>;
      }
    },
  };

  // Markdownのスタイルを定義
  const StyledMarkdown = styled("div")(({ theme }) => ({
    a: {
      color: theme.palette.primary.main,
      target: "_blank",
      rel: "noopener noreferrer",
    },
    th: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.grey[100],
      textAlign: "left",
      border: "1px solid " + theme.palette.grey[800],
      "&:first-of-type": {
        borderTopLeftRadius: "5px",
      },
      "&:last-child": {
        borderTopRightRadius: "5px",
      },
    },
    td: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      border: `1px solid ${theme.palette.grey[800]}`,
    },
    "tr:nth-of-type(even)": {
      backgroundColor: "#262626",
    },
    "tr:nth-of-type(odd)": {},
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
      border: "1px solid " + theme.palette.grey[800],
      borderRadius: "5px",
    },
    "tr:last-child td": {
      "&:first-of-type": {
        borderBottomLeftRadius: "5px",
      },
      "&:last-child": {
        borderBottomRightRadius: "5px",
      },
    },
    "li::marker": {
      color: theme.palette.grey[600],
    },
    hr: {
      border: "none",
      height: "1px",
      backgroundColor: theme.palette.grey[700],
      margin: `${theme.spacing(2)} 0`,
    },
  }));

  // 横棒をMarkdownのHR記法に変換
  const convertDashesToHr = (text: string): string => {
    return text.replace(/^[─]{3,}$/gm, "---");
  };

  return (
    <StyledMarkdown onCopy={handleCopy}>
      <ReactMarkdown remarkPlugins={[remarkGfm]} components={CustomMarkdown}>
        {convertDashesToHr(children)}
      </ReactMarkdown>
    </StyledMarkdown>
  );
};
